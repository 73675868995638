<template>
  <v-tab-item :value="tabName">
    <tab-card
      :button-label="buttonLabel"
      :callback="callback"
      :csrf="csrf"
      :errors="errors"
      :estimate-id="estimateId"
      :kentem-id="kentemId"
      :msg="msg"
    >
      <slot />
    </tab-card>
  </v-tab-item>
</template>

<script>
import TabCard from "./tab-card.vue";

export default {
  components: {
    TabCard,
  },
  props: {
    buttonLabel: {
      type: String,
      required: false,
      default: "Register",
    },
    callback: {
      type: Function,
      require: true,
      default: () => 1,
    },
    csrf: {
      type: String,
      required: true,
    },
    errors: {
      type: Object,
      default: () => {},
    },
    estimateId: {
      type: Number,
      default: null,
    },
    kentemId: {
      type: String,
      required: true,
    },
    msg: {
      type: String,
      default: null,
    },
    tabName: {
      type: String,
      required: true,
    },
  },
};
</script>
