<template>
  <div class="text-center">
    <v-progress-circular
      v-if="downloading"
      :indeterminate="progress === 0"
      :value="progress"
      color="light-blue"
    />
    <template v-if="progress === 100 && contents && contents.data">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card>
              <v-card-title class="subheading font-weight-bold">
                {{ contents.data.name }}
              </v-card-title>
              <v-divider />
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>商品コード:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ contents.data.kentem_product_code }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>商魂商品コード:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ contents.data.shokon_product_code }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>助数詞:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ contents.data.counter_word }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>商品区分:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ contents.data.classification }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>仕切率コード:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ contents.data.partition_rate_code }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>標準価格:</v-list-item-content>
                  <v-list-item-content
                    class="align-end"
                  >
                    {{ contents.data.regular_price | formatYen }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>商魂標準価格:</v-list-item-content>
                  <v-list-item-content
                    class="align-end"
                  >
                    {{ contents.data.shokon_regular_price | formatYen }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>商魂商品名:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ contents.data.shokon_product_name }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>品種コード:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ contents.data.oso_code }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>OSO商品名:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ contents.data.oso_product_name }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>仕切価格:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ contents.data.richo_price | formatYen }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>OSO標準価格:</v-list-item-content>
                  <v-list-item-content
                    class="align-end"
                  >
                    {{ contents.data.oso_regular_price | formatYen }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>連番:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ contents.data.serial_number }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>シリーズコード:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ contents.data.series_code }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>[単体 CKD 乗り換え]番号:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ contents.data.type_number }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>[ソフト 保守 など]番号:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ contents.data.software_classification }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>区分コード:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ contents.data.category }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="8"
            lg="9"
          >
            <v-container>
              <v-toolbar
                class="mb-2"
                color="indigo darken-5"
                dark
                flag
              >
                <v-toolbar-title>セット商品一覧</v-toolbar-title>
              </v-toolbar>
              <v-row>
                <v-col
                  v-for="set_item in contents.data.set_products"
                  :key="set_item.set_code"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="4"
                >
                  <v-card>
                    <v-card-title class="subheading font-weight-bold">
                      {{ set_item.name }}
                    </v-card-title>
                    <v-divider />
                    <v-list dense>
                      <v-list-item>
                        <v-list-item-content>セット商品コード:</v-list-item-content>
                        <v-list-item-content>{{ set_item.set_code }}</v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>枝番:</v-list-item-content>
                        <v-list-item-content>{{ set_item.branch }}</v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import Axios from "axios";
import Token from "./token";

export default {
  filters: {
    formatYen: (val) => {
      if (!val) {
        return "";
      }
      return val.toLocaleString() + "円";
    },
  },
  props: {
    kentemIdUrl: {
      type: String,
      default: "https://id.kentem.com",
    },
    productCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      bearer: "",
      contents: "",
      downloading: true,
      footerProps: {
        itemsPerPageOptions: [4],
      },
      itemsPerPage: 4,
      progress: 0,
      url: this.kentemIdUrl + "/api/v1/kentem_product/detail/",
      tokenUrl: "/api/v1/token",
    };
  },
  created: function () {
    this.url = this.url + this.productCode;
    this.getTokenAndLoadList();
  },

  methods: {
    /**
     * 
     */
    async loadList() {
      await Axios.get(this.url, {
        onDownloadProgress: this.onDownload,
        headers: {
          Authorization: this.bearer,
        },
      })
        .then((res) => {
          if (res.data) {
            this.contents = res.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.downloading = false;
      this.progress = 100;
    },
    /**
     * 
     * @param {Object} e 
     */
    onDownload(e) {
      this.progress = Math.floor((e.loaded * 100) / e.total);
    },
    /**
     * 
     */
    async getTokenAndLoadList() {
      this.bearer = await Token(this.tokenUrl);
      this.loadList();
    },
  },
};
</script>