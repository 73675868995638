<template>
  <div class="text-center">
    <v-progress-circular
      v-if="downloading"
      :indeterminate="progress === 0"
      :value="progress"
      color="light-blue"
    />
    <div class="alert alert-warning" role="alert" v-if="ksCloudDbStatus == 2">
      {{ ksCloudDbStatus | convertKsCloudDbStatusToText }}
    </div>
    <template v-if="progress === 100 && contents">
      <v-container>
        <v-card>
          <v-toolbar class="mb-2" color="indigo darken-5" dark flag>
            <v-toolbar-title
              >{{ contents.data.name }} ({{
                this.customerKanaFormValue
              }})</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn rounded color="secondary" dark @click="openNewEstimate()">
              見積新規作成
            </v-btn>
            <v-btn
              rounded
              color="secondary"
              dark
              @click="openUserDB(contents.data.shokon_code)"
            >
              ユーザーDB
            </v-btn>
            <v-btn
              rounded
              color="secondary"
              dark
              @click="openCloudDetail(contents.data.shokon_code)"
            >
              クラウド詳細
            </v-btn>
            <v-btn rounded color="secondary" dark @click="openOsc()">
              OSC
            </v-btn>
          </v-toolbar>
          <v-simple-table>
            <tbody>
              <tr v-for="r in basicRecords" :key="r.key">
                <td>{{ r.name }}</td>
                <td>{{ contents[r.parent][r.key] }}</td>
              </tr>
              <tr>
                <td>住所</td>
                <td>
                  〒{{ this.customerZipFormValue }} {{ contents.data.address }}
                </td>
              </tr>
              <tr>
                <td>電話 (FAX)</td>
                <td>
                  {{ this.customerTelFormValue }} ({{
                    this.customerFaxFormValue
                  }})
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-container>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            備考/キーコード備考/プロテクタ関連
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="pre-scrollable">
              <v-simple-table>
                <tbody v-if="contents.data.print_license">
                  <tr>
                    <td>備考</td>
                    <td>
                      {{ contents.data.print_license.data.note }}
                    </td>
                  </tr>
                  <tr>
                    <td>キーコード関連</td>
                    <td style="word-break: break-word">
                      {{ contents.data.print_license.data.keycode }}
                    </td>
                  </tr>
                  <tr>
                    <td>プロテクト関連</td>
                    <td style="word-break: break-word">
                      {{ contents.data.print_license.data.protect }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>出荷履歴</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="table-container">
              <table class="shipping-history-table">
                <thead>
                  <tr>
                    <th class="header-cell">ID</th>
                    <th class="header-cell">商品コード</th>
                    <th class="header-cell">ソフト名</th>
                    <th class="header-cell">発送区分</th>
                    <th class="header-cell">出荷日</th>
                    <th class="header-cell">出荷依頼者</th>
                    <th class="header-cell">販売店</th>
                    <th class="header-cell">備考</th>
                    <th class="header-cell">発送担当者</th>
                    <th class="header-cell">発送先</th>
                  </tr>
                </thead>
                <tbody v-if="contents.data.shipping_history">
                  <tr
                    v-for="s_item in contents.data.shipping_history.data"
                    :key="s_item.id"
                  >
                    <td>{{ s_item.userID }}</td>
                    <td>{{ s_item.item_code }}</td>
                    <td>{{ s_item.item_name }}</td>
                    <td>{{ s_item.shipping_classfication }}</td>
                    <td>{{ s_item.ship_date }}</td>
                    <td>{{ s_item.shipping_requester }}</td>
                    <td>{{ s_item.salse_shop }}</td>
                    <td>{{ s_item.dealer_remarks }}</td>
                    <td>{{ s_item.shipping_person }}</td>
                    <td>{{ s_item.shipping_address }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-container>
        <v-tabs>
          <v-tab
            v-for="(ref_contents, ref_num) in contents.data.reference_numbers"
            :key="ref_num"
          >
            {{ ref_num }}
          </v-tab>
          <v-tab-item
            v-for="(ref_contents, ref_num) in contents.data.reference_numbers"
            :key="ref_num"
          >
            <v-container>
              <v-tabs>
                <v-tab v-for="tab in tabs" :key="tab.name">
                  {{ tab.name }}
                </v-tab>
                <v-tab-item>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4" lg="3">
                        <v-card>
                          <v-toolbar
                            class="mb-2"
                            color="indigo darken-5"
                            dark
                            flag
                          >
                            <v-toolbar-title>デキスパート情報</v-toolbar-title>
                          </v-toolbar>
                          <v-simple-table>
                            <tbody v-if="ref_contents.dekispart">
                              <tr v-for="d in dekispartRecords" :key="d.key">
                                <td>{{ d.name }}</td>
                                <td>{{ ref_contents.dekispart[d.key] }}</td>
                              </tr>
                              <tr>
                                <td>会員種別</td>
                                <td
                                  v-if="
                                    ref_contents.dekispart.membership_type_name
                                  "
                                >
                                  {{
                                    setMembershipName(
                                      ref_contents.dekispart
                                        .membership_type_name,
                                      ref_contents.dekispart
                                        .old_membership_type_name
                                    )
                                  }}
                                </td>
                                <td v-else />
                              </tr>
                              <tr>
                                <td>更新方法</td>
                                <td
                                  v-if="
                                    ref_contents.dekispart.contract_start_date
                                  "
                                >
                                  {{ ref_contents.dekispart.deposit_type_name }}
                                </td>
                                <td v-else />
                              </tr>
                              <tr>
                                <td>契約残月</td>
                                <td
                                  v-if="
                                    ref_contents.dekispart.contract_start_date
                                  "
                                >
                                  {{
                                    ref_contents.dekispart
                                      .remaining_month_of_contract
                                      | formatRemainingMonths
                                  }}
                                </td>
                                <td v-else />
                              </tr>
                              <tr>
                                <td>業務委託費</td>
                                <td
                                  v-if="
                                    ref_contents.dekispart.contract_start_date
                                  "
                                >
                                  {{
                                    ref_contents.dekispart.subcontracting_cost
                                      | convertSubcontractingCostText
                                  }}
                                </td>
                                <td v-else />
                              </tr>
                              <tr>
                                <td>備考</td>
                                <td
                                  v-if="
                                    ref_contents.dekispart.contract_start_date
                                  "
                                >
                                  {{ ref_contents.dekispart.remarks_update }}
                                  {{ ref_contents.dekispart.remarks_plural }}
                                  {{
                                    ref_contents.dekispart
                                      .remarks_automatic_transfer
                                  }}
                                  {{ ref_contents.dekispart.remarks_others }}
                                </td>
                                <td v-else />
                              </tr>
                              <tr>
                                <td>販売店1</td>
                                <td
                                  v-if="
                                    ref_contents.dekispart.contract_start_date
                                  "
                                >
                                  {{ ref_contents.dekispart.dealership_code1 }}
                                  {{ ref_contents.dekispart.dealership_name1 }}
                                </td>
                                <td v-else />
                              </tr>
                              <tr>
                                <td>販売店2</td>
                                <td
                                  v-if="
                                    ref_contents.dekispart.contract_start_date
                                  "
                                >
                                  {{ ref_contents.dekispart.dealership_code2 }}
                                  {{ ref_contents.dekispart.dealership_name2 }}
                                </td>
                                <td v-else />
                              </tr>
                              <tr>
                                <td>加入状態</td>
                                <td
                                  v-if="
                                    ref_contents.dekispart.contract_start_date
                                  "
                                >
                                  {{
                                    ref_contents.dekispart.is_canceled
                                      | convertBooleanMaintenanceText
                                  }}
                                </td>
                                <td v-else>未加入</td>
                              </tr>
                              <tr>
                                <td>更新案内</td>
                                <td
                                  v-if="
                                    ref_contents.dekispart.contract_start_date
                                  "
                                >
                                  {{
                                    ref_contents.dekispart.is_updating
                                      | convertBooleanUpdatingText
                                  }}
                                </td>
                                <td v-else />
                              </tr>
                            </tbody>
                            <p v-else>情報が取得できませんでした。</p>
                          </v-simple-table>
                        </v-card>
                        <v-card
                          v-if="
                            contents.data.net_license &&
                            contents.data.net_license.data &&
                            contents.data.net_license.data.length > 0 &&
                            contents.data.net_license.data[0].nl_summary &&
                            contents.data.net_license.data[0].nl_summary
                              .length > 0
                          "
                        >
                          <v-toolbar
                            class="mb-2"
                            color="indigo darken-5"
                            dark
                            flag
                          >
                            <v-toolbar-title>NETライセンス</v-toolbar-title>
                          </v-toolbar>
                          <v-simple-table>
                            <thead>
                              <tr>
                                <th>ライセンス番号</th>
                                <th>ライセンス本数</th>
                                <th>使用PC台数</th>
                              </tr>
                            </thead>
                            <tbody
                              v-if="
                                contents.data.net_license &&
                                contents.data.net_license.data &&
                                contents.data.net_license.data.length > 0 &&
                                contents.data.net_license.data[0].nl_summary &&
                                contents.data.net_license.data[0].nl_summary
                                  .length > 0
                              "
                            >
                              <tr
                                v-for="s_item in contents.data.net_license
                                  .data[0].nl_summary"
                                :key="s_item.id"
                              >
                                <td>NL{{ s_item.product_num }}</td>
                                <td>{{ s_item.license }}</td>
                                <td>{{ s_item.pc_used }}</td>
                              </tr>
                            </tbody>

                            <p v-else>情報が取得できませんでした。</p>
                          </v-simple-table>
                        </v-card>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" lg="3">
                        <v-data-iterator
                          v-if="ref_contents.dekispart"
                          :items="ref_contents.dekispart.products"
                          hide-default-footer
                          :items-per-page="1000"
                        >
                          <template #header>
                            <v-toolbar
                              class="mb-2"
                              color="indigo darken-5"
                              dark
                              flag
                            >
                              <v-toolbar-title>
                                顧客所有商品一覧
                              </v-toolbar-title>
                            </v-toolbar>
                          </template>
                          <template #default="props">
                            <v-row>
                              <v-col
                                v-for="item in props.items"
                                :key="item.id"
                                cols="12"
                                sm="12"
                                md="12"
                                lg="12"
                              >
                                <v-card v-if="item.is_prin">
                                  <v-card-title
                                    class="subheading font-weight-bold"
                                  >
                                    {{ item.name }}
                                  </v-card-title>
                                </v-card>
                              </v-col>
                            </v-row>
                          </template>
                        </v-data-iterator>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
                <v-tab-item>
                  <v-tabs>
                    <v-tab v-for="tab in cloudTabs" :key="tab.name">
                      {{ tab.name }}
                    </v-tab>
                    <v-tab-item>
                      <v-container>
                        <v-card>
                          <v-container>
                            <v-data-iterator
                              :items="ref_contents.cloud"
                              hide-default-footer
                              :items-per-page="1000"
                            >
                              <template #header>
                                <v-toolbar
                                  class="mb-2"
                                  color="indigo darken-5"
                                  dark
                                  flag
                                >
                                  <v-toolbar-title>
                                    クラウド情報一覧
                                  </v-toolbar-title>
                                </v-toolbar>
                              </template>
                              <template #default="props">
                                <v-row>
                                  <v-col
                                    v-for="item in props.items"
                                    :key="item.id"
                                    cols="12"
                                    sm="6"
                                    md="4"
                                    lg="3"
                                  >
                                    <v-card>
                                      <v-card-title
                                        class="subheading font-weight-bold"
                                      >
                                        クラウドID:
                                        {{ item.management_code }}
                                      </v-card-title>
                                      <v-divider />
                                      <v-list dense>
                                        <v-list-item>
                                          <v-list-item-content>
                                            備考:
                                          </v-list-item-content>
                                          <v-list-item-content
                                            class="align-end"
                                          >
                                            {{ item.remarks_update }}
                                            {{ item.remarks_others }}
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                          <v-list-item-content>
                                            クラウド販売店:
                                          </v-list-item-content>
                                          <v-list-item-content
                                            class="align-end"
                                          >
                                            {{ item.dealership_code1 }}
                                            {{ item.dealership_name1 }}
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                          <v-list-item-content>
                                            快測AR販売店:
                                          </v-list-item-content>
                                          <v-list-item-content
                                            class="align-end"
                                          >
                                            {{
                                              item.kaisoku_ar_dealership_code1
                                            }}
                                            {{
                                              item.kaisoku_ar_dealership_name1
                                            }}
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                          <v-list-item-content>
                                            快測ナビ販売店:
                                          </v-list-item-content>
                                          <v-list-item-content
                                            class="align-end"
                                          >
                                            {{
                                              item.kaisoku_navi_dealership_code1
                                            }}
                                            {{
                                              item.kaisoku_navi_dealership_name1
                                            }}
                                          </v-list-item-content>
                                        </v-list-item>
                                      </v-list>
                                      <v-container>
                                        <v-card>
                                          <v-toolbar
                                            class="mb-2"
                                            color="indigo darken-5"
                                            dark
                                            flag
                                          >
                                            <v-toolbar-title>
                                              発送先情報
                                            </v-toolbar-title>
                                          </v-toolbar>
                                          <v-simple-table>
                                            <tbody>
                                              <tr
                                                v-for="c in cloudReceiverRecords"
                                                :key="c.key"
                                              >
                                                <td>{{ c.name }}</td>
                                                <td>
                                                  {{ item.receiver[c.key] }}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </v-simple-table>
                                        </v-card>
                                      </v-container>
                                      <v-container>
                                        <v-card>
                                          <v-toolbar
                                            class="mb-2"
                                            color="indigo darken-5"
                                            dark
                                            flag
                                          >
                                            <v-toolbar-title>
                                              データバンク情報
                                            </v-toolbar-title>
                                          </v-toolbar>
                                          <v-simple-table>
                                            <tbody>
                                              <tr
                                                v-for="c in cloudDatabankRecords"
                                                :key="c.key"
                                              >
                                                <td>{{ c.name }}</td>
                                                <td>
                                                  {{ item.databank[c.key] }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>契約残月</td>
                                                <td>
                                                  {{
                                                    item.databank
                                                      .remaining_month_of_contract
                                                      | formatRemainingMonths
                                                  }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>加入状態</td>
                                                <td
                                                  v-if="
                                                    item.databank
                                                      .contract_start_date
                                                  "
                                                >
                                                  {{
                                                    item.databank.is_canceled
                                                      | convertBooleanMaintenanceText
                                                  }}
                                                </td>
                                                <td v-else />
                                              </tr>
                                              <tr>
                                                <td>更新案内</td>
                                                <td>
                                                  {{
                                                    item.databank.is_updating
                                                      | convertBooleanUpdatingText
                                                  }}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </v-simple-table>
                                        </v-card>
                                      </v-container>
                                      <v-container>
                                        <v-card>
                                          <v-toolbar
                                            class="mb-2"
                                            color="indigo darken-5"
                                            dark
                                            flag
                                          >
                                            <v-toolbar-title>
                                              SiteBox情報
                                            </v-toolbar-title>
                                          </v-toolbar>
                                          <v-simple-table>
                                            <tbody>
                                              <tr
                                                v-for="c in cloudSiteboxRecords"
                                                :key="c.key"
                                              >
                                                <td>{{ c.name }}</td>
                                                <td>
                                                  {{ item.sitebox[c.key] }}
                                                </td>
                                              </tr>
                                              <tr
                                                v-for="c in cloudSiteboxBooleanRecords"
                                                :key="c.key"
                                              >
                                                <td>{{ c.name }}</td>
                                                <td>
                                                  {{
                                                    item.sitebox[c.key]
                                                      | convertBooleanText
                                                  }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>契約残月</td>
                                                <td>
                                                  {{
                                                    item.sitebox
                                                      .remaining_month_of_contract
                                                      | formatRemainingMonths
                                                  }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>加入状態</td>
                                                <td
                                                  v-if="
                                                    item.sitebox
                                                      .contract_start_date
                                                  "
                                                >
                                                  {{
                                                    item.sitebox.is_canceled
                                                      | convertBooleanMaintenanceText
                                                  }}
                                                </td>
                                                <td v-else />
                                              </tr>
                                              <tr>
                                                <td>更新案内</td>
                                                <td>
                                                  {{
                                                    item.sitebox.is_updating
                                                      | convertBooleanUpdatingText
                                                  }}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </v-simple-table>
                                        </v-card>
                                      </v-container>
                                      <v-container>
                                        <v-card>
                                          <v-toolbar
                                            class="mb-2"
                                            color="indigo darken-5"
                                            dark
                                            flag
                                          >
                                            <v-toolbar-title>
                                              SiteBox トンネル情報
                                            </v-toolbar-title>
                                          </v-toolbar>
                                          <v-simple-table>
                                            <tbody>
                                              <tr
                                                v-for="c in cloudSiteboxTunnelRecords"
                                                :key="c.key"
                                              >
                                                <td>{{ c.name }}</td>
                                                <td>
                                                  {{
                                                    item.sitebox_tunnel[c.key]
                                                  }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>契約残月</td>
                                                <td>
                                                  {{
                                                    item.sitebox_tunnel
                                                      .remaining_month_of_contract
                                                      | formatRemainingMonths
                                                  }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>加入状態</td>
                                                <td
                                                  v-if="
                                                    item.sitebox_tunnel
                                                      .contract_start_date
                                                  "
                                                >
                                                  {{
                                                    item.sitebox_tunnel
                                                      .is_canceled
                                                      | convertBooleanMaintenanceText
                                                  }}
                                                </td>
                                                <td v-else />
                                              </tr>
                                              <tr>
                                                <td>更新案内</td>
                                                <td
                                                  v-if="
                                                    item.sitebox_tunnel
                                                      .contract_start_date
                                                  "
                                                >
                                                  {{
                                                    item.sitebox_tunnel
                                                      .is_updating
                                                      | convertBooleanUpdatingText
                                                  }}
                                                </td>
                                                <td v-else />
                                              </tr>
                                            </tbody>
                                          </v-simple-table>
                                        </v-card>
                                      </v-container>
                                      <v-container>
                                        <v-card>
                                          <v-toolbar
                                            class="mb-2"
                                            color="indigo darken-5"
                                            dark
                                            flag
                                          >
                                            <v-toolbar-title>
                                              SiteBox配筋検査情報
                                            </v-toolbar-title>
                                          </v-toolbar>
                                          <v-simple-table>
                                            <tbody>
                                              <tr
                                                v-for="c in cloudSleeveBackingProcessOfReinfoceingBarRecords"
                                                :key="c.key"
                                              >
                                                <td>{{ c.name }}</td>
                                                <td>
                                                  {{
                                                    item
                                                      .sleeve_backing_process_of_reinfoceing_bar[
                                                      c.key
                                                    ]
                                                  }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>契約残月</td>
                                                <td>
                                                  {{
                                                    item
                                                      .sleeve_backing_process_of_reinfoceing_bar
                                                      .remaining_month_of_contract
                                                      | formatRemainingMonths
                                                  }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>加入状態</td>
                                                <td
                                                  v-if="
                                                    item
                                                      .sleeve_backing_process_of_reinfoceing_bar
                                                      .contract_start_date
                                                  "
                                                >
                                                  {{
                                                    item
                                                      .sleeve_backing_process_of_reinfoceing_bar
                                                      .is_canceled
                                                      | convertBooleanMaintenanceText
                                                  }}
                                                </td>
                                                <td v-else />
                                              </tr>
                                              <tr>
                                                <td>更新案内</td>
                                                <td
                                                  v-if="
                                                    item
                                                      .sleeve_backing_process_of_reinfoceing_bar
                                                      .contract_start_date
                                                  "
                                                >
                                                  {{
                                                    item
                                                      .sleeve_backing_process_of_reinfoceing_bar
                                                      .is_updating
                                                      | convertBooleanUpdatingText
                                                  }}
                                                </td>
                                                <td v-else />
                                              </tr>
                                            </tbody>
                                          </v-simple-table>
                                        </v-card>
                                      </v-container>
                                      <v-container>
                                        <v-card>
                                          <v-toolbar
                                            class="mb-2"
                                            color="indigo darken-5"
                                            dark
                                            flag
                                          >
                                            <v-toolbar-title>
                                              写管屋クラウド情報
                                            </v-toolbar-title>
                                          </v-toolbar>
                                          <v-simple-table>
                                            <tbody>
                                              <tr
                                                v-for="c in cloudDatabankPremiumRecords"
                                                :key="c.key"
                                              >
                                                <td>{{ c.name }}</td>
                                                <td>
                                                  {{
                                                    item.databank_premium[c.key]
                                                  }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>契約残月</td>
                                                <td>
                                                  {{
                                                    item.databank_premium
                                                      .remaining_month_of_contract
                                                      | formatRemainingMonths
                                                  }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>加入状態</td>
                                                <td
                                                  v-if="
                                                    item.databank_premium
                                                      .contract_start_date
                                                  "
                                                >
                                                  {{
                                                    item.databank_premium
                                                      .is_canceled
                                                      | convertBooleanMaintenanceText
                                                  }}
                                                </td>
                                                <td v-else />
                                              </tr>
                                              <tr>
                                                <td>更新案内</td>
                                                <td
                                                  v-if="
                                                    item.databank_premium
                                                      .contract_start_date
                                                  "
                                                >
                                                  {{
                                                    item.databank_premium
                                                      .is_updating
                                                      | convertBooleanUpdatingText
                                                  }}
                                                </td>
                                                <td v-else />
                                              </tr>
                                            </tbody>
                                          </v-simple-table>
                                        </v-card>
                                      </v-container>
                                      <v-container>
                                        <v-card>
                                          <v-toolbar
                                            class="mb-2"
                                            color="indigo darken-5"
                                            dark
                                            flag
                                          >
                                            <v-toolbar-title>
                                              出来形管理クラウド情報
                                            </v-toolbar-title>
                                          </v-toolbar>
                                          <v-simple-table>
                                            <tbody>
                                              <tr
                                                v-for="c in cloudFinishedShapeCloudRecords"
                                                :key="c.key"
                                              >
                                                <td>{{ c.name }}</td>
                                                <td>
                                                  {{
                                                    item.finished_shape_cloud[
                                                      c.key
                                                    ]
                                                  }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>契約残月</td>
                                                <td>
                                                  {{
                                                    item.finished_shape_cloud
                                                      .remaining_month_of_contract
                                                      | formatRemainingMonths
                                                  }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>加入状態</td>
                                                <td
                                                  v-if="
                                                    item.finished_shape_cloud
                                                      .contract_start_date
                                                  "
                                                >
                                                  {{
                                                    item.finished_shape_cloud
                                                      .is_canceled
                                                      | convertBooleanMaintenanceText
                                                  }}
                                                </td>
                                                <td v-else />
                                              </tr>
                                              <tr>
                                                <td>更新案内</td>
                                                <td
                                                  v-if="
                                                    item.finished_shape_cloud
                                                      .contract_start_date
                                                  "
                                                >
                                                  {{
                                                    item.finished_shape_cloud
                                                      .is_updating
                                                      | convertBooleanUpdatingText
                                                  }}
                                                </td>
                                                <td v-else />
                                              </tr>
                                            </tbody>
                                          </v-simple-table>
                                        </v-card>
                                      </v-container>
                                      <v-container>
                                        <v-card>
                                          <v-toolbar
                                            class="mb-2"
                                            color="indigo darken-5"
                                            dark
                                            flag
                                          >
                                            <v-toolbar-title>
                                              品質管理クラウド情報
                                            </v-toolbar-title>
                                          </v-toolbar>
                                          <v-simple-table>
                                            <tbody>
                                              <tr
                                                v-for="c in cloudQualityManagementCloudRecords"
                                                :key="c.key"
                                              >
                                                <td>{{ c.name }}</td>
                                                <td>
                                                  {{
                                                    item
                                                      .quality_management_cloud[
                                                      c.key
                                                    ]
                                                  }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>契約残月</td>
                                                <td>
                                                  {{
                                                    item
                                                      .quality_management_cloud
                                                      .remaining_month_of_contract
                                                      | formatRemainingMonths
                                                  }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>加入状態</td>
                                                <td
                                                  v-if="
                                                    item
                                                      .quality_management_cloud
                                                      .contract_start_date
                                                  "
                                                >
                                                  {{
                                                    item
                                                      .quality_management_cloud
                                                      .is_canceled
                                                      | convertBooleanMaintenanceText
                                                  }}
                                                </td>
                                                <td v-else />
                                              </tr>
                                              <tr>
                                                <td>更新案内</td>
                                                <td
                                                  v-if="
                                                    item
                                                      .quality_management_cloud
                                                      .contract_start_date
                                                  "
                                                >
                                                  {{
                                                    item
                                                      .quality_management_cloud
                                                      .is_updating
                                                      | convertBooleanUpdatingText
                                                  }}
                                                </td>
                                                <td v-else />
                                              </tr>
                                            </tbody>
                                          </v-simple-table>
                                        </v-card>
                                      </v-container>
                                      <v-container>
                                        <v-card>
                                          <v-toolbar
                                            class="mb-2"
                                            color="indigo darken-5"
                                            dark
                                            flag
                                          >
                                            <v-toolbar-title>
                                              施工体制クラウド情報
                                            </v-toolbar-title>
                                          </v-toolbar>
                                          <v-simple-table>
                                            <tbody>
                                              <tr
                                                v-for="c in cloudConstructionArrangementCloudRecords"
                                                :key="c.key"
                                              >
                                                <td>{{ c.name }}</td>
                                                <td>
                                                  {{
                                                    item
                                                      .construction_arrangement[
                                                      c.key
                                                    ]
                                                  }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>契約残月</td>
                                                <td>
                                                  {{
                                                    item
                                                      .construction_arrangement
                                                      .remaining_month_of_contract
                                                      | formatRemainingMonths
                                                  }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>加入状態</td>
                                                <td
                                                  v-if="
                                                    item
                                                      .construction_arrangement
                                                      .contract_start_date
                                                  "
                                                >
                                                  {{
                                                    item
                                                      .construction_arrangement
                                                      .is_canceled
                                                      | convertBooleanMaintenanceText
                                                  }}
                                                </td>
                                                <td v-else />
                                              </tr>
                                              <tr>
                                                <td>更新案内</td>
                                                <td
                                                  v-if="
                                                    item
                                                      .construction_arrangement
                                                      .contract_start_date
                                                  "
                                                >
                                                  {{
                                                    item
                                                      .construction_arrangement
                                                      .is_updating
                                                      | convertBooleanUpdatingText
                                                  }}
                                                </td>
                                                <td v-else />
                                              </tr>
                                            </tbody>
                                          </v-simple-table>
                                        </v-card>
                                      </v-container>
                                      <v-container>
                                        <v-card>
                                          <v-toolbar
                                            class="mb-2"
                                            color="indigo darken-5"
                                            dark
                                            flag
                                          >
                                            <v-toolbar-title>
                                              フィールドネット情報
                                            </v-toolbar-title>
                                          </v-toolbar>
                                          <v-simple-table>
                                            <tbody>
                                              <tr
                                                v-for="c in cloudFieldnetRecords"
                                                :key="c.key"
                                              >
                                                <td>{{ c.name }}</td>
                                                <td>
                                                  {{ item.fieldnet[c.key] }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>契約残月</td>
                                                <td>
                                                  {{
                                                    item.fieldnet
                                                      .remaining_month_of_contract
                                                      | formatRemainingMonths
                                                  }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>加入状態</td>
                                                <td
                                                  v-if="
                                                    item.fieldnet
                                                      .contract_start_date
                                                  "
                                                >
                                                  {{
                                                    item.fieldnet.is_canceled
                                                      | convertBooleanMaintenanceText
                                                  }}
                                                </td>
                                                <td v-else />
                                              </tr>
                                              <tr>
                                                <td>更新案内</td>
                                                <td>
                                                  {{
                                                    item.fieldnet.is_updating
                                                      | convertBooleanUpdatingText
                                                  }}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </v-simple-table>
                                        </v-card>
                                      </v-container>
                                      <v-container>
                                        <v-card>
                                          <v-toolbar
                                            class="mb-2"
                                            color="indigo darken-5"
                                            dark
                                            flag
                                          >
                                            <v-toolbar-title>
                                              快測Scan情報
                                            </v-toolbar-title>
                                          </v-toolbar>
                                          <v-simple-table>
                                            <tbody>
                                              <tr
                                                v-for="c in cloudKaisokuScanRecords"
                                                :key="c.key"
                                              >
                                                <td>{{ c.name }}</td>
                                                <td>
                                                  {{ item.kaisoku_scan[c.key] }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>契約残月</td>
                                                <td>
                                                  {{
                                                    item.kaisoku_scan
                                                      .remaining_month_of_contract
                                                      | formatRemainingMonths
                                                  }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>加入状態</td>
                                                <td
                                                  v-if="
                                                    item.kaisoku_scan
                                                      .contract_start_date
                                                  "
                                                >
                                                  {{
                                                    item.kaisoku_scan
                                                      .is_canceled
                                                      | convertBooleanMaintenanceText
                                                  }}
                                                </td>
                                                <td v-else />
                                              </tr>
                                              <tr>
                                                <td>更新案内</td>
                                                <td
                                                  v-if="
                                                    item.kaisoku_scan
                                                      .contract_start_date
                                                  "
                                                >
                                                  {{
                                                    item.kaisoku_scan
                                                      .is_updating
                                                      | convertBooleanUpdatingText
                                                  }}
                                                </td>
                                                <td v-else />
                                              </tr>
                                            </tbody>
                                          </v-simple-table>
                                        </v-card>
                                      </v-container>
                                      <v-container>
                                        <v-card>
                                          <v-toolbar
                                            class="mb-2"
                                            color="indigo darken-5"
                                            dark
                                            flag
                                          >
                                            <v-toolbar-title>
                                              KENTEM-CONNECT情報
                                            </v-toolbar-title>
                                          </v-toolbar>
                                          <v-simple-table>
                                            <tbody>
                                              <tr
                                                v-for="c in cloudKentemConnectRecords"
                                                :key="c.key"
                                              >
                                                <td>{{ c.name }}</td>
                                                <td>
                                                  {{
                                                    item.kentem_connect[c.key]
                                                  }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>契約残月</td>
                                                <td>
                                                  {{
                                                    item.kentem_connect
                                                      .remaining_month_of_contract
                                                      | formatRemainingMonths
                                                  }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>加入状態</td>
                                                <td
                                                  v-if="
                                                    item.kentem_connect
                                                      .contract_start_date
                                                  "
                                                >
                                                  {{
                                                    item.kentem_connect
                                                      .is_canceled
                                                      | convertBooleanMaintenanceText
                                                  }}
                                                </td>
                                                <td v-else />
                                              </tr>
                                              <tr>
                                                <td>更新案内</td>
                                                <td
                                                  v-if="
                                                    item.kentem_connect
                                                      .contract_start_date
                                                  "
                                                >
                                                  {{
                                                    item.kentem_connect
                                                      .is_updating
                                                      | convertBooleanUpdatingText
                                                  }}
                                                </td>
                                                <td v-else />
                                              </tr>
                                            </tbody>
                                          </v-simple-table>
                                        </v-card>
                                      </v-container>
                                      <v-container>
                                        <v-card>
                                          <v-toolbar
                                            class="mb-2"
                                            color="indigo darken-5"
                                            dark
                                            flag
                                          >
                                            <v-toolbar-title>
                                              日報管理クラウド情報
                                            </v-toolbar-title>
                                          </v-toolbar>
                                          <v-simple-table>
                                            <tbody>
                                              <tr
                                                v-for="c in cloudProjectManagementCloudRecords"
                                                :key="c.key"
                                              >
                                                <td>{{ c.name }}</td>
                                                <td>
                                                  {{
                                                    item
                                                      .project_management_cloud[
                                                      c.key
                                                    ]
                                                  }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>契約残月</td>
                                                <td>
                                                  {{
                                                    item
                                                      .project_management_cloud
                                                      .remaining_month_of_contract
                                                      | formatRemainingMonths
                                                  }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>加入状態</td>
                                                <td
                                                  v-if="
                                                    item
                                                      .project_management_cloud
                                                      .contract_start_date
                                                  "
                                                >
                                                  {{
                                                    item
                                                      .project_management_cloud
                                                      .is_canceled
                                                      | convertBooleanMaintenanceText
                                                  }}
                                                </td>
                                                <td v-else />
                                              </tr>
                                              <tr>
                                                <td>更新案内</td>
                                                <td
                                                  v-if="
                                                    item
                                                      .project_management_cloud
                                                      .contract_start_date
                                                  "
                                                >
                                                  {{
                                                    item
                                                      .project_management_cloud
                                                      .is_updating
                                                      | convertBooleanUpdatingText
                                                  }}
                                                </td>
                                                <td v-else />
                                              </tr>
                                            </tbody>
                                          </v-simple-table>
                                        </v-card>
                                      </v-container>
                                      <v-container>
                                        <v-toolbar
                                          class="mb-2"
                                          color="indigo darken-5"
                                          dark
                                          flag
                                        >
                                          <v-toolbar-title>
                                            遠隔臨場 SiteLive情報一覧
                                          </v-toolbar-title>
                                        </v-toolbar>
                                        <v-row>
                                          <v-col
                                            v-for="r_item in item.remote_inspection_contract"
                                            :key="r_item.id"
                                            cols="12"
                                            sm="12"
                                            md="12"
                                            lg="12"
                                          >
                                            <v-card>
                                              <v-card-title
                                                class="subheading font-weight-bold"
                                              >
                                                サブID:
                                                {{ r_item.management_sub_code }}
                                              </v-card-title>
                                              <v-divider />
                                              <v-list dense>
                                                <v-list-item
                                                  v-for="c in cloudRemoteInspectionContractRecords"
                                                  :key="c.key"
                                                >
                                                  <v-list-item-content>
                                                    {{ c.name }}:
                                                  </v-list-item-content>
                                                  <v-list-item-content
                                                    class="align-end"
                                                  >
                                                    {{ r_item[c.key] }}
                                                  </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item>
                                                  <v-list-item-content>
                                                    契約残月:
                                                  </v-list-item-content>
                                                  <v-list-item-content>
                                                    {{
                                                      r_item.ri_remaining_month_of_contract
                                                        | formatRemainingMonths
                                                    }}
                                                  </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item>
                                                  <v-list-item-content>
                                                    加入状態:
                                                  </v-list-item-content>
                                                  <v-list-item-content
                                                    v-if="
                                                      r_item.ri_contract_start_date
                                                    "
                                                  >
                                                    {{
                                                      r_item.ri_is_canceled
                                                        | convertBooleanMaintenanceText
                                                    }}
                                                  </v-list-item-content>
                                                  <v-list-item-content v-else />
                                                </v-list-item>
                                                <v-list-item>
                                                  <v-list-item-content>
                                                    更新案内:
                                                  </v-list-item-content>
                                                  <v-list-item-content>
                                                    {{
                                                      r_item.ri_is_updating
                                                        | convertBooleanUpdatingText
                                                    }}
                                                  </v-list-item-content>
                                                </v-list-item>
                                              </v-list>
                                            </v-card>
                                          </v-col>
                                        </v-row>
                                      </v-container>
                                      <v-container>
                                        <v-card>
                                          <v-toolbar
                                            class="mb-2"
                                            color="indigo darken-5"
                                            dark
                                            flag
                                          >
                                            <v-toolbar-title>
                                              快測AR情報
                                            </v-toolbar-title>
                                          </v-toolbar>
                                          <v-simple-table>
                                            <tbody
                                              v-for="ksar_item in item.kaisoku_ar"
                                              :key="ksar_item.id"
                                              cols="12"
                                              sm="12"
                                              md="12"
                                              lg="12"
                                            >
                                              <tr
                                                v-for="c in cloudKaisokuArRecords"
                                                :key="c.key"
                                              >
                                                <td>{{ c.name }}</td>
                                                <td>
                                                  {{ ksar_item[c.key] }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>契約残月</td>
                                                <td>
                                                  {{
                                                    ksar_item.remaining_month_of_contract
                                                      | formatRemainingMonths
                                                  }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>加入状態</td>
                                                <td
                                                  v-if="
                                                    ksar_item.contract_start_date
                                                  "
                                                >
                                                  {{
                                                    ksar_item.is_canceled
                                                      | convertBooleanMaintenanceText
                                                  }}
                                                </td>
                                                <td v-else />
                                              </tr>
                                              <tr>
                                                <td>更新案内</td>
                                                <td
                                                  v-if="
                                                    ksar_item.contract_start_date
                                                  "
                                                >
                                                  {{
                                                    ksar_item.is_updating
                                                      | convertBooleanUpdatingText
                                                  }}
                                                </td>
                                                <td v-else />
                                              </tr>
                                            </tbody>
                                          </v-simple-table>
                                        </v-card>
                                      </v-container>
                                      <v-container>
                                        <v-toolbar
                                          class="mb-2"
                                          color="indigo darken-5"
                                          dark
                                          flag
                                        >
                                          <v-toolbar-title>
                                            快測ナビ情報一覧
                                          </v-toolbar-title>
                                        </v-toolbar>
                                        <v-row>
                                          <v-col
                                            v-for="k_item in item.kaisoku_navi"
                                            :key="k_item.id"
                                            cols="12"
                                            sm="12"
                                            md="12"
                                            lg="12"
                                          >
                                            <v-card>
                                              <v-card-title
                                                class="subheading font-weight-bold"
                                              >
                                                サブID:
                                                {{ k_item.management_sub_code }}
                                              </v-card-title>
                                              <v-divider />
                                              <v-list dense>
                                                <v-list-item
                                                  v-for="c in cloudKaisokuNaviRecords"
                                                  :key="c.key"
                                                >
                                                  <v-list-item-content>
                                                    {{ c.name }}:
                                                  </v-list-item-content>
                                                  <v-list-item-content
                                                    class="align-end"
                                                  >
                                                    {{ k_item[c.key] }}
                                                  </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item>
                                                  <v-list-item-content>
                                                    契約残月:
                                                  </v-list-item-content>
                                                  <v-list-item-content>
                                                    {{
                                                      k_item.remaining_month_of_contract
                                                        | formatRemainingMonths
                                                    }}
                                                  </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item>
                                                  <v-list-item-content>
                                                    加入状態:
                                                  </v-list-item-content>
                                                  <v-list-item-content
                                                    v-if="
                                                      k_item.contract_start_date
                                                    "
                                                  >
                                                    {{
                                                      k_item.is_canceled
                                                        | convertBooleanMaintenanceText
                                                    }}
                                                  </v-list-item-content>
                                                  <v-list-item-content v-else />
                                                </v-list-item>
                                                <v-list-item>
                                                  <v-list-item-content>
                                                    更新案内:
                                                  </v-list-item-content>
                                                  <v-list-item-content>
                                                    {{
                                                      k_item.is_updating
                                                        | convertBooleanUpdatingText
                                                    }}
                                                  </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item>
                                                  <v-list-item-content>
                                                    アクティベーションコード:
                                                  </v-list-item-content>
                                                  <v-list-item-content
                                                    v-if="
                                                      k_item.activation_code
                                                    "
                                                    class="wrap-text"
                                                  >
                                                    {{
                                                      k_item.activation_code
                                                        | formatActivationCode
                                                    }}
                                                  </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item>
                                                  <v-list-item-content>
                                                    端末名:
                                                  </v-list-item-content>
                                                  <v-list-item-content
                                                    v-if="k_item.terminal_name"
                                                    class="wrap-text"
                                                  >
                                                    {{ k_item.terminal_name }}
                                                  </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item>
                                                  <v-list-item-content>
                                                    ユーザー名:
                                                  </v-list-item-content>
                                                  <v-list-item-content
                                                    v-if="k_item.user_name"
                                                    class="wrap-text"
                                                  >
                                                    {{ k_item.user_name }}
                                                  </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item>
                                                  <v-list-item-content>
                                                    ユーザーメールアドレス:
                                                  </v-list-item-content>
                                                  <v-list-item-content
                                                    v-if="k_item.user_mail"
                                                    class="wrap-text"
                                                  >
                                                    {{ k_item.user_mail }}
                                                  </v-list-item-content>
                                                </v-list-item>
                                              </v-list>
                                            </v-card>
                                          </v-col>
                                        </v-row>
                                      </v-container>
                                      <v-container>
                                        <v-card>
                                          <v-toolbar
                                            class="mb-2"
                                            color="indigo darken-5"
                                            dark
                                            flag
                                          >
                                            <v-toolbar-title>
                                              工事実績DBクラウド情報
                                            </v-toolbar-title>
                                          </v-toolbar>
                                          <v-simple-table>
                                            <tbody
                                              v-for="kdc_item in item.construction_result_cloud"
                                              :key="kdc_item.id"
                                              cols="12"
                                              sm="12"
                                              md="12"
                                              lg="12"
                                            >
                                              <tr
                                                v-for="c in constructionResultCloudRecords"
                                                :key="c.key"
                                              >
                                                <td>{{ c.name }}</td>
                                                <td>
                                                  {{ kdc_item[c.key] }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>契約容量(GB)</td>
                                                <td>
                                                  {{
                                                    kdc_item.gigabyte_capacity
                                                  }}GB
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>ライセンス数</td>
                                                <td>
                                                  {{
                                                    kdc_item.number_of_licenses
                                                  }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>契約残月</td>
                                                <td>
                                                  {{
                                                    kdc_item.remaining_month_of_contract
                                                      | formatRemainingMonths
                                                  }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>加入状態</td>
                                                <td
                                                  v-if="
                                                    kdc_item.contract_start_date
                                                  "
                                                >
                                                  {{
                                                    kdc_item.is_canceled
                                                      | convertBooleanMaintenanceText
                                                  }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>更新案内</td>
                                                <td
                                                  v-if="
                                                    kdc_item.contract_start_date
                                                  "
                                                >
                                                  {{
                                                    kdc_item.is_updating
                                                      | convertBooleanUpdatingText
                                                  }}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </v-simple-table>
                                        </v-card>
                                      </v-container>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </template>
                            </v-data-iterator>
                          </v-container>
                        </v-card>
                      </v-container>
                    </v-tab-item>
                    <v-tab-item>
                      <v-tabs>
                        <v-tab
                          v-for="(ref_contents, ref_num) in ref_contents.cloud"
                          :key="ref_num"
                        >
                          {{ ref_contents.management_code }}
                        </v-tab>
                        <v-tab-item
                          v-for="(ref_contents, ref_num) in ref_contents.cloud"
                          :key="ref_num"
                        >
                          <v-tabs v-if="'user_profile' in ref_contents">
                            <v-tab
                              v-for="(cloud_user_tab, tab_num) in cloudUserTabs"
                              :key="tab_num"
                            >
                              {{ cloud_user_tab.name }}
                            </v-tab>
                            <v-tab-item>
                              <v-simple-table>
                                <thead>
                                  <tr>
                                    <th>名前</th>
                                    <th>メールアドレス</th>
                                    <th>権限</th>
                                    <th>SiteBox</th>
                                    <th>SiteBoxトンネル</th>
                                    <th>SiteBox配筋検査</th>
                                    <th>写管屋クラウド</th>
                                    <th>出来形管理クラウド</th>
                                    <th>快測Scan</th>
                                    <th>フィールド・ネット</th>
                                    <th>KENTEM-CONNECT</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="p in ref_contents.user_profile.filter(
                                      (item) => !item.isProvisional
                                    )"
                                    :key="p.userId"
                                  >
                                    <td>{{ p.lastName }} {{ p.firstName }}</td>
                                    <td>{{ p.userName }}</td>
                                    <td v-if="p.roles">
                                      {{
                                        getRoleText(
                                          p,
                                          "UserAdmin",
                                          "管理者",
                                          "メンバー"
                                        )
                                      }}
                                    </td>
                                    <td v-else>メンバー</td>
                                    <td v-if="p.roles">
                                      {{ getRoleText(p, "SiteBoxUser") }}
                                    </td>
                                    <td v-else>×</td>
                                    <td v-if="p.roles">
                                      {{ getRoleText(p, "SiteBoxNatmUser") }}
                                    </td>
                                    <td v-else>×</td>
                                    <td v-if="p.roles">
                                      {{ getRoleText(p, "SiteBoxRCUser") }}
                                    </td>
                                    <td v-else>×</td>
                                    <td v-if="p.roles">
                                      {{ getRoleText(p, "DataBankPhotoUser") }}
                                    </td>
                                    <td v-else>×</td>
                                    <td v-if="p.roles">
                                      {{ getRoleText(p, "DegUser") }}
                                    </td>
                                    <td v-else>×</td>
                                    <td v-if="p.roles">
                                      {{ getRoleText(p, "KsScanUser") }}
                                    </td>
                                    <td v-else>×</td>
                                    <td v-if="p.roles">
                                      {{ getRoleText(p, "KgmUser") }}
                                    </td>
                                    <td v-else>×</td>
                                    <td v-if="p.roles">
                                      {{ getRoleText(p, "KentemConnectUser") }}
                                    </td>
                                    <td v-else>×</td>
                                  </tr>
                                </tbody>
                              </v-simple-table>
                            </v-tab-item>
                            <v-tab-item>
                              <v-simple-table>
                                <thead>
                                  <tr>
                                    <th>メールアドレス</th>
                                    <th>権限</th>
                                    <th>SiteBox</th>
                                    <th>SiteBoxトンネル</th>
                                    <th>SiteBox配筋検査</th>
                                    <th>写管屋クラウド</th>
                                    <th>出来形管理クラウド</th>
                                    <th>快測Scan</th>
                                    <th>フィールド・ネット</th>
                                    <th>KENTEM-CONNECT</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="p in ref_contents.user_profile.filter(
                                      (item) => item.isProvisional
                                    )"
                                    :key="p.userId"
                                  >
                                    <td>{{ p.userName }}</td>
                                    <td v-if="p.roles">
                                      {{
                                        getRoleText(
                                          p,
                                          "UserAdmin",
                                          "管理者",
                                          "メンバー"
                                        )
                                      }}
                                    </td>
                                    <td v-else>メンバー</td>
                                    <td v-if="p.roles">
                                      {{ getRoleText(p, "SiteBoxUser") }}
                                    </td>
                                    <td v-else>×</td>
                                    <td v-if="p.roles">
                                      {{ getRoleText(p, "SiteBoxNatmUser") }}
                                    </td>
                                    <td v-else>×</td>
                                    <td v-if="p.roles">
                                      {{ getRoleText(p, "SiteBoxRCUser") }}
                                    </td>
                                    <td v-else>×</td>
                                    <td v-if="p.roles">
                                      {{ getRoleText(p, "DataBankPhotoUser") }}
                                    </td>
                                    <td v-else>×</td>
                                    <td v-if="p.roles">
                                      {{ getRoleText(p, "DegUser") }}
                                    </td>
                                    <td v-else>×</td>
                                    <td v-if="p.roles">
                                      {{ getRoleText(p, "KsScanUser") }}
                                    </td>
                                    <td v-else>×</td>
                                    <td v-if="p.roles">
                                      {{ getRoleText(p, "KgmUser") }}
                                    </td>
                                    <td v-else>×</td>
                                    <td v-if="p.roles">
                                      {{ getRoleText(p, "KentemConnectUser") }}
                                    </td>
                                    <td v-else>×</td>
                                  </tr>
                                </tbody>
                              </v-simple-table>
                            </v-tab-item>
                          </v-tabs>
                        </v-tab-item>
                      </v-tabs>
                    </v-tab-item>
                  </v-tabs>
                </v-tab-item>
                <v-tab-item>
                  <v-container>
                    <v-card>
                      <v-container>
                        <v-data-iterator
                          :items="ref_contents.dekispart_school"
                          hide-default-footer
                          :items-per-page="1000"
                        >
                          <template #header>
                            <v-toolbar
                              class="mb-2"
                              color="indigo darken-5"
                              dark
                              flag
                            >
                              <v-toolbar-title>
                                デキスパートスクール情報一覧
                              </v-toolbar-title>
                            </v-toolbar>
                          </template>
                          <template #default="props">
                            <v-row>
                              <v-col
                                v-for="item in props.items"
                                :key="item.id"
                                cols="12"
                                sm="6"
                                md="4"
                                lg="3"
                              >
                                <v-card>
                                  <v-card-title
                                    class="subheading font-weight-bold"
                                  >
                                    デキスクID:
                                    {{ item.dekispart_school_maintenance_id }}
                                  </v-card-title>
                                  <v-divider />
                                  <v-list dense>
                                    <v-list-item
                                      v-for="d in dekispartSchoolRecords"
                                      :key="d.key"
                                    >
                                      <v-list-item-content>
                                        {{ d.name }}:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{ item[d.key] }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        契約残月:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{
                                          item.remaining_month_of_contract
                                            | formatRemainingMonths
                                        }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        オプション:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{
                                          item.is_option
                                            | convertBooleanMaintenanceText
                                        }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        更新方法:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{ item.renewal_type }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        業務委託費:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{
                                          item.subcontracting_cost
                                            | convertSubcontractingCostText
                                        }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        販売店:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{ item.dealership_code1 }}
                                        {{ item.dealership_name1 }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        販売店2:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{ item.dealership_code2 }}
                                        {{ item.dealership_name2 }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        加入状態:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{
                                          item.is_canceled
                                            | convertBooleanMaintenanceText
                                        }}
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                  <v-container>
                                    <v-toolbar
                                      class="mb-2"
                                      color="indigo darken-5"
                                      dark
                                      flag
                                    >
                                      <v-toolbar-title>
                                        備考情報一覧
                                      </v-toolbar-title>
                                    </v-toolbar>
                                    <v-row>
                                      <v-col
                                        v-for="r_item in item.remarks"
                                        :key="r_item.id"
                                        cols="12"
                                        sm="12"
                                        md="12"
                                        lg="12"
                                      >
                                        <v-card>
                                          <v-list dense>
                                            <v-list-item>
                                              <v-list-item-content>
                                                備考:
                                              </v-list-item-content>
                                              <v-list-item-content
                                                class="align-end"
                                              >
                                                {{ r_item.remarks1 }}
                                                {{ r_item.remarks2 }}
                                              </v-list-item-content>
                                            </v-list-item>
                                          </v-list>
                                        </v-card>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card>
                              </v-col>
                            </v-row>
                          </template>
                        </v-data-iterator>
                      </v-container>
                    </v-card>
                  </v-container>
                </v-tab-item>
                <v-tab-item>
                  <v-container>
                    <v-card>
                      <v-container>
                        <v-data-iterator
                          :items="ref_contents.innosite"
                          hide-default-footer
                          :items-per-page="1000"
                        >
                          <template #header>
                            <v-toolbar
                              class="mb-2"
                              color="indigo darken-5"
                              dark
                              flag
                            >
                              <v-toolbar-title>
                                イノサイト情報一覧
                              </v-toolbar-title>
                            </v-toolbar>
                          </template>
                          <template #default="props">
                            <v-row>
                              <v-col
                                v-for="item in props.items"
                                :key="item.id"
                                cols="12"
                                sm="6"
                                md="4"
                                lg="3"
                              >
                                <v-card>
                                  <v-card-title
                                    class="subheading font-weight-bold"
                                  >
                                    {{ item.product_name }}<br />ID:
                                    {{ item.innosite_id }}
                                  </v-card-title>
                                  <v-divider />
                                  <v-list dense>
                                    <v-list-item
                                      v-for="i in innositeRecords"
                                      :key="i.key"
                                    >
                                      <v-list-item-content>
                                        {{ i.name }}:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{ item[i.key] }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        契約残月:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{
                                          item.remaining_month_of_contract
                                            | formatRemainingMonths
                                        }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        業務委託費:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{
                                          item.subcontracting_cost
                                            | convertSubcontractingCostText
                                        }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        販売店1:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{ item.dealership_code1 }}
                                        {{ item.dealership_name1 }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        販売店2:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{ item.dealership_code2 }}
                                        {{ item.dealership_name2 }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        加入状態:
                                      </v-list-item-content>
                                      <v-list-item-content
                                        v-if="item.contract_start_date"
                                      >
                                        {{
                                          item.is_canceled
                                            | convertBooleanMaintenanceText
                                        }}
                                      </v-list-item-content>
                                      <v-list-item-content v-else />
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        更新案内:
                                      </v-list-item-content>
                                      <v-list-item-content>
                                        {{
                                          item.is_updating
                                            | convertBooleanUpdatingText
                                        }}
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                  <v-container>
                                    <v-toolbar
                                      class="mb-2"
                                      color="indigo darken-5"
                                      dark
                                      flag
                                    >
                                      <v-toolbar-title>
                                        オプション情報一覧
                                      </v-toolbar-title>
                                    </v-toolbar>
                                    <v-row>
                                      <v-col
                                        v-for="o_item in item.options"
                                        :key="o_item.id"
                                        cols="12"
                                        sm="12"
                                        md="12"
                                        lg="12"
                                      >
                                        <v-card>
                                          <v-card-title
                                            class="subheading font-weight-bold"
                                          >
                                            {{ o_item.option_name }}
                                          </v-card-title>
                                          <v-divider />
                                          <v-list dense>
                                            <v-list-item
                                              v-for="i in innositeOptionRecords"
                                              :key="i.key"
                                            >
                                              <v-list-item-content>
                                                {{ i.name }}:
                                              </v-list-item-content>
                                              <v-list-item-content
                                                class="align-end"
                                              >
                                                {{ o_item[i.key] }}
                                              </v-list-item-content>
                                            </v-list-item>
                                          </v-list>
                                        </v-card>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card>
                              </v-col>
                            </v-row>
                          </template>
                        </v-data-iterator>
                      </v-container>
                    </v-card>
                  </v-container>
                </v-tab-item>
                <v-tab-item>
                  <v-container>
                    <v-card>
                      <v-container>
                        <v-data-iterator
                          :items="ref_contents.construction_result"
                          hide-default-footer
                          :items-per-page="1000"
                        >
                          <template #header>
                            <v-toolbar
                              class="mb-2"
                              color="indigo darken-5"
                              dark
                              flag
                            >
                              <v-toolbar-title>工事実績一覧</v-toolbar-title>
                            </v-toolbar>
                          </template>
                          <template #default="props">
                            <v-row>
                              <v-col
                                v-for="item in props.items"
                                :key="item.id"
                                cols="12"
                                sm="6"
                                md="4"
                                lg="3"
                              >
                                <v-card>
                                  <v-card-title
                                    class="subheading font-weight-bold"
                                  >
                                    ユーザーID:
                                    {{ item.user_id }}
                                  </v-card-title>
                                  <v-divider />
                                  <v-list dense>
                                    <v-list-item
                                      v-for="c in constructionResultRecords"
                                      :key="c.key"
                                    >
                                      <v-list-item-content>
                                        {{ c.name }}:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{ item[c.key] }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        契約残月:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{
                                          item.remaining_month_of_contract
                                            | formatRemainingMonths
                                        }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        業務委託費:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{
                                          item.subcontracting_cost
                                            | convertSubcontractingCostText
                                        }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        備考:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{ item.remarks1 }}
                                        {{ item.remarks2 }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        販売店1:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{ item.dealership_code1 }}
                                        {{ item.dealership_name1 }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        販売店2:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{ item.dealership_code2 }}
                                        {{ item.dealership_name2 }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        加入状態:
                                      </v-list-item-content>
                                      <v-list-item-content
                                        v-if="item.contract_start_date"
                                      >
                                        {{
                                          item.is_canceled
                                            | convertBooleanMaintenanceText
                                        }}
                                      </v-list-item-content>
                                      <v-list-item-content v-else />
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        更新案内:
                                      </v-list-item-content>
                                      <v-list-item-content>
                                        {{
                                          item.is_updating
                                            | convertBooleanUpdatingText
                                        }}
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-card>
                              </v-col>
                            </v-row>
                          </template>
                        </v-data-iterator>
                      </v-container>
                    </v-card>
                  </v-container>
                </v-tab-item>
                <v-tab-item>
                  <v-container>
                    <v-card>
                      <v-container>
                        <div class="table-container">
                          <table class="kjk-table">
                            <thead>
                              <tr>
                                <th class="header-cell">案件コード</th>
                                <th class="header-cell">対応状況</th>
                                <th class="header-cell">商品</th>
                                <th class="header-cell">3Dモデル</th>
                                <th class="header-cell">工期開始</th>
                                <th class="header-cell">工期終了</th>
                                <th class="header-cell">利用終了</th>
                                <th class="header-cell">サービス終了</th>
                                <th class="header-cell">発注大中小</th>
                                <th class="header-cell">工事名+工事番号</th>
                                <th class="header-cell">販売店</th>
                              </tr>
                            </thead>
                            <tbody v-if="ref_contents.case_log">
                              <tr
                                v-for="k_item in ref_contents.case_log"
                                :key="k_item.id"
                              >
                                <td>{{ k_item.case_code }}</td>
                                <td>{{ k_item.action_status }}</td>
                                <td>
                                  {{
                                    k_item.product_name
                                      | convertKjkRevsignProductNameText
                                  }}
                                </td>
                                <td>
                                  {{
                                    k_item.is_three_dimensions_view
                                      | convertBooleanThreeDModelText
                                  }}
                                </td>
                                <td>{{ k_item.construction_start }}</td>
                                <td>{{ k_item.construction_end }}</td>
                                <td>{{ k_item.contract_end_date }}</td>
                                <td>{{ k_item.service_end_date }}</td>
                                <td>
                                  <template v-if="k_item.government_name1">
                                    {{ k_item.government_name1 }}
                                    <template v-if="k_item.government_name2">
                                      _{{ k_item.government_name2 }}
                                    </template>
                                    <template v-if="k_item.government_name3">
                                      _{{ k_item.government_name3 }}
                                    </template>
                                  </template>
                                </td>
                                <td>
                                  <template v-if="k_item.construction_name">
                                    {{ k_item.construction_name }}
                                    <template v-if="k_item.koji_no">
                                      &nbsp;{{ k_item.koji_no }}
                                    </template>
                                  </template>
                                </td>
                                <td>{{ k_item.shop_name }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </v-container>
                    </v-card>
                  </v-container>
                </v-tab-item>
                <v-tab-item>
                  <v-container>
                    <v-card>
                      <v-container>
                        <v-data-iterator
                          :items="ref_contents.prodougu"
                          hide-default-footer
                          :items-per-page="1000"
                        >
                          <template #header>
                            <v-toolbar
                              class="mb-2"
                              color="indigo darken-5"
                              dark
                              flag
                            >
                              <v-toolbar-title>Prodougu一覧</v-toolbar-title>
                            </v-toolbar>
                          </template>
                          <template #default="props">
                            <v-row>
                              <v-col
                                v-for="item in props.items"
                                :key="item.id"
                                cols="12"
                                sm="6"
                                md="4"
                                lg="3"
                              >
                                <v-toolbar
                                  class="mb-2"
                                  color="indigo darken-5"
                                  dark
                                  flag
                                  v-if="item.series_code === '1'"
                                >
                                  <v-toolbar-title>
                                    クラウドアプリ情報一覧
                                  </v-toolbar-title>
                                </v-toolbar>
                                <v-card>
                                  <v-card-title
                                    class="subheading font-weight-bold"
                                  >
                                    ユーザーID:
                                    {{ item.id }}
                                  </v-card-title>
                                  <v-divider />
                                  <v-list dense>
                                    <v-list-item
                                      v-for="c in prodouguResultRecords"
                                      :key="c.key"
                                    >
                                      <v-list-item-content>
                                        {{ c.name }}:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{ item[c.key] }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        契約残月:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{
                                          item.remaining_month_of_contract
                                            | formatRemainingMonths
                                        }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        加入状態:
                                      </v-list-item-content>
                                      <v-list-item-content
                                        v-if="item.contract_start_date"
                                      >
                                        {{
                                          item.is_canceled
                                            | convertBooleanMaintenanceText
                                        }}
                                      </v-list-item-content>
                                      <v-list-item-content v-else />
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        販売店1:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{ item.sales_code1 }}
                                        {{ item.sales_name1 }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        販売店2:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{ item.sales_code2 }}
                                        {{ item.sales_name2 }}
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                  <v-container v-if="item.series_code === '1'">
                                    <v-toolbar
                                      class="mb-2"
                                      color="indigo darken-5"
                                      dark
                                      flag
                                    >
                                      <v-toolbar-title>
                                        モバイルアプリ情報一覧
                                      </v-toolbar-title>
                                    </v-toolbar>
                                    <v-row>
                                      <v-col
                                        v-for="o_item in item.prodougu_mobile"
                                        :key="o_item.id"
                                        cols="12"
                                        sm="12"
                                        md="12"
                                        lg="12"
                                      >
                                        <v-card>
                                          <v-card-title
                                            class="subheading font-weight-bold"
                                          >
                                            サブID:{{ o_item.sub_number }}
                                          </v-card-title>
                                          <v-divider />
                                          <v-list dense>
                                            <v-list-item
                                              v-for="i in prodouguMobileRecords"
                                              :key="i.key"
                                            >
                                              <v-list-item-content>
                                                {{ i.name }}:
                                              </v-list-item-content>
                                              <v-list-item-content
                                                class="align-end"
                                              >
                                                {{ o_item[i.key] }}
                                              </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                              <v-list-item-content>
                                                契約残月:
                                              </v-list-item-content>
                                              <v-list-item-content
                                                class="align-end"
                                              >
                                                {{
                                                  item.remaining_month_of_contract
                                                    | formatRemainingMonths
                                                }}
                                              </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                              <v-list-item-content>
                                                加入状態:
                                              </v-list-item-content>
                                              <v-list-item-content
                                                v-if="item.contract_start_date"
                                              >
                                                {{
                                                  item.is_canceled
                                                    | convertBooleanMaintenanceText
                                                }}
                                              </v-list-item-content>
                                              <v-list-item-content v-else />
                                            </v-list-item>
                                          </v-list>
                                        </v-card>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card>
                              </v-col>
                            </v-row>
                          </template>
                        </v-data-iterator>
                      </v-container>
                    </v-card>
                  </v-container>
                </v-tab-item>
                <v-tab-item>
                  <v-container>
                    <v-card>
                      <v-container>
                        <v-data-iterator
                          :items="ref_contents.doko_plan"
                          hide-default-footer
                          :items-per-page="1000"
                        >
                          <template #header>
                            <v-toolbar
                              class="mb-2"
                              color="indigo darken-5"
                              dark
                              flag
                            >
                              <v-toolbar-title>DOKO-PLAN一覧</v-toolbar-title>
                            </v-toolbar>
                          </template>
                          <template #default="props">
                            <v-row>
                              <v-col
                                v-for="item in props.items"
                                :key="item.id"
                                cols="12"
                                sm="6"
                                md="4"
                                lg="3"
                              >
                                <v-card>
                                  <v-card-title
                                    class="subheading font-weight-bold"
                                  >
                                    DOKO-PLAN ID:
                                    {{ item.id }}
                                  </v-card-title>
                                  <v-divider />
                                  <v-list dense>
                                    <v-list-item>
                                      <v-list-item-content>
                                        開始日:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{ item.contract_start_date }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        満了日:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{ item.contract_end_date }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        契約残月:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{
                                          item.remaining_month_of_contract
                                            | formatRemainingMonths
                                        }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        ライセンス数:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{ item.number_of_licenses }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        販売店1:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{ item.sales_code1 }}
                                        {{ item.sales_name1 }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        販売店2:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{ item.sales_code2 }}
                                        {{ item.sales_name2 }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        加入状態:
                                      </v-list-item-content>
                                      <v-list-item-content
                                        v-if="item.contract_start_date"
                                      >
                                        {{
                                          item.is_canceled
                                            | convertBooleanMaintenanceText
                                        }}
                                      </v-list-item-content>
                                      <v-list-item-content v-else />
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        更新案内:
                                      </v-list-item-content>
                                      <v-list-item-content>
                                        {{
                                          item.is_updating
                                            | convertBooleanUpdatingText
                                        }}
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-card>
                              </v-col>
                            </v-row>
                          </template>
                        </v-data-iterator>
                      </v-container>
                    </v-card>
                  </v-container>
                </v-tab-item>
                <v-tab-item>
                  <v-container>
                    <v-card>
                      <v-container>
                        <v-data-iterator
                          :items="ref_contents.xzero"
                          hide-default-footer
                          :items-per-page="1000"
                        >
                          <template #header>
                            <v-toolbar
                              class="mb-2"
                              color="indigo darken-5"
                              dark
                              flag
                            >
                              <v-toolbar-title>クロスゼロ一覧</v-toolbar-title>
                            </v-toolbar>
                          </template>
                          <template #default="props">
                            <v-row>
                              <v-col
                                v-for="item in props.items"
                                :key="item.id"
                                cols="12"
                                sm="6"
                                md="4"
                                lg="3"
                              >
                                <v-card>
                                  <v-divider />
                                  <v-list dense>
                                    <v-list-item>
                                      <v-list-item-content>
                                        状態:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{
                                          item.is_terminated
                                            | convertBooleanMaintenanceText
                                        }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        プラン:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{ item.plan }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        利用期間:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{ item.contract_start_date }}
                                        ~
                                        {{ item.contract_end_date }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        販売店名:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{ item.ditributor_name }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        担当営業:
                                      </v-list-item-content>
                                      <v-list-item-content class="align-end">
                                        {{ item.staff_name }}
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-card>
                              </v-col>
                            </v-row>
                          </template>
                        </v-data-iterator>
                      </v-container>
                    </v-card>
                  </v-container>
                </v-tab-item>
              </v-tabs>
            </v-container>
          </v-tab-item>
        </v-tabs>
      </v-container>
      <v-container v-if="contents.data.partition">
        <v-row>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-card>
              <v-toolbar class="mb-2" color="indigo darken-5" dark flag>
                <v-toolbar-title>デキスパート仕切</v-toolbar-title>
              </v-toolbar>
              <v-simple-table>
                <tbody v-if="contents.data.partition">
                  <tr>
                    <td>通常</td>
                    <td>
                      {{ contents.data.partition.mai_devs }}
                    </td>
                  </tr>
                  <tr>
                    <td>保守</td>
                    <td>
                      {{ contents.data.partition.mai_devh }}
                    </td>
                  </tr>
                </tbody>
                <p v-else>情報が取得できませんでした。</p>
              </v-simple-table>
            </v-card>
            <v-card>
              <v-toolbar class="mb-2" color="indigo darken-5" dark flag>
                <v-toolbar-title>工事実績仕切</v-toolbar-title>
              </v-toolbar>
              <v-simple-table>
                <tbody v-if="contents.data.partition">
                  <tr>
                    <td>通常</td>
                    <td>
                      {{ contents.data.partition.mai_devjd }}
                    </td>
                  </tr>
                  <tr>
                    <td>保守</td>
                    <td>
                      {{ contents.data.partition.mai_devjdh }}
                    </td>
                  </tr>
                </tbody>
                <p v-else>情報が取得できませんでした。</p>
              </v-simple-table>
            </v-card>
            <v-card>
              <v-toolbar class="mb-2" color="indigo darken-5" dark flag>
                <v-toolbar-title>イノサイト仕切</v-toolbar-title>
              </v-toolbar>
              <v-simple-table>
                <tbody v-if="contents.data.partition">
                  <tr>
                    <td>通常</td>
                    <td>
                      {{ contents.data.partition.mai_devsi }}
                    </td>
                  </tr>
                  <tr>
                    <td>保守</td>
                    <td>
                      {{ contents.data.partition.mai_devsih }}
                    </td>
                  </tr>
                </tbody>
                <p v-else>情報が取得できませんでした。</p>
              </v-simple-table>
            </v-card>
            <v-card>
              <v-toolbar class="mb-2" color="indigo darken-5" dark flag>
                <v-toolbar-title>クラウド仕切</v-toolbar-title>
              </v-toolbar>
              <v-simple-table>
                <tbody v-if="contents.data.partition">
                  <tr>
                    <td>KSDB</td>
                    <td>
                      {{ contents.data.partition.mai_devdb }}
                    </td>
                  </tr>
                  <tr>
                    <td>SiteBox 他</td>
                    <td>
                      {{ contents.data.partition.mai_devsb }}
                    </td>
                  </tr>
                </tbody>
                <p v-else>情報が取得できませんでした。</p>
              </v-simple-table>
            </v-card>
            <v-card>
              <v-toolbar class="mb-2" color="indigo darken-5" dark flag>
                <v-toolbar-title>工事情報共有システム</v-toolbar-title>
              </v-toolbar>
              <v-simple-table>
                <tbody v-if="contents.data.partition">
                  <tr>
                    <td>通常</td>
                    <td>
                      {{ contents.data.partition.mai_devkjk }}
                    </td>
                  </tr>
                </tbody>
                <p v-else>情報が取得できませんでした。</p>
              </v-simple-table>
            </v-card>
            <v-card>
              <v-toolbar class="mb-2" color="indigo darken-5" dark flag>
                <v-toolbar-title>その他</v-toolbar-title>
              </v-toolbar>
              <v-simple-table>
                <tbody v-if="contents.data.partition">
                  <tr>
                    <td>デキスク</td>
                    <td>
                      {{ contents.data.partition.mai_devds }}
                    </td>
                  </tr>
                  <tr>
                    <td>TsunaguWeb</td>
                    <td>
                      {{ contents.data.partition.mai_devtng }}
                    </td>
                  </tr>
                  <tr>
                    <td>TsunaguWeb製作費</td>
                    <td>
                      {{ contents.data.partition.mai_devtngp }}
                    </td>
                  </tr>
                  <tr>
                    <td>サポート</td>
                    <td>
                      {{ contents.data.partition.mai_devsp }}
                    </td>
                  </tr>
                </tbody>
                <p v-else>情報が取得できませんでした。</p>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>


<style>
/* 固定ヘッダー用のスタイル */
.table-container {
  overflow-x: auto;
  max-height: 400px; /* ここの高さは適宜調整してください */
}

/* 特定のテーブルに適用するためのクラス「shipping-history-table」を指定 */
.shipping-history-table {
  width: 100%;
  border-collapse: collapse;
}

.shipping-history-table thead {
  position: sticky;
  top: 0;
  background-color: #f0f0f0;
  z-index: 1;
}

.shipping-history-table th,
.shipping-history-table td {
  border: 1px solid #ccc;
  padding: 8px;
}

/* 特定のテーブルに適用するためのクラス「shipping-history-table」を指定 */
.kjk-table {
  width: 100%;
  border-collapse: collapse;
}

.kjk-table thead {
  position: sticky;
  top: 0;
  background-color: #d9e5ff;
  z-index: 1;
}

.kjk-table th,
.kjk-table td {
  border: 1px solid #ccc;
  padding: 8px;
}
</style>

<script>
import Axios from "axios";
import AxiosRetry from "axios-retry";
import Token from "./token";

export default {
  filters: {
    addComma: (val) => {
      return val.toLocaleString();
    },
    convertBooleanText: (val) => {
      return val === true ? "はい" : "いいえ";
    },
    convertBooleanMaintenanceText: (val) => {
      return val === true ? "退会" : "加入中";
    },
    convertBooleanUpdatingText: (val) => {
      return val === true ? "案内中" : "";
    },
    convertBooleanProvisionalMemberText: (val) => {
      return val === true ? "申込中" : "いいえ";
    },
    convertBooleanDekispartSchoolPriceText: (val) => {
      return val === true ? "申込価格" : "価格";
    },
    convertSubcontractingCostText: (val) => {
      return val === -1 ? "有" : "無";
    },
    convertKjkRevsignProductNameText: (val) => {
      return val === "kjk" ? "KJK" : "RevSIGN";
    },
    convertBooleanThreeDModelText: (val) => {
      return val === 1 ? "あり・有償" : "なし";
    },
    convertKsCloudDbStatusToText: (val) => {
      switch (val) {
        case 0:
          return "読み込み中";
        case 1:
          return "正常";
        case 2:
          return (
            "次の理由により一部の情報を取得できません | KsCloudDB: " +
            this.ksCloudDbMaintenanceInfo.reason
          );
        case 3:
          return "不明";
        case 4:
          return "不明";
      }
    },
    formatActivationCode: (val) => {
      const num = 4;
      const tmpLastPos = val.length % num;
      const lastPos = tmpLastPos === 0 ? 4 : tmpLastPos;
      for (let i = val.length - lastPos; i >= 1; i -= 4) {
        val = val.slice(0, i) + "-" + val.slice(i);
      }
      return val;
    },
    formatRemainingMonths: (val) => {
      const isMinus = val < 0;
      val = Math.abs(val);
      const year = (val / 12) | 0;
      const month = val - year * 12;
      let str = isMinus ? "- " : "";
      str += year + " 年 " + month + " ヶ月";
      return str;
    },
  },
  props: {
    kentemId: {
      type: String,
      required: true,
    },
    kentemIdUrl: {
      type: String,
      default: "https://id.kentem.com",
    },
    apiToken: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cloudTabs: [
        { name: "クラウド情報一覧" },
        { name: "クラウドユーザー情報" },
      ],
      cloudUserTabs: [{ name: "登録済み" }, { name: "招待中" }],
      contents: "",
      downloading: true,
      progress: 0,
      tabs: [
        { name: "デキスパート" },
        { name: "クラウド" },
        { name: "デキスク" },
        { name: "イノサイト" },
        { name: "工事実績" },
        { name: "RevSIGN" },
        { name: "Prodougu" },
        { name: "DOKO-PLAN" },
        { name: "クロスゼロ" },
      ],
      basicRecords: [
        { key: "kentem_id", name: "Kentem ID", parent: "request" },
        { key: "shokon_code", name: "商魂コード", parent: "data" },
      ],
      dekispartRecords: [
        { key: "user_id", name: "ユーザーID" },
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
      ],
      cloudReceiverRecords: [
        { key: "company_name1", name: "会社名1" },
        { key: "company_name2", name: "会社名2" },
        { key: "company_address", name: "住所" },
      ],
      cloudDatabankRecords: [
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
        { key: "gigabyte_capacity", name: "契約容量(GB)" },
      ],
      cloudSiteboxRecords: [
        { key: "number_of_licenses", name: "ライセンス数" },
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
      ],
      cloudSiteboxBooleanRecords: [
        { key: "is_set_pack", name: "おまとめパック" },
      ],
      cloudFieldnetRecords: [
        { key: "number_of_licenses", name: "ライセンス数" },
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
      ],
      cloudSiteboxTunnelRecords: [
        { key: "number_of_licenses", name: "ライセンス数" },
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
      ],
      cloudSleeveBackingProcessOfReinfoceingBarRecords: [
        { key: "number_of_licenses", name: "ライセンス数" },
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
      ],
      cloudDatabankPremiumRecords: [
        { key: "number_of_licenses", name: "ライセンス数" },
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
      ],
      cloudFinishedShapeCloudRecords: [
        { key: "number_of_licenses", name: "ライセンス数" },
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
      ],
      cloudQualityManagementCloudRecords: [
        { key: "number_of_licenses", name: "ライセンス数" },
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
      ],
      cloudConstructionArrangementCloudRecords: [
        { key: "number_of_licenses", name: "ライセンス数" },
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
      ],
      cloudKentemConnectRecords: [
        { key: "number_of_licenses", name: "ライセンス数" },
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
      ],
      cloudKaisokuScanRecords: [
        { key: "number_of_licenses", name: "ライセンス数" },
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
      ],
      cloudProjectManagementCloudRecords: [
        { key: "number_of_licenses", name: "ライセンス数" },
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
      ],
      cloudRemoteInspectionContractRecords: [
        { key: "ri_contract_start_date", name: "開始日" },
        { key: "ri_contract_end_date", name: "満了日" },
      ],
      constructionResultCloudRecords: [
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
      ],
      cloudKaisokuArRecords: [
        { key: "number_of_licenses", name: "ライセンス数" },
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
      ],
      cloudKaisokuNaviRecords: [
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
        { key: "edition", name: "エディション" },
      ],
      dekispartSchoolRecords: [
        { key: "constract_start_date", name: "開始日" },
        { key: "constract_end_date", name: "満了日" },
      ],
      CaseLogRecords: [
        { key: "category", name: "カテゴリー" },
        { key: "request_date", name: "依頼日" },
        { key: "action_status", name: "進捗状況" },
        { key: "construction_name", name: "工事名" },
        { key: "construction_start", name: "工期開始日" },
        { key: "construction_end", name: "工期満了日" },
        { key: "shop_name", name: "販売店名" },
        { key: "shop_shcode", name: "販売店コード" },
      ],
      CaseLogOrderRecords: [
        { key: "system_company_cd", name: "会社コード" },
        { key: "government_name1", name: "発注機関名" },
        { key: "government_name2", name: "発注機関部名" },
        { key: "government_name3", name: "発注機関事務所名" },
        { key: "government_name4", name: "発注機関課名" },
        { key: "government_address", name: "発注機関住所" },
        { key: "government_tel", name: "発注機関TEL	" },
        { key: "government_fax", name: "発注機関FAX" },
      ],
      dekispartSchoolBooleanRecords: [
        { key: "is_sixty_thousand_yen", name: "6万円か" },
        { key: "is_twenty_five_thousand_yen", name: "2万5千円か" },
        { key: "is_campaign", name: "キャンペーンか" },
      ],
      innositeRecords: [
        { key: "auth_name", name: "認証名" },
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
        { key: "number_of_licenses", name: "ライセンス数" },
        { key: "deposit_type_name", name: "更新方法" },
      ],
      innositeOptionRecords: [
        { key: "number_of_options", name: "オプション本数" },
      ],
      constructionResultRecords: [
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
        { key: "number_of_licenses", name: "ライセンス数" },
        { key: "deposit_type_name", name: "更新方法" },
      ],
      prodouguResultRecords: [
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
        { key: "capacity_of_contract", name: "契約容量(GB)" },
        { key: "number_of_licenses", name: "ライセンス数" },
      ],
      prodouguMobileRecords: [
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
        { key: "number_of_licenses", name: "ライセンス数" },
      ],
      baseUrl: this.KentemIdUrl + "/",
      url: this.kentemIdUrl + "/api/v1/kentem/ks_cloud_db/detail/",
      withoutKsCloudDbUrl: this.kentemIdUrl + "/api/v1/kentem/detail/",
      tokenUrl: "/api/v1/token",
      ksCloudDbMaintenanceInfo: null,
      ksCloudDbStatus: 0,
      bearer: "",
      customerKanaFormValue: "",
      customerZipFormValue: "",
      customerTelFormValue: "",
      customerFaxFormValue: "",
      customerPartyId: "",
    };
  },
  watch: {
    kentemId: function (newKentemId) {
      this.url = "/api/v1/kentem/ks_cloud_db/detail/";
      this.url = this.url + newKentemId;
      this.withoutKsCloudDbUrl = "/api/v1/kentem/detail/";
      this.withoutKsCloudDbUrl = this.withoutKsCloudDbUrl + newKentemId;
      this.getTokenAndLoadList();
    },
  },
  created: function () {
    this.onCreated();
  },

  methods: {
    /**
     *
     */
    async onCreated() {
      this.url = this.url + this.kentemId;
      this.withoutKsCloudDbUrl = this.withoutKsCloudDbUrl + this.kentemId;
      await this.queryKsCloudDbMaintenanceInformation();
      this.getTokenAndLoadList();
    },

    /**
     *
     */
    async loadList() {
      await Axios.get(
        this.ksCloudDbStatus != 2 ? this.url : this.withoutKsCloudDbUrl,
        {
          onDownloadProgress: this.onDownload,
          headers: {
            Authorization: this.bearer,
          },
        }
      )
        .then((res) => {
          if (res.data) {
            this.contents = res.data;
            this.getKentemInfo(this.kentemId);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.downloading = false;
      this.progress = 100;

      Object.keys(this.contents.data.reference_numbers).forEach((key) => {
        if (this.contents.data.reference_numbers[key].dekispart) {
          console.log(
            this.contents.data.reference_numbers[key].dekispart.products
          );
          this.contents.data.reference_numbers[key].dekispart.products.sort(
            this.compareDekispartProducts
          );
        }
      });
    },

    /**
     *
     */
    async queryKsCloudDbMaintenanceInformation() {
      AxiosRetry(Axios, {
        retries: 5,
        retryCondition: () => true,
        retryDelay: (retryCount, error) => 2,
      });
      await Axios.get("/api/v1/ks_cloud_db/maintenance", {
        headers: {
          Authorization: "Bearer " + this.apiToken,
        },
      })
        .then((res) => {
          if (res.data) {
            this.ksCloudDbMaintenanceInfo = res.data;
            if (this.ksCloudDbMaintenanceInfo.is_under_maintenance) {
              this.ksCloudDbStatus = 2;
            }
            this.ksCloudDbStatus = 1;
          } else {
            this.ksCloudDbStatus = 4;
          }
        })
        .catch((error) => {
          this.ksCloudDbStatus = 3;
          console.log(error);
        });
    },

    /**
     *
     * @param {Object} e
     */
    onDownload(e) {
      this.progress = Math.floor((e.loaded * 100) / e.total);
    },

    /**
     *
     */
    async getTokenAndLoadList() {
      this.bearer = await Token(this.tokenUrl);
      this.loadList();
    },

    /**
     *
     * @param {Object} first
     * @param {Object} second
     */
    compareDekispartProducts(first, second) {
      const obsolete_prefix = "×";
      if (
        first.name.charAt(0) === obsolete_prefix &&
        second.name.charAt(0) !== obsolete_prefix
      ) {
        return 1;
      }
      if (
        first.name.charAt(0) !== obsolete_prefix &&
        second.name.charAt(0) === obsolete_prefix
      ) {
        return -1;
      }
      return 0;
    },

    /**
     *
     * @param {String} id
     */
    getKentemInfo(id) {
      Axios.get(`/api/v1/osc/kentem_info/${id}`, {
        headers: {
          Authorization: "Bearer " + this.apiToken,
        },
      })
        .then((res) => {
          if (res.data) {
            this.customerKanaFormValue = res.data.OrganizationDEO_kana_c ?? "";
            this.customerZipFormValue = res.data.PostalCode ?? "";
            this.customerTelFormValue = res.data.RawPhoneNumber ?? "";
            (this.customerFaxFormValue = res.data.RawFaxNumber ?? ""),
              (this.customerPartyId = res.data.PartyId ?? "");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    /**
     * @param {Object} p
     * @param {String} name
     * @param {String} ableText
     * @param {String} unableText
     */
    getRoleText(p, name, ableText = "利用者", unableText = "×") {
      return p.roles.map((item) => item.roleName).includes(name)
        ? ableText
        : unableText;
    },
    /**
     *
     */
    openNewEstimate() {
      const url = "/estimate/new/from/kenteminfo/" + this.kentemId;
      window.open(url, "_blank");
    },
    /**
     *
     * @param {String} shokonCode
     */
    openUserDB(shokonCode) {
      const url =
        "http://ks-db.kentem.net/userdb/userdb2.php?scode=" +
        shokonCode +
        "&slist=3&rlist=2";
      window.open(url, "_blank");
    },
    /**
     *
     * @param {String} shokonCode
     */
    openCloudDetail(shokonCode) {
      const url = "http://adks-cloud.kentem.net/Customer/" + shokonCode;
      window.open(url, "_blank");
    },
    /**
     *
     */
    openOsc() {
      const url =
        "https://fa-epck-saasfaprod1.fa.ocs.oraclecloud.com/crmUI/faces/FuseOverview?fndGlobalItemNodeId=itemNode_workspace_workspace&fndTaskItemNodeId=ZCA_CC_INTERACTIVE_UI_CRM&fnd=%3BentityName%253DAccount%253BsubTabName%253DOverview%253BrecordId%253D" +
        this.customerPartyId +
        "%253B%3B%3B%3Bfalse%3B256%3B%3B%3B";
      window.open(url, "_blank");
    },

    /**
     *
     * @param {string} membership_name - 新しい会員名
     * @param {string} old_membership_name - 以前の会員名
     */
    setMembershipName(membership_name, old_membership_name) {
      // old_membership_nameがnullまたは空白の場合は(membership_name)を表示
      if (!old_membership_name) {
        return `(${membership_name})`;
      }
      // old_membership_nameに値がある場合はmembership_name(old_membership_name)を表示
      return `${membership_name} (${old_membership_name})`;
    },
  },
};
</script>