<template>
  <div class="text-center">
    <div class="form-group">
      <p>KentemIDキャッシュ削除</p>
      <v-btn
        color="blue darken-1"
        text
        @click="clearKentemIdCache()"
      >
        {{
          $t("delete")
        }}
      </v-btn>
    </div>
    <div class="form-group">
      <p>KentemIDトークン再発行</p>
      <v-btn
        color="blue darken-1"
        text
        @click="issueKentemIdToken()"
      >
        {{
          $t("issue")
        }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import Token from "./token";

export default {
    props: {
        kentemIdUrl: {
            type: String,
            default: "https://id.kentem.com",
        },
    },
    data() {
        return {
            bearer: "",
            tokenUrl: "/api/v1/token",
        };
    },
    created: function () {
        this.getToken();
    },
    methods: {
        /**
         * 
         */
        clearKentemIdCache() {
            Axios.delete(this.kentemIdUrl + '/api/v1/artisan/cache/clear', {
                headers: {
                    Authorization: this.bearer,
                },
            })
            .then(() => {
                alert("キャッシュ削除完了");
            })
            .catch((error) => {
                console.log(error);
                switch (error.response.status) {
                case 401:
                    alert(
                        "セッションの期限切れなどの理由により、通信に失敗しました。リロードしてください。"
                    );
                    break;
                case 404:
                    alert("接続先が見つかりませんでした。");
                    break;
                case 422:
                    alert(JSON.stringify(error.response.data.errors));
                    break;
                case 500:
                    alert("内部エラー");
                    break;
                default:
                    alert("通信エラーが発生しました、再度お試しください。");
                    break;
                }
            });
        },
        /**
         * 
         */
        issueKentemIdToken() {
            Axios.post('/api/v1/kentem_id/token/issue')
            .then(() => {
                alert("トークン発行完了");
            })
            .catch((error) => {
                console.log(error);
                switch (error.response.status) {
                case 401:
                    alert(
                        "セッションの期限切れなどの理由により、通信に失敗しました。リロードしてください。"
                    );
                    break;
                case 404:
                    alert("接続先が見つかりませんでした。");
                    break;
                case 422:
                    alert(JSON.stringify(error.response.data.errors));
                    break;
                case 500:
                    alert("内部エラー");
                    break;
                default:
                    alert("通信エラーが発生しました、再度お試しください。");
                    break;
                }
            });
        },
        /**
         * 
         */
        async getToken() {
            this.bearer = await Token(this.tokenUrl);
        },
    }
};
</script>