<template>
    <div>
      <div
        class="drop-area"
        @dragenter="dragEnter"
        @dragleave="dragLeave"
        @dragover.prevent
        @drop.prevent="dropFile"
        :class="{'enter-drop-area': isEntered}"
      >
        ここにドラッグ&ドロップしてファイルをアップロード
      </div>
    </div>
  </template>

  <script>
  import Axios from "axios";
  export default {
    props: {
      token: {
          type: String,
          required: true,
      },
    },
    data() {
      return {
        headers: [],
        loading: true,
        loadingLabel: this.$i18n.translate("loading"),
        files: [],
        isEntered: false,
      };
    },
    created: function () {
    },
    methods: {
      /**
       *
       */
      dragEnter() {
        this.isEntered = true;
      },
      /**
       *
       */
      dragLeave() {
        this.isEntered = false;
      },
      /**
       *
       * @param {Number} index
       */
      deleteFile(index) {
        this.files.splice(index, 1);
      },
      /**
       * @param {Event} event
       */
      dropFile(event) {
        const temporaryFiles = event.dataTransfer.files;
        if (temporaryFiles.length > 1) {
          alert("複数ファイルのアップロードには対応していません");
          return;
        }
        if (temporaryFiles.length < 0) {
          alert("ファイルが指定されていません");
          return
        }
        if (temporaryFiles.item(0).size > 1048576) {
          alert("1MBを超えるファイルはアップロードできません");
          return;
        }
        this.files.push(temporaryFiles.item(0));
        this.isEntered = false;

        this.sendFile();
      },
      /**
       *
       */
      sendFile() {
        this.files.forEach(file => {
          const formData = new FormData();
          formData.append('pdf', file);
          Axios.post(
            '/api/v1/hardware_parts_pdf',
            formData,
            {
              headers: { Authorization: "Bearer " + this.token },
            }
          )
          .then((res) => {
            console.log("success");
            this.files.splice(0, this.files.length);
            alert("アップロードに成功しました");
          })
          .catch((error) => {
            console.log(error);
            switch (error.response.status) {
              case 401:
                alert(
                  "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
                );
                break;
              case 404:
                alert("接続先が見つかりませんでした。");
                break;
              case 422:
                alert(
                  JSON.stringify(error.response.data.errors.kentem_product_id[0])
                );
                break;
              case 500:
                alert("内部エラー");
                break;
              default:
                alert("通信エラーが発生しました、再度お試しください。");
                break;
            }
          });
        });
      }
    }
  }
  </script>
