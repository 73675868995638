<template>
  <div class="col-xs-12 col-sm-12 col-md-12 py-0">
    <div class="form-row">
      <div class="col-xs-12 col-sm-6 col-md-6 py-0">
        <div class="form-group">
          <label class="col-form-label">
            <span>{{ customerLabelData }}</span>
            <span
              v-if="customerRequired"
              class="badge badge-primary"
            >{{
              $t("required")
            }}</span>
            <span
              v-else
              class="badge badge-secondary"
            >{{
              $t("optional")
            }}</span>
          </label>
          <div class="input-group">
            <input
              type="text"
              :value="customerLabelValue"
              :readonly="true"
              class="form-control"
            >
            <div class="input-group-append" v-if="!this.readonly">
              <button class="btn btn-outline-secondary" @click="clearSelection" type="button">
                ×
              </button>
            </div>
          </div>
          <input
            type="hidden"
            :name="customerName"
            :value="customerFormValue"
            :required="customerRequired"
            readonly="readonly"
          >
          <v-dialog
            v-model="customerDialog"
            persistent
            scrollable
            max-width="1000px"
            style="z-index: 1050"
          >
            <template v-if="!this.readonly" #activator="{ on }">
              <v-btn
                color="primary"
                dark
                v-on="on"
              >
                {{
                  $t("reference")
                }}
              </v-btn>
            </template>
            <v-card>
              <v-toolbar
                color="light-blue darken-4"
                dark
              >
                <v-toolbar-title>{{ customerTitleData }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon>
                  <v-icon
                    @click="customerDialog = false"
                  >
                    fas fa-window-close
                  </v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <ref-customerset-customercard
                        :callback="selectCustomerItem"
                        :customer-headers="customerHeaders"
                        :customer-items="customerItems"
                        :customer-loading="customerLoading"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue darken-1"
                  text
                  @click="customerDialog = false"
                >
                  {{ $t("close") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="customerDetail"
            max-width="1300px"
            style="z-index: 1050"
          >
            <template #activator="{ on }">
              <v-btn
                color="primary"
                dark
                v-on="on"
              >
                {{ $t("detail") }}
              </v-btn>
            </template>
            <kentem-detail
              :kentem-id="customerFormValue"
              :kentem-id-url="kentemIdUrl"
            />
          </v-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import Token from "./token";

export default {
  props: {
    customerDefaultLabelName: {
      type: String,
      default: "name",
    },
    customerDefaultValue: {
      type: String,
      default: "",
    },
    customerIdName: {
      type: String,
      default: "kentem_id",
    },
    customerLabel: {
      type: String,
      default: "",
    },
    customerLabelKey: {
      type: String,
      default: "name",
    },
    customerName: {
      type: String,
      default: "customer_kentem_id",
    },
    customerRequired: {
      type: Boolean,
      default: true,
    },
    customerTitle: {
      type: String,
      default: "",
    },
    customerUrl: {
      type: String,
      default: "https://id.kentem.com" + "/api/v1/kentem_customer/data_table",
    },
    dealerUrl: {
      type: String,
      default: "https://id.kentem.com" + "/api/v1/kentem_sales/data_table",
    },
    kentemIdUrl: {
      type: String,
      default: "https://id.kentem.com",
    },
    old: {
      type: [Object, Array],
      default: () => {},
    },
    kentemIdDefault: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      bearer: "",
      customerDefaultLabel: this.$i18n.translate("estimate.actnm"),
      customerDefaultTitle: this.$i18n.translate("顧客情報一覧"),
      customerDialog: false,
      customerDetail: false,
      customerFormValue: this.customerDefaultValue,
      customerHeaders: [],
      customerItems: [],
      customerLabelData: "",
      customerLabelName: this.customerDefaultLabelName,
      customerLabelValue: "",
      customerLoading: true,
      customerTitleData: "",
      tokenUrl: "/api/v1/token",
    };
  },
  created: function () {
    this.customerLabelName =
      this.customerDefaultLabelName ?? this.customerIdName;
    this.dealerLabelName = this.dealerDefaultLabelName ?? this.dealerIdName;
    this.referenceLabelName =
      this.referenceDefaultLabelName ?? this.referenceIdName;

    if (this.customerLabel === "") {
      this.customerLabelData = this.customerDefaultLabel;
    }
    if (this.customerTitle === "") {
      this.customerTitleData = this.customerDefaultTitle;
    }

    this.getTokenAndLoadList();
  },
  methods: {
    clearSelection() {
      this.customerLabelValue = ""; // ラベルをクリア
      this.customerFormValue = ""; // IDをクリア
    },
    /**
     *
     */
    async loadList() {
      this.customerLoading = true;
      if (this.customerFormValue !== "" && this.customerLabelKey !== "") {
        this.customerLabelValue = this.$i18n.translate("loading");
      }
      const data = await this.getData();
      if (data.customer) {
        this.customerHeaders = data.customer.headers;
        this.customerItems = data.customer.items;
      }
      if (data.dealer) {
        this.dealerHeaders = data.dealer.headers;
        this.dealerItems = data.dealer.items;
      }

      if(this.kentemIdDefault !== ""){
        this.customerFormValue = this.kentemIdDefault
      }

      if (this.customerFormValue !== "" && this.customerLabelKey !== "") {
        this.referenceItems = await this.getReferencesByKentemIdPromise(
          this.customerFormValue
        );
        const defaultItem = this.customerItems.find(
          (row) => row[this.customerIdName] === this.customerFormValue
        );
        this.customerLabelValue =
          typeof defaultItem !== "undefined"
            ? defaultItem[this.customerLabelKey]
            : this.$i18n.translate("unknown");
      }
      this.customerLoading = false;
    },
    /**
     *
     */
    getData() {
      return Promise.all([
        Axios.get(this.customerUrl, {
          headers: {
            Authorization: this.bearer,
          },
        }),
        Axios.get(this.dealerUrl, {
          headers: {
            Authorization: this.bearer,
          },
        }),
      ])
        .then(([customer, dealer]) => {
          const data = {};
          data.customer = customer.data;
          data.dealer = dealer.data;
          return Promise.resolve(data);
        })
        .catch((error) => {console.log(error);});
    },
    /**
     *
     * @param {String} id
     */
    getReferencesByKentemId(id) {
      this.referenceLabelValue = this.$i18n.translate("loading");
      Axios.get(
        this.kentemIdUrl + `/api/v1/kentem/reference/data_table/${id}`,
        {
          headers: {
            Authorization: this.bearer,
          },
        }
      )
        .then((res) => {
          this.referenceLoading = false;
          this.referenceLabelValue = "";
          if (res.data) {
            this.referenceHeaders = res.data.headers;
            this.referenceItems = res.data.items;
          }
        })
        .catch((error) => {
          console.log(error);
          this.referenceLabelValue = this.$i18n.translate("unknown");
        });
    },
    /**
     *
     * @param {String} id
     */
    getReferencesByKentemIdPromise(id) {
      this.referenceLabelValue = this.$i18n.translate("loading");
      return Promise.all([
        Axios.get(
          this.kentemIdUrl + `/api/v1/kentem/reference/data_table/${id}`,
          {
            headers: {
              Authorization: this.bearer,
            },
          }
        ),
      ])
        .then((res) => {
          this.referenceLoading = false;
          this.referenceLabelValue = "";
          if (res[0].data) {
            this.referenceHeaders = res[0].data.headers;
            return Promise.resolve(res[0].data.items);
          }
        })
        .catch((error) => {
          console.log(error);
          this.referenceLabelValue = this.$i18n.translate("unknown");
        });
    },
    /**
     *
     * @param {String} id
     */
    getShokonCodeByKentemId(id) {
      Axios.get(
        this.kentemIdUrl + `/api/v1/kentem_information/shokon_code/${id}`,
        {
          headers: {
            Authorization: this.bearer,
          },
        }
      )
        .then((res) => {
          if (res.data) {
            this.changeSendtoBydealerIdName(res.data.data);
            this.changeSalesSagmentBydealerIdName(res.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
          this.dealerLabelValue = this.$i18n.translate("unknown");
        });
    },
    /**
     *
     * @param {Object} item
     */
    async selectCustomerItem(item) {
      // エンドユーザーを設定
      this.customerFormValue = item[this.customerIdName];
      this.customerLabelValue = item[this.customerLabelName];
      this.customerDialog = false;
    },
    /**
     *
     */
    async getTokenAndLoadList() {
      this.bearer = await Token(this.tokenUrl);
      this.loadList();
    },
  },
};
</script>
