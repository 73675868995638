<template>
    <v-card
      class="mx-auto"
      elevation="2"
      outlined
      color="light-blue lighten-5"
    >
      <v-card-title>
        <h2>{{ title }}</h2>
        <v-spacer />
      </v-card-title>
      <v-row>
        <v-col cols="5">
          <v-progress-circular
            v-if="connecting"
            :indeterminate="progress === 0"
            :value="progress"
            color="light-blue"
          />
          <p class="text-end">注文確認担当</p>
        </v-col>
        <v-col cols="6">

          <v-select
            v-model="inputValue"
            :options="userList"
            :reduce="(name) => name.id"
            label="name"
          >
            <template #search="{ attributes, events }">
              <input
                v-model="inputValue"
                name="user-id-input"
                class="vs__search"
                v-bind="attributes"
                style="background-color: white"
                v-on="events"
              >
            </template>
          </v-select>



        </v-col>
        <v-col cols="1">
          <v-btn type="button" class="bg-gradient-primary shadow" icon @click="create">
            <i class="fas fa-plus" />
          </v-btn>
        </v-col>
      </v-row>
      <v-text-field
        v-model="search"
        append-icon="fa-search"
        :label="searchLabel"
        single-line
        hide-details
      />
      <v-data-table
        :headers="headers"
        :items="OrderConfirmationStaffItems"
        class="elevation-1"
        :search="search"
      >
        <template #[`item.delete`]="{ item }">
          <form
            :action="getDeleteUrl(baseurl, item)"
            method="post"
          >
            <input
              type="hidden"
              name="_token"
              :value="getCsrfToken()"
            >
            <input
              type="hidden"
              name="_method"
              value="DELETE"
            >
            <v-btn
              v-confirm="deleteConfirmationLabel"
              type="submit"
              icon
              class="bg-gradient-danger shadow"
            >
              <i class="fas fa-user-slash" />
            </v-btn>
          </form>
        </template>
      </v-data-table>
    </v-card>
  </template>
  
  <script>
  import Axios from "axios";
  
  export default {
    props: {
      apiToken: {
          type: String,
          required: true,
      },
      baseurl: {
        type: String,
        required: true,
      },
      headers: {
        type: Array,
        required: true,
      },
      items: {
        type: Array,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      idName: {
        type: String,
        default: "id",
      },
      userList: {
        type: Array,
        required: true,
      }
    },
    data() {
      return {
        connecting: false,
        deleteConfirmationLabel: this.$i18n.translate("delete ok?"),
        inputValue: "",
        OrderConfirmationStaffItems: [],
        progress: 100,
        search: "",
        searchLabel: this.$i18n.translate("search"),
      };
    },
    created() {
      this.OrderConfirmationStaffItems = this.items;

      this.userList.unshift(
        {
          'id': 0,
          'name': '',
        },
      );
    },
    methods: {
      /**
       * 
       * @param {String} baseurl 
       * @param {Object} item 
       */
      detailItem(baseurl, item) {
        location.href = "/" + baseurl + "/" + item[this.idName];
      },
      /**
       * 
       * @param {String} baseurl 
       * @param {Object} item 
       */
      editItem(baseurl, item) {
        location.href = "/" + baseurl + "/" + item[this.idName] + "/edit";
      },
      /**
       * 
       * @param {Object} item 
       */
      isViewableItem(item) {
        return item.view;
      },
      /**
       * 
       */
      getCsrfToken() {
        return document.getElementsByName("csrf-token")[0].content;
      },
      /**
       * 
       * @param {String} baseurl 
       * @param {Object} item 
       */
      getDeleteUrl(baseurl, item) {
        return "/" + baseurl + "/" + item[this.idName];
      },
      /**
       * 
       */
      create() {
        this.connecting = true;
        this.progress = 0;
        Axios.post(
          `/api/v1/order_confirmation_staff`,
          {
            user_id: this.inputValue,
          },
          {
            headers: {
              Authorization: 'Bearer ' + this.apiToken
            },
          }
        ).then((res) => {
              this.connecting = false;
              this.progress = 100;
              this.OrderConfirmationStaffItems.push(res.data);
        }).catch((error) => {
              this.connecting = false;
              this.progress = 100;
              console.log(error);
              if (error.response.data.message) {
                alert(error.response.data.message);
                return;
              }
              switch (error.response.status) {
                  case 401:
                      alert("認証エラー");
                      break;
                  default:
                      alert(error);
              }
        });
      },
    },
  };
  </script>