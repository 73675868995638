<template>
  <v-container>
    <v-btn
      v-if="canCreate"
      :href="'/faq/create'"
      icon
      class="bg-gradient-primary shadow"
    >
      <i class="fas fa-plus"></i>
    </v-btn>
    <v-expansion-panels>
      <v-expansion-panel v-for="(item, i) in list" :key="i">
        <faq-item :item="item"
        :can-update="canUpdate"
        :can-delete="canDelete"></faq-item>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
  export default {
    props: {
      list: {
        type: Array,
        required: true,
      },
      canCreate: {
        type: Boolean,
        required: false,
        default: false,
      },
      canUpdate: {
        type: Boolean,
        required: false,
        default: false,
      },
      canDelete: {
        type: Boolean,
        required: false,
        default: false,
      }
    }
  };
  </script>
