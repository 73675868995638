var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-striped table-hover"},[_c('thead',_vm._l((_vm.headers),function(header){return _c('th',{key:header.id,attrs:{"scope":"col"}},[_vm._v("\n        "+_vm._s(header.text)+"\n      ")])}),0),_vm._v(" "),_c('draggable',{attrs:{"tag":"tbody"},on:{"end":_vm.onDragged},model:{value:(_vm.draggableList),callback:function ($$v) {_vm.draggableList=$$v},expression:"draggableList"}},_vm._l((_vm.draggableList),function(row){return _c('tr',{key:row.id},[_c('td',[_vm._v("\n          "+_vm._s(row.id)+"\n        ")]),_vm._v(" "),_c('td',[_vm._v("\n          "+_vm._s(row.kentem_product_id)+"\n        ")]),_vm._v(" "),(!_vm.isEditingProductName || _vm.editingIdForProductName !== row.id)?_c('td',{on:{"dblclick":function($event){return _vm.startEditingProductName(row.id, row.product_name)}}},[_vm._v("\n          "+_vm._s(row.product_name)+"\n        ")]):_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.product_name),expression:"row.product_name"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(row.product_name)},on:{"change":function($event){return _vm.updateProductName(row.id, row.product_name)},"input":function($event){if($event.target.composing){ return; }_vm.$set(row, "product_name", $event.target.value)}}})]),_vm._v(" "),_c('td',{class:_vm.updatedItems[row.id] &&
            _vm.updatedItems[row.id].includes('regular_price')
              ? 'red--text'
              : ''},[_vm._v("\n          "+_vm._s(_vm._f("addComma")(row.regular_price))+"\n        ")]),_vm._v(" "),(
            row.category !== 'B' &&
            (!_vm.isEditingQuantity || _vm.editingIdForQuantity !== row.id)
          )?_c('td',{on:{"dblclick":function($event){return _vm.startEditingQuantity(row.id, row.quantity)}}},[_vm._v("\n          "+_vm._s(row.quantity)+"\n        ")]):(row.category !== 'B')?_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.quantity),expression:"row.quantity"}],staticClass:"form-control",attrs:{"type":"number","min":"1","step":"1"},domProps:{"value":(row.quantity)},on:{"change":function($event){return _vm.updateQuantity(row.id, row.quantity)},"input":function($event){if($event.target.composing){ return; }_vm.$set(row, "quantity", $event.target.value)}}})]):_c('td',[_vm._v("\n          "+_vm._s(row.quantity)+"\n        ")]),_vm._v(" "),_c('td',[_vm._v("\n          "+_vm._s(_vm._f("addComma")(row.unit_price))+"\n        ")]),_vm._v(" "),_c('td',{class:_vm.updatedItems[row.id] &&
            _vm.updatedItems[row.id].includes('partition_rate')
              ? 'red--text'
              : ''},[_c('v-container',[_c('v-row',{attrs:{"align-content":"center"}},[(_vm.isBulkUpdatePartitionRateValue)?_c('v-checkbox',{staticClass:"m-0 p-0",attrs:{"value":row.id},model:{value:(_vm.bulkPartitionRateItems),callback:function ($$v) {_vm.bulkPartitionRateItems=$$v},expression:"bulkPartitionRateItems"}}):_vm._e(),_vm._v("\n              "+_vm._s(row.partition_rate)+"\n            ")],1)],1)],1),_vm._v(" "),(!_vm.isEditingVarietyId || _vm.editingIdForVarietyId !== row.id)?_c('td',{class:_vm.updatedItems[row.id] &&
            _vm.updatedItems[row.id].includes('variety_id')
              ? 'red--text'
              : '',on:{"dblclick":function($event){return _vm.startEditingVarietyId(row.id, row.variety_id)}}},[_vm._v("\n          "+_vm._s(row.variety_id)+"\n        ")]):_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.variety_id),expression:"row.variety_id"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(row.variety_id)},on:{"change":function($event){return _vm.updateVarietyId(row.id, row.variety_id)},"input":function($event){if($event.target.composing){ return; }_vm.$set(row, "variety_id", $event.target.value)}}})]),_vm._v(" "),_c('td',[_vm._v("\n          "+_vm._s(row.oso_product_code)+"\n        ")]),_vm._v(" "),_c('td',[_c('a',{attrs:{"href":'/admin/product/edit/' + row.id}},[_c('i',{staticClass:"fas fa-tools"})])]),_vm._v(" "),_c('td',[_c('form',{attrs:{"action":'/admin/product/delete/' + row.id,"method":"post"}},[_c('input',{attrs:{"type":"hidden","name":"_method","value":"DELETE"}}),_vm._v(" "),_c('input',{attrs:{"type":"hidden","name":"_token"},domProps:{"value":_vm.csrf}}),_vm._v(" "),(_vm.isSelectiveDeletionValue)?_c('v-checkbox',{staticClass:"m-0 p-0",attrs:{"value":row.id},model:{value:(_vm.selectiveDeletionItems),callback:function ($$v) {_vm.selectiveDeletionItems=$$v},expression:"selectiveDeletionItems"}}):_vm._e(),_vm._v(" "),(!_vm.isSelectiveDeletionValue)?_c('v-btn',{staticClass:"bg-gradient-danger shadow",attrs:{"type":"button","icon":""},on:{"click":function($event){return _vm.deleteItem(row.id)}}},[_c('i',{staticClass:"far fa-trash-alt"})]):_vm._e()],1)])])}),0)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }