<template>
  <div>
    <form
      ref="confirmForm"
      @submit.prevent="showConfirmation"
      :action="action"
      method="post"
    >
      <input type="hidden" name="_token" :value="csrf" />
      <input type="hidden" name="id" :value="estimateId" />
      <v-btn type="submit" :color="color" class="btn-block"
        ><span style="color: white">{{ text }}</span></v-btn
      >
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    estimateId: {
      type: Number,
      required: true,
    },
    csrf: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      shippingInstructionProductList: [],
    };
  },
  computed: {
    isDekispartFlg() {
      return (
        this.shippingInstructionProductList &&
        !this.shippingInstructionProductList.some((item) => {
          return item.product_code === "1516";
        }) &&
        this.shippingInstructionProductList.some((item) => {
          return (
            item.user_id !== null &&
            (item.requirement_code === 1 || item.requirement_code === 2) &&
            (item.shipment_way === 1 ||
              item.shipment_way === 2 ||
              item.shipment_way === 3) &&
            (item.user_id.startsWith("012") || item.user_id.startsWith("8001"))
          );
        })
      );
    },
    isInnositeFlg() {
      return (
        this.shippingInstructionProductList &&
        !this.shippingInstructionProductList.some(
          (item) => item.product_code === "1582"
        ) &&
        this.shippingInstructionProductList.some((item) => {
          return (
            item.user_id !== null &&
            (item.requirement_code === 1 || item.requirement_code === 2) &&
            (item.shipment_way === 1 ||
              item.shipment_way === 2 ||
              item.shipment_way === 3) &&
            (item.user_id.startsWith("31") ||
              item.user_id.startsWith("32") ||
              item.user_id.startsWith("33") ||
              item.user_id.startsWith("35"))
          );
        })
      );
    },
  },
  methods: {
    /**
     * 見積IDを条件に出荷指示商品情報を取得する
     * @return {Promise}
     */
    getShippingInstructionProductByEstimateId() {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/v1/shipping_instruction_product/estimate_id/${this.estimateId}`,
            {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            }
          )
          .then((res) => {
            if (res.data) {
              this.shippingInstructionProductList = res.data;
              resolve(res.data); // 成功時にデータを返す
            } else {
              reject(new Error("No data received")); // データがない場合はエラーを返す
            }
          })
          .catch((error) => {
            console.error(error);
            reject(error); // エラーが発生した場合はエラーを返す
          });
      });
    },
    async showConfirmation() {
      try {
        await this.getShippingInstructionProductByEstimateId();
        let message = "そのまま実行してよろしいですか？";

        if (this.isDekispartFlg) {
          message =
            "デキスパート最新版の設定の必要があります。そのまま実行しますか？";
        } else if (this.isInnositeFlg) {
          message =
            "INNOSiTE最新版の設定の必要があります。そのまま実行しますか？";
        }

        if (confirm(message)) {
          this.$refs.confirmForm.submit();
        }
      } catch (error) {
        console.error(
          "Failed to get shipping instruction product list:",
          error
        );
      }
    },
  },
};
</script>
