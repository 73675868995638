<template>
  <div class="text-center">
    <template v-if="progress === 100 && contents && ref !== null">
      <v-container>
        <v-tabs>
          <v-tab
            v-for="tab in tabs"
            :key="tab.name"
          >
            所有状況
          </v-tab>
          <v-tab-item>
            <v-container>
              <v-data-iterator
                :items="
                  contents.data.reference_numbers[ref].construction_result
                "
                hide-default-footer
                :items-per-page="1000"
              >
                <template #default="props">
                  <v-row class="pre-scrollable">
                    <v-col
                      v-for="item in props.items"
                      :key="item.id"
                      cols="8"
                      sm="12"
                    >
                      <v-card>
                        <v-simple-table>
                          <tbody v-if="item">
                            <tr>
                              <td
                                v-if="item.is_canceled == true"
                                colspan="4"
                              >
                                ID:{{ item.user_id }}
                                {{
                                  item.is_canceled
                                    | convertBooleanMaintenanceText
                                }}
                              </td>
                              <td
                                v-else
                                colspan="4"
                              >
                                ID:{{ item.user_id }}
                                {{
                                  item.is_canceled
                                    | convertBooleanMaintenanceText
                                }}
                                {{
                                  item.is_updating | convertBooleanUpdatingText
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td bgcolor="#d3d3d3">
                                ライセンス数
                              </td>
                              <td bgcolor="#d3d3d3">
                                期間
                              </td>
                              <td bgcolor="#d3d3d3">
                                契約残月
                              </td>
                              <td bgcolor="#d3d3d3">
                                更新方法
                              </td>
                            </tr>
                            <tr>
                              <td>{{ item.number_of_licenses }}本</td>
                              <td>
                                {{ item.contract_start_date }}～{{
                                  item.contract_end_date
                                }}
                              </td>
                              <td>
                                {{ item.remaining_month_of_contract | formatRemainingMonths }}
                              </td>
                              <td>{{ item.deposit_type_name }}</td>
                            </tr>
                            <tr>
                              <td
                                colspan="3"
                                bgcolor="#d3d3d3"
                              >
                                販売店
                              </td>
                              <td bgcolor="#d3d3d3">
                                業務委託費
                              </td>
                            </tr>
                            <tr>
                              <td colspan="3">
                                {{ item.dealership_code1 }}
                                {{ item.dealership_name1 }}
                              </td>
                              <td>
                                {{
                                  item.subcontracting_cost
                                    | convertSubcontractingCostText
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td bgcolor="#d3d3d3">
                                備考
                              </td>
                              <td colspan="3">
                                {{ item.remarks1 }}
                                {{ item.remarks2 }}
                              </td>
                            </tr>
                          </tbody>
                          <p v-else>
                            情報が取得できませんでした。
                          </p>
                        </v-simple-table>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
              </v-data-iterator>
            </v-container>
          </v-tab-item>
        </v-tabs>
      </v-container>
    </template>
  </div>
</template>

<script>
import Axios from "axios";
import Token from "./token";

export default {
  filters: {
    addComma: (val) => {
      return val.toLocaleString();
    },
    convertBooleanText: (val) => {
      return val === true ? "はい" : "いいえ";
    },
    convertBooleanMaintenanceText: (val) => {
      return val === true ? "退会" : "加入中";
    },
    convertBooleanUpdatingText: (val) => {
      return val === true ? "更新案内中" : "";
    },
    convertBooleanProvisionalMemberText: (val) => {
      return val === true ? "申込中" : "いいえ";
    },
    convertBooleanDekispartSchoolPriceText: (val) => {
      return val === true ? "申込価格" : "価格";
    },
    convertSubcontractingCostText: (val) => {
      return val === -1 ? "有" : "無";
    },
    formatRemainingMonths: (val) => {
      const isMinus = val < 0;
      val = Math.abs(val);
      const year = val / 12 | 0;
      const month = val - year * 12;
      let str = isMinus ? "- " : "";
      str += year + " 年 " + month + " ヶ月";
      return str;
    },
  },
  props: {
    kentemId: {
      type: String,
      required: true,
    },
    kentemIdUrl: {
      type: String,
      default: "https://id.kentem.com",
    },
    reference: {
      type: Number,
      required: false,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      contents: "",
      downloading: true,
      progress: 0,
      tabs: [{ name: "工事実績" }],
      constructionResultRecords: [
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
        { key: "number_of_licenses", name: "ライセンス数" },
        { key: "deposit_type_name", name: "入金種類" },
      ],
      baseUrl: this.KentemIdUrl + "/",
      url: this.kentemIdUrl + "/api/v1/kentem/detail/",
      tokenUrl: "/api/v1/token",
      bearer: "",
      ref: 0,
    };
  },
  watch: {
    kentemId: function (newKentemId) {
      this.url = "/api/v1/kentem/detail/";
      this.url = this.url + newKentemId;
      this.getTokenAndLoadList();
    },
    reference: function (newReference) {
      this.ref = newReference;
    },
  },
  created: function () {
    this.ref = this.reference;
    this.url = this.url + this.kentemId;
    this.getTokenAndLoadList();
  },

  methods: {
    /**
     * 
     */
    async loadList() {
      await Axios.get(this.url, {
        onDownloadProgress: this.onDownload,
        headers: {
          Authorization: this.bearer,
        },
      })
        .then((res) => {
          if (res.data) {
            this.contents = res.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.downloading = false;
      this.progress = 100;
    },
    /**
     * 
     * @param {Object} e 
     */
    onDownload(e) {
      this.progress = Math.floor((e.loaded * 100) / e.total);
    },
    async getTokenAndLoadList() {
      this.bearer = await Token(this.tokenUrl);
      this.loadList();
    },
  },
};
</script>

<style>
.pre-scrollable {
  max-height: 280px;
  overflow-y: scroll;
}
</style>