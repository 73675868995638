<template>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="rgb(54,96,172)"
          v-bind="attrs"
          v-on="on"
        >
          <span style="color: white">ユーザー以外の受注</span>
        </v-btn>
      </template>
  <v-card>
  <v-card-title>
    <strong><span style="color:red;">ユーザー以外の受注</span></strong>
    販売店のデモ版及びOSCに登録のない会社への
    売上計上時の受注処理となります。
    見積などはこちらのボタンからは作成されません。
  </v-card-title>
  <v-divider></v-divider>
  <v-card-actions>
    <v-spacer></v-spacer>
    <form @submit="onSubmit" :action="'/estimate/new/non_user'" method="get"> <input type="hidden" name="_token" :value="csrf">
      <v-btn type="submit">作成</v-btn>
    </form>
  </v-card-actions>
</v-card>
</v-dialog>
</template>
<script>
    export default {
      props: {
      csrf: {
          type: String,
          required: true,
      },
    },
      data () {
        return {
          dialog: false,
          departmentCode:""
        }
      },
    methods: {
      /**
       * 
       * @param {Event} event 
       */
      onSubmit(event) {

      }
    }
    }
</script>