<template>
  <v-tab-item :value="tabName">
    <tab-card-without-form
      :csrf="csrf"
      :errors="errors"
      :msg="msg"
    >
      <slot />
    </tab-card-without-form>
  </v-tab-item>
</template>

<script>
import TabCardWithoutForm from './tab-card-without-form.vue';

export default {
    components: {
        TabCardWithoutForm,
    },
    props: {
        csrf: {
            type: String,
            required: true,
        },
        errors: {
            type: Object,
            default: () => {},
        },
        msg: {
            type: String,
            default: null,
        },
        tabName: {
            type: String,
            required: true,
        }
    }
}
</script>
