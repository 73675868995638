var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"my-2",attrs:{"elevation":"2","outlined":"","color":"light-blue lighten-5"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('h2',{staticClass:"col-xs-12 col-sm-4 col-md-4 mt-2 py-0"},[_vm._v("\n        "+_vm._s(_vm.title)+"\n      ")]),_vm._v(" "),(_vm.connecting)?_c('v-progress-circular',{attrs:{"indeterminate":_vm.progress === 0,"value":_vm.progress,"color":"light-blue"}}):_vm._e()],1)],1),_vm._v(" "),_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(t){return _c('v-tab',{key:t.name,attrs:{"href":'#tab-' + t.name}},[_vm._v("\n      "+_vm._s(t.name)+"\n    ")])}),1),_vm._v(" "),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":'tab-一覧'}},[_c('v-card',[_c('v-text-field',{attrs:{"append-icon":"fa-search","label":_vm.searchLabel,"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.allocatedItems,"search":_vm.search},scopedSlots:_vm._u([{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.editItem(_vm.baseurl, item)}}},[_vm._v("\n                fa-user-edit\n                ")])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('form',{attrs:{"action":_vm.getDeleteUrl(_vm.baseurl, item),"method":"post"}},[_c('input',{attrs:{"type":"hidden","name":"_token"},domProps:{"value":_vm.getCsrfToken()}}),_vm._v(" "),_c('input',{attrs:{"type":"hidden","name":"_method","value":"DELETE"}}),_vm._v(" "),_c('v-btn',{directives:[{name:"confirm",rawName:"v-confirm",value:(_vm.deleteConfirmationLabel),expression:"deleteConfirmationLabel"}],staticClass:"bg-gradient-danger shadow",attrs:{"type":"submit","icon":""}},[_c('i',{staticClass:"fas fa-user-slash"})])],1)]}}],null,true)})],1)],1),_vm._v(" "),_c('v-tab-item',{attrs:{"value":'tab-未割当'}},[_c('v-card',[_c('v-text-field',{attrs:{"append-icon":"fa-search","label":_vm.searchLabel,"single-line":"","hide-details":""},model:{value:(_vm.searchUnallocated),callback:function ($$v) {_vm.searchUnallocated=$$v},expression:"searchUnallocated"}}),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.kentemProductTableHeaders,"items":_vm.unallocatedItems,"search":_vm.searchUnallocated},scopedSlots:_vm._u([{key:"item.osc_product_name",fn:function(ref){
var item = ref.item;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.productNameEntry[item.kentem_product_code]),expression:"productNameEntry[item.kentem_product_code]"}],staticClass:"form-control",attrs:{"name":'osc_product_name' + item.kentem_product_code},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.productNameEntry, item.kentem_product_code, $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.oscProductNameList),function(r){return _c('option',{key:r.id,domProps:{"value":r.id}},[_vm._v("\n                            "+_vm._s(r.nickname !== null ? r.nickname : r.name)+"\n                        ")])}),0)]}},{key:"item.apply",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.applyOscProductChanges(item)}}},[_vm._v("\n                        fa-save\n                    ")])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }