<template>
  <button type="button" class="search-toggle-btn mb-3" @click="toggleBox">
    <i
      :class="
        isBoxVisible ? 'fas fa-toggle-on' : 'fas fa-toggle-off'
      "
    ></i>
    {{ isBoxVisible ? "検索項目を非表示" : "検索項目を表示" }}
  </button>
</template>

<script>
export default {
  data() {
    return {
      isBoxVisible: false,  // コンポーネント内で保持する状態
    };
  },
  methods: {
    toggleBox() {
      this.isBoxVisible = !this.isBoxVisible;  // 表示・非表示を切り替える
      toggleFormFade();  // 親にイベントを伝える
    },
  },
};
function toggleFormFade() {
    const element = document.querySelector('.form-toggle-box');
    if (element.style.display === 'none' || element.style.display === '') {
        element.style.display = 'block';
    } else {
        element.style.display = 'none';
    }
}

</script>

<style scoped>
.search-toggle-btn {
  display: flex;
  align-items: center;
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-width: 168px;
}

.search-toggle-btn:hover {
  background-color: #2980b9;
}

.search-toggle-btn i {
  margin-right: 8px;
}
</style>
