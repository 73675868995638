import Vue from 'vue';
import { Doughnut } from 'vue-chartjs'

Vue.component('PieChart', {
  extends: Doughnut,
  props: {
    data: {
      type: Array,
      required: true
    },
    bgColor: {
      type: Array,
      required: false,
      default: new Array()
    },
    labels: {
      type: Array,
      required: false,
      default: new Array()
    },
  },
  mounted() {
    this.renderChart({
      labels: this.labels,
      datasets: [
        {
          backgroundColor: this.bgColor,
          data: this.data
        }
      ]
    }, { responsive: true, maintainAspectRatio: false })
  }

})