<template>
  <div class="text-center">
    <template v-if="progress === 100 && contents && ref !== null">
      <v-container>
        <v-tabs>
          <v-tab v-for="tab in tabs" :key="tab.name"> 所有状況 </v-tab>
          <v-tab-item>
            <v-container>
              <v-data-iterator
                :items="filteredItems"
                hide-default-footer
                :items-per-page="1000"
              >
                <template #default="props">
                  <v-row class="pre-scrollable">
                    <v-col
                      v-for="item in props.items"
                      :key="item.id"
                      cols="12"
                      sm="12"
                    >
                      <v-card v-if="titleData === item.management_code">
                        <v-simple-table dense>
                          <tbody>
                            <tr>
                              <td colspan="5">
                                ID:{{ item.management_code
                                }}{{ item.remarks_update }}
                              </td>
                            </tr>
                            <tr>
                              <td bgcolor="#d3d3d3">契約形式</td>
                              <td colspan="2" bgcolor="#d3d3d3">
                                クラウド販売店
                              </td>
                              <td colspan="2" bgcolor="#d3d3d3">
                                快測ナビ販売店
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {{ item.contract_type | convertContractType }}
                              </td>
                              <td colspan="2">
                                {{ item.dealership_code1 }}
                                {{ item.dealership_name1 }}
                              </td>
                              <td colspan="2">
                                {{ item.kaisoku_navi_dealership_code1 }}
                                {{ item.kaisoku_navi_dealership_name1 }}
                              </td>
                            </tr>
                            <tr>
                              <td>商品</td>
                              <td colspan="2">容量・数量・サブIDなど</td>
                              <td>期間</td>
                              <td>契約残月</td>
                            </tr>
                            <tr>
                              <td>KSデータバンク</td>
                              <td>{{ item.databank.gigabyte_capacity }}GB</td>
                              <td />
                              <td>
                                {{ item.databank.contract_start_date }}~{{
                                  item.databank.contract_end_date
                                }}
                              </td>
                              <td>
                                {{
                                  item.databank.remaining_month_of_contract
                                    | formatRemainingMonths
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>SiteBox</td>
                              <td>{{ item.sitebox.number_of_licenses }}</td>
                              <td>
                                {{
                                  item.sitebox.is_set_pack
                                    | convertBooleanSiteBoxText
                                }}
                              </td>
                              <td>
                                {{ item.sitebox.contract_start_date }}~{{
                                  item.sitebox.contract_end_date
                                }}
                              </td>
                              <td>
                                {{
                                  item.sitebox.remaining_month_of_contract
                                    | formatRemainingMonths
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>SiteBoxトンネル</td>
                              <td>
                                {{ item.sitebox_tunnel.number_of_licenses }}
                              </td>
                              <td />
                              <td>
                                {{ item.sitebox_tunnel.contract_start_date }}~{{
                                  item.sitebox_tunnel.contract_end_date
                                }}
                              </td>
                              <td>
                                {{
                                  item.sitebox_tunnel
                                    .remaining_month_of_contract
                                    | formatRemainingMonths
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>SiteBox配筋検査</td>
                              <td>
                                {{
                                  item.sleeve_backing_process_of_reinfoceing_bar
                                    .number_of_licenses
                                }}
                              </td>
                              <td />
                              <td>
                                {{
                                  item.sleeve_backing_process_of_reinfoceing_bar
                                    .contract_start_date
                                }}~{{
                                  item.sleeve_backing_process_of_reinfoceing_bar
                                    .contract_end_date
                                }}
                              </td>
                              <td>
                                {{
                                  item.sleeve_backing_process_of_reinfoceing_bar
                                    .remaining_month_of_contract
                                    | formatRemainingMonths
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>写管屋クラウド</td>
                              <td>
                                {{ item.databank_premium.number_of_licenses }}
                              </td>
                              <td />
                              <td>
                                {{
                                  item.databank_premium.contract_start_date
                                }}~{{ item.databank_premium.contract_end_date }}
                              </td>
                              <td>
                                {{
                                  item.databank_premium
                                    .remaining_month_of_contract
                                    | formatRemainingMonths
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>出来形管理クラウド</td>
                              <td>
                                {{
                                  item.finished_shape_cloud.number_of_licenses
                                }}
                              </td>
                              <td />
                              <td>
                                {{
                                  item.finished_shape_cloud.contract_start_date
                                }}~{{
                                  item.finished_shape_cloud.contract_end_date
                                }}
                              </td>
                              <td>
                                {{
                                  item.finished_shape_cloud
                                    .remaining_month_of_contract
                                    | formatRemainingMonths
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>品質管理クラウド</td>
                              <td>
                                {{
                                  item.quality_management_cloud
                                    .number_of_licenses
                                }}
                              </td>
                              <td />
                              <td>
                                {{
                                  item.quality_management_cloud
                                    .contract_start_date
                                }}~{{
                                  item.quality_management_cloud
                                    .contract_end_date
                                }}
                              </td>
                              <td>
                                {{
                                  item.quality_management_cloud
                                    .remaining_month_of_contract
                                    | formatRemainingMonths
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>施工体制クラウド</td>
                              <td>
                                {{
                                  item.construction_arrangement
                                    .number_of_licenses
                                }}
                              </td>
                              <td />
                              <td>
                                {{
                                  item.construction_arrangement
                                    .contract_start_date
                                }}~{{
                                  item.construction_arrangement
                                    .contract_end_date
                                }}
                              </td>
                              <td>
                                {{
                                  item.construction_arrangement
                                    .remaining_month_of_contract
                                    | formatRemainingMonths
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>フィールドネット</td>
                              <td>{{ item.fieldnet.number_of_licenses }}</td>
                              <td />
                              <td>
                                {{ item.fieldnet.contract_start_date }}~{{
                                  item.fieldnet.contract_end_date
                                }}
                              </td>
                              <td>
                                {{
                                  item.fieldnet.remaining_month_of_contract
                                    | formatRemainingMonths
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>快測Scan</td>
                              <td>
                                {{ item.kaisoku_scan.number_of_licenses }}
                              </td>
                              <td />
                              <td>
                                {{ item.kaisoku_scan.contract_start_date }}~{{
                                  item.kaisoku_scan.contract_end_date
                                }}
                              </td>
                              <td>
                                {{
                                  item.kaisoku_scan.remaining_month_of_contract
                                    | formatRemainingMonths
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>KENTEM-CONNECT</td>
                              <td>
                                {{ item.kentem_connect.number_of_licenses }}
                              </td>
                              <td />
                              <td>
                                {{ item.kentem_connect.contract_start_date }}~{{
                                  item.kentem_connect.contract_end_date
                                }}
                              </td>
                              <td>
                                {{
                                  item.kentem_connect
                                    .remaining_month_of_contract
                                    | formatRemainingMonths
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>日報管理クラウド</td>
                              <td>
                                {{
                                  item.project_management_cloud
                                    .number_of_licenses
                                }}
                              </td>
                              <td />
                              <td>
                                {{
                                  item.project_management_cloud
                                    .contract_start_date
                                }}~{{
                                  item.project_management_cloud
                                    .contract_end_date
                                }}
                              </td>
                              <td>
                                {{
                                  item.project_management_cloud
                                    .remaining_month_of_contract
                                    | formatRemainingMonths
                                }}
                              </td>
                            </tr>
                            <tr
                              v-for="r_item in item.remote_inspection_contract"
                              :key="r_item.id"
                            >
                              <td>遠隔臨場 SiteLive</td>
                              <td>
                                {{
                                  r_item.ri_management_sub_code
                                    | convertRSubNumber
                                }}
                              </td>
                              <td>
                                {{ r_item.ri_contract_start_date }}~{{
                                  r_item.ri_contract_end_date
                                }}
                              </td>
                              <td>
                                {{
                                  r_item.ri_remaining_month_of_contract
                                    | formatRemainingMonths
                                }}
                              </td>
                            </tr>
                            <tr
                              v-for="ksar_item in item.kaisoku_ar"
                              :key="ksar_item.id"
                            >
                              <td>快測AR</td>
                              <td>
                                {{ ksar_item.number_of_licenses }}
                              </td>
                              <td />
                              <td>
                                {{ ksar_item.contract_start_date }}~{{
                                  ksar_item.contract_end_date
                                }}
                              </td>
                              <td>
                                {{
                                  ksar_item.remaining_month_of_contract
                                    | formatRemainingMonths
                                }}
                              </td>
                            </tr>
                            <tr
                              v-for="k_item in item.kaisoku_navi"
                              :key="k_item.id"
                            >
                              <td>快測ナビ{{ k_item.edition }}</td>
                              <td>
                                {{
                                  k_item.management_sub_code | convertKSubNumber
                                }}
                              </td>
                              <td>{{ k_item.edition }}</td>
                              <td>
                                {{ k_item.contract_start_date }}~{{
                                  k_item.contract_end_date
                                }}
                              </td>
                              <td>
                                {{
                                  k_item.remaining_month_of_contract
                                    | formatRemainingMonths
                                }}
                              </td>
                            </tr>
                            <tr
                              v-for="kdc_item in item.construction_result_cloud"
                              :key="kdc_item.id"
                            >
                              <td>工事実績DBクラウド</td>
                              <td>
                                {{ kdc_item.number_of_licenses }}・
                                {{ kdc_item.gigabyte_capacity }}GB
                              </td>
                              <td />
                              <td>
                                {{ kdc_item.contract_start_date }}~{{
                                  kdc_item.contract_end_date
                                }}
                              </td>
                              <td>
                                {{
                                  kdc_item.remaining_month_of_contract
                                    | formatRemainingMonths
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td bgcolor="#d3d3d3">備考</td>
                              <td colspan="4">
                                {{ item.remarks_others }}
                              </td>
                            </tr>
                            <tr>
                              <td bgcolor="#d3d3d3">発送先情報</td>
                              <td colspan="4">
                                {{ item.receiver.company_name1 }}
                                {{ item.receiver.company_name2 }}
                              </td>
                            </tr>
                            <tr>
                              <td bgcolor="#d3d3d3">〒・住所</td>
                              <td colspan="4">
                                {{ item.receiver.company_postal_code }}
                                {{ item.receiver.company_address }}
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
              </v-data-iterator>
            </v-container>
          </v-tab-item>
        </v-tabs>
      </v-container>
    </template>
  </div>
</template>

<script>
import Axios from "axios";
import Token from "./token";

export default {
  filters: {
    addComma: (val) => {
      return val.toLocaleString();
    },
    convertBooleanSiteBoxText: (val) => {
      return val === true ? "おまとめ" : "";
    },
    convertBooleanText: (val) => {
      return val === true ? "はい" : "いいえ";
    },
    convertBooleanMaintenanceText: (val) => {
      return val === true ? "退会" : "加入中";
    },
    convertBooleanUpdatingText: (val) => {
      return val === true ? "案内中" : "";
    },
    convertBooleanProvisionalMemberText: (val) => {
      return val === true ? "申込中" : "いいえ";
    },
    convertBooleanDekispartSchoolPriceText: (val) => {
      return val === true ? "申込価格" : "価格";
    },
    convertContractType: (val) => {
      switch (val) {
        case "0":
          return "正規";
        case "1":
          return "デモ版";
        case "2":
          return "社内";
        case "3":
          return "貸出";
        case "5":
          return "レンタル";
      }
      return val === true ? "申込価格" : "価格";
    },
    convertKSubNumber: (val) => {
      return val.length === 1
        ? ("K000" + val).slice(-5)
        : ("K00" + val).slice(-5);
    },
    convertRSubNumber: (val) => {
      return val.length === 1
        ? ("R000" + val).slice(-5)
        : ("R00" + val).slice(-5);
    },
    convertRemainingMonth: (val) => {
      return val === 0 ? "" : val;
    },
    formatRemainingMonths: (val) => {
      const isMinus = val < 0;
      val = Math.abs(val);
      const year = (val / 12) | 0;
      const month = val - year * 12;
      let str = isMinus ? "- " : "";
      str += year + " 年 " + month + " ヶ月";
      return str;
    },
  },
  props: {
    kentemId: {
      type: String,
      required: true,
    },
    kentemIdUrl: {
      type: String,
      default: "https://id.kentem.com",
    },
    reference: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      contents: "",
      downloading: true,
      progress: 0,
      tabs: [{ name: "クラウド情報" }],
      cloudReceiverRecords: [
        { key: "company_name1", name: "会社名1" },
        { key: "company_name2", name: "会社名2" },
        { key: "company_postal_code", name: "郵便番号" },
        { key: "company_address", name: "住所" },
      ],
      cloudDatabankRecords: [
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
        { key: "gigabyte_capacity", name: "契約容量(GB)" },
      ],
      cloudSiteboxRecords: [
        { key: "number_of_licenses", name: "ライセンス数" },
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
      ],
      cloudSiteboxBooleanRecords: [
        { key: "is_set_pack", name: "おまとめパック" },
      ],
      cloudFieldnetRecords: [
        { key: "number_of_licenses", name: "ライセンス数" },
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
      ],
      cloudSiteboxTunnelRecords: [
        { key: "number_of_licenses", name: "ライセンス数" },
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
      ],
      cloudDatabankPremiumRecords: [
        { key: "number_of_licenses", name: "ライセンス数" },
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
      ],
      cloudKaisokuNaviRecords: [
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
        { key: "edition", name: "エディション" },
      ],
      baseUrl: this.KentemIdUrl + "/",
      url: this.kentemIdUrl + "/api/v1/kentem/detail/",
      tokenUrl: "/api/v1/token",
      bearer: "",
      titleData: "",
      ref: "",
    };
  },
  watch: {
    kentemId: function (newKentemId) {
      this.url = "/api/v1/kentem/detail/";
      this.url = this.url + newKentemId;
      this.getTokenAndLoadList();
    },
    reference: function (newReference) {
      this.ref = newReference;
    },
    title: function (newTitle) {
      this.titleData = newTitle;
    },
  },
  created: function () {
    this.ref = this.reference;
    this.titleData = this.title;
    this.url = this.url + this.kentemId;
    this.getTokenAndLoadList();
  },
  computed: {
    filteredItems() {
      return this.contents.data.reference_numbers[this.ref].cloud.filter(
        (item) => item.management_code === this.titleData
      );
    },
  },
  methods: {
    /**
     *
     */
    async loadList() {
      await Axios.get(this.url, {
        onDownloadProgress: this.onDownload,
        headers: {
          Authorization: this.bearer,
        },
      })
        .then((res) => {
          if (res.data) {
            this.contents = res.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.downloading = false;
      this.progress = 100;
    },
    /**
     *
     * @param {Object} e
     */
    onDownload(e) {
      this.progress = Math.floor((e.loaded * 100) / e.total);
    },
    async getTokenAndLoadList() {
      this.bearer = await Token(this.tokenUrl);
      this.loadList();
    },
  },
};
</script>

<style>
.pre-scrollable {
  max-height: 280px;
  overflow-y: scroll;
}
</style>