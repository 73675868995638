<template>
  <div class="form-group">
    <label class="col-form-label">
      <span>商品シリーズ</span>
      <span
        v-if="seriesrequired"
        class="badge badge-primary"
      >{{ $t('required') }}</span>
      <span
        v-else
        class="badge badge-secondary"
      >{{ $t('optional') }}</span>
    </label>
    <select
      v-model="selected"
      class="form-control"
      @change="selectaddProductBySeriesCode(selected)"
    >
      <option
        value
        selected
      >
        選択してください
      </option>
      <option value="A">
        デキスパート
      </option>
      <option value="B">
        デキスク
      </option>
      <option value="C">
        工事実績
      </option>
      <option value="D">
        INNOSiTE
      </option>
      <option value="E">
        クラウドサービス
      </option>
      <option value="F">
        工事情報共有システム
      </option>
      <option value="G">
        TsunaguWeb
      </option>
      <option value="H">
        PRODOUGU
      </option>
      <option value="I">
        KENTEM TECH
      </option>
      <option value="J">
        クロスゼロ
      </option>
      <option value="K">
        貸出・デモ版
      </option>
    </select>
    <label class="col-form-label">
      <span>紐づけ先クラウドID</span>
      <span
        v-if="cloudIDrequired"
        class="badge badge-primary"
      >{{ $t('required') }}</span>
      <span
        v-else
        class="badge badge-secondary"
      >{{ $t('optional') }}</span>
    </label>
    <select
      v-model="selectCloudIds"
      name="cloud_id"
      class="form-control"
    >
      <option
        value
        selected
      >
        選択してください
      </option>
      <option
        v-for="r in cloudIds"
        :key="r"
        :value="r"
      >
        {{ r }}
      </option>
    </select>
    <label class="col-form-label">
      <span>紐づけ先快測ナビID</span>
      <span
        v-if="kaisokuNaviIDrequired"
        class="badge badge-primary"
      >{{ $t('required') }}</span>
      <span
        v-else
        class="badge badge-secondary"
      >{{ $t('optional') }}</span>
    </label>
    <select
      v-model="selectKaisokuNaviIds"
      name="kaisoku_navi_id"
      class="form-control"
    >
      <option
        value
        selected
      >
        選択してください
      </option>
      <option
        v-for="k in kaisokuNaviIds"
        :key="k"
        :value="k"
      >
        {{ k }}
      </option>
    </select>
    <label class="col-form-label">
      <span>{{ label }}</span>
      <span
        v-if="required"
        class="badge badge-primary"
      >{{ $t('required') }}</span>
      <span
        v-else
        class="badge badge-secondary"
      >{{ $t('optional') }}</span>
    </label>
    <tr
      v-for="(product,index) in label_product_input"
      :key="index"
    >
      <td width="900">
        <input
          type="text"
          :value="product"
          :disabled="false"
          :readonly="false"
          class="form-control"
        >
      </td>
      <td>
        <v-btn
          color="primary"
          @click="deleteItem(index)"
        >
          {{ $t('delete') }}
        </v-btn>
      </td>
    </tr>
    <tr
      v-for="(product,a) in product_input"
      :key="a"
    >
      <td width="900">
        <input
          type="hidden"
          :value="product"
          name="kentem_product_id[]"
          :disabled="false"
          :readonly="false"
          class="form-control"
        >
      </td>
    </tr>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="1000px"
      style="z-index: 1050;"
    >
      <template #activator="{ on }">
        <v-btn
          color="primary"
          dark
          v-on="on"
        >
          {{ $t('reference') }}
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
          color="light-blue darken-4"
          dark
        >
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon>
            <v-icon @click="dialog = false">
              fas fa-window-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card
                  class="mx-auto"
                  elevation="2"
                  outlined
                  color="light-blue lighten-5"
                >
                  <v-text-field
                    v-model="search"
                    append-icon="fa-search"
                    :label="searchLabel"
                    single-line
                    hide-details
                  />
                  <v-data-table
                    :headers="headers"
                    :items="items"
                    :items-per-page="10"
                    class="elevation-1"
                    :search="search"
                    :loading="loading"
                    :loading-text="loadingLabel"
                    multi-sort
                  >
                    <template #item="{ item }">
                      <tr @click="selectItem(item)">
                        <td
                          v-for="header in headers"
                          :key="header.value"
                        >
                          {{ item[header.value] }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Axios from "axios";
import Token from "./token";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    idName: {
      type: String,
      default: "id",
    },
    defaultLabelName: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: String,
      default: "",
    },
    labelKey: {
      type: String,
      default: "",
    },
    seriesrequired: {
      type: Boolean,
      default: false,
    },
    cloudIDrequired: {
      type: Boolean,
      default: false,
    },
    kaisokuNaviIDrequired: {
      type: Boolean,
      default: false,
    },
    kentemIdUrl: {
      type: String,
      default: "https://id.kentem.com",
    },
    receivePlanDate: {
      type: String,
      default: "{{$receive_order_plan_date}}",
    },
    referenceNumber: {
      type: Number,
      default: 0,
    },
    defaultProduct: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      bearer: "",
      dialog: false,
      formValue: this.defaultValue,
      labelName: this.defaultLabelName,
      labelValue: "",
      headers: [],
      items: [],
      cloudItems: [],
      kaisokuNaviItems: [],
      cloudIds: [],
      kaisokuNaviIds: [],
      loading: false,
      loadingLabel: this.$i18n.translate("loading"),
      placeholder: "",
      search: "",
      searchLabel: this.$i18n.translate("search"),
      selected: "",
      selectLabel: this.$i18n.translate("select"),
      selectCloudIds: "",
      selectKaisokuNaviIds: "",
      tokenUrl: "/api/v1/token",
      product_input: [],
      label_product_input: [],
    };
  },

  methods: {
    /**
     * 
     * @param {String} series_code 
     */
    async selectaddProductBySeriesCode(series_code) {
      this.loading = true;
      this.bearer = await Token(this.tokenUrl);
      Axios.get(
        this.kentemIdUrl +
          `/api/v1/kentem_product/data_table/add_product/${series_code}`,
        {
          headers: {
            Authorization: this.bearer,
          },
        }
      )
        .then((res) => {
          this.loading = false;
          this.labelValue = "";
          if (res.data) {
            this.headers = res.data.headers;
            this.items = res.data.items;
            console.log(series_code);
            this.cloudIds = [];
            this.kaisokuNaviIds = [];
            if (series_code == "E") {
              this.selectCloudByReferenceNumber(this.referenceNumber);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.labelValue = this.$i18n.translate("unknown");
        });
    },
    /**
     * 
     * @param {String} referenceNumber 
     */
    async selectCloudByReferenceNumber(referenceNumber) {
      this.loading = true;
      this.bearer = await Token(this.tokenUrl);
      Axios.get(
        this.kentemIdUrl + `/api/v1/kentem/reference/${referenceNumber}`,
        {
          headers: {
            Authorization: this.bearer,
          },
        }
      )
        .then((res) => {
          this.loading = false;
          if (res.data) {
            this.cloudItems = res.data.data.cloud;
            //cloudIDを取得
            if (this.cloudItems.length > 0) {
              for (let i = 0; i < this.cloudItems.length; i++) {
                this.cloudIds.push(this.cloudItems[i].management_code);
              }

              this.selectKaisokuNaviId();
            } else {
              this.cloudIds.push("新規ID");
              this.selectKaisokuNaviId();
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    /**
     * 
     */
    selectKaisokuNaviId() {
      //快測ナビIDを取得
      if (this.cloudItems.length > 0) {
        for (let i = 0; i < this.cloudItems.length; i++) {
          this.kaisokuNaviItems = this.cloudItems[i].kaisoku_navi;
        }
        for (let i = 0; i < this.kaisokuNaviItems.length; i++) {
          this.kaisokuNaviIds.push(this.kaisokuNaviItems[i].id);
        }
      } else {
        this.kaisokuNaviIds.push("新規ID");
      }
    },
    /**
     * 
     * @param {*} item 
     */
    selectItem(item) {
      this.formValue = item[this.idName];
      this.labelValue = item[this.labelName];
      this.product_input.push(this.formValue);
      this.label_product_input.push(this.labelValue);
      this.dialog = false;
    },
    /**
     * 
     * @param {Number} index 
     */
    deleteItem(index) {
      this.product_input.splice(index, 1);
    },
  },
};
</script>