<template>
  <div class="col-xs-12 col-sm-12 col-md-12 py-0">
    <div class="form-row">
      <div class="col-xs-12 col-sm-6 col-md-6 py-0">
        <div class="form-group">
          <label class="col-form-label">
            <span>{{ customerLabelData }}</span>
            <span
              v-if="customerRequired"
              class="badge badge-primary"
            >{{
              $t("required")
            }}</span>
            <span
              v-else
              class="badge badge-secondary"
            >{{
              $t("optional")
            }}</span>
          </label>
          <input
            type="text"
            name='labelCustomerName'
            :value="customerLabelValue"
            :readonly="true"
            class="form-control"
          >
          <input
            type="hidden"
            :name="customerName"
            :value="customerFormValue"
            :required="customerRequired"
            readonly="readonly"
          >
          <v-dialog
            v-model="customerDialog"
            persistent
            scrollable
            max-width="1000px"
            style="z-index: 1050"
          >
            <template #activator="{ on }">
              <v-btn
                color="primary"
                dark
                v-on="on"
              >
                {{
                  $t("reference")
                }}
              </v-btn>
            </template>
            <v-card>
              <v-toolbar
                color="light-blue darken-4"
                dark
              >
                <v-toolbar-title>{{ customerTitleData }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon>
                  <v-icon
                    @click="customerDialog = false"
                  >
                    fas fa-window-close
                  </v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <ref-customerset-customercard
                        :callback="selectCustomerItem"
                        :customer-headers="customerHeaders"
                        :customer-items="customerItems"
                        :customer-loading="customerLoading"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue darken-1"
                  text
                  @click="customerDialog = false"
                >
                  {{ $t("close") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="customerDetail"
            max-width="1300px"
            style="z-index: 1050"
          >
            <template #activator="{ on }">
              <v-btn
                color="primary"
                dark
                v-on="on"
              >
                {{ $t("detail") }}
              </v-btn>
            </template>
            <kentem-detail
              :kentem-id="customerFormValue"
              :kentem-id-url="kentemIdUrl"
            />
          </v-dialog>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-6 py-0">
        <div class="form-group">
          <label class="col-form-label">
            <span>{{ referenceLabelData }}</span>
            <span
              v-if="referenceRequired"
              class="badge badge-primary"
            >{{
              $t("required")
            }}</span>
            <span
              v-else
              class="badge badge-secondary"
            >{{
              $t("optional")
            }}</span>
          </label>
          <input
            type="text"
            name="labelReferenceName"
            :value="referenceLabelValue"
            :readonly="true"
            class="form-control"
          >
          <input
            type="hidden"
            :name="referenceName"
            :value="referenceFormValue"
            :required="referenceRequired"
            readonly="readonly"
          >
          <v-dialog
            v-model="referenceDialog"
            persistent
            scrollable
            max-width="1000px"
            style="z-index: 1050"
          >
            <template #activator="{ on }">
              <v-btn
                color="primary"
                dark
                v-on="on"
              >
                {{
                  $t("reference")
                }}
              </v-btn>
            </template>
            <v-card>
              <v-toolbar
                color="light-blue darken-4"
                dark
              >
                <v-toolbar-title>{{ referenceTitleData }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon>
                  <v-icon
                    @click="referenceDialog = false"
                  >
                    fas fa-window-close
                  </v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-card
                        class="mx-auto"
                        elevation="2"
                        outlined
                        color="light-blue lighten-5"
                      >
                        <v-text-field
                          v-model="referenceSearch"
                          append-icon="fa-search"
                          :label="searchLabel"
                          single-line
                          hide-details
                        />
                        <v-data-table
                          :headers="referenceHeaders"
                          :items="referenceItems"
                          :items-per-page="10"
                          class="elevation-1"
                          :search="referenceSearch"
                          :loading="referenceLoading"
                          :loading-text="loadingLabel"
                          multi-sort
                        >
                          <template #item="{ item }">
                            <tr @click="selectReferenceItem(item)">
                              <td
                                v-for="header in referenceHeaders"
                                :key="header.value"
                              >
                                {{ item[header.value] }}
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue darken-1"
                  text
                  @click="referenceDialog = false"
                >
                  {{ $t("close") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-xs-12 col-sm-6 col-md-6 py-0">
        <div class="form-group">
          <label class="col-form-label">
            <span>{{ dealerLabelData }}</span>
            <span
              v-if="dealerRequired"
              class="badge badge-primary"
            >{{
              $t("required")
            }}</span>
            <span
              v-else
              class="badge badge-secondary"
            >{{
              $t("optional")
            }}</span>
          </label>
          <input
            type="text"
            name="labelDealerName"
            :value="dealerLabelValue"
            :readonly="true"
            class="form-control"
          >
          <input
            type="hidden"
            :name="dealerName"
            :value="dealerFormValue"
            :required="dealerRequired"
            readonly="readonly"
          >
          <v-dialog
            v-model="dealerDialog"
            persistent
            scrollable
            max-width="1000px"
            style="z-index: 1050"
          >
            <template #activator="{ on }">
              <v-btn
                color="primary"
                dark
                v-on="on"
              >
                {{
                  $t("reference")
                }}
              </v-btn>
            </template>
            <v-card>
              <v-toolbar
                color="light-blue darken-4"
                dark
              >
                <v-toolbar-title>{{ dealerTitleData }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon>
                  <v-icon
                    @click="dealerDialog = false"
                  >
                    fas fa-window-close
                  </v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <ref-customerset-customercard
                        :callback="selectDealerItem"
                        :customer-headers="dealerHeaders"
                        :customer-items="dealerItems"
                        :customer-loading="dealerLoading"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dealerDialog = false"
                >
                  {{ $t("close") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <div class="form-row">
          <div class="col-xs-12 col-sm-6 col-md-6 py-0">
            <label class="col-form-label">
              <span>送付先</span>
              <span
                v-if="sendtoRequired"
                class="badge badge-primary"
              >{{
                $t("required")
              }}</span>
              <span
                v-else
                class="badge badge-secondary"
              >{{
                $t("optional")
              }}</span>
            </label>
            <select
              id="sendto"
              v-model="sendtoValue"
              name="send_to"
              required="required"
              class="form-control"
              style="background-color: #e9ecef"
            >
              <option value="1">
                販売店
              </option>
              <option value="2">
                ユーザー
              </option>
            </select>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6 py-0">
            <label class="col-form-label">
              <span>見積形式</span>
              <span
                v-if="salessegmentRequired"
                class="badge badge-primary"
              >{{
                $t("required")
              }}</span>
              <span
                v-else
                class="badge badge-secondary"
              >{{
                $t("optional")
              }}</span>
            </label>
            <select
              id="sales_segment"
              v-model="selessegmentValue"
              name="sales_segment"
              required="required"
              class="form-control"
              style="background-color: #e9ecef"
            >
              <option value="1">
                販売店
              </option>
              <option value="2">
                販売店（リコー）
              </option>
              <option value="3">
                販売店（建築資料）
              </option>
              <option value="4">
                直販
              </option>
              <option value="5">
                直販（リース）
              </option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="col-xs-12 col-sm-6 col-md-6 py-0">
            <label class="col-form-label">
              <span>取引方法</span>
              <span
                v-if="dealmethodRequired"
                class="badge badge-primary"
              >{{
                $t("required")
              }}</span>
              <span
                v-else
                class="badge badge-secondary"
              >{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              name="labelDealMethod"
              :disabled="false"
              :readonly="false"
              class="form-control"
              maxlength="14"
              v-model="dealMethodLabelValue"
            >
            <input
              type="hidden"
              name="deal_method"
              :value="dealMethodFormValue"
            >
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6 py-0">
            <label class="col-form-label">
              <span>有効期限</span>
              <span
                v-if="expirationdateRequired"
                class="badge badge-primary"
              >{{
                $t("required")
              }}</span>
              <span
                v-else
                class="badge badge-secondary"
              >{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              name="labelExpirationDate"
              :disabled="false"
              :readonly="false"
              class="form-control"
              maxlength="20"
              v-model="expirationDateLabelValue"
            >
            <input
              type="hidden"
              name="expiration_date"
              :value="this.expirationDateFormValue"
            >
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6 py-0">
            <label class="col-form-label">
              <span>受渡期日</span>
              <span
                v-if="deliverydeadlineRequired"
                class="badge badge-primary"
              >{{
                $t("required")
              }}</span>
              <span
                v-else
                class="badge badge-secondary"
              >{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              name="labelDeliveryDeadline"
              :disabled="false"
              :readonly="false"
              class="form-control"
              maxlength="20"
              v-model="deliveryDeadlineLabelValue"
            >
            <input
              type="hidden"
              name="delivery_deadline"
              :value="this.deliveryDeadlineFormValue"
            >
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6 py-0">
            <label class="col-form-label">
              <span>支払条件</span>
              <span
                v-if="paymenttermsRequired"
                class="badge badge-primary"
              >{{
                $t("required")
              }}</span>
              <span
                v-else
                class="badge badge-secondary"
              >{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              name="labelPaymentTerms"
              :disabled="false"
              :readonly="false"
              class="form-control"
              maxlength="14"
              v-model="paymentTermsLabelValue"
            >
            <input
              type="hidden"
              name="payment_terms"
              :value="this.paymentTermsFormValue"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import Token from "./token";

export default {
  props: {
    customerDefaultLabelName: {
      type: String,
      default: "name",
    },
    customerDefaultValue: {
      type: String,
      default: "",
    },
    customerIdName: {
      type: String,
      default: "kentem_id",
    },
    customerLabel: {
      type: String,
      default: "",
    },
    customerLabelKey: {
      type: String,
      default: "name",
    },
    customerName: {
      type: String,
      default: "customer_kentem_id",
    },
    customerRequired: {
      type: Boolean,
      default: true,
    },
    customerTitle: {
      type: String,
      default: "",
    },
    customerUrl: {
      type: String,
      default: "https://id.kentem.com" + "/api/v1/kentem_customer/data_table",
    },
    dealerDefaultLabelName: {
      type: String,
      default: "name",
    },
    dealerDefaultValue: {
      type: String,
      default: "",
    },
    dealerIdName: {
      type: String,
      default: "kentem_id",
    },
    dealerLabel: {
      type: String,
      default: "",
    },
    dealerLabelKey: {
      type: String,
      default: "name",
    },
    dealerName: {
      type: String,
      default: "sales_kentem_id",
    },
    dealerRequired: {
      type: Boolean,
      default: true,
    },
    dealerTitle: {
      type: String,
      default: "",
    },
    dealerUrl: {
      type: String,
      default: "https://id.kentem.com" + "/api/v1/kentem_sales/data_table",
    },
    kentemIdUrl: {
      type: String,
      default: "https://id.kentem.com",
    },
    old: {
      type: [Object, Array],
      default: () => {},
    },
    referenceDefaultLabelName: {
      type: String,
      default: "reference_number",
    },
    referenceDefaultValue: {
      type: [String, Number],
      default: "",
    },
    referenceIdName: {
      type: String,
      default: "reference_number",
    },
    referenceLabel: {
      type: String,
      default: "",
    },
    referenceLabelKey: {
      type: String,
      default: "reference_number",
    },
    referenceName: {
      type: String,
      default: "reference_number",
    },
    referenceRequired: {
      type: Boolean,
      default: false,
    },
    referenceTitle: {
      type: String,
      default: "",
    },
    sendtoRequired: {
      type: Boolean,
      default: true,
    },
    salessegmentRequired: {
      type: Boolean,
      default: true,
    },
    sendtoDefault: {
      type: [String, Number],
      default: document.getElementById("sendto"),
    },
    salessegmentDefault: {
      type: [String, Number],
      default: document.getElementById("salessegment"),
    },
    dealmethodRequired: {
      type: Boolean,
      default: false,
    },
    expirationdateRequired: {
      type: Boolean,
      default: false,
    },
    deliverydeadlineRequired: {
      type: Boolean,
      default: false,
    },
    paymenttermsRequired: {
      type: Boolean,
      default: false,
    },
    kentemIdDefault: {
      type: String,
      default: "",
    },
    referenceNumberDefault: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      bearer: "",
      customerDefaultLabel: this.$i18n.translate("estimate.actnm"),
      customerDefaultTitle: this.$i18n.translate("顧客情報一覧"),
      customerDialog: false,
      customerDetail: false,
      customerFormValue: this.customerDefaultValue,
      customerHeaders: [],
      customerItems: [],
      customerLabelData: "",
      customerLabelName: this.customerDefaultLabelName,
      customerLabelValue: "",
      customerLoading: true,
      customerTitleData: "",
      dealerDefaultLabel: this.$i18n.translate("販売店"),
      dealerDefaultTitle: this.$i18n.translate("販売店情報一覧"),
      dealerDialog: false,
      dealerFormValue: this.dealerDefaultValue,
      dealerHeaders: [],
      dealerItems: [],
      dealerLabelData: "",
      dealerLabelName: this.dealerDefaultLabelName,
      dealerLabelValue: "",
      dealerLoading: true,
      dealerSearch: "",
      dealerTitleData: "",
      isWatchSalesSegmentValue: true,
      referenceDefaultLabel: this.$i18n.translate("Kentem整理番号"),
      referenceDefaultTitle: this.$i18n.translate("整理番号一覧"),
      referenceDialog: false,
      referenceFormValue: this.referenceDefaultValue,
      referenceHeaders: [],
      referenceItems: [],
      referenceLabelData: "",
      referenceLabelName: this.referenceDefaultLabelName,
      referenceLabelValue: "",
      referenceLoading: true,
      referenceSearch: "",
      referenceTitleData: "",
      loadingLabel: this.$i18n.translate("loading"),
      searchLabel: this.$i18n.translate("search"),
      selected: "",
      sendtoValue: this.sendtoDefault,
      selessegmentValue: this.salessegmentDefault,
      tokenUrl: "/api/v1/token",
      dealMethodLabelValue: "月末締め翌月現金お振込み",
      expirationDateLabelValue:"発行日より１ヶ月",
      deliveryDeadlineLabelValue:"ご注文後２週間以内",
      dealMethodFormValue: "月末締め翌月現金お振込み",
      expirationDateFormValue:"発行日より１ヶ月",
      deliveryDeadlineFormValue:"ご注文後２週間以内",
      paymentTermsLabelValue: "-",
      paymentTermsFormValue: "-",
    };
  },
  created: function () {
    console.log(JSON.stringify(this.old));
    if ('send_to' in this.old) {
      this.sendtoValue = this.old.send_to;
    }
    if ('sales_segment' in this.old) {
      this.selessegmentValue = this.old.sales_segment;
    }
    if ('labelExpirationDate' in this.old) {
      this.expirationDateLabelValue = this.old.labelExpirationDate;
    }
    if ('labelDeliveryDeadline' in this.old) {
      this.deliveryDeadlineLabelValue = this.old.labelDeliveryDeadline;
    }

    this.customerLabelName =
      this.customerDefaultLabelName ?? this.customerIdName;
    this.dealerLabelName = this.dealerDefaultLabelName ?? this.dealerIdName;
    this.referenceLabelName =
      this.referenceDefaultLabelName ?? this.referenceIdName;

    if (this.customerLabel === "") {
      this.customerLabelData = this.customerDefaultLabel;
    }
    if (this.customerTitle === "") {
      this.customerTitleData = this.customerDefaultTitle;
    }
    if (this.dealerLabel === "") {
      this.dealerLabelData = this.dealerDefaultLabel;
    }
    if (this.dealerTitle === "") {
      this.dealerTitleData = this.dealerDefaultTitle;
    }
    if (this.referenceLabel === "") {
      this.referenceLabelData = this.referenceDefaultLabel;
    }
    if (this.referenceTitle === "") {
      this.referenceTitleData = this.referenceDefaultTitle;
    }

    if ('labelDealMethod' in this.old) {
      this.isWatchSalesSegmentValue = false;
      this.dealMethodLabelValue = this.old.labelDealMethod;
    }
    if ('labelPaymentTerms' in this.old) {
      this.isWatchSalesSegmentValue = false;
      this.paymentTermsLabelValue = this.old.labelPaymentTerms;
    }

    this.getTokenAndLoadList();

    if ('labelCustomerName' in this.old) {
      this.customerLabelValue = this.old.labelCustomerName;
    }
    if (this.customerName in this.old) {
      this.customerFormValue = this.old[this.customerName];
    }

    if ('labelReferenceName' in this.old) {
      this.referenceLabelValue = this.old.labelReferenceName;
    }
    if (this.referenceName in this.old) {
      this.referenceFormValue = this.old[this.referenceName];
    }

    if ('labelDealerName' in this.old) {
      this.dealerLabelValue = this.old.labelDealerName;
    }
    if (this.dealerName in this.old) {
      this.dealerFormValue = this.old[this.dealerName];
    }
  },
watch: {
    selessegmentValue: function (newSelessegmentValue, _) {
      if (!this.isWatchSalesSegmentValue) {
        this.isWatchSalesSegmentValue = true;
        return;
      }
      if(newSelessegmentValue == 1 || newSelessegmentValue == 3 || newSelessegmentValue == 4){
        this.dealMethodLabelValue = "月末締め翌月現金お振込み";
        if(newSelessegmentValue == 4){
          this.paymentTermsLabelValue = "現金一括";
        }else{
          this.paymentTermsLabelValue = "-";
        }
      }else if(newSelessegmentValue == 5){
        this.dealMethodLabelValue = "リース契約";
        this.paymentTermsLabelValue = "-";
      }else if(newSelessegmentValue == 2){
        this.dealMethodLabelValue = "貴社取り決め通り";
        this.paymentTermsLabelValue = "-";
      }
    },
    dealMethodLabelValue: function (newDealmethodLabelValue, _) {
      this.dealMethodFormValue = newDealmethodLabelValue;
    },
    expirationDateLabelValue: function (newExpirationateLabelValue, _) {
      this.expirationDateFormValue = newExpirationateLabelValue;
    },
    deliveryDeadlineLabelValue: function (newDeliveryeadlineLabelValue, _) {
      this.deliveryDeadlineFormValue = newDeliveryeadlineLabelValue;
    },
    paymentTermsLabelValue: function (newPaymentTermsLabelValue, _) {
      this.paymentTermsFormValue = newPaymentTermsLabelValue;
    },
  },
  methods: {
    /**
     *
     */
    async loadList() {
      this.customerLoading = true;
      if (this.customerFormValue !== "" && this.customerLabelKey !== "") {
        this.customerLabelValue = this.$i18n.translate("loading");
      }
      this.dealerLoading = true;
      if (this.dealerFormValue !== "" && this.dealerLabelKey !== "") {
        this.dealerLabelValue = this.$i18n.translate("loading");
      }
      this.referenceLoading = true;
      if (this.referenceFormValue !== "" && this.referenceLabelKey !== "") {
        this.referenceLabelValue = this.$i18n.translate("loading");
      }
      const data = await this.getData();
      if (data.customer) {
        this.customerHeaders = data.customer.headers;
        this.customerItems = data.customer.items;
      }
      if (data.dealer) {
        this.dealerHeaders = data.dealer.headers;
        this.dealerItems = data.dealer.items;
      }

      if(this.kentemIdDefault !== ""){
        this.customerFormValue = this.kentemIdDefault
      }

      if(this.referenceNumberDefault !== 0){
        this.referenceLabelValue = this.referenceNumberDefault;
        this.referenceFormValue = this.referenceNumberDefault;
        this.bearer = await Token(this.tokenUrl);
        this.getDealerByReferenceId(this.referenceFormValue);
        this.referenceDialog = false;
      }

      if (this.customerFormValue !== "" && this.customerLabelKey !== "") {
        this.referenceItems = await this.getReferencesByKentemIdPromise(
          this.customerFormValue
        );
        if (this.referenceItems.length == 1) {
          this.selectReferenceItemAuto(this.referenceItems[0]);
        }
        const defaultItem = this.customerItems.find(
          (row) => row[this.customerIdName] === this.customerFormValue
        );
        this.customerLabelValue =
          typeof defaultItem !== "undefined"
            ? defaultItem[this.customerLabelKey]
            : this.$i18n.translate("unknown");
      }
      if (this.dealerFormValue !== "" && this.dealerLabelKey !== "") {
        const defaultItem = this.dealerItems.find(
          (row) => row[this.dealerIdName] === this.dealerFormValue
        );
        this.dealerLabelValue =
          typeof defaultItem !== "undefined"
            ? defaultItem[this.dealerLabelKey]
            : this.$i18n.translate("unknown");
      }
      if (this.referenceFormValue !== "" && this.referenceLabelKey !== "") {
        const defaultItem = this.referenceItems.find(
          (row) => row[this.referenceIdName] === this.referenceFormValue
        );
        this.referenceLabelValue =
          typeof defaultItem !== "undefined"
            ? defaultItem[this.referenceLabelKey]
            : this.$i18n.translate("unknown");
      }
      this.customerLoading = false;
      this.dealerLoading = false;
      this.referenceLoading = false;
    },
    /**
     *
     */
    getData() {
      return Promise.all([
        Axios.get(this.customerUrl, {
          headers: {
            Authorization: this.bearer,
          },
        }),
        Axios.get(this.dealerUrl, {
          headers: {
            Authorization: this.bearer,
          },
        }),
      ])
        .then(([customer, dealer]) => {
          const data = {};
          data.customer = customer.data;
          data.dealer = dealer.data;
          return Promise.resolve(data);
        })
        .catch((error) => {console.log(error);});
    },
    /**
     *
     * @param {String} id
     */
    getDealerByReferenceId(id) {
      this.dealerLabelValue = this.$i18n.translate("loading");
      Axios.get(this.kentemIdUrl + `/api/v1/kentem/reference/dekispart/${id}`, {
        headers: {
          Authorization: this.bearer,
        },
      })
        .then((res) => {
          if (res.data) {
            this.setDealerKentemIdByCode(
              res.data.data.dekispart.dealership_code1
            );
            this.dealerLabelValue = res.data.data.dekispart.dealership_name1;
            /*
            this.changeSendtoBydealerIdName(
              res.data.data.dekispart.dealership_code1
            );
            this.changeSalesSagmentBydealerIdName(
              res.data.data.dekispart.dealership_code1
            );
            */
          }
        })
        .catch((error) => {
          console.log(error);
          this.dealerLabelValue = this.$i18n.translate("unknown");
        });
    },
    /**
     *
     * @param {String} code
     */
    setDealerKentemIdByCode(code) {
      Axios.get(
        this.kentemIdUrl + `/api/v1/kentem_information/shokon/${code}`,
        {
          headers: {
            Authorization: this.bearer,
          },
        }
      )
        .then((res) => {
          if (res.data) {
            this.dealerFormValue = res.data.data.kentem_id;
            this.dealerLabelValue = res.data.data.name;
            this.getShokonCodeByKentemId(res.data.data.kentem_id);
          } else {
            this.dealerLabelValue = this.$i18n.translate("unknown");
          }
        })
        .catch((error) => {
          console.log(error);
          this.dealerLabelValue = this.$i18n.translate("unknown");
        });
    },
    /**
     *
     * @param {String} id
     */
    getReferencesByKentemId(id) {
      this.referenceLabelValue = this.$i18n.translate("loading");
      Axios.get(
        this.kentemIdUrl + `/api/v1/kentem/reference/data_table/${id}`,
        {
          headers: {
            Authorization: this.bearer,
          },
        }
      )
        .then((res) => {
          this.referenceLoading = false;
          this.referenceLabelValue = "";
          if (res.data) {
            this.referenceHeaders = res.data.headers;
            this.referenceItems = res.data.items;
          }
        })
        .catch((error) => {
          console.log(error);
          this.referenceLabelValue = this.$i18n.translate("unknown");
        });
    },
    /**
     *
     * @param {String} id
     */
    getReferencesByKentemIdPromise(id) {
      this.referenceLabelValue = this.$i18n.translate("loading");
      return Promise.all([
        Axios.get(
          this.kentemIdUrl + `/api/v1/kentem/reference/data_table/${id}`,
          {
            headers: {
              Authorization: this.bearer,
            },
          }
        ),
      ])
        .then((res) => {
          this.referenceLoading = false;
          this.referenceLabelValue = "";
          if (res[0].data) {
            this.referenceHeaders = res[0].data.headers;
            return Promise.resolve(res[0].data.items);
          }
        })
        .catch((error) => {
          console.log(error);
          this.referenceLabelValue = this.$i18n.translate("unknown");
        });
    },
    /**
     *
     * @param {String} id
     */
    getShokonCodeByKentemId(id) {
      Axios.get(
        this.kentemIdUrl + `/api/v1/kentem_information/shokon_code/${id}`,
        {
          headers: {
            Authorization: this.bearer,
          },
        }
      )
        .then((res) => {
          if (res.data) {
            this.changeSendtoBydealerIdName(res.data.data);
            this.changeSalesSagmentBydealerIdName(res.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
          this.dealerLabelValue = this.$i18n.translate("unknown");
        });
    },
    /**
     *
     * @param {Object} item
     */
    async selectCustomerItem(item) {
      // エンドユーザーを設定
      this.customerFormValue = item[this.customerIdName];
      this.customerLabelValue = item[this.customerLabelName];

      // 整理番号と販売店を初期化
      this.dealerFormValue = "";
      this.dealerLabelValue = "";
      this.referenceLabelValue = "";
      this.referenceFormValue = "";

      this.bearer = await Token(this.tokenUrl);
      this.referenceItems = await this.getReferencesByKentemIdPromise(
        this.customerFormValue
      );
      if (this.referenceItems.length == 1) {
        this.selectReferenceItemAuto(this.referenceItems[0]);
      }
      this.customerDialog = false;
    },
    /**
     *
     * @param {Object} item
     */
    async selectDealerItem(item) {
      this.dealerFormValue = item[this.dealerIdName];
      this.dealerLabelValue = item[this.dealerLabelName];
      this.bearer = await Token(this.tokenUrl);
      this.getShokonCodeByKentemId(item[this.dealerIdName]);
      this.dealerDialog = false;
    },
    /**
     *
     * @param {Object} item
     */
    async selectReferenceItem(item) {
      this.referenceLabelValue = item[this.referenceLabelName];
      this.referenceFormValue = item[this.referenceIdName];
      this.bearer = await Token(this.tokenUrl);
      this.getDealerByReferenceId(this.referenceFormValue);
      this.referenceDialog = false;
    },
    /**
     *
     * @param {Object} item
     */
    async selectReferenceItemAuto(item) {
      this.referenceLabelValue = item["reference_number"];
      this.referenceFormValue = item["reference_number"];
      this.bearer = await Token(this.tokenUrl);
      this.getDealerByReferenceId(this.referenceFormValue);
      this.referenceDialog = false;
    },
    /**
     *
     * @param {String} dealerCode
     */
    changeSendtoBydealerIdName(dealerCode) {
      if (dealerCode == "kshh") {
        this.sendtoValue = 2;
      } else {
        this.sendtoValue = 1;
      }
    },
    /**
     *
     * @param {String} dealerCode
     */
    changeSalesSagmentBydealerIdName(dealerCode) {
      if (dealerCode == "kshh") {
        this.selessegmentValue = 4;
      } else if (dealerCode == "004359") {
        this.selessegmentValue = 2;
      } else if (dealerCode == "000286") {
        this.selessegmentValue = 3;
      } else {
        this.selessegmentValue = 1;
      }
    },
    /**
     *
     */
    async getTokenAndLoadList() {
      this.bearer = await Token(this.tokenUrl);
      this.loadList();
    },
  },
};
</script>
