<template>
  <table>
    <tr
      v-for="(product, index) in productNameList"
      :key="`display-product-list-${index}`"
    >
      <td width="900">
        <input
          type="text"
          :value="product"
          name="product_name[]"
          :disabled="false"
          :readonly="false"
          class="form-control"
        >
      </td>
      <td>
        <v-btn
          color="primary"
          @click="deleteItem(index)"
        >
          {{
            $t("delete")
          }}
        </v-btn>
      </td>
    </tr>
    <tr
      v-for="(product, a) in productIdList"
      :key="`product-list-${a}`"
    >
      <td width="900">
        <input
          type="hidden"
          :value="product"
          name="kentem_product_id[]"
          :disabled="false"
          :readonly="false"
          class="form-control"
        >
      </td>
    </tr>
  </table>
</template>

<script>
export default {
    props: {
        deleteItem: {
            type: Function,
            required: true,
        },
        productNameList: {
            type: Array,
            required: true,
        },
        productIdList: {
            type: Array,
            required: true,
        }
    }
}
</script>