<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="1000px"
    style="z-index: 1050"
  >
    <template #activator="{ on }">
      <v-btn
        color="primary"
        dark
        v-on="on"
      >
        {{ $t("reference") }}
      </v-btn>
    </template>
    <v-card>
      <v-toolbar
        color="light-blue darken-4"
        dark
      >
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon>
          <v-icon @click="dialog = false">
            fas fa-window-close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-card
                class="mx-auto"
                elevation="2"
                outlined
                color="light-blue lighten-5"
              >
                <v-text-field
                  v-model="search"
                  append-icon="fa-search"
                  :label="searchLabel"
                  single-line
                  hide-details
                />
                <v-data-table
                  :headers="headers"
                  :items="items"
                  :items-per-page="10"
                  class="elevation-1"
                  :search="search"
                  :loading="loading"
                  :loading-text="loadingLabel"
                  multi-sort
                >
                  <template #item="{ item }">
                    <tr
                      :class="{ 'selected-row': selectedProductIdList.includes(item[idName])}"
                      @click="toggleItem(item)"
                    >
                      <td
                        v-for="header in headers"
                        :key="header.value"
                      >
                        {{ item[header.value] }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="dialog = false"
        >
          {{
            $t("close")
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    props: {
        deleteItemById: {
            type: Function,
            required: true
        },
        headers: {
            type: Array,
            requeired: true,
            default: () => [],
        },
        idName: {
            type: String,
            default: "id",
        },
        items: {
            type: Array,
            requeired: true,
            default: () => [],
        },
        loading: {
            type: Boolean,
            requeired: true
        },
        selectItem: {
            type: Function,
            required: true
        },
        selectedProductIdList: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            dialog: false,
            loadingLabel: this.$i18n.translate("loading"),
            search: "",
            searchLabel: this.$i18n.translate("search")
        };
    },
    methods: {
      /**
       * 
       * @param {Array} item 
       * 
       * @return {Function}
       */
        toggleItem(item) {
            if (this.selectedProductIdList.includes(item[this.idName])) {
                return this.deleteItemById(item[this.idName]);
            }
            return this.selectItem(item);
        }
    }
};
</script>

<style scoped>
.selected-row {
    background: repeating-linear-gradient(#0cff49a1, #1f8002);
}
.selected-row:hover {
    background: repeating-linear-gradient(#0cff49a1, #fff) !important;
}
</style>