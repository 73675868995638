<template>
  <div class="d-flex flex-wrap">
    <div v-for="(group, index) in data" :key="index" class="card mx-2 mb-2">
      <div class="card-body">
        <table class="table table-striped">
          <tbody>
            <tr v-for="(value, key) in group" :key="key">
              <td>{{ $t(key) }}</td>
              <td>
                {{ value }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  data() {
    return {
      i18n: {
        estimate_id: "見積ID",
        billing_company: "請求先会社名",
        billing_zip: "請求先郵便番号",
        billing_address1: "請求先住所1",
        billing_address2: "請求先住所2",
        billing_contact_name: "請求先担当者名",
        billing_note: "請求先備考",
        billing_order_number: "請求先注文番号",
        billing_shokon_code: "請求先商魂コード",
        order_received_confirmation_created_at: "発送確認日",
        order_received_confirmation_person: "発送確認者",
        reese_company_name: "リース会社名",
        reese_company_shokon_code: "リース会社商魂コード",
        shipment_date: "発送日",
        shipment_remarks: "発送備考",
        shipping_company: "発送先会社名",
        shipping_zip: "発送先郵便番号",
        shipping_address1: "発送先住所1",
        shipping_address2: "発送先住所2",
        shipping_tel: "発送先電話番号",
        shipping_contact_name: "発送先担当者名",
        shipping_mail_address: "クラウドメールアドレス",
        shipping_mail_address2: "デキスクメールアドレス",
        shipping_note: "発送先備考",
        shipping_shokon_code: "発送先商魂コード",
        sales_date: "売上日",
        billing_date: "請求日",
        order_date: "受注日",
        sales_representative: "売上担当コード",
        remarks: "生産管理部への連絡",
        edit_shipment_date: "出荷日編集",
        edit_sales_date: "売上日編集",
        edit_billing_date: "請求日編集",
        edit_shipping_shokon_code: "発送先商魂コード編集",
        edit_billing_shokon_code: "請求先商魂コード編集",
        edit_shipping_company: "発送先会社名編集",
        edit_billing_company: "請求先会社名編集",
        is_np_classfication: "NP掛け払い区分",
        sales_representative_person_full_name: "売上担当名",
        contact_shipping_team_remarks: "出荷チームへの連絡",
        reference_number: "整理番号",
      },
    };
  },
  methods: {
    $t(key) {
      return this.i18n[key] || key; // 翻訳が見つからない場合は key 自体を返す
    },
  },
};
</script>

<style scoped>
.table td {
  word-wrap: break-word; /* 単語の途中で折り返しを許可 */
  max-width: 200px; /* 折り返しの最大幅を設定 */
}
</style>
