<template>
  <v-card class="mx-auto stock-table-wrap" outlined>
    <h2 class="mt-2 fs4">モデルマスタ</h2>
    <v-btn v-if="canEdit" color="primary" elevation="1" @click="addNewRow">モデル登録</v-btn>
    <v-data-table
      :headers="headersWithOperation"
      :items="editableItems"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      class="stock-table mt-5"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td v-for="header in headers" :key="header.value">
            <div v-if="!item.editMode">
              <span v-if="header.value === 'status'" :class="getStatusColorClass(item.status)">
                {{ getStatusName(item.status) }}
              </span>
              <span v-else>{{ item[header.value] }}</span>
            </div>
            <div v-else>
              <template v-if="header.value === 'status'">
                <v-select
                  v-model="item[header.value]"
                  :options="statusListForSelect"
                  :reduce="option => option.value"
                  :clearable="false"
                  :filterable="false"
                ></v-select>
              </template>
              <datepicker-field
                v-else-if="header.value === 'first_receive_date'"
						    :value.sync="item[header.value]"
                hide-details
                dense
              ></datepicker-field>
              <v-textarea
                v-else-if="header.value !== 'id'"
                v-model="item[header.value]"
                hide-details
                dense
                auto-grow
              ></v-textarea>
              <span v-else>{{ item[header.value] }}</span>
            </div>
          </td>
          <td v-if="canEdit">
            <v-icon v-if="!item.editMode" @click="enableEditMode(item)">mdi-pencil</v-icon>
            <v-icon v-else @click="submitChanges(item)">mdi-check</v-icon>
            <v-icon v-if="item.editMode" @click="cancelEdit(item)">mdi-close</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" timeout="3000" bottom right>
      {{ snackbarMessage }}
    </v-snackbar>
  </v-card>
</template>

<script>
import axios from 'axios';
export default {
  props: {
    canEdit: {
      type: Boolean,
      required: true,
    },
    segmentId: {
      type: Number,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    statusListForGetName: {
      type: Array,
      required: true,
    },
    statusListForSelect: {
      type: Array,
      required: true,
    },
    apiEndpoint: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      editableItems: [],
      originalItems: [],
      snackbar: false,
      snackbarMessage: '',
      sortBy: "id",
      sortDesc: true,
    };
  },
  created() {
    this.initializeEditableItems();
  },
  computed: {
    headersWithOperation() {
      if(this.canEdit){
        return [...this.headers, { text: '操作', value: 'operation', sortable: false }];
      }else{
        return this.headers;
      }
    }
  },
  methods: {
    initializeEditableItems() {
      this.editableItems = this.items.map(item => ({...item, editMode: false}));
      this.originalItems = JSON.parse(JSON.stringify(this.items)); // オリジナルのコピーを保持
    },
    getStatusColorClass(status) {
      const statusColorMap = new Map([
        [1, "light-green--text text--accent-4 font-weight-bold"],
        [2, "grey--text"],
      ]);
      return statusColorMap.get(status) || "";
    },
    getStatusName(status) {
      return this.statusListForGetName[status];
    },
    addNewRow() {
      // status=1のレコードを探す
      const templateItem = this.editableItems.find(item => item.status === 1);
      // 新しい行のベースを作成
      const newRow = { 
        id: 'new', 
        editMode: true 
      };

      if (templateItem) {
        // status=1のレコードが見つかった場合、その値をコピー
        this.headers.forEach(header => {
          if (header.value !== 'id') {
            newRow[header.value] = templateItem[header.value];
          }
        });
      } else {
        // status=1のレコードが見つからない場合、空の値をセット
        this.headers.forEach(header => {
          if (header.value !== 'id') {
            newRow[header.value] = '';
          }
        });
      }
      // 新しい行を追加
      this.editableItems.push(newRow);
    },
    enableEditMode(item) {
      const targetItem = this.editableItems.find(i => i.id === item.id);
      if (targetItem) {
        targetItem.editMode = true;
      }
    },
    cancelEdit(item) {
      const index = this.editableItems.findIndex(i => i.id === item.id);
      if (index !== -1) {
        if (this.editableItems[index].id === 'new') {
          this.editableItems.splice(index, 1);
        } else {
          this.editableItems[index].editMode = false;
          Object.assign(this.editableItems[index], this.originalItems.find(i => i.id === this.editableItems[index].id));
        }
        this.snackbarMessage = "キャンセルしました";
        this.snackbar = true;
      }
    },
    async submitChanges(item) {
      const index = this.editableItems.findIndex(i => i.id === item.id);
      if (index === -1) return;

      const editedItem = { ...this.editableItems[index] };
      delete editedItem.editMode;

      const originalItem = this.originalItems.find(i => i.id === editedItem.id);
      if (JSON.stringify(editedItem) === JSON.stringify(originalItem)) {
        this.cancelEdit(item);
        return;
      }

      try {
        const response = await axios.post(this.apiEndpoint, {
          ...editedItem,
          stock_segment_id: this.segmentId,
        }, {
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        });

        if (response.status !== 200) {
          throw new Error("Failed to update item.");
        }

        if (editedItem.id === 'new') {
          this.$set(this.editableItems[index], 'id', response.data.id);
        }
        this.$set(this.editableItems[index], 'editMode', false);

        this.snackbarMessage = "更新が成功しました！";
        this.snackbar = true;
        // アラートを表示してページリロード
        window.alert("更新が完了しました。");
        window.location.reload();

      } catch (error) {
        console.error("Error submitting changes:", error);

        if (error.response && error.response.data) {
          this.snackbarMessage = error.response.data.message || "更新に失敗しました。";
        } else {
          this.snackbarMessage = "更新に失敗しました。";
        }
        this.snackbar = true;
      }
    },
  },
  watch: {
    items: {
      handler() {
        this.initializeEditableItems();
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.v-card {
  border: none;
}
.stock-table-wrap{
  width: 1200px;
}
.stock-table {
  white-space: nowrap;
  .v-data-table__wrapper {
      overflow-x: visible!important;
      overflow-y: visible!important;;
  }
  thead {
    background: #BBDEFB;
  }
  th {
    font-weight: bold;
  }
  td{
    max-width:300px;
    text-wrap-mode:wrap;
  }
}
.v-icon {
  cursor: pointer;
}
</style>