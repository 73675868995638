<template>
  <span>
    <v-expansion-panel-header>{{ item.question }}
      <template v-slot:actions v-if="canUpdate | canDelete">
        <form v-if="canDelete" :action="'/faq/' + item.id" method="post">
          <input type="hidden" name="_token" :value="getCsrfToken()" />
          <input type="hidden" name="_method" value="DELETE" />
            <v-btn
              v-confirm="deleteConfirmationLabel"
              type="submit"
              icon
              class="bg-gradient-danger shadow"
            >
            <i class="far fa-trash-alt"></i>
          </v-btn>
        </form>
        <v-btn
          v-if="canUpdate"
          :href="'/faq/' + item.id + '/edit'"
          icon
          class="bg-gradient-primary shadow"
        >
          <i class="fas fa-pencil-alt"></i>
        </v-btn>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      {{ item.answer }}
      <aside class="text-right caption">更新日: {{ item.updated_at }}</aside>
    </v-expansion-panel-content>
  </span>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    canUpdate: {
      type: Boolean,
      required: false,
      default: false,
    },
    canDelete: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      deleteConfirmationLabel: this.$i18n.translate("delete ok?")
    };
  },
  methods: {
    /**
     *
     */
     getCsrfToken() {
      return document.getElementsByName("csrf-token")[0].content;
    },
  }
};
</script>
