<template>
  <div class="double-check-container">
    <v-row>
      <!-- First Checker Button -->
      <v-col cols="3" class="check-column">
        <label class="check-label">発注処理</label>
        <v-btn
          :class="{ checked: localFirstCheckerId }"
          outlined
          block
          depressed
          @click="toggleFirstChecker"
        >
          <span :class="{ 'checker-name': localFirstCheckerId }">{{ localFirstCheckerSurname || '---' }}</span>
        </v-btn>
        <input type="hidden" name="order_primary_checker" :value="localFirstCheckerId">
      </v-col>
      <!-- Second Checker Button -->
      <v-col cols="3" class="check-column">
        <label class="check-label">発注処理ダブルチェック</label>
        <v-btn
          :class="{ checked: localSecondCheckerId }"
          outlined
          block
          depressed
          @click="toggleSecondChecker"
        >
          <span :class="{ 'checker-name': localSecondCheckerId }">{{ localSecondCheckerSurname || '---' }}</span>
        </v-btn>
        <input type="hidden" name="order_secondary_checker" :value="localSecondCheckerId">
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    currentUserId: {
      type: Number,
      required: true
    },
    currentUserSurname: {
      type: String,
      required: true
    },
    firstCheckerId: {
      type: Number,
      default: null
    },
    firstCheckerSurname: {
      type: String,
      default: null
    },
    secondCheckerId: {
      type: Number,
      default: null
    },
    secondCheckerSurname: {
      type: String,
      default: null
    },
    allowSamePersonsCheck: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // propsの値をコピーしてローカルで管理
      localFirstCheckerId: this.firstCheckerId,
      localFirstCheckerSurname: this.firstCheckerSurname,
      localSecondCheckerId: this.secondCheckerId,
      localSecondCheckerSurname: this.secondCheckerSurname
    };
  },
  watch: {
    // propsの変更を監視してローカルデータを更新
    firstCheckerId(newVal) {
      this.localFirstCheckerId = newVal;
    },
    firstCheckerSurname(newVal) {
      this.localFirstCheckerSurname = newVal;
    },
    secondCheckerId(newVal) {
      this.localSecondCheckerId = newVal;
    },
    secondCheckerSurname(newVal) {
      this.localSecondCheckerSurname = newVal;
    }
  },
  methods: {
    toggleFirstChecker() {
      if (this.localFirstCheckerId === this.currentUserId) {
        if (confirm('チェックを外しますか？')) {
          this.localFirstCheckerId = null;
          this.localFirstCheckerSurname = null;
        }
      } else if (!this.localFirstCheckerId && confirm('発注処理を確認しましたか？')) {
        this.localFirstCheckerId = this.currentUserId;
        this.localFirstCheckerSurname = this.currentUserSurname;
      }
    },
    toggleSecondChecker() {
      console.log('toggleSecondChecker');
      // First Checkerがいない場合はSecond Checkerできない
      if (!this.localFirstCheckerId) {
        alert('先に発注処理を完了してください。');
        return;
      }
      // allowSamePersonsCheckがfalseのとき、同じ人物が両方チェックできないようにする
      if (!this.allowSamePersonsCheck && this.localFirstCheckerId === this.currentUserId) {
        alert('発注処理と発注ダブルチェックは同じ人物が行うことはできません。');
        return;
      }
      if (this.localSecondCheckerId === this.currentUserId) {
        if (confirm('チェックを外しますか？')) {
          this.localSecondCheckerId = null;
          this.localSecondCheckerSurname = null;
        }
      } else if (!this.localSecondCheckerId && confirm("チェックは完了しましたか？")) {
        this.localSecondCheckerId = this.currentUserId;
        this.localSecondCheckerSurname = this.currentUserSurname;
      }
    }
  }
};
</script>
<style scoped>
.double-check-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
}
.check-column {
  display: flex;
  flex-direction: column;
}
.check-label {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
}
.v-btn {
  border: 1px solid #B0BEC5;
  background-color: transparent !important;
  color: #000 !important;
}
.v-btn.checked {
  border-color: red !important;
}
.checker-name {
  color: red;
}
</style>
