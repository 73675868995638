<template>
  <div>
    <v-btn color="primary" @click="showExclusionList = true"
      >納品書除外リスト</v-btn
    >
    <v-dialog
      v-model="showExclusionList"
      max-width="1000"
      overlay-opacity="0.8"
    >
      <div>
        <v-toolbar flat>
          <v-toolbar-title>納品書除外リスト</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-on="on"
                @click="newItem"
                >新規</v-btn
              >
            </template>
            <v-card>
              <v-card-title> </v-card-title>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm6 md4>
                      <v-text-field
                        v-model="product_code"
                        label="商品コード"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md4>
                      <v-text-field
                        v-model="product_name"
                        label="商品名"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" @click="save">保存</v-btn>
                <v-btn color="blue darken-1" @click="close">キャンセル</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <div class="table-responsive">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th v-for="header in headers" :key="header.id" scope="col">
                  {{ header.text }}
                </th>
              </tr>
            </thead>
            <tr v-for="row in EditItems" :key="row.id">
              <td>
                {{ row.id }}
              </td>
              <td
                v-if="
                  !isEditingProductCode || editingIdForProductCode !== row.id
                "
                @dblclick="startEditingProductCode(row.id, row.product_code)"
              >
                {{ row.product_code }}
              </td>
              <td v-else>
                <input
                  v-model="row.product_code"
                  type="text"
                  class="form-control"
                  @change="updateProductCode(row.id, row.product_code)"
                />
              </td>
              <td
                v-if="
                  !isEditingProductName || editingIdForProductName !== row.id
                "
                @dblclick="startEditingProductName(row.id, row.product_name)"
              >
                {{ row.product_name }}
              </td>
              <td v-else>
                <input
                  v-model="row.product_name"
                  type="text"
                  class="form-control"
                  @change="updateProductName(row.id, row.product_name)"
                />
              </td>
              <td>
                <v-btn type="button" icon @click="deleteItem(row.id)">
                  <i class="far fa-trash-alt" />
                </v-btn>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Axios from "axios";
export default {
  props: {
    csrf: {
      type: String,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    isEditingProductCode: false,
    isEditingProductName: false,
    editingIdForProductCode: 0,
    editingIdForProductName: 0,
    showExclusionList: false,
    EditItems: [],
    product_code: "",
    product_name: "",
  }),
  watch: {
    items: function (newItems, _) {
      this.EditItems = newItems;
    },
  },
  created() {
    this.loadData();
  },
  computed: {},

  methods: {
    /**
     *
     * @param {Number} id
     * @param {String} product_code
     */
    startEditingProductCode(id, product_code) {
      this.editingIdForProductCode = id;
      this.beforeProductCode = product_code;
      this.isEditingProductCode = true;
    },
    /**
     *
     * @param {Number} id
     * @param {String} product_name
     */
    startEditingProductName(id, product_name) {
      this.editingIdForProductName = id;
      this.beforeProductName = product_name;
      this.isEditingProductName = true;
    },
    /**
     *
     * @param {Number} id
     * @param {String} product_code
     */
    updateProductCode(id, product_code) {
      Axios.patch(
        `/api/v1/delivery_exclusion/product_code/update`,
        {
          id: id,
          product_code: product_code,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      ).catch((error) => {
        switch (error.response.status) {
          case 401:
            alert(
              "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
            );
            break;
          case 404:
            alert("接続先が見つかりませんでした。");
            break;
          case 422:
            alert(JSON.stringify(error.response.data.errors));
            break;
          case 500:
            alert("内部エラー");
            break;
          default:
            alert("通信エラーが発生しました、再度お試しください。");
            break;
        }
        console.log(error);
      });

      this.editingIdForProductCode = 0;
      this.isEditingProductCode = false;
    },
    /**
     *
     * @param {Number} id
     * @param {String} product_name
     */
    updateProductName(id, product_name) {
      Axios.patch(
        `/api/v1/delivery_exclusion/product_name/update`,
        {
          id: id,
          product_name: product_name,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      ).catch((error) => {
        switch (error.response.status) {
          case 401:
            alert(
              "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
            );
            break;
          case 404:
            alert("接続先が見つかりませんでした。");
            break;
          case 422:
            alert(JSON.stringify(error.response.data.errors));
            break;
          case 500:
            alert("内部エラー");
            break;
          default:
            alert("通信エラーが発生しました、再度お試しください。");
            break;
        }
        console.log(error);
      });

      this.editingIdForProductName = 0;
      this.isEditingProductName = false;
    },
    /**
     *
     * @param {Number} id
     */
    deleteItem(id) {
      if (!confirm("本当に削除しますか?")) {
        return;
      }
      Axios.delete(`/api/v1/delivery_exclusion/` + id, {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((res) => {
          if (res.data) {
            this.loadData();
          }
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
          console.log(error);
        });
      this.showExclusionList = false;
    },
    /**
     *
     * @param {*} _evt
     */
    onDragged(_evt) {
      this.draggableList.forEach((element, idx) => {
        Axios.post(
          `/api/v1/shipping_instruction/update`,
          {
            id: element.id,
            order: idx,
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        ).catch((error) => {
          console.log(error);
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
        });
      });
    },
    /**
     *
     * @param {String} product_code
     * @param {String} product_name
     */
    save() {
      Axios.post(
        `/api/v1/delivery_exclusion/create`,
        {
          product_code: this.product_code,
          product_name: this.product_name,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      )
        .then((response) => {
          if (response.data) {
            alert("保存が完了しました。");
            this.EditItems.push(response.data);
            this.product_code = "";
            this.product_name = "";
          }
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
          console.log(error);
        });
      this.close();
    },
    close() {
      this.dialog = false;
    },
    newItem() {},
    loadData() {
      Axios.get("/api/v1/delivery_exclusion/get", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          if (response.data) {
            console.log(response.data);
            this.EditItems = response.data;
          }
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
          console.log(error);
        });
    },
  },
};
</script>

<style>
.table-responsive table {
  background-color: white; /* 背景色を白に設定 */
}
</style>
