<template>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="1000px"
      style="z-index: 1050"
    >
      <template #activator="{ on }">
        <v-btn
          dark
          v-on="on"
          class="btn btn-primar bg-gradient-primary btn-block my-2"
        >
          {{ $t("確認事項通知") }}
        </v-btn>
      </template>
      <v-card>
        <form
          method="post"
          @submit.prevent="send()"
        >
        <v-toolbar
          color="light-blue darken-4"
          dark
        >
          <v-toolbar-title>確認事項入力</v-toolbar-title>
          <v-spacer />
          <v-btn icon>
            <v-icon @click="dialog = false">
              fas fa-window-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-textarea
                  v-model="estimateConfirmation"
                  label="確認事項"
                  required
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="row col-xs-12 col-sm-6 col-md-6">
            <button
              class="btn btn-primary bg-gradient-primary btn-block"
              type="submit"
            >
              送信
            </button>
          </div>
        </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </template>

  <script>
  import Axios from "axios";

  export default {
    props: {
      estimateId: {
        type: Number,
        required: true,
      },
      token: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        estimateConfirmation: '',
        dialog: false,
      };
    },
    methods: {
      /**
       *
       */
       send() {
        const formData = new FormData();
        formData.append("id",  this.estimateId)
        formData.append("content", this.estimateConfirmation);
        this.dialog = false;
        Axios.post(`/api/v1/estimate/estimate/notify/confirmation`, formData, {
              headers: { Authorization: "Bearer " + this.token },
            })
              .then(() => {
                alert("送信しました");
                // this.connecting = false;
                // this.progress = 100;
              })
              .catch((error) => {
                // this.connecting = false;
                // this.progress = 100;
                console.log(error);
                switch (error.response.status) {
                  case 400:
                    alert(JSON.stringify(error.response.data.message));
                    break;
                  case 401:
                    alert(
                      "認証に失敗しました"
                    );
                    break;
                  case 404:
                    alert("接続先が見つかりませんでした");
                    break;
                  case 422:
                    alert(JSON.stringify(error.response.data.errors));
                    break;
                  case 500:
                    switch(error.response.data.message) {
                      case "The company Not Found":
                        alert("会社情報が見つかりませんでした");
                        break;
                      default:
                        alert("内部エラー");
                        break;
                    }
                    break;
                  default:
                    alert("通信エラーが発生しました、再度お試しください");
                    break;
                }
              });
      },
    },
  };
  </script>
