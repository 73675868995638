<template>
  <v-card
    class="mx-auto"
    elevation="2"
    outlined
    color="light-blue lighten-5"
  >
    <v-card-title>
      <h2>{{ title }}</h2>
      <v-spacer />
    </v-card-title>
    <v-text-field
      v-model="search"
      append-icon="fa-search"
      :label="searchLabel"
      single-line
      hide-details
    />
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="10"
      :search="search"
      class="elevation-1"
      multi-sort
    />
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      search: "",
      searchLabel: this.$i18n.translate("search")
    };
  }
};
</script>