<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="700">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on"> 請求書CSV </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          伝票開始番号を指定してください
        </v-card-title>
        <div class="col-xs-12 col-sm-6 col-md-6 py-0">
          <label class="col-form-label">
            <span>伝票開始番号</span>
            <span class="badge badge-primary">必須</span>
          </label>
          <input
            type="text"
            class="form-control"
            v-model="slipStartNumber"
            required
          />
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <form @submit="onSubmit" :action="'/invoice/csv'" method="post">
            <input type="hidden" name="_token" :value="csrf" />
            <input
              type="hidden"
              name="slip_start_number"
              :value="this.slipStartNumber"
            />
            <input type="hidden" name="ids[]" :value="this.estimateIds" />
            <v-btn type="submit" class="report-form">出力</v-btn>
          </form>
        </v-card-actions>
        <v-card-text> </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    token: {
      type: String,
      required: true,
    },
    estimateIds: {
      type: Array,
      default: {},
    },
    csrf: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      slipStartNumber: "",
    };
  },
  methods: {
    /**
     *
     */
    onSubmit() {
      if (!this.slipStartNumber || this.slipStartNumber.trim() === "") {
        this.slipStartNumber = ""; // デフォルトの日付を設定
        alert(
          "伝票開始番号が設定されていないため、処理を中止します。入力してください。"
        );
        return; // 処理を中止
      }
    },
  },
};
</script>