<template>
<div class="form-row">
    <div class="col-xs-12 col-sm-6 col-md-6 py-0">
        <label class="col-form-label">
            <span>出荷日</span>
            <span v-if="shipmentDateRequired" class="badge badge-primary">{{
                $t("required")
              }}</span>
            <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
        </label>
        <input
            type="date"
            :disabled="false"
            :readonly="false"
            class="form-control"
            v-model="shipmentDateLabelValue"
            :min="this.minshipmentDateLabelValue"
            :required="true"
        >
        <input type="hidden" name="shipment_date" :value="this.shipmentDateFormValue">
    </div>
    <div class="col-xs-12 col-sm-6 col-md-6 py-0">
        <label class="col-form-label">
            <span>売上日</span>
            <span v-if="salesDateRequired" class="badge badge-primary">{{
                $t("required")
              }}</span>
            <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
        </label>
        <input
            type="date"
            :disabled="this.salesDateDisabled"
            :readonly="this.salesDateReadonly"
            class="form-control"
            v-model="salesDateFormValue"
            :min="this.minSalesDateLabelValue"
            :max="this.maxSalesDateLabelValue"
            :required="true"
        >
        <input type="hidden" name="sales_date" :value="this.salesDateFormValue">
    </div>
    <div class="col-xs-12 col-sm-6 col-md-6 py-0">
        <label class="col-form-label">
            <span>請求日</span>
            <span v-if="billingDateRequired" class="badge badge-primary">{{
                $t("required")
              }}</span>
            <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
        </label>
        <input
            type="date"
            :disabled="false"
            :readonly="false"
            class="form-control"
            name="billing_date"
            v-model="billingDateLabelValue"
            :min="this.minBillingDateLabelValue"
            :max="this.maxBillingDateLabelValue"
            :required="true"
        >
    </div>
</div>
</template>

<script>
import Axios from "axios";

export default {
    props: {
        dateForShippingDateCalculation: {
            type: String,
            default: "",
        },
        oldShipmentDate: {
            type: [String, null],
            default: null,
        },
        oldSalesDate: {
            type: [String, null],
            default: null,
        },
        oldBillingDate: {
            type: [String, null],
            default: null,
        },
        token: {
            type: String,
            required: true,
        },
        time: {
            type: String,
            default: "",
        },
        isProductionManagement: {
            type: Boolean,
            default: false,
        },
        isModify: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            shipmentDateFormValue: "",
            salesDateFormValue: "",
            shipmentDateLabelValue: "",
            salesDateLabelValue: "",
            billingDateLabelValue: "",
            minshipmentDateLabelValue: "",
            minBillingDateLabelValue: "",
            maxBillingDateLabelValue: "",
            minSalesDateLabelValue: "",
            maxSalesDateLabelValue: "",
            items: [],
            first_business_day: "",
            second_business_day: "",
            salesDateDisabled: true,
            salesDateReadonly: true,
            shipmentDateRequired: true,
            salesDateRequired: true,
            billingDateRequired: true,
        }
    },
    created: function () {
        this.loadList();
    },
    watch: {
        shipmentDateLabelValue: function (newshipmentDateLabelValue, _) {
            this.shipmentDateFormValue = newshipmentDateLabelValue;
            if (!this.isProductionManagement || !this.isModify) {
                this.salesDateLabelValue = newshipmentDateLabelValue;
                this.salesDateFormValue = newshipmentDateLabelValue;
                this.billingDateLabelValue = newshipmentDateLabelValue;
            }
            this.minBillingDateLabelValue = this.isProductionManagement
                ? null
                : this.minDays(newshipmentDateLabelValue, 1);
            this.maxBillingDateLabelValue = this.isProductionManagement
                ? null
                : this.maxDays(newshipmentDateLabelValue, 1);
            this.minshipmentDateLabelValue = this.isProductionManagement
                ? null
                : newshipmentDateLabelValue;
            //売上日を活性にするかどうかチェックする
            this.checkShipmentDate(newshipmentDateLabelValue);
        },
        salesDateDisabled: function (newsalesDateDisabled, _) {
            this.salesDateDisabled = newsalesDateDisabled;
        },
        salesDateReadonly: function (newsalesDateReadonly, _) {
            this.salesDateReadonly = newsalesDateReadonly;
        },
    },
    methods: {
        /**
         * 休日データ一覧をthis.itemsに格納する
         */
        async loadList() {
            this.loading = true;
            //休日取得API
            await Axios.get(
                `/api/v1/kentem/holidays`,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token,
                    },
                }
            ).then(
                (res) => {
                    if (res) {
                        this.items = res.data;
                    }
                }
            ).catch(
                (error) => {
                    console.log(error);
                }
            );

            this.loading = false;

            this.items = this.sortItems(this.items);
            if (this.isModify && this.isProductionManagement) {
                if (this.oldSalesDate !== null) {
                    this.salesDateLabelValue = this.oldSalesDate;
                    this.salesDateFormValue = this.oldSalesDate;
                }
                if (this.oldBillingDate !== null) {
                    this.billingDateLabelValue = this.oldBillingDate;
                }
            }
            if (this.oldShipmentDate !== null) {
                this.shipmentDateLabelValue = this.oldShipmentDate;
                return;
            }
            this.shipmentDateLabelValue =  this.formatSetting(
                this.calculateShippingDate(
                    this.dateForShippingDateCalculation
                )
            );
        },
        /**
         * 指定した月数を足した日付を返す
         * 
         * @param {String} date 
         * @param {Number} months 
         * 
         * @return {String}
         */
        maxDays(date, months) {
            var date1 = new Date(date);
            date1.setMonth(date1.getMonth() + months);
            return this.formatDate(date1);
        },
        /**
         * 指定した月数を減らした日付を返す
         * 
         * @param {String} date 
         * @param {Number} months 
         * 
         * @return {String}
         */
        minDays(date, months) {
            var date1 = new Date(date);
            date1.setMonth(date1.getMonth() - months);
            return this.formatDate(date1);
        },
        /**
         * Dateをyyyy-mm-dd形式の文字列に変換する
         * 
         * @param {Date} dt 
         * 
         * @return {String}
         */
        formatDate(dt) {
            var y = dt.getFullYear().toString();
            var m = ('00' + (dt.getMonth() + 1)).slice(-2);
            var d = ('00' + dt.getDate().toString()).slice(-2);
            return (y + '-' + m + '-' + d);
        },
        /**
         * Dateをyyyymmdd形式の文字列に変換する
         * 
         * @param {Date} dt 
         * 
         * @return {String}
         */
        formatDate1(dt) {
            var y = dt.getFullYear().toString();
            var m = ('00' + (dt.getMonth() + 1).toString()).slice(-2);
            var d = ('00' + dt.getDate().toString()).slice(-2);
            return (y  + m + d);
        },
        /**
         * yyyymmdd形式の文字列をyyyy-mm-dd形式に変換する
         * 
         * @param {String} dt yyyymmdd形式の文字列
         * 
         * @return {String} yyyy-mm-dd形式の文字列
         */
        formatSetting(dt) {
            var y = dt.substring(0, 4);
            var m = dt.substring(4, 6);
            var d = dt.substring(6, 8);
            return (y + '-' + m + '-' + d);
        },
        /**
         * 来月頭の日付を取得する
         * 
         * @return {String}
         */
        getNextBeginningDate() {
            var current_date = new Date();
            current_date.setMonth(current_date.getMonth() + 1);
            current_date.setDate(1);
            //getDay 0:日曜日、1:月曜日、2:火曜日、3:水曜日、4:木曜日、5:金曜日、6:土曜日
            if(current_date.getDay() == 6 || current_date.getDay() == 0){
                if(current_date.getDay() == 6){
                    current_date.setDate(3);
                }else{
                    current_date.setDate(2);
                }
            }
            return this.formatDate1(current_date);
        },
        /**
         * 今月末の日付を取得する
         * 
         * @return {String}
         */
        getEndOfThisMonth() {
            var current_date = new Date();
            return this.formatDate(new Date(current_date.getFullYear(), current_date.getMonth() + 1, 0));
        },
        /**
         * yyyymmdd形式の文字列の日時を1日足して返す
         * 
         * @param {String} dt yyyymmdd形式の文字列
         * 
         * @return {String} yyyymmdd形式の文字列
         */
        addDate(dt) {
            var date = new Date(dt.substring(0, 4), dt.substring(4, 6)-1, dt.substring(6, 8));
            date.setDate(date.getDate() + 1);
            return this.formatDate1(date);
        },
        /**
         * 売上日を有効にするかどうかチェックし反映させる
         * 
         * @param {String} dt 
         */
        checkShipmentDate(dt) {
            if (this.isProductionManagement) {
                this.salesDateDisabled = false;
                this.salesDateReadonly = false;
                return;
            }

            //現在の日付
            var current_date = new Date();
            var current_date_month = current_date.getMonth() + 1;

            //今月末
            var end_of_this_month = this.getEndOfThisMonth();

            //来月の第一営業日を取得
            var next_beginning_date = this.getNextBeginningDate();

            //出荷日
            var shipment_date = new Date(dt);
            var shipment_date_month = shipment_date.getMonth() + 1;
            if (current_date_month < shipment_date_month) {
                //翌月の営業日取得
                this.getBusinessDay(next_beginning_date);
                if (
                    this.first_business_day == this.formatDate1(shipment_date) ||
                    this.second_business_day == this.formatDate1(shipment_date)
                ) {
                    if(this.first_business_day == this.formatDate1(shipment_date)){
                        this.minSalesDateLabelValue = end_of_this_month;
                        this.maxSalesDateLabelValue = this.formatSetting(this.first_business_day);
                    } else if(this.second_business_day == this.formatDate1(shipment_date)) {
                        this.minSalesDateLabelValue = end_of_this_month;
                        this.maxSalesDateLabelValue = this.formatSetting(this.second_business_day);
                    }
                    this.salesDateDisabled = false;
                    this.salesDateReadonly = false;
                } else {
                    this.salesDateDisabled = true;
                    this.salesDateReadonly = true;
                }
            }
        },
        /**
         * first_business_day, second_business_dayを設定する
         * 
         * @param {String} date 
         */
        async getBusinessDay(date) {
            this.loading = true;
                this.items.forEach((element) => {
                    if (this.first_business_day == "") {
                         if (element.date == date && element.is_non_business_day == false) {
                            this.first_business_day = date;
                            date = this.addDate(this.first_business_day);
                        }
                    } else if(this.second_business_day == "") {
                        if (element.date == date && element.is_non_business_day == false) {
                            this.second_business_day = date;
                        }
                    }
                });
            this.loading = false;
        },
        /**
         *  配列を日付でソートする
         * 
         * @param {Array} items 
         * 
         * @return {Array}
         */
        sortItems(items) {
            return items.sort((a, b) => (a.date < b.date) ? -1 : 1);
        },
        /**
         * yyyy-mm-dd形式の現在の日付から出荷日を計算する
         * 
         * @param {String} date yyyy-mm-dd形式の現在の日付
         * 
         * @return {String}
         */
        calculateShippingDate(date) {
            // 特別対応 issue #3955 
            // if (["2022-06-17", "2022-06-18", "2022-06-19"].includes(date)) {
            //     return "20220622";
            // }
            // if (date == "2022-06-20") {
            //     const target = new Date(date + " " + this.time);
            //     const startDateTime = new Date(date + " 09:00:00");
            //     const endDateTime = new Date(date + " 12:59:59");
            //     if (target.getTime() >= startDateTime.getTime() && target.getTime() <= endDateTime.getTime()) {
            //         return "20220623";
            //     }
            // }

            const key = date.replace(/-/g, '');
            let index = this.items.findIndex((e) => e.date === key);

            if (this.items[index].is_non_business_day) {
                let counter = 0;
                this.items.slice(index + 1).some((e) => {
                    counter++;
                    if (!e.is_non_business_day) {
                        return true;
                    }
                });
                index += counter;
            }

            let counter = 2;
            let result = "";
            this.items.slice(index + 1).some((e) => {
                if (!e.is_non_business_day) {
                    counter--;
                    result = e.date;
                    return counter === 0;
                }
            });
            return result;
        },
    },
};
</script>
