<template>
  <div class="form-group">
    <label class="col-form-label">
      <span>商品カテゴリー</span>
      <span v-if="cloudrequired" class="badge badge-primary">{{
        $t("required")
      }}</span>
      <span v-else class="badge badge-secondary">{{ $t("optional") }}</span>
    </label>
    <multiselect
      v-model="selectedSeries"
      placeholder="選択してください"
      label="name"
      track-by="id"
      :multiple="true"
      :options="series"
      :taggable="true"
      :close-on-select="true"
    />
    <label class="col-form-label">
      <span>{{ label }}</span>
      <span v-if="required" class="badge badge-primary">{{
        $t("required")
      }}</span>
      <span v-else class="badge badge-secondary">{{ $t("optional") }}</span>
    </label>
    <product-dialog
      :delete-item-by-id="deleteItemById"
      :headers="headers"
      :id-name="idName"
      :items="items_condition"
      :loading="loading"
      :select-item="selectItem"
      :selected-product-id-list="selectedProductIdList"
      :title="title"
    />
    <product-table
      :delete-item="deleteItem"
      :product-name-list="selectedProductNameList"
      :product-id-list="selectedProductIdList"
    />
  </div>
</template>

<script>
import Axios from "axios";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

import ProductDialog from "./refproductlist/product-dialog.vue";
import ProductTable from "./refproductlist/product-table.vue";
import SelectItem from "./refproductlist/select-item.js";
import Token from "./token";

export default {
  components: {
    Multiselect,
    ProductDialog,
    ProductTable,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    idName: {
      type: String,
      default: "id",
    },
    defaultLabelName: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    kaisokurequired: {
      type: Boolean,
      default: false,
    },
    cloudrequired: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: String,
      default: "",
    },
    labelKey: {
      type: String,
      default: "",
    },
    useAutoClose: {
      type: Boolean,
      default: true,
    },
    ricohUrl: {
      type: String,
      required: true,
    },
    salesSegment: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      bearer: "",
      formValue: this.defaultValue,
      labelName: this.defaultLabelName,
      labelValue: "",
      headers: [],
      items: [],
      items_condition: [],
      items_condition_default: [],
      selectedSeriesIds: [],
      selectedSeries: [],
      loading: true,
      placeholder: "",
      selected: "",
      tokenUrl: "/api/v1/token",
      selectedProductIdList: [],
      selectedProductNameList: [],
      value: [],
      series: [
        {
          name: "コンストラクションスクール",
          id: "34",
        },
        {
          name: "INNOSiTE TK",
          id: "35",
        },
        {
          name: "Training & Education",
          id: "36",
        },
      ],
    };
  },
  watch: {
    selectedSeries(newValues) {
      this.selectedSeriesIds = newValues.map((obj) => obj.id);

      if (this.selectedSeriesIds.length === 0) {
        this.items_condition = this.items_condition_default;
      } else {
        this.items_condition = this.selectedSeriesIds.reduce((acc, element) => {
          return acc.concat(
            this.items.filter((item) => item.type_number == element)
          );
        }, []);
      }

      this.loading = false;
    },
  },
  created: function () {
    if (this.labelName === "") {
      this.labelName = this.idName;
    }
    this.labelName = this.defaultLabelName ?? this.idName;
    this.getTokenAndLoadList();
  },

  methods: {
    /**
     *
     */
    async loadList() {
      this.loading = true;
      if (this.formValue !== "" && this.labelKey !== "") {
        this.labelValue = this.$i18n.translate("loading");
      }
      if (this.salesSegment == 2) {
        await Axios.get(this.ricohUrl, {
          headers: {
            Authorization: this.bearer,
          },
        })
          .then((res) => {
            if (res.data) {
              this.headers = res.data.headers;
              this.items = res.data.items;
              this.items_condition = res.data.items;
              this.items_condition_default = res.data.items;
            }
          })
          .catch((error) => {
            console.log(error);
            this.labelValue = this.$i18n.translate("unknown");
          });
      } else {
        await Axios.get(this.url, {
          headers: {
            Authorization: this.bearer,
          },
        })
          .then((res) => {
            if (res.data) {
              this.headers = res.data.headers;
              this.items = res.data.items;
              this.items_condition = res.data.items;
              this.items_condition_default = res.data.items;
            }
          })
          .catch((error) => {
            console.log(error);
            this.labelValue = this.$i18n.translate("unknown");
          });
      }

      if (this.formValue !== "" && this.labelKey !== "") {
        const defaultItem = this.items.find(
          (row) => row[this.idName] === this.formValue
        );
        this.labelValue =
          typeof defaultItem !== "undefined"
            ? defaultItem[this.labelKey]
            : this.$i18n.translate("unknown");
      }
      this.loading = false;
    },
    /**
     *
     * @param {Object} item
     */
    selectItem(item) {
      SelectItem(this, item, this.useAutoClose);
    },
    /**
     *
     * @param {Number} index
     */
    deleteItem(index) {
      this.selectedProductIdList.splice(index, 1);
      this.selectedProductNameList.splice(index, 1);
    },
    /**
     *
     */
    deleteItemAll() {
      this.selectedProductIdList = [];
      this.selectedProductNameList = [];
    },
    /**
     *
     * @param {Number} id
     */
    deleteItemById(id) {
      const index = this.selectedProductIdList.indexOf(id);
      this.deleteItem(index);
    },
    /**
     *
     */
    async getTokenAndLoadList() {
      this.bearer = await Token(this.tokenUrl);
      this.loadList();
    },
  },
};
</script>
