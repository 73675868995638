<template>
  <div>
    <div class="button-group" style="display: flex">
      <!-- hapilogi-csv -->
      <hapilogi-csv
        :estimate-ids="this.localEstimateIds"
        :token="this.token"
        :csrf="this.csrf"
        style="margin-right: 10px"
      ></hapilogi-csv>

      <!-- yamato-invoice-csv -->
      <yamato-invoice-csv
        :estimate-ids="this.localEstimateIds"
        :token="this.token"
        :csrf="this.csrf"
        style="margin-right: 10px"
      ></yamato-invoice-csv>

      <!-- nittu-invoice-csv -->
      <nittu-invoice-csv
        :estimate-ids="this.localEstimateIds"
        :token="this.token"
        :csrf="this.csrf"
        style="margin-right: 10px"
      ></nittu-invoice-csv>

      <!-- invoice-csv -->
      <invoice-csv
        :estimate-ids="this.localEstimateIds"
        :token="this.token"
        :csrf="this.csrf"
        style="margin-right: 10px"
      ></invoice-csv>

      <!-- delivery-exclusion-list -->
      <delivery-exclusion-list
        :csrf="this.csrf"
        :token="this.token"
        :headers="this.headers"
        :items="this.items"
        style="margin-right: 10px"
      ></delivery-exclusion-list>

      <form @submit="onSubmit" :action="'/delivery_slip_input'" method="get">
        <input type="hidden" name="_token" :value="csrf" />
        <input type="hidden" name="ids[]" :value="this.localEstimateIds" />
        <v-btn type="submit" color="primary">納品書PDF</v-btn>
      </form>

      <div style="margin-right: 10px"></div>

      <form
        @submit="onSubmit"
        :action="'/delivery_slip_input/separate'"
        method="get"
      >
        <input type="hidden" name="_token" :value="csrf" />
        <input type="hidden" name="ids[]" :value="this.localEstimateIds" />
        <v-btn type="submit" color="primary">納品書Folder PDF</v-btn>
      </form>

      <div style="margin-right: 10px"></div>

      <form @submit="confirmSubmit" :action="'/user_db/transfer'" method="post">
        <input type="hidden" name="_token" :value="csrf" />
        <input type="hidden" name="ids[]" :value="this.localEstimateIds" />
        <v-btn type="submit" color="primary">UserDB転送</v-btn>
      </form>
    </div>

    <div style="margin-bottom: 20px"></div>

    <div class="button-group" style="display: flex">
      <form
        @submit="onSubmit"
        :action="'/order/download/order_pdfs/list'"
        method="post"
      >
        <input type="hidden" name="_token" :value="csrf" />
        <input type="hidden" name="ids[]" :value="this.localEstimateIds" />
        <v-btn type="submit" class="report-form">受注確定レポートDL</v-btn>
      </form>

      <div style="margin-right: 10px"></div>

      <form
        @submit="onSubmit"
        :action="'/order/download/hardware/list'"
        method="post"
      >
        <input type="hidden" name="_token" :value="csrf" />
        <input type="hidden" name="ids[]" :value="this.localEstimateIds" />
        <v-btn type="submit" class="report-form">ハードCSVDL</v-btn>
      </form>

      <div style="margin-right: 10px"></div>

      <form
        @submit="onSubmit"
        :action="'/order/download/shipping_email_address/list'"
        method="post"
      >
        <input type="hidden" name="_token" :value="csrf" />
        <input type="hidden" name="ids[]" :value="this.localEstimateIds" />
        <v-btn type="submit" class="report-form"
          >発送先メールアドレスCSVDL</v-btn
        >
      </form>

      <div style="margin-right: 10px"></div>

      <form @submit="onSubmit" :action="'/shipment/list'" method="post">
        <input type="hidden" name="_token" :value="csrf" />
        <input type="hidden" name="ids[]" :value="this.localEstimateIds" />
        <v-btn type="submit" class="report-form">出荷一覧表DL</v-btn>
      </form>

      <div style="margin-right: 10px"></div>

      <form @submit="onSubmit" :action="'/shipment/detail/list'" method="post">
        <input type="hidden" name="_token" :value="csrf" />
        <input type="hidden" name="ids[]" :value="this.localEstimateIds" />
        <v-btn type="submit" class="report-form">出荷内容物一覧表DL</v-btn>
      </form>
    </div>

    <div style="margin-bottom: 20px"></div>

    <v-card class="mt-2">
      <v-card-text>
        <!-- shipping-instruction-search-form -->
        <shipping-instruction-search-form
          :csrf="this.csrf"
          :token="this.token"
          :shipping-instructions="this.shippingInstructions"
          :customer-names="this.customerNames"
          :sales-names="this.salesNames"
          :shipment-way-statuses="this.shipmentWayStatuses"
          :shipment-statuses="this.shipmentStatuses"
          :order-confirmation-report-output-statuses="
            this.orderConfirmationReportOutputStatuses
          "
          :delivery-slip-output-statuses="this.deliverySlipOutputStatuses"
          :confirmation-completer-list="this.confirmationCompleterList"
          @selected-instructions-updated="updateSelectedInstructions"
        ></shipping-instruction-search-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import HapilogiCsv from "./hapilogi-csv.vue";
import YamatoInvoiceCsv from "./yamato-invoice-csv.vue";
import NittuInvoiceCsv from "./nittu-invoice-csv.vue";
import InvoiceCsv from "./invoice-csv.vue";
import DeliveryExclusionList from "./delivery-exclusion-list.vue";
import ShippingInstructionSearchForm from "./shipping-instruction-search-form.vue";
export default {
  components: {
    HapilogiCsv,
    YamatoInvoiceCsv,
    NittuInvoiceCsv,
    InvoiceCsv,
    DeliveryExclusionList,
    ShippingInstructionSearchForm,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
    csrf: {
      type: String,
      required: true,
    },
    customerNames: {
      type: Object,
      required: true,
    },
    salesNames: {
      type: Object,
      required: true,
    },
    shippingInstructions: {
      type: Array,
      required: true,
    },
    estimateIds: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    shipmentWayStatuses: {
      type: Array,
      required: true,
    },
    shipmentStatuses: {
      type: Array,
      required: true,
    },
    orderConfirmationReportOutputStatuses: {
      type: Array,
      required: true,
    },
    deliverySlipOutputStatuses: {
      type: Array,
      required: true,
    },
    confirmationCompleterList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localEstimateIds: [],
    };
  },
  methods: {
    updateSelectedInstructions(selectedData) {
      this.localEstimateIds = selectedData;
    },
    onSubmit(event) {
      if (!this.validateInput()) {
        event.preventDefault();
        return;
      }
    },
    confirmSubmit(event) {
      if (!this.validateInput()) {
        event.preventDefault();
        return;
      }
      if (confirm("本当に実行してよろしいですか？")) {
        this.onSubmit();
      }else{
        event.preventDefault();
      }
    },
    validateInput() {
      if (this.localEstimateIds.length === 0) {
        alert("明細を選択してください");
        return false;
      }
      return true;
    },
  },
};
</script>

<style>
.report-form {
  background-color: #ffc04d !important;
  color: white !important;
}
</style>
