/**
 * searchに会社名を設定する
 *
 * @param {Object} search
 * @param {String} e
 */
const makeSearchName = (search, e) => {
        const parsedSearch = JSON.parse(search);
        return JSON.stringify({
            name: e,
            kana: parsedSearch.kana,
            address: parsedSearch.address,
            kentem_id: parsedSearch.kentem_id,
            shokon_code: parsedSearch.shokon_code,
        });
}

/**
 * searchに会社名読み仮名を設定する
 *
 * @param {Object} search
 * @param {String} e
 */
const makeSearchKana = (search, e) => {
        const parsedSearch = JSON.parse(search);
        return JSON.stringify({
            name: parsedSearch.name,
            kana: e,
            address: parsedSearch.address,
            kentem_id: parsedSearch.kentem_id,
            shokon_code: parsedSearch.shokon_code,
        });
}

/**
 * searchに住所を設定する
 *
 * @param {Object} search
 * @param {String} e
 */
const makeSearchAddress = (search, e) => {
        const parsedSearch = JSON.parse(search);
        return JSON.stringify({
            name: parsedSearch.name,
            kana: parsedSearch.kana,
            address: e,
            kentem_id: parsedSearch.kentem_id,
            shokon_code: parsedSearch.shokon_code,
        });
}

/**
 * searchにKentemIdを設定する
 *
 * @param {Object} search
 * @param {String} e
 */
const makeSearchKentemId = (search, e) => {
        const parsedSearch = JSON.parse(search);
        return JSON.stringify({
            name: parsedSearch.name,
            kana: parsedSearch.kana,
            address: parsedSearch.address,
            kentem_id: e,
            shokon_code: parsedSearch.shokon_code,
        });
}

/**
 * searchにKentemIdを設定する
 *
 * @param {Object} search
 * @param {String} e
 */
const makeSearchShokonCode = (search, e) => {
        const parsedSearch = JSON.parse(search);
        return JSON.stringify({
            name: parsedSearch.name,
            kana: parsedSearch.kana,
            address: parsedSearch.address,
            kentem_id: parsedSearch.kentem_id,
            shokon_code: e,
        });
}

/**
 * 検索フィルター
 *
 * @param {*} value
 * @param {Object} search
 * @param {Object} item
 */
const filterSearch = () => {
    return (value, search, item) => {
        const parsedSearch = JSON.parse(search);
        const requiredKeys = ['address', 'kentem_id', 'shokon_code'];

        return (
          search != null &&
          (
              isNotNullAndString(item, 'sales_name') ||
              isNotNullAndString(item, 'name')
          ) &&
          (
              isNotNullAndString(item, 'sales_name_f') ||
              isNotNullAndString(item, 'yomigana')
          ) &&
          requiredKeys.every(
            key => containsSearchValue(item, key, parsedSearch[key])
          ) &&
          (
            'name' in parsedSearch &&
            'kana' in parsedSearch &&
            (
              (
                containsSearchValue(item, 'sales_name', parsedSearch.name) &&
                containsSearchValue(item, 'sales_name_f', parsedSearch.kana)
              ) ||
              (
                containsSearchValue(item, 'name', parsedSearch.name) &&
                containsSearchValue(item, 'yomigana', parsedSearch.kana)
              )
            )
          )
        );
      }
}

/**
 * item[key]が文字列か
 *
 * @param {Object} item
 * @param {String} key
 */
const isNotNullAndString = (item, key) => {
    return item[key] != null && typeof item[key] === "string";
}

/**
 * 検索文字列が含まれているか
 *
 * @param {*} item
 * @param {*} key
 * @param {*} search
 */
const containsSearchValue = (item, key, search) => {
    return isNotNullAndString(item, key) &&
    item[key].includes(search);
}

export {
    filterSearch,
    makeSearchAddress,
    makeSearchKana,
    makeSearchKentemId,
    makeSearchName,
    makeSearchShokonCode
};
