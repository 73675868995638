<template>
  <div class="form-group">
    <label class="col-form-label">
      <span>{{ label }}</span>
      <span
        v-if="required"
        class="badge badge-primary"
      >{{
        $t("required")
      }}</span>
      <span
        v-else
        class="badge badge-secondary"
      >{{ $t("optional") }}</span>
    </label>
    <v-textarea
      :value="shipmentRemarksFormValue"
      name="shipment_remarks"
      auto-grow
      counter
      no-resize
      clearable
      outlined
      placeholder="文字を入力"
      rows="3"
      maxlength="200"
    >
    </v-textarea> 
  </div>
</template>

<script>

export default {
  props: {
    idName: {
      type: String,
      default: "id",
    },
    defaultLabelName: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: String,
      default: "",
    },
    labelKey: {
      type: String,
      default: "",
    },
    estimateId: {
      type: String,
      default: "estimate_id",
    },
    shipmentRemarksDefaultValue: {
      type: String,
      default: "shipment_remarks",
    },
  },
  data() {
    return {
      dialog: false,
      formValue: this.defaultValue,
      labelName: this.defaultLabelName,
      labelValue: "",
      loading: true,
      loadingLabel: this.$i18n.translate("loading"),
      placeholder: "",
      shipmentRemarksFormValue:this.shipmentRemarksDefaultValue
    }
  },
  created: function () {
    if (this.labelName === "") {
      this.labelName = this.idName;
    }
    this.labelName = this.defaultLabelName ?? this.idName;

    this.loadList();
  },
  methods: {
    /**
     * 
     */
    async loadList() {
      this.loading = true;
      if (this.formValue !== "" && this.labelKey !== "") {
        this.labelValue = this.$i18n.translate("loading");
      }

      if (this.formValue !== "" && this.labelKey !== "") {
        const defaultItem = this.items.find(
          (row) => row[this.idName] === this.formValue
        );
        this.labelValue =
          typeof defaultItem !== "undefined"
            ? defaultItem[this.labelKey]
            : this.$i18n.translate("unknown");
      }
      this.loading = false;
    },
  },
};
</script>