<template>
  <v-select
    v-model="selected"
    :options="optionList"
    :reduce="(name) => name.id"
    label="name"
    v-on="$_selectList_setInitialValue(value)"
  >
    <template #search="{ attributes, events }">
      <input
        v-model="selected"
        :name="name"
        class="vs__search"
        :required="required == true"
        v-bind="attributes"
        style="background-color: white"
        v-on="events"
      >
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: [String, Number],
      required: false,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      optionList: [],
      selected: null,
    };
  },
  created() {
    this.optionList = this.options;
    if (!this.required) {
      this.optionList.unshift(
        {
          'id': 0,
          'name': '',
        },
      );
    }
    //this.draggableList = this.items;
  },
  methods: {
    $_selectList_setInitialValue: function (val) {
      if (this.selected !== null) {
        return;
      }
      this.selected = val ?? "";
    },
  },
};
</script>