<template>
  <div>
    <v-progress-circular
      v-if="connecting"
      :indeterminate="progress === 0"
      :value="progress"
      color="light-blue"
    />
    <v-card>
      <v-card-text>
        <v-expansion-panels focusable v-if="shippingInstructionStatus === 0">
          <v-expansion-panel>
            <v-expansion-panel-header>一括変更</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-row align-content="center">
                  <v-col cols="auto" class="mr-auto">
                    <v-switch
                      v-model="isBulkUpdateProductName2"
                      :label="`商品名2一括変更モード: ${
                        isBulkUpdateProductName2 ? 'ON' : 'OFF'
                      }`"
                    />
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <v-btn
                      v-if="isBulkUpdateProductName2"
                      color="secondary"
                      @click="changeStatusProductName2Target(false)"
                      >全選択解除</v-btn
                    >
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <v-btn
                      v-if="isBulkUpdateProductName2"
                      color="secondary"
                      @click="changeStatusProductName2Target(true)"
                      >全選択</v-btn
                    >
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <label
                      v-if="isBulkUpdateProductName2"
                      for="bulcProductName2"
                      >商品名2</label
                    >
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <input
                      v-if="isBulkUpdateProductName2"
                      v-model="bulkProductName2"
                      name="bulkProductName2"
                      id="bulkProductName2"
                      class="form-control"
                    />
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <v-btn
                      v-if="isBulkUpdateProductName2"
                      type="button"
                      icon
                      class="bg-gradient-primary shadow"
                      @click="bulkUpdateProductName2"
                    >
                      <i class="fas fa-save" />
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
              <v-container>
                <v-row align-content="center">
                  <v-col cols="auto" class="mr-auto">
                    <v-switch
                      v-model="isBulkUpdateUserId"
                      :label="`ユーザーID一括変更モード: ${
                        isBulkUpdateUserId ? 'ON' : 'OFF'
                      }`"
                    />
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <v-btn
                      v-if="isBulkUpdateUserId"
                      color="secondary"
                      @click="changeStatusUserIdTarget(false)"
                      >全選択解除</v-btn
                    >
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <v-btn
                      v-if="isBulkUpdateUserId"
                      color="secondary"
                      @click="changeStatusUserIdTarget(true)"
                      >全選択</v-btn
                    >
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <label v-if="isBulkUpdateUserId" for="bulcUserId"
                      >ユーザーID</label
                    >
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <input
                      v-if="isBulkUpdateUserId"
                      v-model="bulkUserId"
                      name="bulkUserId"
                      id="bulkUserId"
                      class="form-control"
                    />
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <v-btn
                      v-if="isBulkUpdateUserId"
                      type="button"
                      icon
                      class="bg-gradient-primary shadow"
                      @click="bulkUpdateUserId"
                    >
                      <i class="fas fa-save" />
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
              <v-container>
                <v-row align-content="center">
                  <v-col cols="auto" class="mr-auto">
                    <v-switch
                      v-model="isBulkUpdateRequirementCode"
                      :label="`要件コード一括変更モード: ${
                        isBulkUpdateRequirementCode ? 'ON' : 'OFF'
                      }`"
                    />
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <v-btn
                      v-if="isBulkUpdateRequirementCode"
                      color="secondary"
                      @click="changeStatusRequirementCodeTarget(false)"
                      >全選択解除</v-btn
                    >
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <v-btn
                      v-if="isBulkUpdateRequirementCode"
                      color="secondary"
                      @click="changeStatusRequirementCodeTarget(true)"
                      >全選択</v-btn
                    >
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <label
                      v-if="isBulkUpdateRequirementCode"
                      for="bulcRequirementCode"
                      >要件コード
                    </label>
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <select
                      v-if="isBulkUpdateRequirementCode"
                      v-model="bulkRequirementCode"
                      name="balkRequirementCode"
                      id="balkRequirementCode"
                      class="form-control"
                    >
                      <option value="1">既存_販売店</option>
                      <option value="2">既存_直販</option>
                      <option value="3">新規_販売店</option>
                      <option value="4">新規_直販</option>
                    </select>
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <v-btn
                      v-if="isBulkUpdateRequirementCode"
                      type="button"
                      icon
                      class="bg-gradient-primary shadow"
                      @click="bulkUpdateRequirementCode()"
                    >
                      <i class="fas fa-save" />
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
              <v-container>
                <v-row align-content="center">
                  <v-col cols="auto" class="mr-auto">
                    <v-switch
                      v-model="isBulkUpdateProductCode"
                      :label="`商品コード一括変更モード: ${
                        isBulkUpdateProductCode ? 'ON' : 'OFF'
                      }`"
                    />
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <v-btn
                      v-if="isBulkUpdateProductCode"
                      color="secondary"
                      @click="changeStatusProductCodeTarget(false)"
                      >全選択解除</v-btn
                    >
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <v-btn
                      v-if="isBulkUpdateProductCode"
                      color="secondary"
                      @click="changeStatusProductCodeTarget(true)"
                      >全選択</v-btn
                    >
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <label v-if="isBulkUpdateProductCode" for="bulcProductCode"
                      >商品コード</label
                    >
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <select
                      v-if="isBulkUpdateProductCode"
                      v-model="bulkProductCode"
                      name="balkProductCode"
                      id="balkProductCode"
                      class="form-control"
                    >
                      <option value="001">新規</option>
                      <option value="000">再加入</option>
                      <option value="003">更新</option>
                      <option value="005">追加</option>
                      <option value="013">先取</option>
                      <option value="002">新規複数年</option>
                      <option value="100">再加入複数年</option>
                      <option value="103">更新複数年</option>
                      <option value="105">追加複数年</option>
                      <option value="113">先取複数年</option>
                    </select>
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <v-btn
                      v-if="isBulkUpdateProductCode"
                      type="button"
                      icon
                      class="bg-gradient-primary shadow"
                      @click="bulkUpdateProductCode()"
                    >
                      <i class="fas fa-save" />
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
              <v-container>
                <v-row align-content="center">
                  <v-col cols="auto" class="mr-auto">
                    <v-switch
                      v-model="isBulkUpdateShipmentWay"
                      :label="`出荷形態一括変更モード: ${
                        isBulkUpdateShipmentWay ? 'ON' : 'OFF'
                      }`"
                    />
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <v-btn
                      v-if="isBulkUpdateShipmentWay"
                      color="secondary"
                      @click="changeStatusShipmentWayTarget(false)"
                      >全選択解除</v-btn
                    >
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <v-btn
                      v-if="isBulkUpdateShipmentWay"
                      color="secondary"
                      @click="changeStatusShipmentWayTarget(true)"
                      >全選択</v-btn
                    >
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <label v-if="isBulkUpdateShipmentWay" for="bulcShipmentWay"
                      >出荷形態</label
                    >
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <select
                      v-if="isBulkUpdateShipmentWay"
                      v-model="bulkShipmentWay"
                      name="balkShipmentWay"
                      id="balkShipmentWay"
                      class="form-control"
                    >
                      <option value="1">奈良委託_ヤマト</option>
                      <option value="7">奈良委託_ヤマト_MQR</option>
                      <option value="2">富士本社_ヤマト</option>
                      <option value="3">富士本社_日通</option>
                      <option value="4">転送処理</option>
                      <option value="5">請求のみ</option>
                      <option value="6">KJK</option>
                    </select>
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <v-btn
                      v-if="isBulkUpdateShipmentWay"
                      type="button"
                      icon
                      class="bg-gradient-primary shadow"
                      @click="bulkUpdateShipmentWay()"
                    >
                      <i class="fas fa-save" />
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
              <v-container>
                <v-row align-content="center">
                  <v-col cols="auto" class="mr-auto">
                    <v-switch
                      v-model="isBulkUpdateRemarks"
                      :label="`備考一括変更モード: ${
                        isBulkUpdateRemarks ? 'ON' : 'OFF'
                      }`"
                    />
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <v-btn
                      v-if="isBulkUpdateRemarks"
                      color="secondary"
                      @click="changeStatusRemarksTarget(false)"
                      >全選択解除</v-btn
                    >
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <v-btn
                      v-if="isBulkUpdateRemarks"
                      color="secondary"
                      @click="changeStatusRemarksTarget(true)"
                      >全選択</v-btn
                    >
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <label v-if="isBulkUpdateRemarks" for="bulcProductName2"
                      >備考</label
                    >
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <input
                      v-if="isBulkUpdateRemarks"
                      v-model="bulkRemarks"
                      name="bulkRemarks"
                      id="bulkRemarks"
                      class="form-control"
                    />
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <v-btn
                      v-if="isBulkUpdateRemarks"
                      type="button"
                      icon
                      class="bg-gradient-primary shadow"
                      @click="bulkUpdateRemarks"
                    >
                      <i class="fas fa-save" />
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <shipping-instruction-editable
          :csrf="csrf"
          :token="token"
          :headers="pHeaders"
          :is-bulk-update-requirement-code="isBulkUpdateRequirementCode"
          :is-bulk-update-product-code="isBulkUpdateProductCode"
          :is-bulk-update-shipment-way="isBulkUpdateShipmentWay"
          :is-bulk-update-user-id="isBulkUpdateUserId"
          :is-bulk-update-product-name2="isBulkUpdateProductName2"
          :is-bulk-update-remarks="isBulkUpdateRemarks"
          :items="pItems"
          :shipping-instruction-status="shippingInstructionStatus"
          ref="shippingInstructionEditable"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Axios from "axios";
import Draggable from "vuedraggable";
import ShippingInstructionEditable from "./shipping-instruction-editable.vue";

export default {
  components: {
    Draggable,
    ShippingInstructionEditable,
  },
  filters: {
    addComma: (val) => {
      return val.toLocaleString();
    },
  },
  props: {
    csrf: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    pHeaders: {
      type: Array,
      required: true,
    },
    pItems: {
      type: Array,
      required: true,
    },
    shippingInstructionStatus: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      beforeRequirementCode: "",
      beforeProductCode: "",
      beforeShipmentWay: "",
      beforeUserId: "",
      beforeProductName2: "",
      draggableList: [],
      editingIdForRequirementCode: 0,
      editingIdForProductCode: 0,
      editingIdForShipmentWay: 0,
      editingIdForUserId: 0,
      editingIdForProductName2: 0,
      isEditingRequirmentCode: false,
      isEditingProductCode: false,
      isEditingShipmentWay: false,
      isEditingUserId: false,
      isEditingProductName2: false,
      bulkRequirementCodeItems: [],
      isBulkUpdateRequirementCodeValue: false,
      bulkRequirementCode: 1,
      isBulkUpdateRequirementCode: false,
      bulkProductCodeItems: [],
      isBulkUpdateProductCodeValue: false,
      bulkProductCode: "001",
      isBulkUpdateProductCode: false,
      bulkShipmentWayItems: [],
      isBulkUpdateShipmentWayValue: false,
      bulkShipmentWay: 1,
      isBulkUpdateShipmentWay: false,
      bulkUserIdItems: [],
      isBulkUpdateUserIdValue: false,
      bulkUserId: "",
      isBulkUpdateUserId: false,
      bulkProductName2Items: [],
      isBulkUpdateProductName2Value: false,
      bulkProductName2: "",
      isBulkUpdateProductName2: false,
      bulkRemarksItems: [],
      isBulkUpdateRemarksValue: false,
      bulkRemarks: "",
      isBulkUpdateRemarks: false,
      connecting: false,
    };
  },
  watch: {
    isBulkUpdateRequirementCode: function (newIsBulkUpdateRequirementCode, _) {
      this.$refs.shippingInstructionEditable.updateIsBulkUpdateRequirementCode(
        newIsBulkUpdateRequirementCode
      );
    },
    isBulkUpdateProductCode: function (newIsBulkUpdateProductCode, _) {
      this.$refs.shippingInstructionEditable.updateIsBulkUpdateProductCode(
        newIsBulkUpdateProductCode
      );
    },
    isBulkUpdateShipmentWay: function (newIsBulkUpdateShipmentWay, _) {
      this.$refs.shippingInstructionEditable.updateIsBulkUpdateShipmentWay(
        newIsBulkUpdateShipmentWay
      );
    },
    isBulkUpdateUserId: function (newIsBulkUpdateUserId, _) {
      this.$refs.shippingInstructionEditable.updateIsBulkUpdateUserId(
        newIsBulkUpdateUserId
      );
    },
    isBulkUpdateProductName2: function (newIsBulkUpdateProductName2, _) {
      this.$refs.shippingInstructionEditable.updateIsBulkUpdateProductName2(
        newIsBulkUpdateProductName2
      );
    },
    isBulkUpdateRemarks: function (newIsBulkUpdateRemarks, _) {
      this.$refs.shippingInstructionEditable.updateIsBulkUpdateRemarks(
        newIsBulkUpdateRemarks
      );
    },
  },
  created() {
    this.draggableList = this.items;
  },
  methods: {
    /**
     *
     * @param {*} _evt
     */
    onDragged(_evt) {
      this.draggableList.forEach((element, idx) => {
        Axios.post(
          `/api/v1/shipping_instruction/update`,
          {
            id: element.id,
            order: idx,
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        ).catch((error) => {
          console.log(error);
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
        });
      });
    },
    /**
     *
     */
    bulkUpdateRequirementCode() {
      this.$refs.shippingInstructionEditable.bulkUpdateRequirementCode(
        this.bulkRequirementCode
      );
    },
    /**
     *
     */
    bulkUpdateProductCode() {
      this.$refs.shippingInstructionEditable.bulkUpdateProductCode(
        this.bulkProductCode
      );
    },
    /**
     *
     */
    bulkUpdateShipmentWay() {
      this.$refs.shippingInstructionEditable.bulkUpdateShipmentWay(
        this.bulkShipmentWay
      );
    },
    /**
     *
     */
    bulkUpdateUserId() {
      this.$refs.shippingInstructionEditable.bulkUpdateUserId(this.bulkUserId);
    },
    /**
     *
     */
    bulkUpdateProductName2() {
      this.$refs.shippingInstructionEditable.bulkUpdateProductName2(
        this.bulkProductName2
      );
    },
    /**
     *
     */
    bulkUpdateRemarks() {
      this.$refs.shippingInstructionEditable.bulkUpdateRemarks(
        this.bulkRemarks
      );
    },
    /**
     *
     * @param {Bool} status
     */
    changeStatusRequirementCodeTarget(status) {
      this.$refs.shippingInstructionEditable.changeStatusRequirementCodeTarget(
        status
      );
    },
    /**
     *
     * @param {Bool} status
     */
    changeStatusProductCodeTarget(status) {
      this.$refs.shippingInstructionEditable.changeStatusProductCodeTarget(
        status
      );
    },
    /**
     *
     * @param {Bool} status
     */
    changeStatusShipmentWayTarget(status) {
      this.$refs.shippingInstructionEditable.changeStatusShipmentWayTarget(
        status
      );
    },
    /**
     *
     * @param {Bool} status
     */
    changeStatusUserIdTarget(status) {
      this.$refs.shippingInstructionEditable.changeStatusUserIdTarget(status);
    },
    /**
     *
     * @param {Bool} status
     */
    changeStatusProductName2Target(status) {
      this.$refs.shippingInstructionEditable.changeStatusProductName2Target(
        status
      );
    },
    /**
     *
     * @param {Bool} status
     */
    changeStatusRemarksTarget(status) {
      this.$refs.shippingInstructionEditable.changeStatusRemarksTarget(status);
    },
  },
};
</script>