<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="700">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on">
          コピーして新規作成
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          どれをコピーしますか？
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <form
            @submit="onSubmit"
            :action="'/estimate/copyEstimate/' + this.estimateId"
            method="get"
          >
            <input type="hidden" name="_token" :value="csrf" />
            <v-btn type="submit">基本情報のみ</v-btn>
          </form>
          <form
            @submit="onSubmit"
            :action="'/estimate/copyProduct/' + this.estimateId"
            method="get"
          >
            <input type="hidden" name="_token" :value="csrf" />
            <v-btn type="submit">商品情報のみ</v-btn>
          </form>
          <form
            @submit="onSubmit"
            :action="'/estimate/copyEstimateProduct/' + this.estimateId"
            method="post"
          >
            <input type="hidden" name="_token" :value="csrf" />
            <v-btn type="submit">基本情報+商品情報</v-btn>
          </form>
          <form
            @submit="onSubmit"
            :action="'/estimate/copyEstimateOrder/' + this.estimateId"
            method="post"
          >
            <input type="hidden" name="_token" :value="csrf" />
            <v-btn v-if="status == 6 || status == 7" type="submit"
              >基本情報+商品情報+受注情報</v-btn
            >
          </form>
        </v-card-actions>
        <v-card-text>
          <strong
            ><span style="color: red"
              >販売店とユーザーのいずれかが変更となる場合はコピー禁止！<br />コピーは仕切・差額・IDなどがそのまま引き継がれるため新規作成でお願いします。</span
            ></strong
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    csrf: {
      type: String,
      required: true,
    },
    estimateId: {
      type: Number,
      default: null,
    },
    status: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    /**
     *
     * @param {Event} event
     */
    onSubmit(event) {},
  },
};
</script>