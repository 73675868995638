<template>
  <v-card class="mx-auto stock-head-wrapper" outlined>
    <!-- 各数値を表示 -->
    <h3 class="stock-head">
      <v-row>
        <v-col v-if="displayStockCnt" cols="4" class="py-1 text-h6">拠点在庫数</v-col><v-col v-if="displayStockCnt" cols="2" class="py-1 text-h6">：{{ stockCnt }}&nbsp;<small>台</small></v-col>
        <v-col v-if="displayStockCntActual" cols="4" class="py-1 text-h6">棚卸数</v-col><v-col v-if="displayStockCntActual" cols="2" class="py-1 text-h6">：{{ stockCntActual }}&nbsp;<small>台</small></v-col>
        <v-col v-if="displayStockCntAtMaker" cols="4" class="py-1 text-h6">預かり在庫数</v-col><v-col v-if="displayStockCntAtMaker" cols="2" class="py-1 text-h6">：{{ stockCntAtMaker }}&nbsp;<small>台</small></v-col>
        <v-col v-if="displayStockCntActual" cols="6" class="py-1 text-body-1">※「拠点在庫」数＋「出荷予定」数</v-col>
        <v-col v-if="displayStockCntReserve" cols="4" class="py-1 text-h6">予約可能数</v-col><v-col v-if="displayStockCntReserve" cols="8" class="py-1 text-h6">：{{ stockCntReserve }}&nbsp;<small>台</small></v-col>
        <v-col v-if="!displayStockCntWaitingDelivery" cols="12" class="py-1 text-subtitle-1">※引き当て済みは上記カウント数から除外</v-col>
        <v-col v-if="displayStockCntWaitingDelivery" cols="4" class="py-1 text-h6">入荷待ち</v-col><v-col v-if="displayStockCntWaitingDelivery" cols="8" class="py-1 text-h6">：{{ stockCntWaitingDelivery }}&nbsp;<small>件</small></v-col>
        <v-col cols="12"><span v-if="isThresholdAlert" class="alert">残在庫が僅かです。</span></v-col>
      </v-row>
    </h3>
    <!-- アラートメッセージ -->
    <v-simple-table class="stock-head-table">
      <thead class="blue lighten-4">
        <tr>
          <th>商品コード</th>
          <th>名称</th>
          <th>定価</th>
          <th>仕切</th>
          <th>仕切価格</th>
          <th>仕入原価</th>
          <th>初回納入日</th>
          <th style="width: 200px;">型番</th>
          <th v-if="displayDeliveryTime">平均納期</th>
          <th>備考</th>
        </tr>
      </thead>
      <tbody>
        <!-- stockHeadTableInfoから各行を動的に表示 -->
        <tr v-for="(item, index) in stockHeadTableInfo" :key="index">
          <td>{{ item.product_codes }}</td>
          <td>{{ item.name }}</td>
          <td>
            {{ formatPrice(item.regular_price) }}<br>
            <span style="font-size: 0.8em; color: grey;">
              粗利: {{ formatPrice(calculateProfitRegular(item.regular_price, item.cost_price)) }}
            </span>
          </td>
          <td>{{ calculatePartition(item.regular_price, item.partition_price) }}%</td>
          <td>
            {{ formatPrice(item.partition_price) }}<br>
            <span style="font-size: 0.8em; color: grey;">
              粗利: {{ formatPrice(calculateProfitPartition(item.partition_price, item.cost_price)) }}
            </span>
          </td>
          <td>{{ formatPrice(item.cost_price) }}</td>
          <td>{{ item.first_receive_date }}</td>
          <td>{{ item.code }}</td>
          <td v-if="displayDeliveryTime">{{ item.average_delivery_time }}日</td>
          <td>{{ item.memo }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-btn depressed color="primary" :href="`/stock/master/`+segmentId">マスタ編集</v-btn>
  </v-card>
</template>

<script>
export default {
  name: 'StockHeadTable',
  props: {
    stockCnt: Number,
    stockCntActual: Number,
    stockCntAtMaker: Number,
    stockCntReserve: Number,
    stockCntWaitingDelivery: Number,
    displayStockCnt: Boolean,
    displayStockCntActual: Boolean,
    displayStockCntAtMaker: Boolean,
    displayStockCntReserve: Boolean,
    displayStockCntWaitingDelivery: Boolean,
    stockHeadTableInfo: Array,
    deliveryTime: String,
    isThresholdAlert: Boolean,
    segmentId: {
      type: Number,
      required: true,
    },
    displayDeliveryTime: Boolean,
  },
  methods: {
    formatPrice(value) {
      return value!==null ? `¥${value.toLocaleString()}` : '';
    },
    calculateProfitRegular(regularPrice, costPrice) {
      return regularPrice - costPrice;
    },
    calculateProfitPartition(partitionPrice, costPrice) {
      return partitionPrice - costPrice;
    },
    calculatePartition(regularPrice, partitionPrice) {
      return (typeof regularPrice === 'number' && regularPrice > 0) ?  ((partitionPrice / regularPrice) * 100).toFixed(2) : ' - ';
    }
  }
}
</script>

<style scoped>
.v-card {
  border: none;
}
.stock-head{
  width: 1200px;
}
.stock-head-wrapper{
  width: 1200px;
}
.stock-head{
  width: 1200px;
}
.stock-head-table{
  width: 1200px;
  thead{
    font-weight: bold;
  }
  th{
    color: white;
  }
}
.alert {
  color: red;
  font-size: 20px;
  font-weight: bold;
  padding: .2em 1em;
  border: 0;
  border-radius: 0;
  background: linear-gradient(transparent 60%, #ff000045 60%);
}
</style>
