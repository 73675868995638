<template>
  <v-card class="mx-auto stock-head-wrapper" outlined>
    <!-- 在庫数と実在庫数を表示 -->
    <h3 class="stock-head">
      <v-row>
        <v-col cols="4">本社在庫数</v-col><v-col cols="2">：{{ stockCnt }}&nbsp;<small>台</small></v-col>
        <v-col cols="4">預かり在庫数</v-col><v-col cols="2">：{{ stockCntAtMaker }}&nbsp;<small>台</small></v-col>
        <v-col cols="4">実在庫数</v-col><v-col cols="2">：{{ stockCntActual }}&nbsp;<small>台</small></v-col>
        <v-col cols="4">予約可能数</v-col><v-col cols="2">：{{ stockCntReserve }}&nbsp;<small>台</small></v-col>
        <v-col cols="12"><span v-if="isThresholdAlert" class="alert">本社在庫が僅かです。</span></v-col>
      </v-row>
    </h3>
    <!-- アラートメッセージ -->
    <v-simple-table class="stock-head-table">
      <thead class="blue lighten-4">
        <tr>
          <th>商品コード</th>
          <th>定価</th>
          <th>仕切</th>
          <th>仕切価格</th>
          <th>仕入原価</th>
          <th>初回納入日</th>
          <th style="width: 200px;">型番</th>
          <th>備考</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ productCodes }}</td>
          <td>
            {{ formatPrice(regularPrice) }}<br>
            <span style="font-size: 0.8em; color: grey;">粗利: {{ formatPrice(calculateProfitRegular(regularPrice, costPrice)) }}</span>
          </td>
          <td>{{ calculatePartition(regularPrice, partitionPrice) }}%</td>
          <td>
            {{ formatPrice(partitionPrice) }}<br>
            <span style="font-size: 0.8em; color: grey;">粗利: {{ formatPrice(calculateProfitPartition(partitionPrice, costPrice)) }}</span>
          </td>
          <td>{{ formatPrice(costPrice) }}</td>
          <td>{{ firstReceiveDate }}</td>
          <td>{{ code }}</td>
          <td>{{ memo }}</td>
          <td rowspan="2"><v-btn depressed color="primary" :href="`/stock/master/`+segmentId">マスタ編集</v-btn></td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  name: 'StockHeadTable',
  props: {
    stockCnt: Number,
    stockCntActual: Number,
    stockCntAtMaker: Number,
    stockCntReserve: Number,
    productCodes: String,
    regularPrice: Number,
    partitionPrice: Number,
    costPrice: Number,
    firstReceiveDate: String,
    code: String,
    memo: String,
    isThresholdAlert: Boolean,
    segmentId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    formatPrice(value) {
      return value!==null ? `¥${value.toLocaleString()}` : '';
    },
    calculateProfitRegular(regularPrice, costPrice) {
      return regularPrice - costPrice;
    },
    calculateProfitPartition(partitionPrice, costPrice) {
      return partitionPrice - costPrice;
    },
    calculatePartition(regularPrice, partitionPrice) {
      return (typeof regularPrice === 'number' && regularPrice > 0) ?  ((partitionPrice / regularPrice) * 100) : ' - ';
    }
  }
}
</script>

<style scoped>
.v-card {
  border: none;
}
.stock-head{
  width: 1200px;
}
.stock-head-wrapper{
  width: 1200px;
}
.stock-head{
  width: 1200px;
}
.stock-head-table{
  width: 1200px;
  thead{
    font-weight: bold;
  }
  th{
    color: white;
  }
}
.alert {
  color: red;
  font-size: 20px;
  font-weight: bold;
  padding: .2em 1em;
  border: 0;
  border-radius: 0;
  background: linear-gradient(transparent 60%, #ff000045 60%);
}
</style>
