/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

require('./bootstrap');

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

require('./components/vue-chart');

import vuetify from './components/vuetify';
import Vuex from 'vuex';
import vuexI18n from 'vuex-i18n';
import Locales from './vue-i18n-locales.generated';
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

Vue.use(Vuex);

const store = new Vuex.Store();
Vue.use(vuexI18n.plugin, store);

Vue.use(VuejsDialog,{
    reverse: true,
    okText: 'はい',
    cancelText: 'キャンセル',
});

Vue.i18n.add('ja', Locales.ja);
Vue.i18n.add('en', Locales.en);

Vue.i18n.set('ja');

import vSelect from "vue-select";
Vue.component("VSelect", vSelect);

import SelectList from './components/select-list.vue';
Vue.component('SelectList', SelectList);

import SearchableTable from './components/searchable-table.vue';
Vue.component('SearchableTable', SearchableTable);

import ApiTable from './components/api-table.vue';
Vue.component('ApiTable', ApiTable);

import AdditionalFileList from './components/additional-file-list.vue';
Vue.component('AdditionalFileList', AdditionalFileList);

import CrudTable from './components/crud-table.vue';
Vue.component('CrudTable', CrudTable);

import CrudTableKentemid from './components/crud-table-kentemid.vue';
Vue.component('CrudTableKentemid', CrudTableKentemid);

import CrudTableOscProductName from './components/crud-table-osc-product-name.vue';
Vue.component('CrudTableOscProductName', CrudTableOscProductName);

import CrudTableOrderConfirmationStaff from './components/crud-table-order-confirmation-staff.vue';
Vue.component('CrudTableOrderConfirmationStaff', CrudTableOrderConfirmationStaff);

import CrudTableTeamsDepartmentWebhook from './components/crud-table-teams-department-webhook';
Vue.component('CrudTableTeamsDepartmentWebhook', CrudTableTeamsDepartmentWebhook);

import ProductEditable from './components/product-editable.vue';
Vue.component('ProductEditable', ProductEditable);

import ShippingInstructionEditable from './components/shipping-instruction-editable.vue';
Vue.component('ShippingInstructionEditable', ShippingInstructionEditable);

import ShippingInstrcutionList from './components/shipping-instrcution-list.vue';
Vue.component('ShippingInstrcutionList', ShippingInstrcutionList);

import ShippingInstrcutionDetail from './components/shipping-instrcution-detail.vue';
Vue.component('ShippingInstrcutionDetail', ShippingInstrcutionDetail);

import HapilogiGroupTable from './components/hapilogi-group-table.vue';
Vue.component('HapilogiGroupTable', HapilogiGroupTable);

import HapilogiCsv from './components/hapilogi-csv.vue';
Vue.component('HapilogiCsv', HapilogiCsv);

import YamatoInvoiceCsv from './components/yamato-invoice-csv.vue';
Vue.component('YamatoInvoiceCsv', YamatoInvoiceCsv);

import NittuInvoiceCsv from './components/nittu-invoice-csv.vue';
Vue.component('NittuInvoiceCsv', NittuInvoiceCsv);

import InvoiceCsv from './components/invoice-csv.vue';
Vue.component('InvoiceCsv', InvoiceCsv);

import RefInput from './components/ref-input.vue';
Vue.component('RefInput', RefInput);

import RefMultiselectPrice from './components/ref-multiselect-price.vue';
Vue.component('RefMultiselectPrice', RefMultiselectPrice);

import RefMultiselectProduct from './components/ref-multiselect-product.vue';
Vue.component('RefMultiselectProduct', RefMultiselectProduct);

import RefMultiselectCloud from './components/ref-multiselect-cloud.vue';
Vue.component('RefMultiselectCloud', RefMultiselectCloud);

import RefMultiselectKaisokuNavi from './components/ref-multiselect-kaisoku-navi.vue';
Vue.component('RefMultiselectKaisokuNavi', RefMultiselectKaisokuNavi);

import RefInputCaution from './components/ref-input-caution.vue';
Vue.component('RefInputCaution', RefInputCaution);

import RefEditCaution from './components/ref-edit-caution.vue';
Vue.component('RefEditCaution', RefEditCaution);

import RefProductListSeriesCode from './components/ref-product-list-seriescode.vue';
Vue.component('RefProductListSeriescode', RefProductListSeriesCode);

import RefProductList from './components/ref-product-list.vue';
Vue.component('RefProductList', RefProductList);

import RefProductDekisList from './components/ref-product-dekis-list.vue';
Vue.component('RefProductDekisList', RefProductDekisList);

import RefProductCloudList from './components/ref-product-cloud-list.vue';
Vue.component('RefProductCloudList', RefProductCloudList);

import RefProductKentemtechList from './components/ref-product-kentemtech-list.vue';
Vue.component('RefProductKentemtechList', RefProductKentemtechList);

import RefProductKentemAcademyList from './components/ref-product-kentem-academy-list.vue';
Vue.component('RefProductKentemAcademyList', RefProductKentemAcademyList);

import RefCustomersetCustomercard from './components/ref-customerset-customercard.vue';
Vue.component('RefCustomersetCustomercard', RefCustomersetCustomercard);

import RefCustomersetNonUser from './components/ref-customerset-non-user.vue';
Vue.component('RefCustomersetNonUser', RefCustomersetNonUser);

import RefCustomerset from './components/ref-customerset.vue';
Vue.component('RefCustomerset', RefCustomerset);

import RefCustomersetOnlyCustomer from './components/ref-customerset-only-customer.vue';
Vue.component('RefCustomersetOnlyCustomer', RefCustomersetOnlyCustomer);

import KentemDetail from './components/kentem-detail.vue';
Vue.component('KentemDetail', KentemDetail);

import DekisDetail from './components/dekis-detail.vue';
Vue.component('DekisDetail', DekisDetail);

import InnositeDetail from './components/innosite-detail.vue';
Vue.component('InnositeDetail', InnositeDetail);

import ConstructionDetail from './components/construction-detail.vue';
Vue.component('ConstructionDetail', ConstructionDetail);

import CloudDetail from './components/cloud-detail.vue';
Vue.component('CloudDetail', CloudDetail);

import DekiskuDetail from './components/dekisku-detail.vue';
Vue.component('DekiskuDetail', DekiskuDetail);

import OtherDetail from './components/other-detail.vue';
Vue.component('OtherDetail', OtherDetail);

import ProdouguDetail from './components/prodougu-detail.vue';
Vue.component('ProdouguDetail', ProdouguDetail);

import ProdouguOwnershipStatus from './components/prodougu-ownership-status.vue';
Vue.component('ProdouguOwnershipStatus', ProdouguOwnershipStatus);

import ProductDetail from './components/product-detail.vue';
Vue.component('ProductDetail', ProductDetail);

import RefBillingset from './components/ref-billingset.vue';
Vue.component('RefBillingset', RefBillingset);

import RefShippingset from './components/ref-shippingset.vue';
Vue.component('RefShippingset', RefShippingset);

import RefShippmentdateSet from './components/ref-shippmentdate-set.vue';
Vue.component('RefShippmentdateSet', RefShippmentdateSet);

import RefInputContactShippingTeamRemarks from './components/ref-input-contact-shipping-team-remarks.vue';
Vue.component('RefInputContactShippingTeamRemarks', RefInputContactShippingTeamRemarks);

import DekisOwnershipStatus from './components/dekis-ownership-status.vue';
Vue.component('DekisOwnershipStatus', DekisOwnershipStatus);

import ConstructionOwnershipStatus from './components/construction-ownership-status.vue';
Vue.component('ConstructionOwnershipStatus', ConstructionOwnershipStatus);

import DekiskuOwnershipStatus from './components/dekisku-ownership-status.vue';
Vue.component('DekiskuOwnershipStatus', DekiskuOwnershipStatus);

import CloudOwnershipStatus from './components/cloud-ownership-status.vue';
Vue.component('CloudOwnershipStatus', CloudOwnershipStatus);

import InnositeOwnershipStatus from './components/innosite-ownership-status.vue';
Vue.component('InnositeOwnershipStatus', InnositeOwnershipStatus);

import SystemIndex from './components/system-index.vue';
Vue.component('SystemIndex', SystemIndex);

import OsoOpportunityUpsert from './components/osc-opportunity-upsert.vue';
Vue.component('OscOpportunityUpsert', OsoOpportunityUpsert);

import OsoOpportunityDelete from './components/osc-opportunity-delete.vue';
Vue.component('OscOpportunityDelete', OsoOpportunityDelete);

import OscOpportunityButtons from './components/osc-opportunity-buttons.vue';
Vue.component('OscOpportunityButtons', OscOpportunityButtons);

import ShippingInstructionConfirm from './components/shipping-instruction-confirm.vue';
Vue.component('ShippingInstructionConfirm', ShippingInstructionConfirm);

import CopyConfirm from './components/copy-confirm.vue';
Vue.component('CopyConfirm', CopyConfirm);

import EstimateNonUserOrderConfirm from './components/estimate-non-user-order-confirm.vue';
Vue.component('EstimateNonUserOrderConfirm', EstimateNonUserOrderConfirm);

import RefInputMemo from './components/ref-input-memo.vue';
Vue.component('RefInputMemo', RefInputMemo);

import RefInputRemarks from './components/ref-input-remarks.vue';
Vue.component('RefInputRemarks', RefInputRemarks);

import RefInputShipmentRemarks from './components/ref-input-shipment-remarks.vue';
Vue.component('RefInputShipmentRemarks', RefInputShipmentRemarks);

import KentemProductOscInformation from './components/kentem-product-osc-information.vue';
Vue.component('KentemProductOscInformation', KentemProductOscInformation);

import ProgressOrderPdfs from './components/progress-order-pdfs.vue';
Vue.component('ProgressOrderPdfs', ProgressOrderPdfs);

import ProgressDeliverySlipPdfs from './components/progress-delivery-slip-pdfs.vue';
Vue.component('ProgressDeliverySlipPdfs', ProgressDeliverySlipPdfs);

import ExternalLinkButtons from './components/external-link-buttons.vue';
Vue.component('ExternalLinkButtons', ExternalLinkButtons);

import InputEstimateConfirmation from './components/input-estimate-confirmation.vue';
Vue.component('InputEstimateConfirmation', InputEstimateConfirmation);

import InputEstimateEstimateConfirmation from './components/input-estimate-estimate-confirmation.vue';
Vue.component('InputEstimateEstimateConfirmation', InputEstimateEstimateConfirmation);

import DeliveryExclusionList from './components/delivery-exclusion-list.vue';
Vue.component('DeliveryExclusionList', DeliveryExclusionList);

import EstimateOrderSearchForm from './components/estimate-order-search-form.vue';
Vue.component('EstimateOrderSearchForm', EstimateOrderSearchForm);

import ShippingInstructionSearchForm from './components/shipping-instruction-search-form.vue';
Vue.component('ShippingInstructionSearchForm', ShippingInstructionSearchForm);

import ShippingInstructionSearch from './components/shipping-instruction-search.vue';
Vue.component('ShippingInstructionSearch', ShippingInstructionSearch);

import FaqList from './components/faq-list.vue';
Vue.component('FaqList', FaqList);

import FaqItem from './components/faq-item.vue';
Vue.component('FaqItem', FaqItem);

import UploadHardwarePartsPdf from './components/upload-hardware-parts-pdf.vue';
Vue.component('UploadHardwarePartsPdf', UploadHardwarePartsPdf);

import VerticalOrderInfoTable from './components/vertical-order-info-table.vue';
Vue.component('VerticalOrderInfoTable', VerticalOrderInfoTable);

import EstimateProduct from './components/estimate-product.vue';
import Vue from 'vue';
Vue.component('EstimateProduct', EstimateProduct)

import estimateSearchFormToggleBtn from './components/estimate-search-form-toggle-btn.vue';
Vue.component('estimateSearchFormToggleBtn', estimateSearchFormToggleBtn);

import EstimateOrderForm from './components/estimate-order-form.vue';
Vue.component('EstimateOrderForm', EstimateOrderForm);

import stockHeadTable from './components/stock-head-table.vue';
Vue.component('stockHeadTable', stockHeadTable);

import stockMdlTable from './components/stock-mdl-table.vue';
Vue.component('stockMdlTable', stockMdlTable);

import stockRentalMdlTable from './components/stock-rental-mdl-table.vue';
Vue.component('stockRentalMdlTable', stockRentalMdlTable);

import stockRentalHeadTable from './components/stock-rental-head-table.vue';
Vue.component('stockRentalHeadTable', stockRentalHeadTable);

import stockRentalPeriod from './components/stock-rental-period.vue';
Vue.component('stockRentalPeriod', stockRentalPeriod);

import stockRentalDoublecheck from './components/stock-rental-doublecheck.vue';
Vue.component('stockRentalDoublecheck', stockRentalDoublecheck);

import stockHardAddform from './components/stock-hard-addform.vue';
Vue.component('stockHardAddform', stockHardAddform);

import stockModelEditableTable from './components/stock-model-editable-table.vue';
Vue.component('stockModelEditableTable', stockModelEditableTable);

import stockSegmentEditableTable from './components/stock-segment-editable-table.vue';
Vue.component('stockSegmentEditableTable', stockSegmentEditableTable);

import stockSegmentTabs from './components/stock-segment-tabs.vue';
Vue.component('stockSegmentTabs', stockSegmentTabs);

import refShippingInstruction from './components/ref-shipping-instruction.vue';
Vue.component('refShippingInstruction', refShippingInstruction);

import datepickerField from './components/datepicker-field.vue';
Vue.component('datepickerField', datepickerField);

import stockHardDoublecheck from './components/stock-hard-doublecheck.vue';
Vue.component('stockHardDoublecheck', stockHardDoublecheck);

import refStockShippingAllocation from './components/ref-stock-shipping-allocation.vue';
Vue.component('refStockShippingAllocation', refStockShippingAllocation);

import refStockRentalShippingAllocation from './components/ref-stock-rental-shipping-allocation.vue';
Vue.component('refStockRentalShippingAllocation', refStockRentalShippingAllocation);

new Vue({
    store,
    el: '#app',
    vuetify,
    data: () => ({
        dialog: false,
    }),
}).$mount('#app')
