<template>
  <div>
    <div class="form-row my-4">
      <!-- 出荷指示情報セレクトボックス -->
      <ref-shipping-instruction
        :shipping-label="shippingLabel"
        :shipping-headers="shippingHeaders"
        :shipping-items="shippingItems"
        :selected="selected"
        @shipmentdate-change="shipmentdateChange"
      ></ref-shipping-instruction>
    </div>
    <div class="form-row my-4">
      <ref-customerset-only-customer
        ref="RefCustomersetOnlyCustomer"
        :kentem-id-url="kentemIdUrl"
        :customer-url="customerUrl"
        :dealer-url="dealerUrl"
        :kentem-id-default="kentemIdDefault" >
      </ref-customerset-only-customer>
    </div>
    <stock-rental-period
        :mode="mode"
        :plan="plan"
        :plan-list-for-select="planListForSelect"
        :start-date.sync="localStartDate"
        :end-date="endDate"
        :shipment-date.sync="localShipmentDate"
        :period-day="periodDay"
        :period-month="periodMonth"
        :period-day-ex="periodDayEx"
        :period-month-ex="periodMonthEx"
        :token="token"
        :start-date-label="startDateLabel"
        :end-date-label="endDateLabel"
        :shipment-date-label="shipmentDateLabel"
        :segment-id="segmentId"
    ></stock-rental-period>
  </div>
</template>
<script>
import axios from 'axios';
import refShippingInstruction from "./ref-shipping-instruction.vue";
import RefCustomersetOnlyCustomer from "./ref-customerset-only-customer.vue";
import stockRentalPeriod from "./stock-rental-period.vue";
export default {
  components: {
    refShippingInstruction,
    RefCustomersetOnlyCustomer,
    stockRentalPeriod,
  },
  props: {
    shippingLabel: {
      type: String,
      default: "出荷指示情報",
    },
    shippingItems: {
      type: Array,
      default: () => [],
    },
    shippingHeaders: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Number,
    },
    kentemIdUrl: {
      type: String,
      default: "https://id.kentem.com",
    },
    customerUrl: {
      type: String,
      default: "https://id.kentem.com" + "/api/v1/kentem_customer/data_table",
    },
    dealerUrl: {
      type: String,
      default: "https://id.kentem.com" + "/api/v1/kentem_sales/data_table",
    },
    kentemIdDefault: {
      type: String,
      default: "",
    },
    orderDate: {
      type: String,
      default: "",
    },
    shipmentDate: {
      type: String,
      default: "",
    },
    receiveDate: {
      type: String,
      default: "",
    },
    apiToken: {
      type: String,
      default: "",
    },
    displayOrderDate: {
      type: Boolean,
      default: true,
    },
    arrayShippingInstructionIdToStatusAndEstimateId: {
      type: Object,
      default: [],
    },
    isLinkShippingInstruction: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      required: true,
    },
    plan: {
      type: Object,
      default: () => ({ value: 0, label: '未選択' }),
    },
    planListForSelect: {
      type: Array,
      required: true,
    },
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
    shipmentDate: {
      type: String,
      default: "",
    },
    periodDay: {
      type: Number,
      default: 0,
    },
    periodMonth: {
      type: Number,
      default: 0,
    },
    periodDayEx: {
      type: Number,
      default: 0,
    },
    periodMonthEx: {
      type: Number,
      default: 0,
    },
    token: {
      type: String,
      default: "",
    },
    startDateLabel: {
      type: String,
      default: "",
    },
    endDateLabel: {
      type: String,
      default: "",
    },
    shipmentDateLabel: {
      type: String,
      default: "",
    },
    segmentId: {
      type: Number,
      required: true,
    },
  },
  watch: {
  },
  data() {
    return {
      localShipmentDate: this.shipmentDate,
      localStartDate: this.startDate,
      newCustomer: [],
      newShipmentDate: ''
    };
  },
  methods: {
    shipmentdateChange(shipment_id) {
      const estimate_id = this.arrayShippingInstructionIdToStatusAndEstimateId[shipment_id].estimate_id;
      if (!this.isLinkShippingInstruction) {
        this.getShipmentDateByEstimateId(estimate_id);
        this.getCustomerByEstimateId(estimate_id);
      }
    },
    getCustomerByEstimateId(estimate_id) {
      axios.get("/api/v1/stocks/getcustomer/"+ estimate_id, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + this.apiToken
        }
      })
      .then(response => {
        this.newCustomer = response.data;
        const item = {};
        item['kentem_id'] = this.newCustomer.customer_kentem_id;
        item['name'] = this.newCustomer.name;
        this.$refs.RefCustomersetOnlyCustomer.selectCustomerItem(item);
      })
      .catch(error => {
        alert(error.response.data.message);
      });
    },
    getShipmentDateByEstimateId(estimate_id) {
      axios.get("/api/v1/stocks/shipmentdate/"+ estimate_id, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + this.apiToken
        }
      })
      .then(response => {
        this.newShipmentDate = response.data;
        if(this.segmentId!==10){
          this.localShipmentDate = this.newShipmentDate;
          this.localStartDate = this.newShipmentDate;
        }
      })
      .catch(error => {
        alert(error.response.data.message);
      });
    },
  },
};
</script>