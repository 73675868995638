<template>
  <div class="form-group">
    <label class="col-form-label">
      <span>商品カテゴリー</span>
      <span v-if="cloudrequired" class="badge badge-primary">{{
        $t("required")
      }}</span>
      <span v-else class="badge badge-secondary">{{ $t("optional") }}</span>
    </label>
    <multiselect
      v-model="selectedClouds"
      placeholder="選択してください"
      label="name"
      track-by="id"
      :multiple="true"
      :options="clouds"
      :taggable="true"
      :close-on-select="true"
    />

    <label class="col-form-label">
      <span>{{ label }}</span>
      <span v-if="required" class="badge badge-primary">{{
        $t("required")
      }}</span>
      <span v-else class="badge badge-secondary">{{ $t("optional") }}</span>
    </label>
    <product-dialog
      :delete-item-by-id="deleteItemById"
      :headers="headers"
      :id-name="idName"
      :items="items_condition"
      :loading="loading"
      :select-item="selectItem"
      :selected-product-id-list="selectedProductIdList"
      :title="title"
    />
    <product-table
      :delete-item="deleteItem"
      :product-name-list="selectedProductNameList"
      :product-id-list="selectedProductIdList"
    />
  </div>
</template>

<script>
import Axios from "axios";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

import ProductDialog from "./refproductlist/product-dialog.vue";
import ProductTable from "./refproductlist/product-table.vue";
import SelectItem from "./refproductlist/select-item.js";
import Token from "./token";

export default {
  components: {
    Multiselect,
    ProductDialog,
    ProductTable,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    idName: {
      type: String,
      default: "id",
    },
    defaultLabelName: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    kaisokurequired: {
      type: Boolean,
      default: false,
    },
    cloudrequired: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: String,
      default: "",
    },
    labelKey: {
      type: String,
      default: "",
    },
    useAutoClose: {
      type: Boolean,
      default: true,
    },
    ricohUrl: {
      type: String,
      required: true,
    },
    salesSegment: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      bearer: "",
      formValue: this.defaultValue,
      labelName: this.defaultLabelName,
      labelValue: "",
      headers: [],
      items: [],
      items_condition: [],
      selectedCloudIds: [],
      selectedKaisokuIds: [],
      selectedClouds: [],
      selectedKaisokus: [],
      loading: true,
      placeholder: "",
      selected: "",
      tokenUrl: "/api/v1/token",
      selectedProductIdList: [],
      selectedProductNameList: [],
      value: [],
      clouds: [
        {
          name: "KSデータバンク",
          id: "8",
        },
        {
          name: "SiteBox",
          id: "9",
        },
        {
          name: "SiteBoxトンネル",
          id: "11",
        },
        {
          name: "SiteBox配筋検査",
          id: "14",
        },
        {
          name: "写管屋クラウド",
          id: "13",
        },
        {
          name: "出来形管理クラウド",
          id: "19",
        },
        {
          name: "品質管理クラウド",
          id: "27",
        },
        {
          name: "施工体制クラウド",
          id: "30",
        },
        {
          name: "フィールドネット",
          id: "10",
        },
        {
          name: "快測Scan",
          id: "22",
        },
        {
          name: "KENTEM-CONNECT",
          id: "18",
        },
        {
          name: "日報管理クラウド",
          id: "28",
        },
        {
          name: "遠隔臨場 SiteLive",
          id: "15",
        },
        {
          name: "快測AR",
          id: "31",
        },
        {
          name: "快測ナビ",
          id: "12",
        },
        {
          name: "工事実績DBクラウド",
          id: "29",
        },
        {
          name: "ハード",
          id: "16",
        },
        {
          name: "Xacti",
          id: "33",
        },
      ],
      kaisokus: [
        {
          name: "快測ナビSTD",
          id: "12",
        },
        {
          name: "快測ナビADV",
          id: "12",
        },
      ],
    };
  },
  watch: {
    selectedClouds(newValues) {
      this.selectedCloudIds = newValues.map((obj) => obj.id);
      this.items_condition = this.items;
      this.selectedCloudIds.forEach((element) => {
        this.items_condition = this.items.filter(
          (item) => item.type_number == element
        );
      });
      this.loading = false;
    },
    selectedKaisokus(newValues) {
      this.selectedKaisokuIds = newValues.map((obj) => obj.id);
      this.items_condition = this.items;
      this.selectedKaisokuIds.forEach((element) => {
        this.items_condition = this.items.filter(
          (item) => item.type_number == element
        );
      });
      this.loading = false;
    },
  },
  created: function () {
    if (this.labelName === "") {
      this.labelName = this.idName;
    }
    this.labelName = this.defaultLabelName ?? this.idName;
    this.getTokenAndLoadList();
  },

  methods: {
    /**
     *
     */
    async loadList() {
      this.loading = true;
      if (this.formValue !== "" && this.labelKey !== "") {
        this.labelValue = this.$i18n.translate("loading");
      }
      if (this.salesSegment == 2) {
        await Axios.get(this.ricohUrl, {
          headers: {
            Authorization: this.bearer,
          },
        })
          .then((res) => {
            if (res.data) {
              this.headers = res.data.headers;
              this.items = res.data.items;
              this.items_condition = res.data.items;
            }
          })
          .catch((error) => {
            console.log(error);
            this.labelValue = this.$i18n.translate("unknown");
          });
      } else {
        await Axios.get(this.url, {
          headers: {
            Authorization: this.bearer,
          },
        })
          .then((res) => {
            if (res.data) {
              this.headers = res.data.headers;
              this.items = res.data.items;
              this.items_condition = res.data.items;
            }
          })
          .catch((error) => {
            console.log(error);
            this.labelValue = this.$i18n.translate("unknown");
          });
      }

      if (this.formValue !== "" && this.labelKey !== "") {
        const defaultItem = this.items.find(
          (row) => row[this.idName] === this.formValue
        );
        this.labelValue =
          typeof defaultItem !== "undefined"
            ? defaultItem[this.labelKey]
            : this.$i18n.translate("unknown");
      }
      this.loading = false;
    },
    /**
     *
     * @param {Object} item
     */
    selectItem(item) {
      SelectItem(this, item, this.useAutoClose);
    },
    /**
     *
     * @param {Number} index
     */
    deleteItem(index) {
      this.selectedProductIdList.splice(index, 1);
      this.selectedProductNameList.splice(index, 1);
    },
    /**
     *
     */
    deleteItemAll() {
      this.selectedProductIdList = [];
      this.selectedProductNameList = [];
    },
    /**
     *
     * @param {Number} id
     */
    deleteItemById(id) {
      const index = this.selectedProductIdList.indexOf(id);
      this.deleteItem(index);
    },
    /**
     *
     */
    async getTokenAndLoadList() {
      this.bearer = await Token(this.tokenUrl);
      this.loadList();
    },
  },
};
</script>
