<template>
  <div class="form-group">
    <label class="col-form-label">
      <span>{{ label }}</span>
      <span
        v-if="required"
        class="badge badge-primary"
      >{{
        $t("required")
      }}</span>
      <span
        v-else
        class="badge badge-secondary"
      >{{ $t("optional") }}</span>
    </label>
    <v-textarea
      :value="cautionFormValue"
      name="caution_point"
      auto-grow
      counter
      no-resize
      clearable
      outlined
      placeholder="文字を入力"
      rows="3"
      maxlength="255"
    >
    </v-textarea> 
  </div>
</template>

<script>
import Axios from "axios";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    idName: {
      type: String,
      default: "id",
    },
    defaultLabelName: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: String,
      default: "",
    },
    labelKey: {
      type: String,
      default: "",
    },
    defaultCaution: {
      type: Array,
      default: () => [],
    },
    estimateId: {
      type: String,
      default: "estimate_id",
    },
    cautionPoint: {
      type: String,
      default: "caution_point",
    },
  },
  data() {
    return {
      dialog: false,
      formValue: this.defaultValue,
      labelName: this.defaultLabelName,
      labelValue: "",
      headers: [],
      items: [],
      loading: true,
      loadingLabel: this.$i18n.translate("loading"),
      placeholder: "",
      search: "",
      searchLabel: this.$i18n.translate("search"),
      caution_input: [""],
      cautionFormValue:this.cautionPoint
    }
  },
  created: function () {
    if (this.labelName === "") {
      this.labelName = this.idName;
    }
    this.labelName = this.defaultLabelName ?? this.idName;
    if (this.defaultCaution !== []) {
      for (let i = 0; i < this.defaultCaution.length; i++) {
        this.caution_input.push(this.defaultCaution[i].caution_point);
      }
    }
    this.loadList();
  },
  methods: {
    /**
     * 
     */
    async loadList() {
      this.loading = true;
      if (this.formValue !== "" && this.labelKey !== "") {
        this.labelValue = this.$i18n.translate("loading");
      }
      await Axios.get(this.url)
        .then((res) => {
          if (res.data) {
            this.headers = res.data.headers;
            this.items = res.data.items;
          }
        })
        .catch((error) => {
          console.log(error);
          this.labelValue = this.$i18n.translate("unknown");
        });

      if (this.formValue !== "" && this.labelKey !== "") {
        const defaultItem = this.items.find(
          (row) => row[this.idName] === this.formValue
        );
        this.labelValue =
          typeof defaultItem !== "undefined"
            ? defaultItem[this.labelKey]
            : this.$i18n.translate("unknown");
      }
      this.loading = false;
    },
  },
};
</script>