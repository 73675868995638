<template>
  <v-card
    class="mx-auto"
    elevation="2"
    outlined
    color="light-blue lighten-5"
  >
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          {{ searchLabel }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-text-field
            append-icon="fa-search"
            :label="addressLabel"
            single-line
            hide-details
            @input="debounceAddressInput"
          />
          <v-text-field
            append-icon="fa-search"
            :label="companyNameLabel"
            single-line
            hide-details
            @input="debounceNameInput"
          />
          <v-text-field
            append-icon="fa-search"
            :label="companyNameKanaLabel"
            single-line
            hide-details
            @input="debounceKanaInput"
          />
          <v-text-field
            append-icon="fa-search"
            :label="kentemIdLabel"
            single-line
            hide-details
            @input="debounceKentemIdInput"
          />
          <v-text-field
            append-icon="fa-search"
            :label="shokonCodeLabel"
            single-line
            hide-details
            @input="debounceShokonCodeInput"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
      :headers="customerHeaders"
      :items="customerItems"
      :items-per-page="10"
      class="elevation-1"
      :search="customerSearch"
      :custom-filter="filterSearch"
      :loading="customerLoading"
      :loading-text="loadingLabel"
      multi-sort
    >
      <template #item="{ item }">
        <tr @click="callback(item)">
          <td
            v-for="header in customerHeaders"
            :key="header.value"
          >
            {{ item[header.value] }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import _ from "lodash";
import {
    filterSearch,
    makeSearchAddress,
    makeSearchKana,
    makeSearchKentemId,
    makeSearchName,
    makeSearchShokonCode
} from "./kentem-id-table-functions";

export default {
  props: {
    callback: {
      type: Function,
      require: true,
      default: () => 1,
    },
    customerHeaders: {
      type: Array,
      default: () => [],
    },
    customerItems: {
      type: Array,
      default: () => [],
    },
    customerLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      customerSearch: JSON.stringify({
        name: "",
        kana: "",
        address: "",
        kentem_id: "",
        shokon_code: "",
      }),
      loadingLabel: this.$i18n.translate("loading"),
      companyNameLabel: this.$i18n.translate("company name"),
      companyNameKanaLabel:
        this.$i18n.translate("company_name_kana") +
        " [全角カナ]",
      addressLabel: this.$i18n.translate("address"),
      kentemIdLabel: this.$i18n.translate("kentem_id"),
      shokonCodeLabel: this.$i18n.translate("shokon_code"),
      searchLabel: this.$i18n.translate("search"),
    };
  },
  methods: {
    debounceNameInput: _.debounce(function (e) {
      this.customerSearch = makeSearchName(this.customerSearch, e);
    }, 500),
    debounceKanaInput: _.debounce(function (e) {
      this.customerSearch = makeSearchKana(this.customerSearch, e);
    }, 500),
    debounceAddressInput: _.debounce(function (e) {
      this.customerSearch = makeSearchAddress(this.customerSearch, e);
    }, 500),
    debounceKentemIdInput: _.debounce(function (e) {
      this.customerSearch = makeSearchKentemId(this.customerSearch, e);
    }, 500),
    debounceShokonCodeInput: _.debounce(function (e) {
      this.customerSearch = makeSearchShokonCode(this.customerSearch, e);
    }, 500),
  },
  computed: {
    filterSearch,
  }
};
</script>
