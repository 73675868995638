<template>
  <div class="form-group">
    <label class="col-form-label">
      <span>{{ label }}</span>
      <span
        v-if="required"
        class="badge badge-primary"
      >{{
        $t("required")
      }}</span>
      <span
        v-else
        class="badge badge-secondary"
      >{{ $t("optional") }}</span>
    </label>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="1000px"
      style="z-index: 1050"
    >
      <template #activator="{ on }">
        <v-btn
          color="primary"
          dark
          v-on="on"
        >
          {{ $t("add") }}
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
          color="light-blue darken-4"
          dark
        >
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon>
            <v-icon @click="dialog = false">
              fas fa-window-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card
                  class="mx-auto"
                  elevation="2"
                  outlined
                  color="light-blue lighten-5"
                >
                  <v-text-field
                    v-model="search"
                    append-icon="fa-search"
                    :label="searchLabel"
                    single-line
                    hide-details
                  />
                  <v-data-table
                    :headers="headers"
                    :items="items"
                    :items-per-page="10"
                    class="elevation-1"
                    :search="search"
                    :loading="loading"
                    :loading-text="loadingLabel"
                    multi-sort
                  >
                    <template #item="{ item }">
                      <tr @click="selectItem(item)">
                        <td
                          v-for="header in headers"
                          :key="header.value"
                        >
                          {{ item[header.value] }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            {{
              $t("close")
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <tr
      v-for="(caution, index) in caution_input"
      :key="index"
    >
      <td width="900">
        <v-textarea
          :value="caution"
          name="caution_point[]"
          auto-grow
          counter
          no-resize
          clearable
          outlined
          placeholder="こちらに備考を記入してください。追加する場合は追加ボタンからになります。"
          rows="3"
          maxlength="255"
        >
        </v-textarea> 
      </td>
      <td>
        <v-btn
          color="primary"
          @click="deleteItem(index)"
        >
          {{
            $t("delete")
          }}
        </v-btn>
      </td>
    </tr>
  </div>
</template>

<script>
import Axios from "axios";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    idName: {
      type: String,
      default: "id",
    },
    defaultLabelName: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: String,
      default: "",
    },
    labelKey: {
      type: String,
      default: "",
    },
    defaultCaution: {
      type: Array,
      default: () => [],
    },
    estimateIdName: {
      type: String,
      default: "estimate_id",
    },
  },
  data() {
    return {
      dialog: false,
      formValue: this.defaultValue,
      labelName: this.defaultLabelName,
      labelValue: "",
      headers: [],
      items: [],
      loading: true,
      loadingLabel: this.$i18n.translate("loading"),
      placeholder: "",
      search: "",
      searchLabel: this.$i18n.translate("search"),
      caution_input: [""],
      cautionFormValue:"",
    }
  },
  created: function () {
    if (this.labelName === "") {
      this.labelName = this.idName;
    }
    this.labelName = this.defaultLabelName ?? this.idName;
    if (this.defaultCaution !== []) {
      for (let i = 0; i < this.defaultCaution.length; i++) {
        this.caution_input.push(this.defaultCaution[i].caution_point);
      }
    }
    this.loadList();
  },

  methods: {
    /**
     * 
     */
    async loadList() {
      this.loading = true;
      if (this.formValue !== "" && this.labelKey !== "") {
        this.labelValue = this.$i18n.translate("loading");
      }
      await Axios.get(this.url)
        .then((res) => {
          if (res.data) {
            this.headers = res.data.headers;
            this.items = res.data.items;
          }
        })
        .catch((error) => {
          console.log(error);
          this.labelValue = this.$i18n.translate("unknown");
        });

      if (this.formValue !== "" && this.labelKey !== "") {
        const defaultItem = this.items.find(
          (row) => row[this.idName] === this.formValue
        );
        this.labelValue =
          typeof defaultItem !== "undefined"
            ? defaultItem[this.labelKey]
            : this.$i18n.translate("unknown");
      }
      this.loading = false;
    },
    /**
     * 
     * @param {Object} item 
     */
    selectItem(item) {
      this.formValue = item[this.idName];
      this.labelValue = item[this.labelName];
      this.caution_input.push(this.formValue);
      this.dialog = false;
    },
    /**
     * 
     * @param {Number} index 
     */
    deleteItem(index) {
      this.caution_input.splice(index, 1);
    },
  },
};
</script>