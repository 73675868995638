<template>
  <div class="inventory-container">
    <div class="add-inventory-btn" @click="toggleForm">
      <v-btn icon color="white">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <span>在庫の追加</span>
    </div>

    <v-expand-transition>
      <div v-if="formVisible" class="inventory-form">
        <v-form>
          <v-row>
            <!-- 在庫数 -->
            <v-col cols="4">
              <v-text-field
                v-model="stockCount"
                label="在庫数"
                type="number"
                suffix="個"
                :error-messages="errorMessages.stockCount"
                :rules="[v => (v && v > 0) || '1以上の数値を入力してください']"
                min="1"
              ></v-text-field>
            </v-col>

            <!-- ステータス -->
            <v-col cols="4">
              <label class="grey--text" style="position: absolute;top: 12px;left: 10px;font-size: 12px;">ステータス</label>
              <v-select
                v-model="selectedStatus"
                :options="statusListForSelect"
                required
                class="stock-select"
              ></v-select>
              <span v-if="errorMessages.selectedStatus" class="error-message">{{ errorMessages.selectedStatus }}</span>
            </v-col>

            <!-- 納品日 -->
            <v-col cols="4">
              <datepicker-field
                :value.sync="deliveryDate"
                label="納品日"
                clearable
                :hide-details="auto"
              ></datepicker-field>
            </v-col>
          </v-row>

          <!-- モデル名 -->
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="モデル名称"
                :value="currentModel"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- 登録ボタン -->
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn color="pink" @click="submitForm">登録</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  props: {
    segmentId: {
      type: Number,
      required: true,
    },
    statusListForSelect: {
      type: Array,
      required: true
    },
    statusListForGetName: {
      type: Object,
      required: true,
    },
    currentModelCode: {
      type: String,
      required: true
    },
    currentModelName: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentModel: this.currentModelName + "（" + this.currentModelCode + "）",
      formVisible: false,
      stockCount: null,
      selectedStatus: null,
      deliveryDate: null,
      errorMessages: {
        stockCount: null,
        selectedStatus: null,
        deliveryDate: null,
      }
    };
  },
  methods: {
    toggleForm() {
      this.formVisible = !this.formVisible;
    },
    validateForm() {
      let isValid = true;
      this.errorMessages.stockCount = this.stockCount ? null : '在庫数は必須です';
      this.errorMessages.selectedStatus = this.selectedStatus ? null : 'ステータスを選択してください';
      // this.errorMessages.deliveryDate = this.deliveryDate ? null : '納品日は必須です';

      if (!this.stockCount || !this.selectedStatus) {
        isValid = false;
      }
      return isValid;
    },
    submitForm() {
      if (this.validateForm()) {
        const payload = {
          segment_id: this.segmentId,
          stock_count: this.stockCount,
          status: this.selectedStatus.value,
          delivery_date: this.deliveryDate,
          model_id: 1
        };
        axios.post('/api/v1/stocks/bulk-insert', payload, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + this.token
          }
        })
        .then(response => {
          console.log('Success:', response.data);
          alert("一括登録が成功しました。");
          window.location.reload();
        })
        .catch(error => {
          console.error('Error:', error);
        });
      }
    }
  },
};
</script>

<style scoped>
.inventory-container {
  position: relative;
  padding: 20px;
  max-width: 600px;
  text-align: right;
}
.add-inventory-btn {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 19px;
  padding: 0 20px 0 0;
  border: 1px solid;
  cursor: pointer;
  background: #3498db;
  color: #fff;
}
.add-inventory-btn span {
  margin-left: 8px;
}
.inventory-form {
  padding: 20px;
  border: 1px solid #3498db;
  border-radius: 8px;
}
.v-btn {
  margin-right: 8px;
}
.text-right {
  text-align: right;
}
.v-btn.pink {
  background-color: #f1948a;
  color: white;
}
.stock-select {
  padding-top: 12px;
  margin-top: 4px;
  .vs__dropdown-toggle {
    border: none; /* 枠線を消す */
    color: rgb(0 0 0 / .42);
    border-radius: 0;
    border-bottom: 1px solid; /* 枠線を消す */
  }
}
.error-message {
  color: red;
  font-size: 12px;
}
</style>