<template>
  <div class="form-group">
    <label class="col-form-label">
      <span>ID</span>
      <span
        v-if="required"
        class="badge badge-primary"
      >{{
        $t("required")
      }}</span>
      <span
        v-else
        class="badge badge-secondary"
      >{{ $t("optional") }}</span>
    </label>
    <select
      v-model="selectInnositeID"
      name="ID"
      class="form-control"
      required
      @change="getDekisIdByInnositeId(selectInnositeID)"
    >
      <option
        value
        selected
      >
        選択してください
      </option>
      <option
        v-for="r in innositeId"
        :key="r"
        :value="r"
      >
        {{ r }}
      </option>
    </select>
    <v-card>
      <innosite-ownership-status
        :kentem-id="kentemId"
        :kentem-id-url="kentemIdUrl"
        :reference="ReferenceValue"
        :title="selectInnositeID"
      />
    </v-card>
  </div>
</template>

<script>
import Axios from "axios";
import Token from "./token";

export default {
  props: {
    kentemId: {
      type: String,
      required: true,
    },
    innositeId: {
      type: Array,
      required: true,
    },
    idName: {
      type: String,
      default: "id",
    },
    defaultLabelName: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: true,
    },
    defaultValue: {
      type: String,
      default: "",
    },
    labelKey: {
      type: String,
      default: "",
    },
    kentemIdUrl: {
      type: String,
      default: "https://id.kentem.com",
    },
    customerDefaultValue: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "https://id.kentem.com/api/v1/kentem/detail/",
    },
  },
  data() {
    return {
      dialog: false,
      contents: "",
      dekisInfo: "",
      customerDetail: false,
      formValue: this.defaultValue,
      labelName: this.defaultLabelName,
      labelValue: "",
      ReferenceValue: null,
      DekisValue: "",
      headers: [],
      items: [],
      loading: false,
      loadingLabel: this.$i18n.translate("loading"),
      placeholder: "",
      search: "",
      searchLabel: this.$i18n.translate("search"),
      selected: "",
      selectLabel: this.$i18n.translate("select"),
      selectInnositeID: "",
      customerFormValue: this.customerDefaultValue,
      tokenUrl: "/api/v1/token",
      bearer: "",
    };
  },

  methods: {
    /**
     * 
     */
    async loadList() {
      console.log(this.kentemId);
      await Axios.get(this.url + this.kentemId, {
        onDownloadProgress: this.onDownload,
        headers: {
          Authorization: this.bearer,
        },
      })
        .then((res) => {
          if (res.data) {
            this.contents = res.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.downloading = false;
      this.progress = 100;
    },
    /**
     * 
     * @param {String} id 
     */
    async getDekisIdByInnositeId(id) {
      console.log(id);
      if (id === "") {
        this.ReferenceValue = null;
        return;
      }
      this.loading = true;
      this.bearer = await Token(this.tokenUrl);
      Axios.get(this.kentemIdUrl + `/api/v1/innosite/reference/${id}`, {
        headers: {
          Authorization: this.bearer,
        },
      })
        .then((res) => {
          this.loading = false;
          this.labelValue = "";
          if (res.data) {
            this.ReferenceValue = res.data;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.labelValue = this.$i18n.translate("unknown");
        });
    },
  },
};
</script>