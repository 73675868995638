<template>
  <v-card class="mx-auto stock-table-wrap" outlined>

    <!-- 検索フォーム開閉ボタン -->
    <v-btn
      @click="isFormVisible = !isFormVisible"
      class="my-2 mr-2"
    >
      {{ isFormVisible ? '検索フォームを閉じる' : '検索フォームを開く' }}
    </v-btn>
    <!-- 検索フォーム -->
    <v-form v-if="isFormVisible" @submit.prevent="onSearch">
      <h2>検索</h2>
      <v-row>
        <v-col cols="2">
          <v-text-field
            v-model="searchId"
            label="ID"
            clearable
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <label class="grey--text" style="position: absolute;">ステータス</label>
          <v-select
            v-model="searchStatus"
            :options="statusListForSelect"
            clearable
            class="stock-select"
            multiple
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-text-field
            v-model="searchKentemId"
            label="KENTEM ID"
            clearable
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="searchShokonCode"
            label="商魂コード"
            clearable
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="searchCompany"
            label="会社名"
            clearable
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <datepicker-field
            v-if="this.segmentId===9"
            :value.sync="searchShipStart"
            label="出荷日 指定日以降"
            :clearable="true"
            hide-details="auto"
          ></datepicker-field>
        </v-col>
        <v-col cols="2">
          <datepicker-field
            v-if="this.segmentId===9"
            :value.sync="searchShipEnd"
            label="出荷日 指定日以前"
            :clearable="true"
            hide-details="auto"
          ></datepicker-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <datepicker-field
            :value.sync="searchStartDateStart"
            label="開始日 指定日以降"
            :clearable="true"
            hide-details="auto"
          ></datepicker-field>
        </v-col>
        <v-col cols="2">
          <datepicker-field
            :value.sync="searchStartDateEnd"
            label="開始日 指定日以前"
            :clearable="true"
            hide-details="auto"
          ></datepicker-field>
        </v-col>
        <v-col cols="2">
          <datepicker-field
            :value.sync="searchEndDateStart"
            label="終了予定日 指定日以降"
            :clearable="true"
            hide-details="auto"
          ></datepicker-field>
        </v-col>
        <v-col cols="2">
          <datepicker-field
            :value.sync="searchEndDateEnd"
            label="終了予定日 指定日以前"
            :clearable="true"
            hide-details="auto"
          ></datepicker-field>
        </v-col>
      </v-row>
    </v-form>

    <!-- データテーブル -->
    <v-btn
      color="primary"
      elevation="1"
      :href="addPageUrl"
      class="my-2"
    >在庫の追加</v-btn>
    <v-data-table
      v-model="selectedItems"
      :headers="headers"
      :items="filteredItems"
      :items-per-page.sync="itemsPerPage"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :page.sync="page"
      class="stock-table mt-5"
    >
      <!-- 件数表示 -->
      <template #top>
        <div class="d-flex justify-end align-center">
          <span>{{ paginationText }}</span>
        </div>
      </template>

      <template #[`item.id`]="{ item }">
        <a :href="getEditUrl(baseurl, item)">{{ item.id }}</a>
      </template>
      <template #[`item.status`]="{ item }">
        <span :class="getStatusColorClass(item.status)">{{ getStatusName(item.status) }}</span>
      </template>
      <template #[`item.is_present_campaign`]="{ item }">
        <v-checkbox disabled v-model="item.is_present_campaign"></v-checkbox>
      </template>
      <template #[`item.delete`]="{ item }">
        <form
          :action="getDeleteUrl(baseurl, item)"
          method="post"
        >
          <input
            type="hidden"
            name="_token"
            :value="getCsrfToken()"
          >
          <input
            type="hidden"
            name="_method"
            value="DELETE"
          >
          <v-btn
            v-confirm="deleteConfirmationLabel"
            type="submit"
            icon
            class="bg-gradient-danger shadow"
          >
            <i class="far fa-trash-alt" />
          </v-btn>
        </form>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  props: {
    canEdit: {
      type: Boolean,
      required: true,
    },
    segmentId: {
      type: Number,
      required: true,
    },
    baseurl: {
      type: String,
      required: true,
    },
    canCreate: {
      type: Boolean,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    idName: {
      type: String,
      default: "id",
    },
    statusListForSelect: {
      type: Array,
      required: true,
    },
    statusListForGetName: {
      type: Object,
      required: true,
    },
    planListForGetName: {
      type: Array,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isFormVisible: false,
      deleteConfirmationLabel: this.$i18n.translate("delete ok?"),
      searchId: "",
      searchStatus: "",
      searchCompany: "",
      searchKentemId: "",
      searchShokonCode: "",
      searchDate: "",
      searchStartDateStart: "",
      searchStartDateEnd: "",
      searchEndDateStart: "",
      searchEndDateEnd: "",
      searchShipStart: "",
      searchShipEnd: "",
      sortBy: "id",
      sortDesc: true,
      bulkUpdateField: "", // 選択された一括変更項目
      bulkOptions: ["ステータス", "納品日"], // 一括変更で選択できる項目
      bulkStatus: null,
      bulkReceiveDate: "",
      selectedItems: [],
      addPageUrl: '/stock/rental/'+this.segmentId+'/new',
      page: 1, // ページ番号
      itemsPerPage: 50, // 1ページあたりの項目数
    };
  },
  computed: {
    filteredItems() {
      return this.items.filter(item => {
        const matchesId = this.searchId ? item.id.toString().includes(this.searchId) : true;
        const matchesStatus = this.searchStatus && this.searchStatus.length > 0 
          ? this.searchStatus.some(status => status.value === item.status) 
          : true;
        const matchesCompany = this.searchCompany ? item.company_name?.includes(this.searchCompany) : true;
        const matchesKentemId = this.searchKentemId ? item.kentem_id?.includes(this.searchKentemId) : true;
        const matchesShokonCode = this.searchShokonCode ? item.shokon_code?.includes(this.searchShokonCode) : true;
        const matchesDate = this.searchDate ? item.receive_date?.includes(this.searchDate) : true;
        
        const matchesStartDateStart = this.searchStartDateStart
          ? (item.start_date !== null && item.start_date !== '' && new Date(item.start_date) >= new Date(this.searchStartDateStart))
          : true;
        const matchesStartDateEnd = this.searchStartDateEnd
          ? (item.start_date !== null && item.start_date !== '' && new Date(item.start_date) <= new Date(this.searchStartDateEnd))
          : true;
        const matchesEndDateStart = this.searchEndDateStart
          ? (item.end_date !== null && item.end_date !== '' && new Date(item.end_date) >= new Date(this.searchEndDateStart))
          : true;
        const matchesEndDateEnd = this.searchEndDateEnd
          ? (item.end_date !== null && item.end_date !== '' && new Date(item.end_date) <= new Date(this.searchEndDateEnd))
          : true;
        const matchesShipStart = this.searchShipStart
          ? (item.shipment_date !== null && item.shipment_date !== '' && new Date(item.shipment_date) >= new Date(this.searchShipStart))
          : true;
        const matchesShipEnd = this.searchShipEnd
          ? (item.shipment_date !== null && item.shipment_date !== '' && new Date(item.shipment_date) <= new Date(this.searchShipEnd))
          : true;

        return matchesId && matchesStatus && matchesCompany && matchesKentemId && matchesShokonCode && matchesDate &&
          matchesStartDateStart && matchesStartDateEnd && matchesEndDateStart && matchesEndDateEnd &&
          matchesShipStart && matchesShipEnd;
      });
    },
    paginationText() {
      const total = this.filteredItems.length;
      const start = (this.page - 1) * this.itemsPerPage + 1;
      const end = this.itemsPerPage !== -1 ? Math.min(this.page * this.itemsPerPage, total) : total;
      return `${total} 件中 ${start} - ${end} 件`;
    }
  },
  methods: {
    getStatusColorClass(status) {
      const statusColorMap = new Map([
        [0, "orange--text font-weight-bold"],
        [1, "orange--text text--darken-2 font-weight-bold"],
        [2, "light-green--text text--accent-4 font-weight-bold"],
        [3, "pink--text text--lighten-1 font-weight-bold"],
        [4, "grey--text font-weight-bold"],
        [5, "pink--text text--lighten-1 font-weight-bold"],
        [6, "orange--text text--darken-2 font-weight-bold"],
        [7, "pink--text text--lighten-1 font-weight-bold"],
        [8, "red--text font-weight-bold"],
        [9, "grey--text font-weight-bold"],
      ]);
      return statusColorMap.get(status) || "";
    },
    getStatusName(status) {
      return this.statusListForGetName[status];
    },
    getPlanName(plan) {
      return this.planListForGetName[plan];
    },
    isDeletableItem(item) {
      return item.delete;
    },
    getCsrfToken() {
      return document.getElementsByName("csrf-token")[0].content;
    },
    getEditUrl(baseurl, item) {
      return "/" + baseurl + "/rental/edit/" + item[this.idName];
    },
    getDeleteUrl(baseurl, item) {
      return "/" + baseurl + "/" + item[this.idName];
    },
    getRowColorClass(item) {
      if (!item.end_date || item.status !== 4) {
        return '';
      }
      const today = new Date();
      const endDate = new Date(item.end_date);
      const oneMonthFromNow = new Date();
      oneMonthFromNow.setMonth(today.getMonth() + 1);
      if (endDate < today) {
        return 'row-red';
      } else if (endDate < oneMonthFromNow) {
        return 'row-yellow';
      } else {
        return '';
      }
    },
	},
};
</script>
<style scoped>
.v-card {
  border: none;
}
h2{
  font-size: 14px;
  font-weight: bold;
  margin-top: 50px;
}
td:has([v-slot~="delete"]) {
  border: none;
  visibility: hidden;
}
.stock-select{
    padding-top: 12px;
    margin-top: 4px;
    .vs__dropdown-toggle {
      border: none; /* 枠線を消す */
      color: rgb(0 0 0 / .42);
      border-radius: 0;
      border-bottom: 1px solid; /* 枠線を消す */
    }
}
.stock-table-wrap{
  width: 1500px;
}
.v-data-table {
  white-space : nowrap;
}
.stock-table{
  white-space : nowrap;
  thead{
    background: #BBDEFB;
    th{
      font-weight: bold;
    }
  }
}
.row-red {
  background-color: #ffebee;
}
.row-yellow {
  background-color: #fff9c4;
}
.stock-table >>> th:last-child {
    position: sticky;
    background: #BBDEFB;
    border-left: 1px;
    right: 0;
}
.stock-table >>> tr td:last-child {
    position: sticky;
    background: #fff;
    border-left: 1px;
    right: 0;
}
</style>