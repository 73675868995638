<!-- DatePickerコンポーネント -->
<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="internalValue"
        :label="label"
        :name="name"
        :clearable="clearable"
        :dense="dense"
        :disabled="disabled"
        :readonly="readonly"
        :hide-details="hideDetails"
        :outlined="outlined"
        :error-messages="errorMessages"
        :required="required"
        v-bind="attrs"
        append-icon="mdi-calendar"
        @input="onInput"
        @click:clear="onClear"
        @click:append="menu = true"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="internalValue"
      :allowed-dates="allowedDates"
      @input="onInput"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    value: {
      type: [String, null],
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: [Boolean, String],
      default: false,
    },
    required: {
      type: Boolean,
      default: false
    },
    kentemHolidays: {
      type: Boolean,
      default: false,
    },
    token: {
      type: String,
    },
  },
  data() {
    return {
      menu: false,
      internalValue: this.value,
      holidays: [],
      errorMessages: null,
    };
  },
  watch: {
    value(newValue) {
      // 外部からのvalue変更を監視してinternalValueを更新
      this.internalValue = newValue;
    },
  },
  methods: {
    async fetchHolidays() {
      if (this.kentemHolidays && this.token) {
        try {
          const response = await axios.get('/api/v1/kentem/holidays', {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + this.token,
            },
          });
          this.holidays = response.data
            .filter(item => item.is_non_business_day)
            .map(item => {
              return item.date.slice(0, 4) + '-' + item.date.slice(4, 6) + '-' + item.date.slice(6, 8);
            });
        } catch (error) {
          console.error('休日取得エラー:', error);
        }
      }
    },
    onInput(value) {
      this.internalValue = value;
      this.$emit('update:value', value);
      this.menu = false;
    },
    onClear() {
      this.internalValue = null;
      this.$emit('update:value', null);
    },
    allowedDates(date) {
      return !this.holidays.includes(date);
    },
    validate() {
      if (this.required && !this.internalValue) {
        this.errorMessages = 'このフィールドは必須です';
      } else {
        this.errorMessages = null;
      }
    }
  },
  created() {
    this.fetchHolidays();
  },
};
</script>