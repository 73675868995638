<template>
  <v-card class="mx-auto" elevation="2" outlined color="light-blue lighten-5">
    <v-card-title>
      <h2>{{ title }}</h2>
      <v-spacer />
    </v-card-title>
    <p v-if="canCreate">
      <a :href="getCreateUrl(baseurl)">
        {{ $t("new") }}
        <i class="fas fa-user-plus" />
      </a>
    </p>
    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
          {{ searchLabel }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-text-field
            append-icon="fa-search"
            :label="addressLabel"
            single-line
            hide-details
            @input="inputAddress"
          />
          <v-text-field
            append-icon="fa-search"
            :label="companyNameLabel"
            single-line
            hide-details
            @input="inputName"
          />
          <v-text-field
            append-icon="fa-search"
            :label="companyNameKanaLabel"
            single-line
            hide-details
            @input="inputKana"
          />
          <v-text-field
            append-icon="fa-search"
            :label="kentemIdLabel"
            single-line
            hide-details
            @input="inputKentemId"
          />
          <v-text-field
            append-icon="fa-search"
            :label="shokonCodeLabel"
            single-line
            hide-details
            @input="inputShokonCode"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1"
      :search="search"
      :custom-filter="filterSearch"
      multi-sort
    >
      <template #[`item.detail`]="{ item }">
        <v-icon
          v-if="isViewableItem(item)"
          small
          class="mr-2"
          @click="detailItem(baseurl, item)"
        >
          fa-search-plus
        </v-icon>
      </template>
      <template #[`item.edit`]="{ item }">
        <v-icon
          v-if="isEditableItem(item)"
          small
          @click="editItem(baseurl, item)"
        >
          fa-user-edit
        </v-icon>
      </template>
      <template #[`item.delete`]="{ item }">
        <form :action="getDeleteUrl(baseurl, item)" method="post">
          <input type="hidden" name="_token" :value="getCsrfToken()" />
          <input type="hidden" name="_method" value="DELETE" />
          <v-btn
            v-confirm="deleteConfirmationLabel"
            type="submit"
            icon
            class="bg-gradient-danger shadow"
          >
            <i class="fas fa-user-slash" />
          </v-btn>
        </form>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {
    filterSearch,
    makeSearchAddress,
    makeSearchKana,
    makeSearchKentemId,
    makeSearchName,
    makeSearchShokonCode
} from "./kentem-id-table-functions";

export default {
  props: {
    baseurl: {
      type: String,
      required: true,
    },
    canCreate: {
      type: Boolean,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    idName: {
      type: String,
      default: "id",
    },
  },
  data() {
    return {
      deleteConfirmationLabel: this.$i18n.translate("delete ok?"),
      search: JSON.stringify({
        name: "",
        kana: "",
        address: "",
        kentem_id: "",
        shokon_code: "",
      }),
      companyNameLabel: this.$i18n.translate("company name"),
      companyNameKanaLabel:
        this.$i18n.translate("company_name_kana") +
        " [全角カナで検索可]",
      addressLabel: this.$i18n.translate("address"),
      kentemIdLabel: this.$i18n.translate("kentem_id"),
      shokonCodeLabel: this.$i18n.translate("shokon_code"),
      searchLabel: this.$i18n.translate("search"),
      panel: [0],
    };
  },

  methods: {
    /**
     *
     * @param {String} baseurl
     * @param {Object} item
     */
    detailItem(baseurl, item) {
      location.href = "/" + baseurl + "/" + item[this.idName];
    },
    /**
     *
     * @param {String} baseurl
     * @param {Object} item
     */
    editItem(baseurl, item) {
      location.href = "/" + baseurl + "/" + item[this.idName] + "/edit";
    },
    /**
     *
     * @param {Object} item
     */
    isViewableItem(item) {
      return item.view;
    },
    /**
     *
     * @param {Object} item
     */
    isEditableItem(item) {
      return item.edit;
    },
    /**
     *
     * @param {Object} item
     */
    isDeletableItem(item) {
      return item.delete;
    },
    /**
     *
     */
    getCsrfToken() {
      return document.getElementsByName("csrf-token")[0].content;
    },
    /**
     *
     * @param {String} baseurl
     */
    getCreateUrl(baseurl) {
      return "/" + baseurl + "/create";
    },
    /**
     *
     * @param {String} baseurl
     * @param {Object} item
     */
    getDeleteUrl(baseurl, item) {
      return "/" + baseurl + "/" + item[this.idName];
    },
    /**
     *
     * @param {String} e
     */
    inputName: function (e) {
        this.search = makeSearchName(this.search, e);
    },
    /**
     *
     * @param {String} e
     */
    inputKana: function (e) {
      this.search = makeSearchKana(this.search, e);
    },
    /**
     *
     * @param {String} e
     */
    inputAddress: function (e) {
      this.search = makeSearchAddress(this.search, e);
    },
    /**
     *
     * @param {String} e
     */
    inputKentemId: function (e) {
      this.search = makeSearchKentemId(this.search, e);
    },
    /**
     *
     * @param {String} e
     */
    inputShokonCode: function (e) {
      this.search = makeSearchShokonCode(this.search, e);
    },
  },
  computed: {
    filterSearch,
  }
};
</script>
