import Axios from "axios";

export default function (url) {
  return Promise.all([Axios.get(url)])
    .then((res) => {
      console.log(res[0]);
      if (res[0].data) {
        return Promise.resolve(res[0].data.data.authorization);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}