<template>
  <div id="app">
    <label class="col-form-label">
      <span>快測ナビ</span>
    </label>
    <span class="badge badge-secondary">{{ $t('optional') }}</span>
    <multiselect
      v-model="value"
      placeholder="選択してください"
      label="label"
      track-by="code"
      :options="options"
      :multiple="true"
      :taggable="true"
      :close-on-select="true"
    />
    <!-- <pre class="language-json"><code>{{ value  }}</code></pre> -->
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  name: "App",
  components: {
    Multiselect,
  },
  data() {
    return {
      value: [],
      options: [
        {
          label: "快測ナビSTD",
          code: "1",
        },
        {
          label: "快測ナビADV",
          code: "2",
        },
      ],
    };
  },

  methods: {
    /**
     * 
     * @param {Object} option 
     */
    customLabel(option) {
      return `${option.name}`;
    },
  },
};
</script>