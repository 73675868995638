<template>
  <v-card class="mx-auto stock-head-wrapper" outlined>
    <!-- 在庫数と実在庫数を表示 -->
    <h3 class="stock-head">
      <v-row>
        <v-col cols="5">レンタル可能台数</v-col>
        <v-col cols="7">：{{ stockCnt }}&nbsp;<small>台<br><span v-if="segmentId===10">　- Pro:{{ stockCntIpadPro }}　- 無印:{{ stockCntIpad }}</span></small></v-col>
        <v-col cols="10">
          <span v-if="isPeriodAlert" class="alert">終了予定日を過ぎたレンタル品があります。</span>
        </v-col>
      </v-row>
    </h3>
    <!-- 展開ボタン -->
    <v-btn @click="toggleTable" class="mt-2 mb-2">
      {{ isTableVisible ? '商品情報を隠す' : '商品情報を表示' }}
    </v-btn>
    <v-btn :href="'/stock/master/'+segmentId" class="mx-2">マスタ編集</v-btn>
    <!-- アラートメッセージ -->
      <v-data-table v-if="isTableVisible"
        style="width: 800px"
        :headers="headers"
        :items="items"
        hide-default-footer
      ></v-data-table>
  </v-card>
</template>

<script>
export default {
  name: 'StockHeadTable',
  props: {
    stockCnt: Number,
    stockCntIpad: {
      type: Number,
      default: 0,
    },
    stockCntIpadPro: {
      type: Number,
      default: 0,
    },
    productCodes: String,
    regularPrice: Number,
    partitionPrice: Number,
    costPrice: Number,
    isPeriodAlert: Boolean,
    headers: Array,
    items: Array,
    segmentId: Number,
  },
  data() {
    return {
      isTableVisible: false // テーブルの表示状態
    };
  },
  methods: {
    formatPrice(value) {
      return `¥${value.toLocaleString()}`;
    },
    calculateProfitRegular(regularPrice, costPrice) {
      return regularPrice - costPrice;
    },
    calculateProfitPartition(partitionPrice, costPrice) {
      return partitionPrice - costPrice;
    },
    calculatePartition(regularPrice, partitionPrice) {
      return ((partitionPrice / regularPrice) * 100);
    },
    toggleTable() {
      this.isTableVisible = !this.isTableVisible; // テーブルの表示状態を切り替え
    }
  }
}
</script>
<style scoped>
.v-card {
  border: none;
}
.stock-head-wrapper {
  width: 1500px;
}
.stock-head {
  width: 600px;
}
.alert {
  color: red;
  font-size: 20px;
  font-weight: bold;
  padding: .2em 1em;
  border: 0;
  border-radius: 0;
  background: linear-gradient(transparent 60%, #ff000045 60%);
}
</style>