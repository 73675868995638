<template>
  <div class="table-responsive">
    <v-progress-linear v-model="progress" height="25">
      <strong>{{ Math.ceil(progress) }}%</strong>
    </v-progress-linear>
    <p>{{ message }}</p>
    <table class="table table-striped table-hover">
      <tbody>
        <tr>
          <td>バッチID</td>
          <td>{{ batchId }}</td>
        </tr>
        <tr>
          <td>バッチ名</td>
          <td>{{ name }}</td>
        </tr>
        <tr>
          <td>ジョブ数</td>
          <td>{{ totalJobs }}</td>
        </tr>
        <tr>
          <td>保留中のジョブ数</td>
          <td>{{ pendingJobs }}</td>
        </tr>
        <tr>
          <td>失敗したジョブ数</td>
          <td>{{ failedJobs }}</td>
        </tr>
        <tr>
          <td>完了したジョブ数</td>
          <td>{{ processedJobs }}</td>
        </tr>
        <tr>
          <td>進捗率</td>
          <td>{{ progress }}%</td>
        </tr>
        <tr>
          <td>完了済みか</td>
          <td>{{ finished }}</td>
        </tr>
        <tr>
          <td>キャンセル済みか</td>
          <td>{{ cancelled }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
  
<script>
import Axios from "axios";
export default {
  props: {
    batchId: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      name: "",
      totalJobs: 0,
      pendingJobs: 0,
      failedJobs: 0,
      message: "ダウンロード完了までページを閉じずにお待ちください",
      processedJobs: 0,
      progress: 0,
      finished: false,
      cancelled: false,
      polling: null,
    };
  },
  methods: {
    /**
     *
     */
    pollData() {
      this.polling = setInterval(() => {
        Axios.get(`/api/v1/batch/information/` + this.batchId, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
          .then((res) => {
            if (res) {
              this.name = res.data.name;
              this.totalJobs = res.data.total_jobs;
              this.pendingJobs = res.data.pending_jobs;
              this.failedJobs = res.data.failed_jobs;
              this.processedJobs = res.data.processed_jobs;
              this.progress = res.data.progress;
              this.finished = res.data.finished;
              this.cancelled = res.data.cancelled;
              if (this.failedJobs === 0 && this.finished) {
                clearInterval(this.polling);
                location.href = "/delivery_slip/download/pdfs/" + this.name;
              }
              if (this.failedJobs > 0) {
                this.message =
                  "ファイルの作成に失敗しました。再度お試しください。";
              }
            }
          })
          .catch((error) => {
            console.log(error);
            switch (error.response.status) {
              case 401:
                alert(
                  "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
                );
                break;
              case 404:
                alert("接続先が見つかりませんでした。");
                break;
              case 422:
                alert(JSON.stringify(error.response.data.errors));
                break;
              case 500:
                alert("内部エラー");
                break;
              default:
                alert("通信エラーが発生しました、再度お試しください。");
                break;
            }
          });
      }, 3000);
    },
  },
  /**
   *
   */
  beforeDestroy() {
    clearInterval(this.polling);
  },
  /**
   *
   */
  created() {
    this.pollData();
  },
};
</script>