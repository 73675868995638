<template>
  <v-card
    class="mx-auto"
    elevation="2"
    outlined
    color="light-blue lighten-5"
  >
    <v-card-title>
      <h2>{{ title }}</h2>
      <v-spacer />
    </v-card-title>
    <v-text-field
      v-model="search"
      append-icon="fa-search"
      :label="searchLabel"
      single-line
      hide-details
    />
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="10"
      :search="search"
      :loading="loading"
      :loading-text="loadingLabel"
      :no-data-text="noDataText"
      class="elevation-1"
      multi-sort
    />
  </v-card>
</template>

<script>
import Axios from "axios";
import Token from "./token";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    token: {
        type: String,
        required: false,
        default: null,
    }
  },
  data() {
    return {
      bearer: "",
      headers: [],
      items: [],
      loading: true,
      loadingLabel: this.$i18n.translate("loading"),
      noDataText: this.$vuetify.noDataText,
      search: "",
      searchLabel: this.$i18n.translate("search"),
      tokenUrl: "/api/v1/token",
    };
  },
  created: function () {
    this.getTokenAndLoadList();
  },
  methods: {
    /**
     *
     */
    async loadList() {
      this.loading = true;
      await Axios.get(this.url, {
        headers: {
          Authorization: this.bearer,
        },
      })
        .then((res) => {
          if (res.data) {
            this.headers = res.data.headers;
            this.items = res.data.items;
          }
        })
        .catch((error) => {
          this.noDataText = "通信エラー";
          console.log(error);
        });
      this.loading = false;
    },
    /**
     *
     */
    async getTokenAndLoadList() {
      if (this.token === null) {
        this.bearer = await Token(this.tokenUrl);
      } else {
        this.bearer = "Bearer " + this.token;
      }
      this.loadList();
    },
  },
};
</script>
