<template>
    <v-container>
        <v-tabs
            v-model="activeTab"
            background-color="blue lighten-5"
            centered
        >
            <v-tab
                v-for="segment in stockSegmentTabList"
                :key="segment.id"
                :href="generateUrl(segment.id)"
                :class="{'active-tab': segment.current}"
                class="custom-tab"
            >
                {{ segment.name }}
            </v-tab>
        </v-tabs>
    </v-container>
  </template>
  
  <script>
  export default {
    props: {
        stockSegmentTabList: {
            type: Array,
            required: true,
        },
        linkUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            activeTab: this.getCurrentTab(),
        };
    },
    methods: {
        generateUrl(segmentId) {
            return this.linkUrl.replace('{id}', segmentId);
        },
        getCurrentTab() {
            const currentSegment = this.stockSegmentTabList.find(segment => segment.current);
            return currentSegment ? this.generateUrl(currentSegment.id) : this.generateUrl(1);
        },
    },
  };
  </script>
  
  <style scoped>
  .custom-tab {
    font-size: 14px;
    line-height: 1em;
    max-width: 140px; /* タブの最大幅を制限 */
    text-align: center;
    white-space: normal; /* 自動的に改行を挿入 */
    padding: 8px;
    min-height: 48px;
  }
  .active-tab {
    background-color: #1976D2;
    color: white;
  }
  </style>