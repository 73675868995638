<template>
  <v-card flat>
    <v-card-text class="row">
      <div class="col-xs-12 col-sm-12 col-md-12">
        <div
          v-if="errors.length"
          class="alert alert-danger"
        >
          <ul>
            <li
              v-for="e in errors"
              :key="e"
            >
              {{ e }}
            </li>
          </ul>
        </div>
        <div
          v-if="msg !== null"
          class="alert alert-info"
        >
          <p>{{ $msg }}</p>
        </div>
        <slot />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
    props: {
        csrf: {
            type: String,
            required: true,
        },
        errors: {
            type: Object,
            default: () => {},
        },
        msg: {
            type: String,
            default: null,
        },
    },
}
</script>
