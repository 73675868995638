<template>
  <div class="form-group">
    <label class="col-form-label">
      <span>{{ label }}</span>
      <span
        v-if="required"
        class="badge badge-primary"
      >{{
        $t("required")
      }}</span>
      <span
        v-else
        class="badge badge-secondary"
      >{{ $t("optional") }}</span>
    </label>
    <v-textarea
      name="remarks"
      auto-grow
      counter
      no-resize
      clearable
      outlined
      placeholder="文字を入力"
      rows="3"
      maxlength="200"
      :value="remarksFormValue"
    >
    </v-textarea>
  </div>
</template>

<script>
export default {
  props: {
    idName: {
      type: String,
      default: "id",
    },
    defaultLabelName: {
      type: String,
      default: null,
    },
    estimateOrder: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: String,
      default: "",
    },
    labelKey: {
      type: String,
      default: "",
    },
    defaultCaution: {
      type: Array,
      default: () => [],
    },
    estimateId: {
      type: String,
      default: "estimate_id",
    },
    isConfirmBack: {
      type: Number,
      default: 0,
    },
    oldRemarks: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialog: false,
      formValue: this.defaultValue,
      labelName: this.defaultLabelName,
      labelValue: "",
      headers: [],
      items: [],
      loading: true,
      loadingLabel: this.$i18n.translate("loading"),
      placeholder: "",
      search: "",
      searchLabel: this.$i18n.translate("search"),
      remarksFormValue: "",
    }
  },
  created: function () {
    if (this.labelName === "") {
      this.labelName = this.idName;
    }
    this.labelName = this.defaultLabelName ?? this.idName;
    if (this.defaultCaution !== []) {
      for (let i = 0; i < this.defaultCaution.length; i++) {
        this.caution_input.push(this.defaultCaution[i].caution_point);
      }
    }

    this.remarksFormValue =
      this.estimateOrder.remarks !== null && this.estimateOrder.remarks !== undefined
        ? this.estimateOrder.remarks : ''

    if(this.isConfirmBack == 1){
      this.remarksFormValue =
      this.oldRemarks !== null && this.oldRemarks !== undefined
        ? this.oldRemarks : ''
    }

    this.loadList();
  },
  mounted() {
    this.remarksFormValue = this.remarksFormValue.replace(/<br>/g, "\n");
  },
  methods: {
    /**
     * 
     */
    async loadList() {
      this.loading = true;
      if (this.formValue !== "" && this.labelKey !== "") {
        this.labelValue = this.$i18n.translate("loading");
      }

      if (this.formValue !== "" && this.labelKey !== "") {
        const defaultItem = this.items.find(
          (row) => row[this.idName] === this.formValue
        );
        this.labelValue =
          typeof defaultItem !== "undefined"
            ? defaultItem[this.labelKey]
            : this.$i18n.translate("unknown");
      }
      this.loading = false;
    },
  },
};
</script>