<template>
  <div class="container mt-4">
    <div class="sticky-container">
      追加対象件数：{{ this.totalCheckedCount }} <br />PDF出力対象件数：{{
        this.totalPdfCount
      }}
    </div>
    <div class="row">
      <!-- 左側の入力要素 -->
      <div class="col-md-5">
        <div class="card p-4">
          <h3>【発送先情報】</h3>
          <div class="form-row">
            <label class="col-form-label">
              <span>{{ companyNameDefaultLabel }}</span>
              <span v-if="companyNameRequired" class="badge badge-primary">{{
                $t("required")
              }}</span>
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              v-model="companyNameFormValue"
              name="company_name"
              :disabled="companyNameDisabled"
              :readonly="companyNameReadonly"
              :required="companyNameRequired"
              class="form-control"
            />
            <label class="col-form-label">
              <span>{{ companyName2DefaultLabel }}</span>
              <span v-if="companyName2Required" class="badge badge-primary">{{
                $t("required")
              }}</span>
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              v-model="companyName2FormValue"
              name="company_name2"
              :disabled="companyName2Disabled"
              :readonly="companyName2Readonly"
              :required="companyName2Required"
              class="form-control"
            />
            <label class="col-form-label">
              <span>{{ contactPersonDefaultLabel }}</span>
              <span v-if="contactPersonRequired" class="badge badge-primary">{{
                $t("required")
              }}</span>
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              v-model="contactPersonFormValue"
              name="contact_person"
              :disabled="contactPersonDisabled"
              :readonly="contactPersonReadonly"
              :required="contactPersonRequired"
              class="form-control"
            />
            <label class="col-form-label">
              <span>{{ postalCodeDefaultLabel }}</span>
              <span v-if="postalCodeRequired" class="badge badge-primary">{{
                $t("required")
              }}</span>
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              v-model="postalCodeFormValue"
              name="postal_code"
              :disabled="postalCodeDisabled"
              :readonly="postalCodeReadonly"
              :required="postalCodeRequired"
              class="form-control"
            />
            <label class="col-form-label">
              <span>{{ addressDefaultLabel }}</span>
              <span v-if="addressRequired" class="badge badge-primary">{{
                $t("required")
              }}</span>
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              v-model="addressFormValue"
              name="address"
              :disabled="addressDisabled"
              :readonly="addressReadonly"
              :required="addressRequired"
              class="form-control"
            />
            <label class="col-form-label">
              <span>{{ address2DefaultLabel }}</span>
              <span v-if="address2Required" class="badge badge-primary">{{
                $t("required")
              }}</span>
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              v-model="address2FormValue"
              name="address2"
              :disabled="address2Disabled"
              :readonly="address2Readonly"
              :required="address2Required"
              class="form-control"
            />
            <label class="col-form-label">
              <span>{{ telephoneNumberDefaultLabel }}</span>
              <span
                v-if="telephoneNumberRequired"
                class="badge badge-primary"
                >{{ $t("required") }}</span
              >
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              v-model="telephoneNumberFormValue"
              name="telephone_number"
              :disabled="telephoneNumberDisabled"
              :readonly="telephoneNumberReadonly"
              :required="telephoneNumberRequired"
              class="form-control"
            />
            <label class="col-form-label">
              <span>{{ arrivalDayDefaultLabel }}</span>
              <span v-if="arrivalDayRequired" class="badge badge-primary">{{
                $t("required")
              }}</span>
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              v-model="arrivalDayFormValue"
              name="arrival_day"
              :disabled="arrivalDayDisabled"
              :readonly="arrivalDayReadonly"
              :required="arrivalDayRequired"
              class="form-control"
            />
            <label class="col-form-label">
              <span>{{ timeDesignationDefaultLabel }}</span>
              <span
                v-if="timeDesignationRequired"
                class="badge badge-primary"
                >{{ $t("required") }}</span
              >
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <select
              :disabled="timeDesignationDisabled"
              :readonly="timeDesignationReadonly"
              :required="timeDesignationRequired"
              v-model="timeDesignationFormValue"
              name="time_designation"
              class="form-control"
            >
              <option value="" selected></option>
              <option value="0812">午前中</option>
              <option value="1416">14～16時</option>
              <option value="1618">16～18時</option>
              <option value="1820">18～20時</option>
              <option value="1921">19～21時</option>
              <option value="0010">午前10時まで</option>
              <option value="0017">午後5時まで</option>
              <option value="0009">日通9時まで</option>
              <option value="0010">日通10時まで</option>
              <option value="AM">日通8～12時</option>
              <option value="PM">日通12～18時</option>
            </select>
            <label class="col-form-label">
              <span>{{ officeEndCodeDefaultLabel }}</span>
              <span v-if="officeEndCodeRequired" class="badge badge-primary">{{
                $t("required")
              }}</span>
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              v-model="officeEndCodeFormValue"
              name="office_end_code"
              :disabled="officeEndCodeDisabled"
              :readonly="officeEndCodeReadonly"
              :required="officeEndCodeRequired"
              class="form-control"
            />
            <label class="col-form-label">
              <span>{{ productName1DefaultLabel }}</span>
              <span v-if="productName1Required" class="badge badge-primary">{{
                $t("required")
              }}</span>
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              v-model="productName1FormValue"
              name="product_name"
              :disabled="productName1Disabled"
              :readonly="productName1Readonly"
              :required="productName1Required"
              class="form-control"
            />
            <label class="col-form-label">
              <span>{{ productName2DefaultLabel }}</span>
              <span v-if="productName2Required" class="badge badge-primary">{{
                $t("required")
              }}</span>
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              v-model="productName2FormValue"
              name="product_name2"
              :disabled="productName2Disabled"
              :readonly="productName2Readonly"
              :required="productName2Required"
              class="form-control"
            />
          </div>
        </div>
        <div class="card p-4">
          <h3>【出荷履歴用】</h3>
          <div class="form-row">
            <label class="col-form-label">
              <span>{{ salesStaffDefaultLabel }}</span>
              <span v-if="salesStaffRequired" class="badge badge-primary">{{
                $t("required")
              }}</span>
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              v-model="salesStaffFormValue"
              name="sales_staff"
              :disabled="salesStaffDisabled"
              :readonly="salesStaffReadonly"
              :required="salesStaffRequired"
              class="form-control"
            />
            <label class="col-form-label">
              <span>{{ destinationDefaultLabel }}</span>
              <span v-if="destinationRequired" class="badge badge-primary">{{
                $t("required")
              }}</span>
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              v-model="destinationFormValue"
              name="destination"
              :disabled="destinationDisabled"
              :readonly="destinationReadonly"
              :required="destinationRequired"
              class="form-control"
            />
          </div>
        </div>
        <div class="card p-4">
          <h3>【本社出荷時/メモ】</h3>
          <div class="card p-4">
            <table class="head-office-shipment-table">
              <thead>
                <tr>
                  <th>指定請求</th>
                  <th>請求分ける</th>
                  <th>請求書不要</th>
                  <th>補助金</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="form-check custom-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="is_specified_request"
                        v-model="isSpecifiedRequestFormValue"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="form-check custom-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="is_separate_charges"
                        v-model="isSeparateChargesFormValue"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="form-check custom-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="is_no_invoice_required"
                        v-model="isNoInvoiceRequiredFormValue"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="form-check custom-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="is_subsidy"
                        v-model="isSubsidyFormValue"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card p-4">
            <table class="head-office-shipment-table">
              <thead>
                <tr>
                  <th>プロテクタ関連</th>
                  <th>CS担当者</th>
                  <th>リース</th>
                  <th>3Dイラスト追加</th>
                  <th>返却リスト</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="form-check custom-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="is_protector_related"
                        v-model="isProtectorRelatedFormValue"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="form-check custom-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="is_cs_person"
                        v-model="isCsPersonFormValue"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="form-check custom-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="is_reese"
                        v-model="isReeseFormValue"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="form-check custom-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="is_added_3d_illustration"
                        v-model="isAdded3dIllustrationFormValue"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="form-check custom-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="is_return_list"
                        v-model="isReturnListFormValue"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <label class="col-form-label">
            <span>{{ memoDefaultLabel }}</span>
            <span v-if="memoRequired" class="badge badge-primary">{{
              $t("required")
            }}</span>
            <span v-else class="badge badge-secondary">{{
              $t("optional")
            }}</span>
          </label>
          <textarea
            v-model="memoFormValue"
            name="head_office_shipping_memo"
            :required="memoRequired"
            class="form-control"
          ></textarea>
        </div>
      </div>
      <!-- 右側のチェックリストの表 -->
      <div class="col-md-7">
        <hapilogi-group-table
          :status="this.status"
          :items="hapilogiGroupFirst"
          tableId="first"
          :token="token"
          @valid-invalid-flag-updated="updateValidInvalidFlagCount"
          @pdf-output-flag-updated="updatePdfOutputFlagCount"
        />
        <hapilogi-group-table
          :status="this.status"
          :items="hapilogiGroupSecond"
          tableId="second"
          :token="token"
          @valid-invalid-flag-updated="updateValidInvalidFlagCount"
          @pdf-output-flag-updated="updatePdfOutputFlagCount"
        />
        <hapilogi-group-table
          :status="this.status"
          :items="hapilogiGroupThird"
          tableId="third"
          :token="token"
          @valid-invalid-flag-updated="updateValidInvalidFlagCount"
          @pdf-output-flag-updated="updatePdfOutputFlagCount"
        />
        <hapilogi-group-table
          :status="this.status"
          :items="hapilogiGroupFourth"
          tableId="fourth"
          :token="token"
          @valid-invalid-flag-updated="updateValidInvalidFlagCount"
          @pdf-output-flag-updated="updatePdfOutputFlagCount"
        />
        <hapilogi-group-table
          :status="this.status"
          :items="headOfficeShipmentGroup"
          tableId="other"
          :token="token"
          @valid-invalid-flag-updated="updateValidInvalidFlagCount"
          @pdf-output-flag-updated="updatePdfOutputFlagCount"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HapilogiGroupTable from "./hapilogi-group-table.vue";
export default {
  components: {
    HapilogiGroupTable,
  },
  props: {
    estimateOrder: {
      type: Object,
      default: () => {},
    },
    shippingInstruction: {
      type: Object,
      default: () => {},
    },
    hapilogiGroupFirst: {
      type: Array,
      default: () => {},
    },
    hapilogiGroupSecond: {
      type: Array,
      default: () => {},
    },
    hapilogiGroupThird: {
      type: Array,
      default: () => {},
    },
    hapilogiGroupFourth: {
      type: Array,
      default: () => {},
    },
    headOfficeShipmentGroup: {
      type: Array,
      default: () => {},
    },
    estimateId: {
      type: Number,
      default: "estimate_id",
    },
    status: {
      type: Number,
      default: "status",
    },
    salesStaffName: {
      type: String,
      default: "sales_staff_name",
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      companyNameDefaultLabel: this.$i18n.translate("会社名"),
      companyName2DefaultLabel: this.$i18n.translate("会社名2"),
      contactPersonDefaultLabel: this.$i18n.translate("担当者"),
      postalCodeDefaultLabel: this.$i18n.translate("郵便番号"),
      addressDefaultLabel: this.$i18n.translate("住所"),
      address2DefaultLabel: this.$i18n.translate("住所2※ビル名等"),
      telephoneNumberDefaultLabel: this.$i18n.translate("電話番号"),
      arrivalDayDefaultLabel: this.$i18n.translate("着日"),
      timeDesignationDefaultLabel: this.$i18n.translate("時間指定"),
      officeEndCodeDefaultLabel: this.$i18n.translate("営業所止コード"),
      productName1DefaultLabel: this.$i18n.translate("品名"),
      productName2DefaultLabel: this.$i18n.translate("品名2"),
      salesStaffDefaultLabel: this.$i18n.translate("営業担当"),
      destinationDefaultLabel: this.$i18n.translate("発送先"),
      memoDefaultLabel: this.$i18n.translate("本社出荷メモ"),
      companyNameFormValue: "",
      companyNameDisabled: false,
      companyNameReadonly: this.status === 3 ? true : false,
      companyNameRequired: false,
      companyName2FormValue: "",
      companyName2Disabled: false,
      companyName2Readonly: this.status === 3 ? true : false,
      companyName2Required: false,
      contactPersonFormValue: "",
      contactPersonDisabled: false,
      contactPersonReadonly: this.status === 3 ? true : false,
      contactPersonRequired: false,
      postalCodeFormValue: "",
      postalCodeDisabled: false,
      postalCodeReadonly: this.status === 3 ? true : false,
      postalCodeRequired: false,
      addressFormValue: "",
      addressDisabled: false,
      addressReadonly: this.status === 3 ? true : false,
      addressRequired: false,
      address2FormValue: "",
      address2Disabled: false,
      address2Readonly: this.status === 3 ? true : false,
      address2Required: false,
      telephoneNumberFormValue: "",
      telephoneNumberDisabled: false,
      telephoneNumberReadonly: this.status === 3 ? true : false,
      telephoneNumberRequired: false,
      arrivalDayFormValue: "",
      arrivalDayDisabled: false,
      arrivalDayReadonly: this.status === 3 ? true : false,
      arrivalDayRequired: false,
      timeDesignationFormValue: "",
      timeDesignationDisabled: false,
      timeDesignationReadonly: this.status === 3 ? true : false,
      timeDesignationRequired: false,
      officeEndCodeFormValue: "",
      officeEndCodeDisabled: false,
      officeEndCodeReadonly: this.status === 3 ? true : false,
      officeEndCodeRequired: false,
      productName1FormValue: "",
      productName1Disabled: false,
      productName1Readonly: this.status === 3 ? true : false,
      productName1Required: false,
      productName2FormValue: "",
      productName2Disabled: false,
      productName2Readonly: this.status === 3 ? true : false,
      productName2Required: false,
      salesStaffFormValue: this.salesStaffName,
      salesStaffDisabled: false,
      salesStaffReadonly: this.status === 3 ? true : false,
      salesStaffRequired: false,
      destinationFormValue: "",
      isSpecifiedRequestFormValue: "",
      isSeparateChargesFormValue: "",
      isNoInvoiceRequiredFormValue: "",
      isSubsidyFormValue: "",
      isProtectorRelatedFormValue: "",
      isCsPersonFormValue: "",
      isReeseFormValue: "",
      isAdded3dIllustrationFormValue: "",
      isReturnListFormValue: "",
      memoFormValue: "",
      destinationDisabled: false,
      destinationReadonly: this.status === 3 ? true : false,
      destinationRequired: false,
      memoRequired: false,
      totalCheckedCount: 0,
      totalPdfCount: 0,
    };
  },
  computed: {},
  created: function () {
    this.companyNameFormValue =
      this.shippingInstruction.company_name !== null &&
      this.shippingInstruction.company_name !== undefined
        ? this.shippingInstruction.company_name
        : this.estimateOrder.shipping_company !== null &&
          this.estimateOrder.shipping_company !== undefined
        ? this.estimateOrder.shipping_company
        : "";

    this.companyName2FormValue =
      this.shippingInstruction.company_name2 !== null &&
      this.shippingInstruction.company_name2 !== undefined
        ? this.shippingInstruction.company_name2
        : "";

    this.contactPersonFormValue =
      this.shippingInstruction.contact_person !== null &&
      this.shippingInstruction.contact_person !== undefined
        ? this.shippingInstruction.contact_person
        : this.estimateOrder.shipping_contact_name !== null &&
          this.estimateOrder.shipping_contact_name !== undefined
        ? this.estimateOrder.shipping_contact_name
        : "";

    this.postalCodeFormValue =
      this.shippingInstruction.postal_code !== null &&
      this.shippingInstruction.postal_code !== undefined
        ? this.shippingInstruction.postal_code
        : this.estimateOrder.shipping_zip !== null &&
          this.estimateOrder.shipping_zip !== undefined
        ? this.estimateOrder.shipping_zip
        : "";

    this.addressFormValue =
      this.shippingInstruction.address !== null &&
      this.shippingInstruction.address !== undefined
        ? this.shippingInstruction.address
        : this.estimateOrder.shipping_address1 !== null &&
          this.estimateOrder.shipping_address1 !== undefined
        ? this.estimateOrder.shipping_address1
        : "";

    this.address2FormValue =
      this.shippingInstruction.address2 !== null &&
      this.shippingInstruction.address2 !== undefined
        ? this.shippingInstruction.address2
        : this.estimateOrder.shipping_address2 !== null &&
          this.estimateOrder.shipping_address2 !== undefined
        ? this.estimateOrder.shipping_address2
        : "";

    this.telephoneNumberFormValue =
      this.shippingInstruction.telephone_number !== null &&
      this.shippingInstruction.telephone_number !== undefined
        ? this.shippingInstruction.telephone_number
        : this.estimateOrder.shipping_tel !== null &&
          this.estimateOrder.shipping_tel !== undefined
        ? this.estimateOrder.shipping_tel
        : "";
    this.arrivalDayFormValue =
      this.shippingInstruction.arrival_day !== null &&
      this.shippingInstruction.arrival_day !== undefined
        ? this.shippingInstruction.arrival_day
        : "";

    this.timeDesignationFormValue =
      this.shippingInstruction.time_designation !== null &&
      this.shippingInstruction.time_designation !== undefined
        ? this.shippingInstruction.time_designation
        : "";

    this.officeEndCodeFormValue =
      this.shippingInstruction.office_end_code !== null &&
      this.shippingInstruction.office_end_code !== undefined
        ? this.shippingInstruction.office_end_code
        : "";

    this.productName1FormValue =
      this.shippingInstruction.product_name !== null &&
      this.shippingInstruction.product_name !== undefined
        ? this.shippingInstruction.product_name
        : "";

    this.productName2FormValue =
      this.shippingInstruction.product_name2 !== null &&
      this.shippingInstruction.product_name2 !== undefined
        ? this.shippingInstruction.product_name2
        : "";

    this.salesStaffFormValue =
      this.shippingInstruction.sales_staff !== null &&
      this.shippingInstruction.sales_staff !== undefined
        ? this.shippingInstruction.sales_staff
        : this.salesStaffName !== null && this.salesStaffName !== undefined
        ? this.salesStaffName
        : "";

    this.destinationFormValue =
      this.shippingInstruction.destination !== null &&
      this.shippingInstruction.destination !== undefined
        ? this.shippingInstruction.destination
        : "";

    this.isSpecifiedRequestFormValue =
      this.shippingInstruction.is_specified_request !== null &&
      this.shippingInstruction.is_specified_request !== undefined
        ? this.shippingInstruction.is_specified_request
        : "";

    this.isSeparateChargesFormValue =
      this.shippingInstruction.is_separate_charges !== null &&
      this.shippingInstruction.is_separate_charges !== undefined
        ? this.shippingInstruction.is_separate_charges
        : "";

    this.isNoInvoiceRequiredFormValue =
      this.shippingInstruction.is_no_invoice_required !== null &&
      this.shippingInstruction.is_no_invoice_required !== undefined
        ? this.shippingInstruction.is_no_invoice_required
        : "";

    this.isSubsidyFormValue =
      this.shippingInstruction.is_subsidy !== null &&
      this.shippingInstruction.is_subsidy !== undefined
        ? this.shippingInstruction.is_subsidy
        : "";

    this.isProtectorRelatedFormValue =
      this.shippingInstruction.is_protector_related !== null &&
      this.shippingInstruction.is_protector_related !== undefined
        ? this.shippingInstruction.is_protector_related
        : "";

    this.isCsPersonFormValue =
      this.shippingInstruction.is_cs_person !== null &&
      this.shippingInstruction.is_cs_person !== undefined
        ? this.shippingInstruction.is_cs_person
        : "";

    this.isReeseFormValue =
      this.shippingInstruction.is_reese !== null &&
      this.shippingInstruction.is_reese !== undefined
        ? this.shippingInstruction.is_reese
        : "";

    this.isAdded3dIllustrationFormValue =
      this.shippingInstruction.is_added_3d_illustration !== null &&
      this.shippingInstruction.is_added_3d_illustration !== undefined
        ? this.shippingInstruction.is_added_3d_illustration
        : "";

    this.isReturnListFormValue =
      this.shippingInstruction.is_return_list !== null &&
      this.shippingInstruction.is_return_list !== undefined
        ? this.shippingInstruction.is_return_list
        : "";

    this.memoFormValue =
      this.shippingInstruction.head_office_shipping_memo !== null &&
      this.shippingInstruction.head_office_shipping_memo !== undefined
        ? this.shippingInstruction.head_office_shipping_memo
        : "";
  },
  methods: {
    updateValidInvalidFlagCount({ tableId }) {
      if (tableId === "first") {
        this.hapilogiGroupFirst = updatedData;
      } else if (tableId === "second") {
        this.hapilogiGroupSecond = updatedData;
      } else if (tableId === "third") {
        this.hapilogiGroupThird = updatedData;
      } else if (tableId === "fourth") {
        this.hapilogiGroupFourth = updatedData;
      } else if (tableId === "other") {
        this.headOfficeShipmentGroup = updatedData;
      }
      this.calculateTotalCheckedCount();
    },
    updatePdfOutputFlagCount({ tableId }) {
      if (tableId === "first") {
        this.hapilogiGroupFirst = updatedData;
      } else if (tableId === "second") {
        this.hapilogiGroupSecond = updatedData;
      } else if (tableId === "third") {
        this.hapilogiGroupThird = updatedData;
      } else if (tableId === "fourth") {
        this.hapilogiGroupFourth = updatedData;
      } else if (tableId === "other") {
        this.headOfficeShipmentGroup = updatedData;
      }
      this.calculateTotalPdfCount(); // PDFカウントを再計算
    },
    calculateCheckedCount(items) {
      return items.filter((item) => item.valid_invalid_flag).length;
    },
    calculateTotalCheckedCount() {
      this.totalCheckedCount =
        this.calculateCheckedCount(this.hapilogiGroupFirst) +
        this.calculateCheckedCount(this.hapilogiGroupSecond) +
        this.calculateCheckedCount(this.hapilogiGroupThird) +
        this.calculateCheckedCount(this.hapilogiGroupFourth);
      this.calculateCheckedCount(this.headOfficeShipmentGroup);
    },
    calculateTotalPdfCount() {
      const firstPdfCount = this.calculatePdfCount(this.hapilogiGroupFirst);
      const secondPdfCount = this.calculatePdfCount(this.hapilogiGroupSecond);
      const thirdPdfCount = this.calculatePdfCount(this.hapilogiGroupThird);
      const fourthPdfCount = this.calculatePdfCount(this.hapilogiGroupFourth);
      const otherPdfCount = this.calculatePdfCount(
        this.headOfficeShipmentGroup
      );

      this.totalPdfCount =
        firstPdfCount +
        secondPdfCount +
        thirdPdfCount +
        fourthPdfCount +
        otherPdfCount;
    },
    calculatePdfCount(group) {
      return group.reduce(
        (count, item) =>
          count +
          ((item.valid_invalid_flag === 1 ||
            item.valid_invalid_flag === true) &&
          item.is_output_pdf === 1
            ? 1
            : 0),
        0
      );
    },
  },
};
</script>

<style>
.head-office-shipment-table {
  width: auto;
  max-width: 100%;
  border-collapse: collapse;
}

.head-office-shipment-table thead {
  position: sticky;
  top: 0;
  background-color: #f0f0f0;
  z-index: 1;
}

.head-office-shipment-table th,
.head-office-shipment-tabletd {
  border: 1px solid #ccc;
  padding: 6px; /* パディングを小さく調整 */
  font-size: 14px; /* フォントサイズを調整 */
}

/* カスタムチェックボックスのスタイル */
.custom-checkbox .form-check-input {
  width: 30px; /* チェックボックスの幅を大きくする */
  height: 30px; /* チェックボックスの高さを大きくする */
}

/* チェックされたときのスタイル */
.custom-checkbox .form-check-input:checked {
  background-color: #007bff; /* チェックが付いた時の背景色 */
}

.sticky-container {
  position: fixed; /* 要素を固定位置に設定 */
  top: 320px; /* 上端からの距離 */
  right: 50px; /* 右端からの距離 */
  width: 200px; /* コンテナの幅 */
  padding: 10px; /* 内側の余白 */
  background-color: white; /* 背景色 */
  border: 1px solid #ccc; /* 境界線 */
  z-index: 1000; /* 他の要素より上に表示 */
}
</style>
