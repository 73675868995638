<template>
    <form method="post" v-if="opportunityNumberValue !== null">
        <button type="button" @click="dialog = true" class="btn btn-primary bg-gradient-primary btn-block">
                OSC商談削除
        </button>
        <v-dialog v-model="dialog" persistent max-width="600px" style="z-index: 1050;">
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                本当に削除してもよろしいでしょうか。
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialog = false">閉じる</v-btn>
                        <v-btn type="button" color="blue darken-1" text @click="remove">削除実行</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-progress-circular
            v-if="connecting"
            :indeterminate="progress === 0"
            :value="progress"
            color="light-blue"
        />
    </form>
</template>

<script>
import Axios from "axios";

export default {
  props: {
    apiToken: {
        type: String,
        required: true,
    },
    estimateId: {
        type: Number,
        required: true,
    },
    opportunityNumber: {
        type: Number,
        default: null,
    }
  },
  data() {
    return {
        connecting: false,
        dialog: false,
        opportunityNumberValue: null,
        progress: 100,
    };
  },
  methods: {
    /**
     * 
     */
    remove() {
        this.dialog = false;
        this.connecting = true;
        this.progress = 0;
        Axios.delete(`/api/v1/estimate/store/osc/opportunity/` + this.estimateId, {
            headers: {
                Authorization: 'Bearer ' + this.apiToken,
                Accept: 'application/json'
            },
        }
        ).then((res) => {
            this.connecting = false;
            this.progress = 100;
            this.$emit('opportunity-number', null);
            this.opportunityNumberValue = null;
        }).catch((error) => {
            this.connecting = false;
            this.progress = 100;
            console.log(error);
            if (error.response.data.message) {
                switch (error.response.data.message) {
                    case 'HTTP request returned status code 401':
                        alert('OSCのユーザー名・パスワードが正しくありません');
                        break;
                    case 'The Owner Not Found':
                        alert('見積作成者に対するOSCユーザーが存在しません');
                        break;
                    default:
                        alert(error.response.data.message);
                }
                return;
            }
            switch (error.response.status) {
                case 401:
                    alert("認証エラー");
                    break;
                default:
                    alert(error);
            }
        });
    },
    /**
     * 
     * @param {Number} num 
     */
    updateOpportunityNumber(num) {
        this.opportunityNumberValue = num;
    }
  },
  created: function () {
      this.opportunityNumberValue = this.opportunityNumber;
  }
};
</script>