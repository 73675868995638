<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1"
      hide-default-footer
      :loading="loading"
      :loading-text="loadingLabel"
      :no-data-text="noDataText"
    >
      <template #[`item.download`]="{ item }">
        <v-icon
          @click="downloadItem(item)"
        >
          fa-file-download
        </v-icon>
      </template>
      <template #[`item.delete`]="{ item }">
        <v-btn type="button" icon class="bg-gradient-danger shadow" @click="deleteItem(item)">
          <i class="far fa-trash-alt" />
        </v-btn>
      </template>
    </v-data-table>
        <div
      class="drop-area"
      @dragenter="dragEnter"
      @dragleave="dragLeave"
      @dragover.prevent
      @drop.prevent="dropFile"
      :class="{'enter-drop-area': isEntered}"
    >
      ここにドラッグ&ドロップしてファイルをアップロード
    </div>
    <div>
      <ul class="additional-file-flex">
        <li v-for="(file, index) in files" :key="index" class="additional-file-flex-col">
          <div class="additional-file-flex-icon-block">
            <span class="additional-file-delete-mark" @click="deleteFile(index)">×</span>
            <i v-if="file.type == 'application/pdf'" class="fas fa-file-pdf additional-file-icon"></i>
            <i v-else-if="['image/jpeg', 'image/png'].includes(file.type)" class="fas fa-file-image additional-file-icon"></i>
            <i v-else class="fas fa-file additional-file-icon"></i>
          </div>
          <span>{{ file.name }}</span>
        </li>
      </ul>
      <div v-show="files.length">
        <button
          class="btn btn-primary bg-gradient-primary"
          type="submit"
          @click="sendFile"
        >
          {{ $t('submit') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
export default {
  props: {
    token: {
        type: String,
        required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      headers: [],
      items: [],
      loading: true,
      loadingLabel: this.$i18n.translate("loading"),
      noDataText: this.$vuetify.noDataText,
      files: [],
      isEntered: false,
      temporaryFiles: [],
    };
  },
  created: function () {
    this.loadList();
  },
  methods: {
    /**
     *
     */
    async loadList() {
      this.loading = true;
      await Axios.get(
        `/api/v1/purchase_order/list/` + this.id,
        {
          headers: {
            Authorization: 'Bearer ' + this.token,
            Accept: 'application/json'
          },
        }
      ).then(
        (res) => {
          if (res.data) {
            this.headers = res.data.headers;
            this.items.splice(0, this.items.length);
            this.items.push(...res.data.items);
          }
        }
      ).catch(
        (error) => {
          this.noDataText = "通信エラー";
          console.log(error);
        }
      );
      this.loading = false;
    },
    /**
     *
     * @param {Object} item
     */
    downloadItem(item) {
      console.log(JSON.stringify(item));
      Axios.get(
        `/api/v1/purchase_order`,
        {
          headers: {
            Authorization: 'Bearer ' + this.token,
            Accept: 'application/json'
          },
          params: {
            id: this.id,
            file_name: item.name
          },
          responseType: 'blob'
        }
      ).then(
        response => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = item.name;
          link.click();
          URL.revokeObjectURL(link.href);
        }
      ).catch(
        (error) => {
          this.noDataText = "通信エラー";
          console.log(error);
        }
      );
    },
    /**
     *
     * @param {Object} item
     */
    deleteItem(item) {
      if (!confirm("本当に削除しますか?")) {
        return;
      }
      Axios.delete(
        `/api/v1/purchase_order`,
        {
          headers: {
            Authorization: 'Bearer ' + this.token,
            Accept: 'application/json'
          },
          params: {
            id: this.id,
            file_name: item.name
          }
        }
      ).then(
        response => {
          if (response.data) {
            const index = this.items.findIndex(element => element.name === item.name);
            this.items.splice(index, 1);
          }
          console.log(response);
        }
      ).catch(
        (error) => {
          this.noDataText = "通信エラー";
          console.log(error);
        }
      );
    },
    /**
     *
     */
    dragEnter() {
      this.isEntered = true;
    },
    /**
     *
     */
    dragLeave() {
      this.isEntered = false;
    },
    /**
     *
     * @param {Number} index
     */
    deleteFile(index) {
      this.files.splice(index, 1);
    },
    /**
     *
     */
    dropFile() {
      this.temporaryFiles = [...event.dataTransfer.files];
      if (this.temporaryFiles.some(file => file.size > 3145728)) {
        alert("3MBを超えるファイルはアップロードできません");
        return;
      }
      this.files.push(...this.temporaryFiles);
      this.temporaryFiles = [];
      this.isEntered = false;
    },
    /**
     *
     */
    sendFile() {
      this.files.forEach(file => {
        const formData = new FormData();
        formData.append('purchase_order', file);
        formData.append('id', this.id);
        Axios.post(
          '/api/v1/purchase_order',
          formData,
          {
            headers: { Authorization: "Bearer " + this.token },
          }
        )
        .then((res) => {
          console.log("success");
          this.files.splice(0, this.files.length);
          this.loadList();
        })
        .catch((error) => {
          console.log(error);
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(
                JSON.stringify(error.response.data.errors.kentem_product_id[0])
              );
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
        });
      });
    }
  }
}
</script>
