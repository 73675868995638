<template>
  <div class="table-responsive">
    <table class="table table-striped table-hover">
      <thead>
        <th v-for="header in headers" :key="header.id" scope="col">
          {{ header.text }}
        </th>
      </thead>
      <draggable v-model="draggableList" tag="tbody" @end="onDragged">
        <tr v-for="row in draggableList" :key="row.id">
          <td>
            {{ row.id }}
          </td>
          <td>
            {{ row.product_code }}
          </td>
          <td
            v-if="
              shippingInstructionStatus === 0 &&
              (!isEditingProductName || editingIdForProductName !== row.id)
            "
            @dblclick="startEditingProductName(row.id, row.product_name)"
          >
            {{ row.product_name }}
          </td>
          <td v-else-if="shippingInstructionStatus === 0">
            <input
              v-model="row.product_name"
              type="text"
              class="form-control"
              @change="updateProductName(row.id, row.product_name)"
            />
          </td>
          <td v-else>
            {{ row.product_name }}
          </td>
          <td v-if="shippingInstructionStatus === 0">
            <v-container>
              <v-row align-content="center">
                <v-checkbox
                  v-if="isBulkUpdateProductName2Value"
                  v-model="bulkProductName2Items"
                  :value="row.id"
                  class="m-0 p-0"
                />
                <input
                  v-model="row.product_name_2"
                  class="form-control"
                  @change="updateProductName2(row.id, row.product_name_2)"
                />
              </v-row>
            </v-container>
          </td>
          <td v-else>
            {{ row.product_name_2 }}
          </td>
          <td
            v-if="
              shippingInstructionStatus === 0 &&
              (!isEditingCostAmount || editingIdForCostAmount !== row.id)
            "
            @dblclick="startEditingCostAmount(row.id, row.cost_amount)"
          >
            {{ row.cost_amount }}
            <span v-if="row.cost_amount === null">
              <i class="fas fa-exclamation-triangle" style="color: #fa0000"></i>
            </span>
          </td>
          <td v-else-if="shippingInstructionStatus === 0">
            <input
              v-model="row.cost_amount"
              type="text"
              class="form-control"
              @change="updateCostAmount(row.id, row.cost_amount)"
            />
            <span v-if="row.cost_amount === null">
              <i class="fas fa-exclamation-triangle" style="color: #fa0000"></i>
            </span>
          </td>
          <td v-else>
            {{ row.cost_amount }}
            <span v-if="row.cost_amount === null">
              <i class="fas fa-exclamation-triangle" style="color: #fa0000"></i>
            </span>
          </td>
          <td v-if="shippingInstructionStatus === 0">
            <v-container>
              <v-row align-content="center">
                <v-checkbox
                  v-if="isBulkUpdateUserIdValue"
                  v-model="bulkUserIdItems"
                  :value="row.id"
                  class="m-0 p-0"
                />
                <input
                  v-model="row.user_id"
                  class="form-control"
                  @change="updateUserId(row.id, row.user_id)"
                />
              </v-row>
            </v-container>
          </td>
          <td v-else>
            {{ row.user_id }}
          </td>
          <td v-if="shippingInstructionStatus === 0">
            <v-container>
              <v-row align-content="center">
                <v-checkbox
                  v-if="isBulkUpdateRequirementCodeValue"
                  v-model="bulkRequirementCodeItems"
                  :value="row.id"
                  class="m-0 p-0"
                />
                <select
                  v-model="row.requirement_code"
                  class="form-control"
                  @change="updateRequirementCode(row.id, row.requirement_code)"
                >
                  <option value="1">既存_販売店</option>
                  <option value="2">既存_直販</option>
                  <option value="3">新規_販売店</option>
                  <option value="4">新規_直販</option>
                </select>
              </v-row>
            </v-container>
          </td>
          <td v-else>
            {{ row.requirement_code }}
          </td>
          <td v-if="shippingInstructionStatus === 0 && row.is_product_activity">
            <v-container>
              <v-row align-content="center">
                <v-checkbox
                  v-if="isBulkUpdateProductCodeValue"
                  v-model="bulkProductCodeItems"
                  :value="row.id"
                  class="m-0 p-0"
                />
                <select
                  v-model="row.shipping_instruction_product_code"
                  class="form-control"
                  @change="
                    updateProductCode(
                      row.id,
                      row.shipping_instruction_product_code
                    )
                  "
                >
                  <option value="001">新規</option>
                  <option value="000">再加入</option>
                  <option value="003">更新</option>
                  <option value="005">追加</option>
                  <option value="013">先取</option>
                  <option value="002">新規複数年</option>
                  <option value="100">再加入複数年</option>
                  <option value="103">更新複数年</option>
                  <option value="105">追加複数年</option>
                  <option value="113">先取複数年</option>
                </select>
              </v-row>
            </v-container>
          </td>
          <td v-else>{{ row.shipping_instruction_product_code }}</td>
          <td v-if="shippingInstructionStatus === 0">
            <v-container>
              <v-row align-content="center">
                <v-checkbox
                  v-if="isBulkUpdateShipmentWayValue"
                  v-model="bulkShipmentWayItems"
                  :value="row.id"
                  class="m-0 p-0"
                />
                <select
                  v-model="row.shipment_way"
                  class="form-control"
                  @change="updateShipmentWay(row.id, row.shipment_way)"
                >
                  <option value="1">奈良委託_ヤマト</option>
                  <option value="7">奈良委託_ヤマト_MQR</option>
                  <option value="2">富士本社_ヤマト</option>
                  <option value="3">富士本社_日通</option>
                  <option value="4">転送処理</option>
                  <option value="5">請求のみ</option>
                  <option value="6">KJK</option>
                </select>
              </v-row>
            </v-container>
          </td>
          <td v-else>{{ row.shipment_way }}</td>
          <td
            v-if="
              shippingInstructionStatus === 0 &&
              (!isEditingRemarks || editingIdForRemarks !== row.id)
            "
            @dblclick="startEditingRemarks(row.id, row.remarks)"
          >
            <v-container>
              <v-row align-content="center">
                <v-checkbox
                  v-if="isBulkUpdateRemarksValue"
                  v-model="bulkRemarksItems"
                  :value="row.id"
                  class="m-0 p-0"
                />
                <input
                  v-model="row.remarks"
                  class="form-control"
                  @change="updateRemarks(row.id, row.remarks)"
                />
              </v-row>
            </v-container>
          </td>
          <td v-else-if="shippingInstructionStatus === 0">
            <input
              v-model="row.remarks"
              type="text"
              class="form-control"
              @change="updateRemarks(row.id, row.remarks)"
            />
          </td>
          <td v-else>{{ row.remarks }}</td>
        </tr>
      </draggable>
    </table>
  </div>
</template>

<script>
import Axios from "axios";
import Draggable from "vuedraggable";

export default {
  components: {
    Draggable,
  },
  filters: {
    addComma: (val) => {
      return val.toLocaleString();
    },
  },
  props: {
    csrf: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    isBulkUpdateRequirementCode: {
      type: Boolean,
      default: false,
    },
    isBulkUpdateProductCode: {
      type: Boolean,
      default: false,
    },
    isBulkUpdateShipmentWay: {
      type: Boolean,
      default: false,
    },
    isBulkUpdateUserId: {
      type: Boolean,
      default: false,
    },
    isBulkUpdateProductName2: {
      type: Boolean,
      default: false,
    },
    isBulkUpdateCostAmount: {
      type: Boolean,
      default: false,
    },
    shippingInstructionStatus: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      beforeRequirementCode: "",
      beforeProductCode: "",
      beforeShipmentWay: "",
      beforeUserId: "",
      beforeProductName2: "",
      beforeRemarks: "",
      beforeProductName: "",
      beforeCostAmount: "",
      draggableList: [],
      editingIdForRequirementCode: 0,
      editingIdForProductCode: 0,
      editingIdForShipmentWay: 0,
      editingIdForUserId: 0,
      editingIdForProductName2: 0,
      editingIdForRemarks: 0,
      editingIdForProductName: 0,
      editingIdForCostAmount: 0,
      isEditingRequirmentCode: false,
      isEditingProductCode: false,
      isEditingShipmentWay: false,
      isEditingUserId: false,
      isEditingProductName2: false,
      isEditingRemarks: false,
      isEditingProductName: false,
      isEditingCostAmount: false,
      bulkRequirementCodeItems: [],
      isBulkUpdateRequirementCodeValue: false,
      bulkProductCodeItems: [],
      isBulkUpdateProductCodeValue: false,
      bulkShipmentWayItems: [],
      isBulkUpdateShipmentWayValue: false,
      bulkUserIdItems: [],
      isBulkUpdateUserIdValue: false,
      bulkProductName2Items: [],
      isBulkUpdateProductName2Value: false,
      bulkRemarksItems: [],
      isBulkUpdateRemarksValue: false,
      bulkCostAmountItems: [],
      isBulkUpdateCostAmountValue: false,
    };
  },
  watch: {
    items: function (newItems, _) {
      this.draggableList = newItems;
    },
  },
  created() {
    this.draggableList = this.items;
  },
  methods: {
    /**
     *
     * @param {*} _evt
     */
    onDragged(_evt) {
      this.draggableList.forEach((element, idx) => {
        Axios.post(
          `/api/v1/shipping_instruction/update`,
          {
            id: element.id,
            order: idx,
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        ).catch((error) => {
          console.log(error);
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
        });
      });
    },
    /**
     *
     * @param {Number} id
     * @param {Number} product_code
     */
    startEditingProductCode(id, product_code) {
      this.editingIdForProductCode = id;
      this.beforeProductCode = product_code;
      this.isEditingProductCode = true;
    },
    /**
     *
     * @param {Number} id
     * @param {String} requirment_code
     */
    startEditingRequirmentCode(id, requirment_code) {
      this.editingIdForRequirementCode = id;
      this.beforeRequirementCode = requirment_code;
      this.isEditingRequirmentCode = true;
    },
    /**
     *
     * @param {Number} id
     * @param {String} shipment_way
     */
    startEditingShipmentWay(id, shipment_way) {
      this.editingIdForShipmentWay = id;
      this.beforeShipmentWay = shipment_way;
      this.isEditingShipmentWay = true;
    },
    /**
     *
     * @param {Number} id
     * @param {String} user_id
     */
    startEditingUserId(id, user_id) {
      this.editingIdForUserId = id;
      this.beforeUserId = user_id;
      this.isEditingUserId = true;
    },
    /**
     *
     * @param {Number} id
     * @param {String} product_name2
     */
    startEditingProductName2(id, product_name2) {
      this.editingIdForProductName2 = id;
      this.beforeProductName2 = product_name2;
      this.isEditingProductName2 = true;
    },
    /**
     *
     * @param {Number} id
     * @param {String} remarks
     */
    startEditingRemarks(id, remarks) {
      this.editingIdForRemarks = id;
      this.beforeRemarks = remarks;
      this.isEditingRemarks = true;
    },
    /**
     *
     * @param {Number} id
     * @param {String} product_name
     */
    startEditingProductName(id, product_name) {
      this.editingIdForProductName = id;
      this.beforeProductName = product_name;
      this.isEditingProductName = true;
    },
    /**
     *
     * @param {Number} id
     * @param {String} cost_amount
     */
    startEditingCostAmount(id, cost_amount) {
      this.editingIdForCostAmount = id;
      this.beforeCostAmount = cost_amount;
      this.isEditingCostAmount = true;
    },
    /**
     *
     * @param {Number} id
     * @param {String} product_code
     */
    updateProductCode(id, product_code) {
      Axios.patch(
        `/api/v1/shipping_instruction_product/product_code/update`,
        {
          id: id,
          product_code: product_code,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      ).catch((error) => {
        switch (error.response.status) {
          case 401:
            alert(
              "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
            );
            break;
          case 404:
            alert("接続先が見つかりませんでした。");
            break;
          case 422:
            alert(JSON.stringify(error.response.data.errors));
            break;
          case 500:
            alert("内部エラー");
            break;
          default:
            alert("通信エラーが発生しました、再度お試しください。");
            break;
        }
        console.log(error);
      });

      this.editingIdForProductCode = 0;
      this.isEditingProdctCode = false;
    },
    /**
     *
     * @param {Number} id
     * @param {Number} requirement_code
     */
    updateRequirementCode(id, requirement_code) {
      Axios.patch(
        `/api/v1/shipping_instruction_product/requirement_code/update`,
        {
          id: id,
          requirement_code: requirement_code,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      ).catch((error) => {
        switch (error.response.status) {
          case 401:
            alert(
              "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
            );
            break;
          case 404:
            alert("接続先が見つかりませんでした。");
            break;
          case 422:
            alert(JSON.stringify(error.response.data.errors));
            break;
          case 500:
            alert("内部エラー");
            break;
          default:
            alert("通信エラーが発生しました、再度お試しください。");
            break;
        }
        console.log(error);
      });

      this.editingIdForRequirementCode = 0;
      this.isEditingRequirmentCode = false;
    },
    /**
     *
     * @param {Number} id
     * @param {Number} shipment_way
     */
    updateShipmentWay(id, shipment_way) {
      Axios.patch(
        `/api/v1/shipping_instruction_product/shipment_way/update`,
        {
          id: id,
          shipment_way: shipment_way,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      ).catch((error) => {
        switch (error.response.status) {
          case 401:
            alert(
              "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
            );
            break;
          case 404:
            alert("接続先が見つかりませんでした。");
            break;
          case 422:
            alert(JSON.stringify(error.response.data.errors));
            break;
          case 500:
            alert("内部エラー");
            break;
          default:
            alert("通信エラーが発生しました、再度お試しください。");
            break;
        }
        console.log(error);
      });

      this.editingIdForShipmentWay = 0;
      this.isEditingShipmentWay = false;
    },
    /**
     *
     * @param {Number} id
     * @param {String} user_id
     */
    updateUserId(id, user_id) {
      Axios.patch(
        `/api/v1/shipping_instruction_product/user_id/update`,
        {
          id: id,
          user_id: user_id,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      ).catch((error) => {
        switch (error.response.status) {
          case 401:
            alert(
              "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
            );
            break;
          case 404:
            alert("接続先が見つかりませんでした。");
            break;
          case 422:
            alert(JSON.stringify(error.response.data.errors));
            break;
          case 500:
            alert("内部エラー");
            break;
          default:
            alert("通信エラーが発生しました、再度お試しください。");
            break;
        }
        console.log(error);
      });

      this.editingIdForUserId = 0;
      this.isEditingUserId = false;
    },
    /**
     *
     * @param {Number} id
     * @param {String} product_name2
     */
    updateProductName2(id, product_name2) {
      Axios.patch(
        `/api/v1/shipping_instruction_product/product_name2/update`,
        {
          id: id,
          product_name2: product_name2,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      ).catch((error) => {
        switch (error.response.status) {
          case 401:
            alert(
              "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
            );
            break;
          case 404:
            alert("接続先が見つかりませんでした。");
            break;
          case 422:
            alert(JSON.stringify(error.response.data.errors));
            break;
          case 500:
            alert("内部エラー");
            break;
          default:
            alert("通信エラーが発生しました、再度お試しください。");
            break;
        }
        console.log(error);
      });

      this.editingIdForProductName2 = 0;
      this.isEditingProductName2 = false;
    },
    /**
     *
     * @param {Number} id
     * @param {String} remarks
     */
    updateRemarks(id, remarks) {
      Axios.patch(
        `/api/v1/shipping_instruction_product/remarks/update`,
        {
          id: id,
          remarks: remarks,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      ).catch((error) => {
        switch (error.response.status) {
          case 401:
            alert(
              "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
            );
            break;
          case 404:
            alert("接続先が見つかりませんでした。");
            break;
          case 422:
            alert(JSON.stringify(error.response.data.errors));
            break;
          case 500:
            alert("内部エラー");
            break;
          default:
            alert("通信エラーが発生しました、再度お試しください。");
            break;
        }
        console.log(error);
      });

      this.editingIdForRemarks = 0;
      this.isEditingRemarks = false;
    },
    /**
     *
     * @param {Number} id
     * @param {String} product_name
     */
    updateProductName(id, product_name) {
      Axios.patch(
        `/api/v1/shipping_instruction_product/product_name/update`,
        {
          id: id,
          product_name: product_name,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      ).catch((error) => {
        switch (error.response.status) {
          case 401:
            alert(
              "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
            );
            break;
          case 404:
            alert("接続先が見つかりませんでした。");
            break;
          case 422:
            alert(JSON.stringify(error.response.data.errors));
            break;
          case 500:
            alert("内部エラー");
            break;
          default:
            alert("通信エラーが発生しました、再度お試しください。");
            break;
        }
        console.log(error);
      });

      this.editingIdForProductName = 0;
      this.isEditingProductName = false;
    },
    /**
     *
     * @param {Number} id
     * @param {String} cost_amount
     */
    updateCostAmount(id, cost_amount) {
      Axios.patch(
        `/api/v1/shipping_instruction_product/cost_amount/update`,
        {
          id: id,
          cost_amount: cost_amount,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      ).catch((error) => {
        switch (error.response.status) {
          case 401:
            alert(
              "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
            );
            break;
          case 404:
            alert("接続先が見つかりませんでした。");
            break;
          case 422:
            alert(JSON.stringify(error.response.data.errors));
            break;
          case 500:
            alert("内部エラー");
            break;
          default:
            alert("通信エラーが発生しました、再度お試しください。");
            break;
        }
        console.log(error);
      });

      this.editingIdForCostAmount = 0;
      this.isEditingCostAmount = false;
    },
    /**
     *
     * @param {Bool} status
     */
    changeStatusRequirementCodeTarget(status) {
      this.bulkRequirementCodeItems.splice(
        0,
        this.bulkRequirementCodeItems.length
      );
      if (status) {
        this.bulkRequirementCodeItems.push(
          ...this.draggableList.map((e) => e.id)
        );
      }
    },
    /**
     *
     * @param {Bool} status
     */
    changeStatusProductCodeTarget(status) {
      this.bulkProductCodeItems.splice(0, this.bulkProductCodeItems.length);
      if (status) {
        this.bulkProductCodeItems.push(...this.draggableList.map((e) => e.id));
      }
    },
    /**
     *
     * @param {Bool} status
     */
    changeStatusShipmentWayTarget(status) {
      this.bulkShipmentWayItems.splice(0, this.bulkShipmentWayItems.length);
      if (status) {
        this.bulkShipmentWayItems.push(...this.draggableList.map((e) => e.id));
      }
    },
    /**
     *
     * @param {Bool} status
     */
    changeStatusUserIdTarget(status) {
      this.bulkUserIdItems.splice(0, this.bulkUserIdItems.length);
      if (status) {
        this.bulkUserIdItems.push(...this.draggableList.map((e) => e.id));
      }
    },
    /**
     *
     * @param {Bool} status
     */
    changeStatusProductName2Target(status) {
      this.bulkProductName2Items.splice(0, this.bulkProductName2Items.length);
      if (status) {
        this.bulkProductName2Items.push(...this.draggableList.map((e) => e.id));
      }
    },
    /**
     *
     * @param {Bool} status
     */
    changeStatusRemarksTarget(status) {
      this.bulkRemarksItems.splice(0, this.bulkRemarksItems.length);
      if (status) {
        this.bulkRemarksItems.push(...this.draggableList.map((e) => e.id));
      }
    },
    /**
     *
     * @param {Number} requirementCode
     */
    bulkUpdateRequirementCode(requirementCode) {
      const idList = this.bulkRequirementCodeItems;
      Axios.patch(
        `/api/v1/shipping_instruction_product/requirement_code/update/list`,
        {
          id_list: idList,
          requirement_code: requirementCode,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      )
        .then(() => {
          idList.forEach((id) => {
            const item = this.draggableList.find((e) => e.id == id);
            item.requirement_code = requirementCode;
          });
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
          console.log(error);
        });
    },
    /**
     *
     * @param {String} productCode
     */
    bulkUpdateProductCode(productCode) {
      const idList = this.bulkProductCodeItems;
      Axios.patch(
        `/api/v1/shipping_instruction_product/product_code/update/list`,
        {
          id_list: idList,
          product_codes: productCode,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      )
        .then(() => {
          idList.forEach((id) => {
            const item = this.draggableList.find((e) => e.id == id);
            item.shipping_instruction_product_code = productCode;
          });
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
          console.log(error);
        });
    },
    /**
     *
     * @param {Number} shipmentWay
     */
    bulkUpdateShipmentWay(shipmentWay) {
      const idList = this.bulkShipmentWayItems;
      Axios.patch(
        `/api/v1/shipping_instruction_product/shipment_way/update/list`,
        {
          id_list: idList,
          shipment_way: shipmentWay,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      )
        .then(() => {
          idList.forEach((id) => {
            const item = this.draggableList.find((e) => e.id == id);
            item.shipment_way = shipmentWay;
          });
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
          console.log(error);
        });
    },
    /**
     *
     * @param {String} user_id
     */
    bulkUpdateUserId(user_id) {
      const idList = this.bulkUserIdItems;
      Axios.patch(
        `/api/v1/shipping_instruction_product/user_id/update/list`,
        {
          id_list: idList,
          user_id: user_id,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      )
        .then(() => {
          idList.forEach((id) => {
            const item = this.draggableList.find((e) => e.id == id);
            item.user_id = user_id;
          });
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
          console.log(error);
        });
    },
    /**
     *
     * @param {String} product_name2
     */
    bulkUpdateProductName2(product_name2) {
      const idList = this.bulkProductName2Items;
      Axios.patch(
        `/api/v1/shipping_instruction_product/product_name2/update/list`,
        {
          id_list: idList,
          product_name2: product_name2,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      )
        .then(() => {
          idList.forEach((id) => {
            const item = this.draggableList.find((e) => e.id == id);
            item.product_name_2 = product_name2;
          });
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
          console.log(error);
        });
    },
    /**
     *
     * @param {String} remarks
     */
    bulkUpdateRemarks(remarks) {
      const idList = this.bulkRemarksItems;
      Axios.patch(
        `/api/v1/shipping_instruction_product/remarks/update/list`,
        {
          id_list: idList,
          remarks: remarks,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      )
        .then(() => {
          idList.forEach((id) => {
            const item = this.draggableList.find((e) => e.id == id);
            item.remarks = remarks;
          });
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
          console.log(error);
        });
    },
    /**
     *
     * @param {Bool} isBulkUpdate
     */
    updateIsBulkUpdateRequirementCode(isBulkUpdate) {
      this.isBulkUpdateRequirementCodeValue = isBulkUpdate;
    },
    /**
     *
     * @param {Bool} isBulkUpdate
     */
    updateIsBulkUpdateProductCode(isBulkUpdate) {
      this.isBulkUpdateProductCodeValue = isBulkUpdate;
    },
    /**
     *
     * @param {Bool} isBulkUpdate
     */
    updateIsBulkUpdateShipmentWay(isBulkUpdate) {
      this.isBulkUpdateShipmentWayValue = isBulkUpdate;
    },
    /**
     *
     * @param {Bool} isBulkUpdate
     */
    updateIsBulkUpdateUserId(isBulkUpdate) {
      this.isBulkUpdateUserIdValue = isBulkUpdate;
    },
    /**
     *
     * @param {Bool} isBulkUpdate
     */
    updateIsBulkUpdateProductName2(isBulkUpdate) {
      this.isBulkUpdateProductName2Value = isBulkUpdate;
    },
    /**
     *
     * @param {Bool} isBulkUpdate
     */
    updateIsBulkUpdateRemarks(isBulkUpdate) {
      this.isBulkUpdateRemarksValue = isBulkUpdate;
    },
  },
};
</script>

<style>
.highlight {
  border: 3px solid red;
}
</style>