<template>
  <div class="table-responsive">
    <table class="table table-striped table-hover">
      <thead>
        <th v-for="header in headers" :key="header.id" scope="col">
          {{ header.text }}
        </th>
      </thead>
      <draggable v-model="draggableList" tag="tbody" @end="onDragged">
        <tr v-for="row in draggableList" :key="row.id">
          <td>
            {{ row.id }}
          </td>
          <td>
            {{ row.kentem_product_id }}
          </td>
          <td
            v-if="!isEditingProductName || editingIdForProductName !== row.id"
            @dblclick="startEditingProductName(row.id, row.product_name)"
          >
            {{ row.product_name }}
          </td>
          <td v-else>
            <input
              v-model="row.product_name"
              type="text"
              class="form-control"
              @change="updateProductName(row.id, row.product_name)"
            />
          </td>
          <td
            :class="
              updatedItems[row.id] &&
              updatedItems[row.id].includes('regular_price')
                ? 'red--text'
                : ''
            "
          >
            {{ row.regular_price | addComma }}
          </td>
          <td
            v-if="
              row.category !== 'B' &&
              (!isEditingQuantity || editingIdForQuantity !== row.id)
            "
            @dblclick="startEditingQuantity(row.id, row.quantity)"
          >
            {{ row.quantity }}
          </td>
          <td v-else-if="row.category !== 'B'">
            <input
              v-model="row.quantity"
              type="number"
              min="1"
              step="1"
              class="form-control"
              @change="updateQuantity(row.id, row.quantity)"
            />
          </td>
          <td v-else>
            {{ row.quantity }}
          </td>

          <td>
            {{ row.unit_price | addComma }}
          </td>
          <td
            :class="
              updatedItems[row.id] &&
              updatedItems[row.id].includes('partition_rate')
                ? 'red--text'
                : ''
            "
          >
            <v-container>
              <v-row align-content="center">
                <v-checkbox
                  v-if="isBulkUpdatePartitionRateValue"
                  v-model="bulkPartitionRateItems"
                  :value="row.id"
                  class="m-0 p-0"
                />
                {{ row.partition_rate }}
              </v-row>
            </v-container>
          </td>
          <td
            v-if="!isEditingVarietyId || editingIdForVarietyId !== row.id"
            :class="
              updatedItems[row.id] &&
              updatedItems[row.id].includes('variety_id')
                ? 'red--text'
                : ''
            "
            @dblclick="startEditingVarietyId(row.id, row.variety_id)"
          >
            {{ row.variety_id }}
          </td>
          <td v-else>
            <input
              v-model="row.variety_id"
              type="text"
              class="form-control"
              @change="updateVarietyId(row.id, row.variety_id)"
            />
          </td>
          <td>
            {{ row.oso_product_code }}
          </td>
          <td>
            <a :href="'/admin/product/edit/' + row.id"
              ><i class="fas fa-tools"
            /></a>
          </td>
          <td>
            <form :action="'/admin/product/delete/' + row.id" method="post">
              <input type="hidden" name="_method" value="DELETE" />
              <input type="hidden" name="_token" :value="csrf" />
              <v-checkbox
                v-if="isSelectiveDeletionValue"
                v-model="selectiveDeletionItems"
                :value="row.id"
                class="m-0 p-0"
              />
              <v-btn
                v-if="!isSelectiveDeletionValue"
                type="button"
                icon
                class="bg-gradient-danger shadow"
                @click="deleteItem(row.id)"
              >
                <i class="far fa-trash-alt" />
              </v-btn>
            </form>
          </td>
        </tr>
      </draggable>
    </table>
  </div>
</template>

<script>
import Axios from "axios";
import Draggable from "vuedraggable";

export default {
  components: {
    Draggable,
  },
  filters: {
    addComma: (val) => {
      return val.toLocaleString();
    },
  },
  props: {
    csrf: {
      type: String,
      required: true,
    },
    estimateId: {
      type: Number,
      default: null,
    },
    token: {
      type: String,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    isBulkUpdatePartitionRate: {
      type: Boolean,
      default: false,
    },
    isSelectiveDeletion: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      beforeProductName: "",
      beforeQuantity: 1,
      beforeVarietyId: "",
      bulkPartitionRateItems: [],
      draggableList: [],
      editingIdForProductName: 0,
      editingIdForQuantity: 0,
      editingIdForVarietyId: 0,
      isEditingProductName: false,
      isEditingQuantity: false,
      isEditingVarietyId: false,
      isBulkUpdatePartitionRateValue: false,
      isSelectiveDeletionValue: false,
      selectiveDeletionItems: [],
      totalAmountPrice: 0,
      updatedItems: [],
    };
  },
  watch: {
    items: function (newItems, _) {
      this.draggableList = newItems;
    },
  },
  created() {
    this.draggableList = this.items;
    this.queryUpdateProductItems(this.estimateId);
  },
  methods: {
    /**
     *
     * @param {*} _evt
     */
    onDragged(_evt) {
      this.draggableList.forEach((element, idx) => {
        Axios.post(
          `/api/v1/estimate/product/order/update`,
          {
            id: element.id,
            order: idx,
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        ).catch((error) => {
          console.log(error);
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
        });
      });
    },
    /**
     *
     * @param {Number} id
     * @param {Number} quantity
     */
    startEditingQuantity(id, quantity) {
      this.editingIdForQuantity = id;
      this.beforeQuantity = quantity;
      this.isEditingQuantity = true;
    },
    /**
     *
     * @param {Number} id
     * @param {String} name
     */
    startEditingProductName(id, name) {
      this.editingIdForProductName = id;
      this.beforeProductName = name;
      this.isEditingProductName = true;
    },
    /**
     *
     * @param {Number} id
     * @param {String} variety_id
     */
    startEditingVarietyId(id, variety_id) {
      this.editingIdForVarietyId = id;
      this.beforeVarietyId = variety_id;
      this.isEditingVarietyId = true;
    },
    /**
     *
     * @param {Number} id
     * @param {Number} quantity
     */
    updateQuantity(id, quantity) {
      if (
        isNaN(quantity) ||
        Math.sign(Number(quantity)) !== 1 ||
        !Number.isInteger(Number(quantity)) ||
        Number(quantity) === 0
      ) {
        alert("数量は1以上の整数でなければなりません");
        this.draggableList.find((e) => e.id == id).quantity =
          this.beforeQuantity;
        this.editingIdForQuantity = 0;
        this.isEditingQuantity = false;
        return;
      }
      Axios.patch(
        `/api/v1/estimate/product/quantity/update`,
        {
          id: id,
          quantity: quantity,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      )
        .then((res) => {
          if (res.data) {
            this.totalAmountPrice = res.data.total_amount;
            this.$emit("total-amount", this.totalAmountPrice);
          }
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
          console.log(error);
        });

      this.editingIdForQuantity = 0;
      this.isEditingQuantity = false;

      this.queryUpdateProductItems(this.estimateId);
    },
    /**
     *
     * @param {Number} id
     * @param {String} name
     */
    updateProductName(id, name) {
      Axios.patch(
        `/api/v1/estimate/product/product_name/update`,
        {
          id: id,
          name: name,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      ).catch((error) => {
        switch (error.response.status) {
          case 401:
            alert(
              "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
            );
            break;
          case 404:
            alert("接続先が見つかりませんでした。");
            break;
          case 422:
            alert(JSON.stringify(error.response.data.errors));
            break;
          case 500:
            alert("内部エラー");
            break;
          default:
            alert("通信エラーが発生しました、再度お試しください。");
            break;
        }
        console.log(error);
      });

      this.editingIdForProductName = 0;
      this.isEditingProductName = false;
    },
    /**
     *
     * @param {Number} id
     * @param {String} variety_id
     */
    updateVarietyId(id, variety_id) {
      Axios.patch(
        `/api/v1/estimate/product/variety_id/update`,
        {
          id: id,
          variety_id: variety_id,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      ).catch((error) => {
        switch (error.response.status) {
          case 401:
            alert(
              "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
            );
            break;
          case 404:
            alert("接続先が見つかりませんでした。");
            break;
          case 422:
            alert(JSON.stringify(error.response.data.errors));
            break;
          case 500:
            alert("内部エラー");
            break;
          default:
            alert("通信エラーが発生しました、再度お試しください。");
            break;
        }
        console.log(error);
      });

      this.editingIdForVarietyId = 0;
      this.isEditingVarietyId = false;

      this.queryUpdateProductItems(this.estimateId);
    },
    /**
     *
     * @param {Number} id
     */
    deleteItem(id) {
      if (!confirm("本当に削除しますか?")) {
        return;
      }
      this.deleteItemWithoutConfirm(id);
    },
    /**
     *
     * @param {Number} id
     */
    deleteItemWithoutConfirm(id) {
      Axios.delete(`/api/v1/estimate/product/delete/` + id, {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((res) => {
          if (res.data) {
            this.totalAmountPrice = res.data.total_amount;
            this.$emit("total-amount", this.totalAmountPrice);
          }
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
          console.log(error);
        });
      this.draggableList = this.draggableList.filter((e) => e.id !== id);
    },
    /**
     *
     * @param {Bool} status
     */
    changeStatusPartitionRateTarget(status) {
      this.bulkPartitionRateItems.splice(0, this.bulkPartitionRateItems.length);
      if (status) {
        this.bulkPartitionRateItems.push(
          ...this.draggableList.map((e) => e.id)
        );
      }
    },
    /**
     *
     * @param {Bool} status
     */
    changeStatusDeleteTarget(status) {
      this.selectiveDeletionItems.splice(0, this.selectiveDeletionItems.length);
      if (status) {
        this.selectiveDeletionItems.push(
          ...this.draggableList.map((e) => e.id)
        );
      }
    },
    /**
     *
     * @param {Number} partitionRate
     */
    bulkUpdatePartitionRate(partitionRate) {
      const idList = this.bulkPartitionRateItems;
      Axios.patch(
        `/api/v1/estimate/product/partition_rate/update/list`,
        {
          id_list: idList,
          partition_rate: partitionRate,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      )
        .then(() => {
          idList.forEach((id) => {
            const item = this.draggableList.find((e) => e.id == id);
            item.partition_rate = partitionRate;
            item.unit_price = (item.regular_price * item.partition_rate) / 100;
          });
          this.totalAmountPrice = this.draggableList.reduce(
            (sum, e) => sum + e.unit_price,
            0
          );
          this.$emit("total-amount", this.totalAmountPrice);
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
          console.log(error);
        });
    },
    /**
     * @param {Number} estimateId
     */
    queryUpdateProductItems(estimateId) {
      Axios.get(
        `/api/v1/audit/estimate_product/updated/estimate/` + estimateId,
        {
          headers: { Authorization: "Bearer " + this.token },
        }
      )
        .then((res) => {
          this.updatedItems = JSON.parse(JSON.stringify(res.data));
        })
        .catch((error) => {
          console.log(error);
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              if (
                error.response.data.message ===
                "Trying to get property 'partition_rate_code' of non-object"
              ) {
                alert("仕切率が設定されていません");
                break;
              }
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
        });
    },
    /**
     *
     * @param {Bool} isBulkUpdate
     */
    updateIsBulkUpdatePartitionRate(isBulkUpdate) {
      this.isBulkUpdatePartitionRateValue = isBulkUpdate;
    },
    /**
     *
     * @param {Bool} isSelective
     */
    updateIsSelectiveDeletion(isSelective) {
      this.isSelectiveDeletionValue = isSelective;
    },
    /**
     *
     */
    clearSelectiveDeletionItems() {
      this.selectiveDeletionItems = [];
    },
  },
};
</script>
