<template>
  <v-card class="mx-auto" outlined>
    <h2 class="mt-2 fs4">区分マスタ</h2>
    <v-simple-table class="editable-table mt-5">
      <thead>
        <tr>
          <th v-for="header in headers" :key="header.value">{{ header.text }}</th>
          <th v-if="canEdit">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in editableItems" :key="item.id">
          <td v-for="header in headers" :key="header.value" :style="header.value === 'name' ? 'width: 300px;' : 'max-width: 150px;'">
            <div v-if="!editMode">
              {{ item[header.value] }}
            </div>
            <div v-else>
              <v-textarea
                v-model="editableItems[index][header.value]"
                hide-details
                dense
                auto-grow
              ></v-textarea>
            </div>
          </td>
          <td v-if="canEdit">
            <v-icon v-if="!editMode" @click="enableEditMode">mdi-pencil</v-icon>
            <v-icon v-else @click="submitChanges">mdi-check</v-icon>
            <v-icon v-if="editMode" @click="cancelEdit">mdi-close</v-icon>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <!-- アップデート通知用のSnackbar -->
    <v-snackbar v-model="snackbar" timeout="3000" bottom right>
      {{ snackbarMessage }}
    </v-snackbar>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    canEdit: {
      type: Boolean,
      required: true,
    },
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    apiEndpoint: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    },
    segmentId: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      editMode: false,
      editableItems: JSON.parse(JSON.stringify(this.items)), // itemsのコピーをdataに保存
      originalItems: JSON.parse(JSON.stringify(this.items)), // 編集前のデータを保持
      snackbar: false, // Snackbarの表示制御
      snackbarMessage: '' // Snackbarに表示するメッセージ
    };
  },
  watch: {
    // itemsが変更された場合に、editableItemsを更新する
    items(newItems) {
      this.editableItems = JSON.parse(JSON.stringify(newItems));
    }
  },
  methods: {
    enableEditMode() {
      this.editMode = true;
      this.originalItems = JSON.parse(JSON.stringify(this.items)); // 編集前のデータを保持
    },
    cancelEdit() {
      this.editMode = false;
      this.editableItems = JSON.parse(JSON.stringify(this.originalItems)); // 元の値に戻す

      // キャンセル時にSnackbarを表示
      this.snackbarMessage = "キャンセルしました";
      this.snackbar = true;
    },
    async submitChanges() {
      // 変更がない場合、キャンセル扱いとする
      if (JSON.stringify(this.editableItems) === JSON.stringify(this.originalItems)) {
        this.cancelEdit();
        return;
      }

      try {
        const editedItem = this.editableItems[0]; // 編集したアイテム（最初の行）
        
        // axiosを使ってAPIリクエスト
        const response = await axios.post(this.apiEndpoint, {
          id: this.segmentId,
          ...editedItem
        }, {
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        });

        if (response.status !== 200) {
          throw new Error("Failed to update item.");
        }

        // データを更新し、編集モードを終了
        this.$emit('update-items', JSON.parse(JSON.stringify(this.editableItems))); // 親コンポーネントに変更を通知
        this.editMode = false;

        // 通知を表示
        this.snackbarMessage = "更新が成功しました！";
        this.snackbar = true;
        window.alert("更新が完了しました。");
        window.location.reload();

      } catch (error) {
        console.error("Error submitting changes:", error);

        // APIからのエラーレスポンスを確認
        if (error.response && error.response.data) {
          const apiErrorMessage = error.response.data.message || "更新に失敗しました。";
          this.snackbarMessage = apiErrorMessage;
        } else {
          this.snackbarMessage = "更新に失敗しました。";
        }
        
        this.snackbar = true;
      }
    }
  }
};
</script>

<style scoped>
.v-card {
  border: none;
  width: 1200px;
}
.v-icon {
  cursor: pointer;
}
.editable-table thead {
  background: #BBDEFB;
}
.editable-table th {
  font-weight: bold;
}
</style>