export default {
    "en": {
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "passwords": {
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "throttled": "Please wait before retrying.",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that e-mail address."
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "ends_with": "The {attribute} must end with one of the following: {values}.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "numeric": "The {attribute} must be greater than {value}.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "string": "The {attribute} must be greater than {value} characters.",
                "array": "The {attribute} must have more than {value} items."
            },
            "gte": {
                "numeric": "The {attribute} must be greater than or equal {value}.",
                "file": "The {attribute} must be greater than or equal {value} kilobytes.",
                "string": "The {attribute} must be greater than or equal {value} characters.",
                "array": "The {attribute} must have {value} items or more."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lt": {
                "numeric": "The {attribute} must be less than {value}.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "string": "The {attribute} must be less than {value} characters.",
                "array": "The {attribute} must have less than {value} items."
            },
            "lte": {
                "numeric": "The {attribute} must be less than or equal {value}.",
                "file": "The {attribute} must be less than or equal {value} kilobytes.",
                "string": "The {attribute} must be less than or equal {value} characters.",
                "array": "The {attribute} must not have more than {value} items."
            },
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "password": "The password is incorrect.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} are present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}.",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "uuid": "The {attribute} must be a valid UUID.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": []
        }
    },
    "ja": {
        "add": "追加",
        "address": "住所",
        "apply": "承認申請",
        "approve": "承認",
        "back": "戻る",
        "go to confirmation screen": "確認画面へ",
        "close": "閉じる",
        "Confirm Password": "パスワードの確認",
        "company name": "会社名",
        "company_name_kana": "会社名(カナ)",
        "complete": "完了",
        "connection error": "通信エラー",
        "copy": "コピーして新規作成",
        "delete": "削除",
        "delete ok?": "本当に削除しますか?",
        "basic": "基本",
        "detail": "詳細",
        "dekispart": "デキスパート",
        "dekisku": "デキスク",
        "construction_result": {
            "stdJID": "オート番号",
            "stdID_J": "デキスパート保守DB整理番号",
            "construction_result": "工事実績DB"
        },
        "innosite": {
            "stdiid": "オート番号",
            "stdid_i": "デキスパート保守DB整理番号",
            "innosite": "イノサイト"
        },
        "cloud": {
            "ID": "オート番号",
            "HoshuId": "整理番号",
            "ManagementCode": "クラウドID",
            "ShokonId": "商魂コード",
            "cloud": "クラウド"
        },
        "kjk": "KJK RevSIGN",
        "tsunaguweb": "TsunaguWeb",
        "lending_demo": "貸出・デモ版",
        "prodougu": "PRODOUGU",
        "xzero": "クロスゼロ",
        "other": "その他",
        "kentem_academy": "KENTEM ACADEMY",
        "proshare": "PROSHARE",
        "kjk_price": "KJK RevSIGN価格表",
        "all": "すべて",
        "E-Mail Address": "電子メールアドレス",
        "edit": "編集",
        "change": "変更",
        "csv": "csv出力",
        "order": "受注",
        "Forgot Your Password?": "パスワードを忘れましたか?",
        "If you’re having trouble clicking the \"{actionText}\" button, copy and paste the URL below\ninto your web browser: [{actionURL}]({actionURL})": "もし\"{actionText}\"ボタンをクリックできない場合は、以下のURLをコピー＆ペーストして、ブラウザから入力してください。[{actionURL}]({actionURL})",
        "issue": "発行",
        "item_ms": "商品マスタ",
        "kentem_id": "KentemID",
        "kentem_product": {
            "counter_word": "助数詞",
            "detail": "詳細",
            "list": "Kentem商品一覧",
            "classification": "商品区分",
            "partition_rate_code": "仕切率コード",
            "regular_price": "標準価格",
            "richo_price": "リコー仕切価格",
            "software_classification": "単体・LAN種別"
        },
        "loading": "読み込み中",
        "Login": "ログイン",
        "logout": "ログアウト",
        "mail": {
            "body": "本文",
            "form": "メールフォーム",
            "If you did not request a password reset, no further action is required.": "パスワードのリセットを要求しなかった場合、それ以上のアクションは不要です。",
            "If you miss the time, you can resend it form here.": "時間を過ぎてしまった場合、下記のURLから再送できます。",
            "Hello!": "こんにちは！",
            "msg_success_send": "メールの送信に成功しました！",
            "password_reset_notification": "パスワードリセット通知",
            "Regards": "よろしくお願いします。",
            "subject": "件名",
            "This password reset link will expire in {count} minutes.": "このパスワードリセットリンクの有効期限は{count}分です。",
            "to": "送信先メールアドレス",
            "You are receiving this email because we received a password reset request for your account.": "アカウントのパスワードリセットリクエストを受け取ったため、このメールを受信して​​います。"
        },
        "Mail Address": "メールアドレス",
        "mailform": "メールフォーム",
        "Name": "名前",
        "new": "新規",
        "note": "備考",
        "optional": "任意",
        "sales_reference": "関連参照",
        "oso_ms": "OSOコードマスタ",
        "password": "パスワード",
        "Password": "パスワード",
        "pdf": "見積確認",
        "pdf_transfer": "使用権譲渡",
        "pdf_dekispart_option": "使用権返却届",
        "pdf_protect_return_notice": "プロテクタ返却届",
        "pdf_automatic_transfer": "自振解除届",
        "Please confirm your password before continuing.": "続行する前にパスワードを確認してください。",
        "refer": "参照",
        "reject": "差し戻し",
        "Register": "登録",
        "reference": "参照",
        "Remember Me": "記憶させる",
        "Reset Password": "パスワードリセット",
        "resend": "再送",
        "required": "必須",
        "role": "ロール",
        "sale_ms": "営業所マスタ",
        "save": "保存",
        "search": "検索",
        "select": "選択",
        "set": "設定",
        "next to product": "商品情報入力へ",
        "version up": "バージョンアップ",
        "maintenance difference": "保守差額",
        "maintenance difference one year": "保守1年",
        "Send Password Reset Link": "パスワードリセットリンクを送信する",
        "set_product": "セット商品",
        "shokon_code": "商魂コード",
        "sk_ms": "商魂マスタ",
        "submit": "送信",
        "update": "更新",
        "No results found.": "結果が見つかりませんでした。",
        "estimate_sales_segment": {
            "sales": "販売店",
            "ricoh": "販売店（リコー）",
            "direct": "直販",
            "reese": "直販（リース）",
            "design_data": "販売店（建築資料）"
        },
        "user_role": {
            "guest": "無効",
            "administrator": "システム管理者",
            "sales_creator": "営業・作成者",
            "sales_chief": "営業所長",
            "sales_manager": "営業部長",
            "production_management_authorizer": "生産管理部・承認者",
            "production_management_creator": "生産管理部・作成者",
            "assistant": "作成者"
        },
        "estimate_protect_type": {
            "simple": "単体",
            "lan": "ＬＡＮ"
        },
        "passwords": {
            "reset": "あなたのパスワードはリセットされました！",
            "sent": "パスワードリセットリンクをメールで送信しました！",
            "throttled": "再試行する前にお待ちください。",
            "token": "このパスワードリセットトークンは無効です。",
            "user": "その電子メールアドレスを持つユーザーが見つかりません。"
        },
        "deposit_type": {
            "121": "自振",
            "122": "振込",
            "211": "指定販売店"
        },
        "product_information": {
            "id": "商品ID",
            "variety_code": "品種コード",
            "formal_name": "商品正式名称",
            "nominal_cost": "定価",
            "partition_rate": "仕切率",
            "shokon_code": "商魂商品コード",
            "shokon_name": "商魂商品名",
            "shokon_set_name": "商魂セット商品名",
            "original_id": "オリジナル商品ID",
            "list": "商品情報一覧"
        },
        "estimate_address_type": {
            "mr": "様",
            "co": "御中"
        },
        "auth": {
            "failed": "ログイン情報が登録されていません。",
            "throttle": "ログインに続けて失敗しています。{seconds}秒後に再度お試しください。"
        },
        "shokon_product_unit_price": {
            "smsp_scd": "商品コード",
            "smsp_pkbn": "単位区分",
            "smsp_hyo": "標準価格",
            "list": "商魂商品単価一覧"
        },
        "shokon_product": {
            "list": "商魂商品一覧",
            "sms_color": "色",
            "sms_comment": "コメント",
            "sms_from": "有効期間開始日",
            "sms_hinmei2": "商品名2",
            "sms_hketa": "箱数小数桁",
            "sms_id": "RESERVED",
            "sms_iketa": "入数小数桁",
            "sms_insdate": "登録Date",
            "sms_inspg": "登録PG",
            "sms_insuser": "登録ユーザー",
            "sms_iri": "入数",
            "sms_jsk": "実績管理",
            "sms_kanaidx": "RESERVED",
            "sms_kanpkbn": "仕入単位区分",
            "sms_kikaku": "規格・型番",
            "sms_konpkbn": "売上単位区分",
            "sms_kosin": "RESERVED",
            "sms_lkbn": "ロット管理",
            "sms_mei": "商品名",
            "sms_mei2": "RESERVED",
            "sms_mkbn": "マスター区分",
            "sms_rcd": "主仕入先コード",
            "sms_regicd": "バーコード",
            "sms_regino": "管理番号",
            "sms_scd": "商品コード",
            "sms_scd2": "商品コード2",
            "sms_scd3": "商品コード3",
            "sms_skbn1": "商品区分1コード",
            "sms_skbn2": "商品区分2コード",
            "sms_skbn3": "商品区分3コード",
            "sms_skbn4": "商品区分4コード",
            "sms_skbn5": "仕切率コード",
            "sms_sketa": "数量小数桁",
            "sms_skomoku1": "商品項目1",
            "sms_skomoku2": "商品項目2",
            "sms_skomoku3": "商品項目3",
            "sms_shasu": "数量端数",
            "sms_siki1": "売上計算式",
            "sms_siki2": "仕入計算式",
            "sms_size": "サイズ",
            "sms_souko": "倉庫コード",
            "sms_state": "使用区分",
            "sms_syskbn": "システム区分",
            "sms_tani": "単位",
            "sms_tketa": "単位小数桁",
            "sms_to": "有効期間終了日",
            "sms_upddate": "更新Date",
            "sms_updpg": "更新PG",
            "sms_upduser": "更新ユーザー",
            "sms_yc1": "予備",
            "sms_yc2": "予備",
            "sms_yc3": "予備",
            "sms_yi1": "予備",
            "sms_yi2": "予備",
            "sms_yi3": "予備",
            "sms_ym1": "予備",
            "sms_ym2": "予備",
            "sms_ym3": "予備",
            "sms_ys1": "予備",
            "sms_ys2": "予備",
            "sms_ys3": "予備",
            "sms_zkbn": "在庫区分",
            "sms_ztan": "在庫単価"
        },
        "kentem_information": {
            "kentem_id": "KentemID",
            "shokon_code": "商魂コード",
            "sales_name": "会社名",
            "address": "住所",
            "sales_name_f": "会社名(カナ)",
            "detail": "詳細",
            "info": "顧客・販売店情報詳細",
            "list_customer": "顧客情報一覧",
            "list_sales": "販売店情報一覧",
            "list": "顧客・販売店情報一覧",
            "select": "選択"
        },
        "product_series_type": {
            "dekispart": "デキスパート",
            "dekisku": "デキスク",
            "construction_result": "工事実績",
            "innosite": "INNOSiTE",
            "cloud": "クラウドサービス",
            "kjk": "KJK RevSIGN",
            "tsunagu_web": "TsunaguWeb",
            "prodougu": "PRODOUGU"
        },
        "direct_shipping_destination": {
            "yms_insdate": "登録Date",
            "yms_insuser": "登録ユーザー",
            "yms_inspg": "登録PG",
            "yms_tmstcd": "得意先コード",
            "yms_cmsid": "相手先ID",
            "yms_fmsid": "先方担当者ID",
            "yms_tcd": "セット商品コード",
            "yms_state": "使用区分",
            "yms_upddate": "更新Date",
            "yms_upduser": "更新ユーザー",
            "yms_updpg": "更新PG",
            "list": "直送先一覧"
        },
        "set_product_data": {
            "setd_no": "セット商品コード",
            "setd_eda": "枝番",
            "setd_scd": "商品コード",
            "setd_jsk": "実績管理",
            "setd_pkbn": "単位区分",
            "list": "セット商品（構成商品）一覧"
        },
        "master": {
            "m_id": "営業所ID"
        },
        "estimate_send_to": {
            "store": "販売店",
            "user": "ユーザー"
        },
        "caution": {
            "id": "ID",
            "series": "シリーズ",
            "caution_point": "注意点",
            "edit": "編集",
            "delete": "削除",
            "caution_title": "注意点",
            "estimate_id": "見積ID"
        },
        "user": {
            "chief_approver": "承認所長ID",
            "chief_approver_name": "承認所長",
            "create": "ユーザー作成",
            "created_at": "作成日時",
            "creator": "作成者ID",
            "creator_name": "作成者名",
            "delete": "削除",
            "deleted_at": "削除日時",
            "department_code": "部門コード",
            "department_name": "部門名",
            "detail": "詳細",
            "edit": "編集",
            "email": "メールアドレス",
            "email_verified_at": "メール認証日時",
            "given_name": "名",
            "id": "ID",
            "info": "ユーザー情報",
            "list": "ユーザー一覧",
            "manager_approver": "承認部長ID",
            "manager_approver_name": "承認部長",
            "msg_success_store": "ユーザーの作成に成功しました",
            "msg_success_update": "ユーザーの更新に成功しました",
            "msg_success_destroy": "ユーザーの削除に成功しました",
            "name": "名前",
            "named info": "{name} の情報",
            "note": "備考",
            "role": "ロール",
            "role_name": "ロール名",
            "staff_code": "担当者コード",
            "surname": "性",
            "updated_at": "更新日時",
            "updated_by": "更新者ID",
            "updated_by_name": "更新者名"
        },
        "kentem_product_software_classification": {
            "simple": "単体",
            "lan1": "LAN",
            "lan3": "LAN",
            "lan4": "LAN",
            "etc": "登録なし"
        },
        "estimate_send_way": {
            "fax": "FAX",
            "email": "メール",
            "by_mail": "郵送"
        },
        "original_product_information": {
            "id": "オリジナル商品ID",
            "product_name": "商品名",
            "list": "オリジナル商品情報一覧"
        },
        "staff": {
            "department_name": "部門名",
            "emst_bmn": "部門コード",
            "emst_kbn": "担当者コード",
            "emst_kosin": "RESERVED",
            "emst_str": "担当者名",
            "emst_upddate": "更新Date",
            "list": "担当者一覧"
        },
        "estimate_approval_presence": {
            "need_approval": "必須",
            "no_need_approval": "不要"
        },
        "destination": {
            "cms_ad1": "住所１",
            "cms_ad2": "住所２",
            "cms_ccd1": "企業コード1",
            "cms_ccd2": "企業コード2",
            "cms_comment": "コメント",
            "cms_hojin": "法人番号",
            "cms_insdate": "登録Date",
            "cms_insuser": "登録ユーザー",
            "cms_inspg": "登録PG",
            "cms_mailad": "会社メールアドレス",
            "cms_fax": "会社FAX",
            "cms_keisyo": "会社敬称",
            "cms_id": "ID",
            "cms_kanaidx": "カナ索引",
            "cms_mei1": "名称１",
            "cms_mei2": "名称２",
            "cms_yms": "直送先フラグ",
            "cms_tms": "得意先フラグ",
            "cms_dms": "決済会社フラグ",
            "cms_xms": "出荷先フラグ",
            "cms_rms": "仕入先フラグ",
            "cms_zero": "コード0フラグ",
            "cms_mail": "郵便番号",
            "cms_tel": "会社TEL",
            "cms_upddate": "更新Date",
            "cms_upduser": "更新ユーザー",
            "cms_updpg": "更新PG",
            "list": "相手先一覧"
        },
        "set_product_header": {
            "seth_insdate": "登録Date",
            "seth_insuser": "登録ユーザー",
            "seth_inspg": "登録PG",
            "seth_no": "セット商品コード",
            "seth_mei": "セット商品名",
            "seth_upddate": "更新Date",
            "seth_upduser": "更新ユーザー",
            "seth_updpg": "更新PG",
            "list": "セット商品（ヘッダー）一覧"
        },
        "maintenance_information": {
            "stdSale1": "販売店コード1",
            "stdSaleNam1": "販売店名1",
            "stdID": "ID",
            "stdName": "ユーザー名1",
            "stdName2": "ユーザー名2",
            "stdNamCode": "商魂コード",
            "stdNamef": "ユーザー名フリガナ",
            "list": "保守DB基本情報一覧"
        },
        "oso": {
            "仕切価格": "仕切価格",
            "品種コード": "品種コード",
            "メーカー商品コード": "メーカー商品コード",
            "商品名": "OSO 商品名",
            "製商品名（カナ）": "製商品名（カナ）",
            "定価": "定価",
            "原価": "原価",
            "csv": "CSV",
            "list": "OSO 一覧",
            "msg_success_store": "アップロードに成功しました",
            "upload": "OSO CSVアップロード"
        },
        "estimate_deal_method": {
            "default": "デフォルト",
            "reese": "リース"
        },
        "pegination": {
            "previous": "&laquo; 前",
            "next": "次 &raquo"
        },
        "estimate_price": {
            "normal": "通常",
            "ten_off": "10%オフ",
            "twenty_off": "20%オフ",
            "single": "単体"
        },
        "dekispart_school": {
            "payuserid": "オート番号",
            "stdID_D": "デキスパート保守DB整理番号"
        },
        "validation": {
            "accepted": "{attribute}を承認してください。",
            "active_url": "{attribute}が有効なURLではありません。",
            "after": "{attribute}には、{date}より後の日付を指定してください。",
            "after_or_equal": "{attribute}には、{date}以降の日付を指定してください。",
            "alpha": "{attribute}はアルファベットのみがご利用できます。",
            "alpha_dash": "{attribute}はアルファベットとダッシュ(-)及び下線(_)がご利用できます。",
            "alpha_num": "{attribute}はアルファベット数字がご利用できます。",
            "array": "{attribute}は配列でなくてはなりません。",
            "before": "{attribute}には、{date}より前の日付をご利用ください。",
            "before_or_equal": "{attribute}には、{date}以前の日付をご利用ください。",
            "between": {
                "numeric": "{attribute}は、{min}から{max}の間で指定してください。",
                "file": "{attribute}は、{min} kBから、{max} kBの間で指定してください。",
                "string": "{attribute}は、{min}文字から、{max}文字の間で指定してください。",
                "array": "{attribute}は、{min}個から{max}個の間で指定してください。"
            },
            "boolean": "{attribute}は、trueかfalseを指定してください。",
            "confirmed": "{attribute} の確認が一致しません。",
            "date": "{attribute}には有効な日付を指定してください。",
            "date_equals": "{attribute}には、{date}と同じ日付けを指定してください。",
            "date_format": "{attribute}は{format}形式で指定してください。",
            "different": "{attribute}と{other}には、異なった内容を指定してください。",
            "digits": "{attribute}は{digits}桁で指定してください。",
            "digits_between": "{attribute}は{min}桁から{max}桁の間で指定してください。",
            "dimensions": "{attribute}の図形サイズが正しくありません。",
            "distinct": "{attribute}には異なった値を指定してください。",
            "email": "{attribute} は有効な電子メールアドレスでなければなりません。",
            "ends_with": "{attribute}には、{values}のどれかで終わる値を指定してください。",
            "exists": "入力された{attribute}に対応するレコードが存在しません。",
            "file": "{attribute}にはファイルを指定してください。",
            "filled": "{attribute} は必須の項目です。",
            "gt": {
                "numeric": "{attribute}には、{value}より大きな値を指定してください。",
                "file": "{attribute}には、{value} kBより大きなファイルを指定してください。",
                "string": "{attribute}は、{value}文字より長く指定してください。",
                "array": "{attribute}には、{value}個より多くのアイテムを指定してください。"
            },
            "gte": {
                "numeric": "{attribute}には、{value}以上の値を指定してください。",
                "file": "{attribute}には、{value} kB以上のファイルを指定してください。",
                "string": "{attribute}は、{value}文字以上で指定してください。",
                "array": "{attribute}には、{value}個以上のアイテムを指定してください。"
            },
            "image": "{attribute}には画像ファイルを指定してください。",
            "in": "不正な{attribute}が入力されました。",
            "in_array": "{attribute}には{other}の値を指定してください。",
            "integer": "{attribute}は整数で指定してください。",
            "ip": "{attribute}には、有効なIPアドレスを指定してください。",
            "ipv4": "{attribute}には、有効なIPv4アドレスを指定してください。",
            "ipv6": "{attribute}には、有効なIPv6アドレスを指定してください。",
            "json": "{attribute}には、有効なJSON文字列を指定してください。",
            "lt": {
                "numeric": "{attribute}には、{value}より小さな値を指定してください。",
                "file": "{attribute}には、{value} kBより小さなファイルを指定してください。",
                "string": "{attribute}は、{value}文字より短く指定してください。",
                "array": "{attribute}には、{value}個より少ないアイテムを指定してください。"
            },
            "lte": {
                "numeric": "{attribute}には、{value}以下の値を指定してください。",
                "file": "{attribute}には、{value} kB以下のファイルを指定してください。",
                "string": "{attribute}は、{value}文字以下で指定してください。",
                "array": "{attribute}には、{value}個以下のアイテムを指定してください。"
            },
            "max": {
                "numeric": "{attribute}には、{max}以下の数字を指定してください。",
                "file": "{attribute}には、{max} kB以下のファイルを指定してください。",
                "string": "{attribute}は、{max}文字以下で指定してください。",
                "array": "{attribute}は{max}個以下指定してください。"
            },
            "mimes": "{attribute}には{values}タイプのファイルを指定してください。",
            "mimetypes": "{attribute}には{values}タイプのファイルを指定してください。",
            "min": {
                "numeric": "{attribute}は {min} 以上の数字でなければなりません。",
                "file": "{attribute}には、{min} kB以上のファイルを指定してください。",
                "string": "{attribute} は少なくとも {min} 文字以上でなければなりません。",
                "array": "{attribute}は{min}個以上指定してください。"
            },
            "not_in": "選択された{attribute}は正しくありません。",
            "not_regex": "{attribute}の形式が正しくありません。",
            "numeric": "{attribute} は数値でなければなりません。",
            "password": "パスワードが不正です。",
            "present": "{attribute}が存在していません。",
            "regex": "{attribute}に正しい形式を指定してください。",
            "required": "{attribute} は入力必須です。",
            "required_if": "{other}が{value}の場合、{attribute}も指定してください。",
            "required_unless": "{other}が{values}でない場合、{attribute}を指定してください。",
            "required_with": "{values}を指定する場合は、{attribute}も指定してください。",
            "required_with_all": "{values}を指定する場合は、{attribute}も指定してください。",
            "required_without": "{values}を指定しない場合は、{attribute}を指定してください。",
            "required_without_all": "{values}のどれも指定しない場合は、{attribute}を指定してください。",
            "same": "{attribute}と{other}には同じ値を指定してください。",
            "size": {
                "numeric": "{attribute}は{size}を指定してください。",
                "file": "{attribute}のファイルは、{size}キロバイトでなくてはなりません。",
                "string": "{attribute}は{size}文字で指定してください。",
                "array": "{attribute}は{size}個指定してください。"
            },
            "starts_with": "{attribute}には、{values}のどれかで始まる値を指定してください。",
            "string": "{attribute} は文字列でなければなりません。",
            "timezone": "{attribute}には、有効なゾーンを指定してください。",
            "unique": "その{attribute}は既に使用されています。",
            "uploaded": "{attribute}のアップロードに失敗しました。",
            "url": "{attribute}に正しい形式を指定してください。",
            "uuid": "{attribute}に有効なUUIDを指定してください。",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": {
                "chief_approver": "承認所長ID",
                "email": "メールアドレス",
                "mail_body": "本文",
                "mail_subject": "件名",
                "mail_to": "送信先メールアドレス",
                "manager_approver": "承認部長ID",
                "name": "名前",
                "note": "備考",
                "password": "パスワード",
                "role": "ロール",
                "department_code": "部門コード",
                "staff_code": "担当者コード"
            }
        },
        "estimate": {
            "id": "見積ID",
            "name": "見積書情報",
            "sales_kentem_id": "販売店KentemID",
            "actnm": "エンドユーザー",
            "customer_name": "エンドユーザー",
            "sales_name": "販売店名",
            "rcvplndt": "受注予定日",
            "rcvplndt_start": "受注予定日（指定日以降）",
            "rcvplndt_end": "受注予定日（指定日以前）",
            "receive_order_plan_date": "受注予定日",
            "slNm": "販売店",
            "sts": "ステータス",
            "status": "ステータス",
            "crt": "作成者",
            "zip": "郵便番号",
            "creator": "作成者",
            "slrpsid": "担当営業ID",
            "sales_reps_id": "担当営業ID",
            "contact_name": "担当者名",
            "order_number": "注文番号",
            "slrps": "担当営業",
            "exprdt": "有効期限",
            "expiration_date": "有効期限",
            "sndwy": "送信方法",
            "send_way": "送信方法",
            "send_way_name": "送信方法",
            "sndto": "送付先",
            "send_to": "送付先",
            "send_to_name": "送付先",
            "typ": "タイプ",
            "type": "タイプ",
            "type_name": "タイプ",
            "slsgmnt": "見積形式",
            "sales_segment": "見積形式",
            "sales_segment_name": "見積形式",
            "txrt": "消費税率",
            "rmrks": "備考",
            "remarks": "備考",
            "rcvordrdt": "受注日",
            "receive_order_date": "受注日",
            "isno": "案件No.",
            "issue_no": "案件No.",
            "endusr": "エンドユーザー様",
            "mailsnddt": "メール送信日",
            "approver": "承認者",
            "dlmthd": "取引方法",
            "deal_method": "取引方法",
            "deal_method_name": "取引方法",
            "created_at": "作成日",
            "updated_at": "更新日",
            "status_name": "ステータス名",
            "addrsstyp": "宛名タイプ",
            "address_type": "宛名タイプ",
            "address_type_name": "宛名タイプ",
            "user_id": "ユーザーID",
            "apprvr_prsnc": "承認有無",
            "approval_presence": "承認有無",
            "approval_presence_name": "承認有無",
            "total_amount_tax": "税込合計金額",
            "total_amount": "合計金額",
            "tax_amount": "消費税額",
            "prdct_id": "商品ID",
            "prdct_name": "商品名",
            "nmbr": "個数",
            "unit": "単位",
            "nominal_cost": "定価",
            "regular_price": "定価",
            "partition_rate": "御仕切率",
            "amount": "金額",
            "variety_code": "品種コード",
            "product_information": "商品情報",
            "product_id": "商品ID",
            "product_name": "商品名",
            "number": "個数",
            "price": "価格",
            "unit_price": "単価",
            "group_id": "グループID",
            "group_version": "グループバージョン",
            "protect_type": "プロテクトタイプ",
            "db_protect_type": "現在のプロテクトタイプ",
            "form_protect_type": "変更後のプロテクトタイプ",
            "addressee": "送付先担当者",
            "sales representative": "担当営業",
            "edit": "編集",
            "delete": "削除",
            "duplicate_permission": "重複登録許可",
            "product_series": "商品シリーズ",
            "ID": "ID",
            "is_canceled": "キャンセル済みか",
            "chief_id": "所長ID",
            "manager_id": "部長ID",
            "business_no": "商談番号",
            "manager_approval_necessity": "部長承認の必要性",
            "maintenance_membership": "会員タイプ",
            "previous_maintenance_membership": "以前の会員タイプ",
            "previous_maintenance_difference_calculation_price": "以前の保守差額計算対象商品合計金額",
            "customer_kentem_id": "エンドユーザーKentemID",
            "reject_reason": "差し戻し事由",
            "remaining_months": "デキスパートの残月数",
            "construction_result_remaining_months": "工事実績の残月数",
            "databank_remaining_months": "データバンクの残月数",
            "fieldnet_remaining_months": "フィールドネットの残月数",
            "kaisoku_navi_remaining_months": "快速ナビの残月数",
            "reference_number": "整理番号",
            "sitebox_remaining_months": "サイトボックスの残月数",
            "sitebox_tunnel_remaining_months": "サイトボックストンネルの残月数",
            "innosite_remaining_months": "イノサイトの残月数",
            "tax_rate": "消費税率",
            "msg_success_apply": "承認申請がされました",
            "msg_success_approve": "承認がされました",
            "msg_success_create": "作成がされました",
            "msg_success_modify": "編集がされました",
            "msg_success_reject": "差し戻しがされました",
            "msg_success_save": "一時保存されました"
        },
        "estimate_product": {
            "id": "見積商品ID",
            "estimate_id": "見積ID",
            "kentem_product_id": "Kentem商品ID",
            "quantity": "個数",
            "cloud_id": "紐づけ先クラウドID",
            "kaisoku_navi_id": "紐づけ先快測ナビID",
            "created_at": "作成日",
            "updated_at": "更新日",
            "name": "商品名",
            "price": "金額",
            "counter_word": "単位",
            "partition_rate": "仕切率(%)",
            "partition_rate_alias": "仕切率(%)",
            "regular_price": "定価",
            "regular_price_alias": "定価",
            "unit_price": "単価",
            "edit": "編集",
            "delete": "削除",
            "is_maintenance_difference_calculation": "保守差額計算対象か",
            "oso_product_code": "OSO商品コード",
            "is_difference": "差額商品か",
            "classification": "値引区分",
            "category": "商品区分",
            "series_code": "シリーズ区分",
            "remarks": "注意点",
            "product_name": "商品名",
            "is_set_product": "セット商品群か",
            "variety_id": "ID"
        },
        "price": {
            "id": "ID",
            "style": "様式",
            "prefecture": "県",
            "registation_fee": "登録料",
            "monthly_usage": "月額利用料",
            "functional_requirements": "機能要件",
            "price_title": "KJKの地域ごとの価格",
            "edit": "編集",
            "delete": "削除"
        },
        "product_owned_customer": {
            "itmID": "オート番号",
            "itmUser": "デキスパート保守DB整理番号"
        },
        "department": {
            "emsb_siwake": "会計部門コード",
            "emsb_kbn": "部門コード",
            "emsb_kosin": "RESERVED",
            "emsb_str": "部門名",
            "emsb_upddate": "更新Date",
            "emsb_souko": "倉庫コード",
            "list": "部門一覧"
        },
        "estimate_type": {
            "new": "新規",
            "add": "追加"
        },
        "estimate_status": {
            "draft": "下書き",
            "reject": "差し戻し",
            "unapproved": "未承認",
            "approved": "見積確定",
            "order_deposit": "見積確定(入金待ち)",
            "order": "受注",
            "shipment": "出荷",
            "delete": "破棄"
        },
        "shipment_way_statuses": {
            "hapilogi": "奈良委託_ヤマト",
            "hapilogi_mqg": "奈良委託_ヤマト_MQR",
            "shipping_from_headquarters_nittu": "富士本社_日通",
            "shipping_from_headquarters_yamato": "富士本社_ヤマト",
            "postal_mail": "転送処理",
            "billing_only": "請求のみ",
            "kjk": "KJK",
        },
        "shipment_statuses": {
            "draft": "出荷指示作成中",
            "in_progress": "出荷指示完了",
            "first_check": "①チェック完了",
            "second_check": "②チェック完了",
            "shipping_completed": "出荷",
            "shipping_cancel":"出荷キャンセル",
        }
    }
}
