<template>
  <v-card flat>
    <v-card-text class="row">
      <div class="col-xs-12 col-sm-12 col-md-12">
        <div
          v-if="errors.length"
          class="alert alert-danger"
        >
          <ul>
            <li
              v-for="e in errors"
              :key="e"
            >
              {{ e }}
            </li>
          </ul>
        </div>
        <div
          v-if="msg !== null"
          class="alert alert-info"
        >
          <p>{{ $msg }}</p>
        </div>
        <form
          method="post"
          @submit.prevent="callback"
        >
          <div class="col-xs-12 col-sm-6 col-md-6">
            <slot />
            <input
              type="hidden"
              name="number"
              value="1"
            >
            <input
              type="hidden"
              name="estimate_id"
              :value="estimateId || ''"
            >
            <input
              type="hidden"
              name="kentem_id"
              :value="kentemId || ''"
            >
            <input
              type="hidden"
              name="_token"
              :value="csrf"
            >
            <div class="row col-xs-12 col-sm-6 col-md-6">
              <button
                class="btn btn-primary bg-gradient-primary btn-block"
                type="submit"
              >
                {{ $t(buttonLabel) }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    buttonLabel: {
      type: String,
      required: false,
      default: "Register",
    },
    callback: {
      type: Function,
      require: true,
      default: () => 1,
    },
    csrf: {
      type: String,
      required: true,
    },
    errors: {
      type: Object,
      default: () => {},
    },
    estimateId: {
      type: Number,
      default: null,
    },
    kentemId: {
      type: String,
      required: true,
    },
    msg: {
      type: String,
      default: null,
    },
  },
};
</script>
