<template>
    <v-card class="mt-2">
        <v-card-text>
            <div class="col-xs-12 col-sm-6 col-md-6 py-0">
                <ref-shippmentdate-set
                    :date-for-shipping-date-calculation="dateForShippingDateCalculation"
                    :time="time"
                    :old-shipment-date="oldShipmentDate"
                    :old-billing-date="oldBillingDate"
                    :old-sales-date="oldSalesDate"
                    :is-modify="isModify"
                    :token="apiToken"
                    :is-production-management="isProductionManagement"
                >
                </ref-shippmentdate-set>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-6 py-0">
                <label class="col-form-label">
                <span>{{ jaSalesRepresentative }}</span>
                    <span v-if="salesRepresentativeRequired" class="badge badge-primary">{{
                        $t("required")
                        }}</span>
                    <span v-else class="badge badge-secondary">{{
                        $t("optional")
                        }}</span>
                </label>
                <div class="form-group" style="background-color:white;">
                    <select-list
                        v-bind:options="staffOptions"
                        :value="salesRepresentativeSelected"
                        :name="'sales_representative'"
                    ></select-list>
                </div>
            </div>
            <input type="hidden" name="estimate_id" :value="estimateId" />
            <input type="hidden" name="id" :value="estimateOrderId" />
            <div>
                <p class="btm1"></p>
            </div>

            <h1>発送先情報</h1>
            <div>
                <p class="btm1"></p>
            </div>

            <div v-if="genericSalesNotice" class="alert alert-success">
                <strong>{{ genericSalesNotice }}</strong>
            </div>

            <div class="form-row">
                <ref-shippingset
                    :kentem-id-url="kentemIdUrl"
                    :customer-url="customerUrl"
                    :kentem-id="customerKentemId"
                    :contact-name="contactName"
                    :customer-default-value="customerDefaultValue"
                    :sales-kentem-id="salesKentemId"
                    :has-new-cloud-product="hasNewCloudProduct"
                    :api-token="apiToken"
                    :estimate-order="estimateOrder"
                    :is-non-users-order="isNonUsersOrder"
                    :dealer-default-value="dealerDefaultValue"
                    :old-shipping-company="oldShippingCompany"
                    :old-shipping-shokon-code="oldShippingShokonCode"
                    :old-shipping-zip="oldShippingZip"
                    :old-shipping-contact-name="oldShippingContactName"
                    :old-shipping-cloud-mail="oldShippingCloudMail"
                    :old-shipping-dekisuku-mail="oldShippingDekisukuMail"
                    :old-shipping-address-line="oldShippingAddressLine"
                    :old-shipping-address-line-second="oldShippingAddressLineSecond"
                    :old-shipping-tel="oldShippingTel"
                    :is-confirm-back="isConfirmBack"
                    :btn-enable="btnEnable"
                    @update-btn-enable="handleBtnEnableChange"
                >
                </ref-shippingset>
            </div>

            <h1>請求先情報</h1>
            <div>
                <p class="btm1"></p>
            </div>

            <div class="form-row">
                <ref-billingset
                    :kentem-id-url="kentemIdUrl"
                    :customer-url="customerUrl"
                    :dealer-url="dealerUrl"
                    :reese-url="reeseUrl"
                    :kentem-id="salesKentemId"
                    :customer-kentem-id="customerKentemId"
                    :contact-name="contactName"
                    :dealer-default-value="dealerDefaultValue"
                    :api-token="apiToken"
                    :estimate-order="estimateOrder"
                    :sales-segment="salesSegment"
                    :old-billing-company="oldBillingCompany"
                    :old-billing-shokon-code="oldBillingShokonCode"
                    :old-billing-zip="oldBillingZip"
                    :old-billing-address-line="oldBillingAddressLine"
                    :old-billing-address-line-second="oldBillingAddressLineSecond"
                    :old-billing-contact-name="oldBillingContactName"
                    :old-reese-company-name="oldReeseCompanyName"
                    :old-reese-company-shokon-code="oldReeseCompanyShokonCode"
                    :is-confirm-back="isConfirmBack"
                >
                </ref-billingset>
            </div>

            <div class="form-row">
                <div class="col-xs-12 col-sm-6 col-md-6 py-0">
                    <div class="form-group">
                        <label class="col-form-label">
                            <span>{{ jaBillingOrderNumberNotice }}</span>
                            <span class="badge badge-primary">{{ $t("required") }}</span>
                        </label>
                        
            <input
              type="text"
              :value="billingOrderNumber"
              name="billing_order_number"
              class="form-control"
            />
                    </div>

                    <ref-input-remarks
                        :id-name="idName"
                        :name="name"
                        :required="false"
                        :label="$t('備考 ※生産管理部への連絡事項がある場合はこちらに記載してください。')"
                        :estimate-order="estimateOrder"
                        :old-remarks="oldRemarks"
                        :is-confirm-back="isConfirmBack"
                    >
                    </ref-input-remarks>
                </div>
            </div>

            <div class="form-row">
                <div class="col-xs-12 col-sm-2 col-md-2 py-0">
                    <v-btn 
                      :color="btnColor"
                      class="btn-block"
                      type="submit"
                      :formaction="btnAction"
                      :disabled="!btnEnable">
                      <span style="color: white">
                        {{ btnEnable ? btnText : '読み込み中' }}
                      </span>
                    </v-btn>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import refShippingSet from './ref-shippingset.vue';
import refBillingSet from './ref-billingset.vue';

export default {
  components: {
    refShippingSet,
    refBillingSet
  },
  props: {
        dateForShippingDateCalculation: {
            type: String,
            default: "",
        },
        time: {
            type: String,
            default: "",
        },
        oldShipmentDate: {
            type: [String, null],
            default: null,
        },
        oldBillingDate: {
            type: [String, null],
            default: null,
        },
        oldSalesDate: {
            type: [String, null],
            default: null,
        },
        isModify: {
            type: Boolean,
            default: false,
        },
        token: {
            type: String,
            required: true,
        },
        isProductionManagement: {
            type: Boolean,
            default: false,
        },
    kentemIdUrl: {
      type: String,
      default: "https://id.kentem.com",
    },
    customerUrl: {
      type: String,
      default:
        "https://id.kentem.com" + "/api/v1/kentem_information/data_table",
    },
    customerKentemId: {
      type: String,
      default: null,
    },
    salesKentemId: {
      type: String,
      default: null,
    },
    contactName: {
      type: String,
      default: "contact_name",
    },
    customerDefaultValue: {
      type: String,
      default: "customer_name",
    },
    hasNewCloudProduct: {
      type: Number,
      default: null,
    },
    estimateOrder: {
      type: Object,
      default: () => {},
    },
    isNonUsersOrder: {
      type: Number,
      default: "is_non_users_order",
    },
    dealerDefaultValue: {
      type: String,
      default: "name",
    },
    oldShippingCompany: {
      type: String,
      default: "",
    },
    oldShippingShokonCode: {
      type: String,
      default: "",
    },
    oldShippingZip: {
      type: String,
      default: "",
    },
    oldShippingContactName: {
      type: String,
      default: "",
    },
    oldShippingCloudMail: {
      type: String,
      default: "",
    },
    oldShippingDekisukuMail: {
      type: String,
      default: "",
    },
    oldShippingAddressLine: {
      type: String,
      default: "",
    },
    oldShippingAddressLineSecond: {
      type: String,
      default: "",
    },
    oldShippingTel: {
      type: String,
      default: "",
    },
    isConfirmBack: {
      type: Number,
      default: 0,
    },
    dealerUrl: {
      type: String,
      default: "https://id.kentem.com" + "/api/v1/kentem_sales/data_table",
    },
    reeseUrl: {
      type: String,
      default: "https://id.kentem.com" + "/api/v1/reese/data_table",
    },
    salesSegment: {
      type: Number,
      default: null,
    },
    oldBillingCompany: {
      type: String,
      default: "",
    },
    oldBillingShokonCode: {
      type: String,
      default: "",
    },
    oldBillingZip: {
      type: String,
      default: "",
    },
    oldBillingAddressLine: {
      type: String,
      default: "",
    },
    oldBillingAddressLineSecond: {
      type: String,
      default: "",
    },
    oldBillingContactName: {
      type: String,
      default: "",
    },
    oldReeseCompanyName: {
      type: String,
      default: "",
    },
    oldReeseCompanyShokonCode: {
      type: String,
      default: "",
    },
    idName: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    oldRemarks: {
      type: String,
      default: "",
    },
    staffOptions: {
      type: Array,
      default: null,
    },
    salesRepresentativeSelected: {
      type: String,
      default: "",
    },
    genericSalesNotice: {
      type: String,
      default: "",
    },
    estimateId: {
      type: String,
      default: "",
    },
    estimateOrderId: {
      type: Number,
      default: "",
    },
    jaBillingOrderNumberNotice: {
      type: String,
      default: "",
    },
    jaSalesRepresentative: {
      type: String,
      default: "",
    },
    billingOrderNumber: {
      type: String,
      default: "",
    },
    salesRepresentativeRequired: {
      type: Boolean,
      default: true,
    },
    apiToken: {
      type: String,
      required: true,
    },
    btnText: {
      type: String,
      default: "",
    },
    btnColor: {
      type: String,
      default: "",
    },
    btnAction: {
      type: String,
      default: "",
    },
    },
    data() {
        return {
            btnEnable: false,
        };
    },
    methods: {
      handleBtnEnableChange(newStatus) {
        this.btnEnable = newStatus; // 子コンポーネントからの変更を受け取る
      }
    }
};
</script>
