<template>
  <div class="col-xs-12 col-sm-12 col-md-12 py-0">
    <div class="form-row">
      <div class="col-xs-12 col-sm-6 col-md-6 py-0">
        <div class="form-group">
          <label class="col-form-label">
            <span>{{ dealerLabelData }}</span>
            <span
              v-if="dealerRequired"
              class="badge badge-primary"
            >{{
              $t("required")
            }}</span>
            <span
              v-else
              class="badge badge-secondary"
            >{{
              $t("optional")
            }}</span>
          </label>
          <input
            type="text"
            name="labelDealerName"
            :value="dealerLabelValue"
            :readonly="true"
            class="form-control"
          >
          <input
            type="hidden"
            :name="dealerName"
            :value="dealerFormValue"
            :required="dealerRequired"
            readonly="readonly"
          >
          <v-dialog
            v-model="dealerDialog"
            persistent
            scrollable
            max-width="1000px"
            style="z-index: 1050"
          >
            <template #activator="{ on }">
              <v-btn
                color="primary"
                dark
                v-on="on"
              >
                {{
                  $t("reference")
                }}
              </v-btn>
            </template>
            <v-card>
              <v-toolbar
                color="light-blue darken-4"
                dark
              >
                <v-toolbar-title>{{ dealerTitleData }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon>
                  <v-icon
                    @click="dealerDialog = false"
                  >
                    fas fa-window-close
                  </v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <strong><span style="color:red;">弊社ユーザー以外への売上計上は、【P1241095　C70544　汎用売上】を選択下さい。</span></strong>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <ref-customerset-customercard
                        :callback="selectDealerItem"
                        :customer-headers="dealerHeaders"
                        :customer-items="dealerItems"
                        :customer-loading="dealerLoading"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dealerDialog = false"
                >
                  {{ $t("close") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import Token from "./token";

export default {
  props: {
    dealerDefaultLabelName: {
      type: String,
      default: "name",
    },
    dealerDefaultValue: {
      type: String,
      default: "",
    },
    dealerIdName: {
      type: String,
      default: "kentem_id",
    },
    dealerLabel: {
      type: String,
      default: "",
    },
    dealerLabelKey: {
      type: String,
      default: "name",
    },
    dealerName: {
      type: String,
      default: "sales_kentem_id",
    },
    dealerRequired: {
      type: Boolean,
      default: true,
    },
    dealerTitle: {
      type: String,
      default: "",
    },
    dealerUrl: {
      type: String,
      default: "https://id.kentem.com" + "/api/v1/kentem_sales/data_table",
    },
    kentemIdUrl: {
      type: String,
      default: "https://id.kentem.com",
    },
    old: {
      type: [Object, Array],
      default: () => {},
    },
    kentemIdDefault: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      bearer: "",
      dealerDefaultLabel: this.$i18n.translate("販売店"),
      dealerDefaultTitle: this.$i18n.translate("販売店情報一覧"),
      dealerDialog: false,
      dealerFormValue: this.dealerDefaultValue,
      dealerHeaders: [],
      dealerItems: [],
      dealerLabelData: "",
      dealerLabelName: this.dealerDefaultLabelName,
      dealerLabelValue: "",
      dealerLoading: true,
      dealerSearch: "",
      dealerTitleData: "",
      loadingLabel: this.$i18n.translate("loading"),
      searchLabel: this.$i18n.translate("search"),
      selected: "",
      tokenUrl: "/api/v1/token",
    };
  },
  created: function () {

    this.dealerLabelName = this.dealerDefaultLabelName ?? this.dealerIdName;

    if (this.dealerLabel === "") {
      this.dealerLabelData = this.dealerDefaultLabel;
    }
    if (this.dealerTitle === "") {
      this.dealerTitleData = this.dealerDefaultTitle;
    }

    this.getTokenAndLoadList();

    if ('labelDealerName' in this.old) {
      this.dealerLabelValue = this.old.labelDealerName;
    }
    if (this.dealerName in this.old) {
      this.dealerFormValue = this.old[this.dealerName];
    }
  },
watch: {
  },
  methods: {
    /**
     *
     */
    async loadList() {
      this.dealerLoading = true;
      if (this.dealerFormValue !== "" && this.dealerLabelKey !== "") {
        this.dealerLabelValue = this.$i18n.translate("loading");
      }
      const data = await this.getData();
      if (data.dealer) {
        this.dealerHeaders = data.dealer.headers;
        this.dealerItems = data.dealer.items;
      }

      if (this.dealerFormValue !== "" && this.dealerLabelKey !== "") {
        const defaultItem = this.dealerItems.find(
          (row) => row[this.dealerIdName] === this.dealerFormValue
        );
        this.dealerLabelValue =
          typeof defaultItem !== "undefined"
            ? defaultItem[this.dealerLabelKey]
            : this.$i18n.translate("unknown");
      }

      this.dealerLoading = false;
    },
    /**
     *
     */
    getData() {
      return Promise.all([
        Axios.get(this.dealerUrl, {
          headers: {
            Authorization: this.bearer,
          },
        }),
      ])
        .then(([dealer]) => {
          const data = {};
          data.dealer = dealer.data;
          return Promise.resolve(data);
        })
        .catch((error) => {console.log(error);});
    },
    /**
     *
     * @param {Object} item
     */
    async selectDealerItem(item) {
      this.dealerFormValue = item[this.dealerIdName];
      this.dealerLabelValue = item[this.dealerLabelName];
      this.dealerDialog = false;
    },
    /**
     *
     */
    async getTokenAndLoadList() {
      this.bearer = await Token(this.tokenUrl);
      this.loadList();
    },
  },
};
</script>
