<template>
    <div class="col-xs-12 col-sm-2 col-md-2 py-0">
        <div class="mb-2">
            <osc-opportunity-upsert
                :api-token="apiToken"
                :estimate-id="estimateId"
                :opportunity-name="opportunityName"
                :opportunity-number="opportunityNumberValue"
                @opportunity-number="onUpdateOpportunityNumber"
                :sales-stage-id-list="salesStageIdList"
                ref="refUpsert"
            ></osc-opportunity-upsert>
        </div>
        <div class="py-2" v-if="isAdmin">
            <osc-opportunity-delete
                :api-token="apiToken"
                :estimate-id="estimateId"
                :opportunity-number="opportunityNumberValue"
                @opportunity-number="onUpdateOpportunityNumber"
                ref="refDelete"
            ></osc-opportunity-delete>
        </div>
    </div>
</template>

<script>
import Axios from "axios";

export default {
  props: {
    apiToken: {
        type: String,
        required: true,
    },
    estimateId: {
        type: Number,
        required: true,
    },
    isAdmin: {
        type: Boolean,
        default: false,
    },
    opportunityName: {
        type: String,
        required: true,
    },
    opportunityNumber: {
        type: Number,
        default: null,
    },
    salesStageIdList: {
        type: Array,
        required: true,
    }
  },
  data() {
    return {
        opportunityNumberValue: null,
    };
  },
  methods: {
    /**
     * 
     * @param {Number} num 
     */
    onUpdateOpportunityNumber(num) {
        this.opportunityNumberValue = num;
        this.$refs.refUpsert.updateOpportunityNumber(num);
        this.$refs.refDelete.updateOpportunityNumber(num);
    }
  },
  created: function () {
      this.opportunityNumberValue = this.opportunityNumber;
  }
};
</script>