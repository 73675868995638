<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="700">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on"> 日通送り状CSV </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          発送日・着日を指定してください
        </v-card-title>
        <div class="col-xs-12 col-sm-6 col-md-6 py-0">
          <label class="col-form-label">
            <span>発送日</span>
            <span class="badge badge-primary">必須</span>
          </label>
          <input
            type="date"
            class="form-control"
            v-model="deliveryDate"
            required
          />
          <label class="col-form-label">
            <span>着日</span>
            <span class="badge badge-primary">必須</span>
          </label>
          <input
            type="date"
            class="form-control"
            v-model="arrivalDate"
            required
          />
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <form @submit="onSubmit" :action="'/nittu_invoice/csv'" method="post">
            <input type="hidden" name="_token" :value="csrf" />
            <input
              type="hidden"
              name="delivery_date"
              :value="this.deliveryDate"
            />
            <input
              type="hidden"
              name="arrival_date"
              :value="this.arrivalDate"
            />
            <input type="hidden" name="ids[]" :value="this.estimateIds" />
            <v-btn type="submit" class="report-form">出力</v-btn>
          </form>
        </v-card-actions>
        <v-card-text> </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    token: {
      type: String,
      required: true,
    },
    estimateIds: {
      type: Array,
      default: {},
    },
    csrf: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      deliveryDate: "",
      arrivalDate: "",
    };
  },
  methods: {
    /**
     *
     */
    onSubmit() {
      if (!this.deliveryDate || this.deliveryDate.trim() === "") {
        this.deliveryDate = ""; // デフォルトの日付を設定
        alert(
          "発送日が設定されていないため、処理を中止します。入力してください。"
        );
        return; // 処理を中止
      }
      if (!this.arrivalDate || this.arrivalDate.trim() === "") {
        this.arrivalDate = ""; // デフォルトの日付を設定
        alert(
          "着日が設定されていないため、処理を中止します。入力してください。"
        );
        return; // 処理を中止
      }
    },
  },
};
</script>