<template>
  <div class="card p-4">
    <table class="hapilogi-table">
      <thead>
        <tr>
          <th>追加対象</th>
          <th>PDF対象</th>
          <th>内容物コード</th>
          <th>内容物</th>
          <th>数量</th>
          <th>チェック1</th>
          <th>チェック2</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, rowIndex) in items"
          :key="rowIndex"
          :class="getRowClasses(item)"
        >
          <td>
            <div class="form-check valid-invalid-checkbox">
              <input
                class="form-check-input"
                type="checkbox"
                name="valid_invalid_flag"
                v-model="item.valid_invalid_flag"
                :disabled="isFlagDisabled"
                @change="
                  updateValidInvalidFlag(item.id, item.valid_invalid_flag, item)
                "
              />
            </div>
          </td>
          <td>
            <div
              class="form-check output-pdf-checkbox"
              :class="{
                'ping-background': item.recheck,
                'blue-background': item.recheck && item.recheck2,
                'green-background': !item.recheck && !item.recheck2,
              }"
            >
              <input
                class="form-check-input"
                type="checkbox"
                name="is_output_pdf"
                v-model="item.is_output_pdf"
                :disabled="isPdfOutputDisabled"
                @change="updateIsOutputPdf(item.id, item.is_output_pdf)"
              />
            </div>
          </td>
          <td>{{ item.item_code }}</td>
          <td>{{ item.item_name }}</td>
          <td>
            <input
              v-model="item.quantity"
              type="number"
              name="quantity"
              :disabled="isQuantityDisabled"
              :readonly="isQuantityReadonly"
              class="form-control"
              @change="updateQuantity(item.id, item.quantity)"
            />
          </td>

          <td>
            <div class="form-check custom-checkbox">
              <input
                class="form-check-input"
                type="checkbox"
                name="recheck"
                v-model="item.recheck"
                :disabled="isRecheckFlagDisabled"
                @change="updateRecheck(item.id, item.recheck, item)"
              />
            </div>
          </td>
          <td>
            <div class="form-check custom-checkbox">
              <input
                class="form-check-input"
                type="checkbox"
                name="recheck2"
                v-model="item.recheck2"
                :disabled="isRecheck2FlagDisabled"
                @change="updateRecheck2(item.id, item.recheck2, item)"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Axios from "axios";

export default {
  props: {
    items: { type: Array, required: true },
    tableId: { type: String, required: true },
    status: { type: Number, required: true },
    token: { type: String, required: true },
  },
  data() {
    return {
      isFlagDisabled: this.status === 3 ? true : false,
      isRecheckFlagDisabled: this.status === 1 ? false : true,
      isRecheck2FlagDisabled: this.status === 2 ? false : true,
      isPdfOutputDisabled: this.status === 1 ? false : true,
      isQuantityDisabled: false,
      isQuantityReadonly: this.status === 3 ? true : false,
      validFlagCount: 0,
      pdfOuntputFlagCount: 0,
    };
  },
  computed: {
    checkedValidInvalidFlagCount() {
      return this.items.filter((item) => item.valid_invalid_flag).length;
    },
    checkedPdfOutputFlagCount() {
      return this.items.filter(
        (item) => item.valid_invalid_flag && item.is_output_pdf
      ).length;
    },
    getCheckboxClass(recheck, recheck2) {
      if (recheck) {
        return "ping-background";
      } else if (recheck && recheck2) {
        return "blue-background";
      } else {
        return "green-background";
      }
    },
  },
  watch: {
    items: {
      deep: true,
      handler(newItems, oldItems) {
        const newValidFlagCount = newItems.filter(
          (item) => item.valid_invalid_flag
        ).length;
        const oldValidFlagCount = oldItems.filter(
          (item) => item.valid_invalid_flag
        ).length;

        if (newValidFlagCount !== oldValidFlagCount) {
          this.validFlagCount = newValidFlagCount;
          this.$emit("valid-invalid-flag-updated", "watch", newValidFlagCount);

          const newPdfFlagCount = newItems.filter(
            (item) => item.valid_invalid_flag && item.is_output_pdf
          ).length;

          if (newPdfFlagCount !== this.pdfOuntputFlagCount) {
            this.pdfOuntputFlagCount = newPdfFlagCount;
            this.$emit("pdf-output-flag-updated", "watch", newPdfFlagCount);
          }
        }
      },
    },
  },
  mounted() {
    this.updateValidInvalidFlagCountOnLoad();
    this.updatePdfOuntputFlagCountOnLoad();
  },
  methods: {
    updateValidInvalidFlag(id, valid_invalid_flag, item) {
      this.handleValidInvalidFlagChange(item);
      Axios.patch(
        `/api/v1/hapilogi/valid_invalid_flag/update`,
        {
          id: id,
          valid_invalid_flag: valid_invalid_flag,
          series: this.tableId,
        },
        {
          headers: { Authorization: "Bearer " + this.token },
        }
      )
        .then(() => {
          this.$nextTick(() => {
            const updatedItemCount = this.items.filter(
              (item) => item.valid_invalid_flag
            ).length;
            if (updatedItemCount !== this.validFlagCount) {
              this.validFlagCount = updatedItemCount;
              this.$emit("valid-invalid-flag-updated", id, updatedItemCount);

              const updatedPdfFlagCount = this.items.filter(
                (item) => item.valid_invalid_flag && item.is_output_pdf
              ).length;
              if (updatedPdfFlagCount !== this.pdfOuntputFlagCount) {
                this.pdfOuntputFlagCount = updatedPdfFlagCount;
                this.$emit("pdf-output-flag-updated", id, updatedPdfFlagCount);
              }
            }
          });
        })
        .catch((error) => this.handleApiError(error));
    },
    updateQuantity(id, quantity) {
      Axios.patch(
        `/api/v1/hapilogi/quantity/update`,
        {
          id: id,
          quantity: quantity,
          series: this.tableId,
        },
        {
          headers: { Authorization: "Bearer " + this.token },
        }
      ).catch((error) => this.handleApiError(error));
    },
    updateRecheck(id, recheck, item) {
      this.handleRecheckChange(item);
      Axios.patch(
        `/api/v1/hapilogi/recheck/update`,
        {
          id: id,
          recheck: recheck,
          series: this.tableId,
        },
        {
          headers: { Authorization: "Bearer " + this.token },
        }
      )
        .then(() => {
          const itemToUpdate = this.items.find((item) => item.id === id);
          if (itemToUpdate) {
            itemToUpdate.recheck = recheck;
            this.$forceUpdate();
          }
        })
        .catch((error) => this.handleApiError(error));
    },
    updateRecheck2(id, recheck2, item) {
      this.handleRecheck2Change(item);
      Axios.patch(
        `/api/v1/hapilogi/recheck2/update`,
        {
          id: id,
          recheck2: recheck2,
          series: this.tableId,
        },
        {
          headers: { Authorization: "Bearer " + this.token },
        }
      )
        .then(() => {
          const itemToUpdate = this.items.find((item) => item.id === id);
          if (itemToUpdate) {
            itemToUpdate.recheck2 = recheck2;
            this.$forceUpdate();
          }
        })
        .catch((error) => this.handleApiError(error));
    },
    updateIsOutputPdf(id, is_output_pdf) {
      Axios.patch(
        `/api/v1/hapilogi/is_output_pdf/update`,
        {
          id: id,
          is_output_pdf: is_output_pdf,
          series: this.tableId,
        },
        {
          headers: { Authorization: "Bearer " + this.token },
        }
      )
        .then(() => {
          const itemToUpdate = this.items.find((item) => item.id === id);
          if (itemToUpdate) {
            itemToUpdate.is_output_pdf = is_output_pdf;
            this.$forceUpdate();
          }
        })
        .catch((error) => this.handleApiError(error));
    },
    updateValidInvalidFlagCountOnLoad() {
      this.validFlagCount = this.checkedValidInvalidFlagCount;
      this.$emit(
        "valid-invalid-flag-updated",
        { id: "load", flag: true },
        this.checkedValidInvalidFlagCount
      );
    },
    updatePdfOuntputFlagCountOnLoad() {
      this.pdfOuntputFlagCount = this.checkedPdfOutputFlagCount;
      this.$emit(
        "pdf-output-flag-updated",
        { id: "load", flag: true },
        this.checkedPdfOutputFlagCount
      );
    },
    handleValidInvalidFlagChange() {
      const parentRow = event.target.closest("tr");

      parentRow.classList.remove("light-blue-bg");
      parentRow.classList.remove("original-bg");

      if (event.target.checked) {
        parentRow.classList.add("light-blue-bg");
      } else {
        parentRow.classList.add("original-bg");
      }
    },

    handleRecheckChange(item) {
      const parentRow = event.target.closest("tr");

      if (event.target.checked) {
        parentRow.classList.remove("light-blue-bg");
        parentRow.classList.add("light-yellow-bg");
      } else {
        parentRow.classList.remove("light-yellow-bg");
        parentRow.classList.add("light-blue-bg");
      }
    },

    handleRecheck2Change(item) {
      const parentRow = event.target.closest("tr");

      if (event.target.checked) {
        parentRow.classList.remove("light-yellow-bg");
        parentRow.classList.add("original-bg");
      } else {
        parentRow.classList.remove("original-bg");
        parentRow.classList.add("light-yellow-bg");
      }
    },
    getRowClasses(item) {
      if (
        (item.valid_invalid_flag === 1 || item.valid_invalid_flag) &&
        (item.recheck === 0 || !item.recheck) &&
        (item.recheck2 === 0 || !item.recheck2)
      ) {
        return "light-blue-bg";
      } else if (
        (item.valid_invalid_flag === 1 || item.valid_invalid_flag) &&
        (item.recheck === 1 || item.recheck) &&
        (item.recheck2 === 0 || !item.recheck2)
      ) {
        return "light-yellow-bg";
      } else if (
        (item.valid_invalid_flag === 1 || item.valid_invalid_flag) &&
        (item.recheck === 1 || item.recheck) &&
        (item.recheck2 === 1 || item.recheck2)
      ) {
        return "original-bg";
      }
      return "original-bg";
    },
    handleApiError(error) {
      switch (error.response.status) {
        case 401:
          alert(
            "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
          );
          break;
        case 404:
          alert("接続先が見つかりませんでした。");
          break;
        case 422:
          alert(JSON.stringify(error.response.data.errors));
          break;
        case 500:
          alert("内部エラー");
          break;
        default:
          alert("通信エラーが発生しました、再度お試しください。");
          break;
      }
      console.log(error);
    },
  },
};
</script>

<style>
.hapilogi-table {
  width: auto;
  max-width: 100%;
  border-collapse: collapse;
}

.hapilogi-table thead {
  position: sticky;
  top: 0;
  background-color: #f0f0f0;
  z-index: 1;
}

.hapilogi-table th,
.hapilogi-table td {
  border: 1px solid #ccc;
  padding: 6px; /* パディングを小さく調整 */
  font-size: 14px; /* フォントサイズを調整 */
}

.light-yellow-bg {
  background-color: lightyellow;
}

.light-blue-bg {
  background-color: lightblue;
}

.original-bg {
  background-color: inherit; /* 背景色を元に戻す */
}

/* カスタムチェックボックスのスタイル */
.valid-invalid-checkbox .form-check-input {
  width: 30px; /* チェックボックスの幅を大きくする */
  height: 30px; /* チェックボックスの高さを大きくする */
}

/* チェックされたときのスタイル */
.valid-invalid-checkbox .form-check-input:checked {
  background-color: #007bff; /* チェックが付いた時の背景色 */
}

/* カスタムチェックボックスのスタイル */
.output-pdf-checkbox .form-check-input {
  width: 30px; /* チェックボックスの幅を大きくする */
  height: 30px; /* チェックボックスの高さを大きくする */
}

.ping-background .form-check-input:checked {
  background-color: lightpink; /* 任意のピンク色 */
}

.blue-background .form-check-input:checked {
  background-color: 007bff; /* 任意の青色 */
}

.green-background .form-check-input:checked {
  background-color: lightgreen; /* 任意の緑色 */
}
</style>


