<template>
    <v-card
        class="my-2"
        elevation="2"
        outlined
        color="light-blue lighten-5"
    >
      <v-toolbar flat>
        <v-toolbar-title>
          <h2 class="col-xs-12 col-sm-4 col-md-4 mt-2 py-0">
            {{ title }}
          </h2>
          <v-progress-circular
            v-if="connecting"
            :indeterminate="progress === 0"
            :value="progress"
            color="light-blue"
            />
        </v-toolbar-title>
      </v-toolbar>
      <v-tabs v-model="tab">
        <v-tab v-for="t in tabs" :key="t.name" :href="'#tab-' + t.name">
          {{ t.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item :value="'tab-一覧'">
            <v-card>
                <v-text-field
                v-model="search"
                append-icon="fa-search"
                :label="searchLabel"
                single-line
                hide-details
                />
                <v-data-table
                :headers="headers"
                :items="allocatedItems"
                class="elevation-1"
                :search="search"
                >
                <template #[`item.edit`]="{ item }">
                    <v-icon
                    small
                    @click="editItem(baseurl, item)"
                    >
                    fa-user-edit
                    </v-icon>
                </template>
                <template #[`item.delete`]="{ item }">
                    <form
                    :action="getDeleteUrl(baseurl, item)"
                    method="post"
                    >
                    <input
                        type="hidden"
                        name="_token"
                        :value="getCsrfToken()"
                    >
                    <input
                        type="hidden"
                        name="_method"
                        value="DELETE"
                    >
                    <v-btn
                        v-confirm="deleteConfirmationLabel"
                        type="submit"
                        icon
                        class="bg-gradient-danger shadow"
                    >
                        <i class="fas fa-user-slash" />
                    </v-btn>
                    </form>
                </template>
                </v-data-table>
            </v-card>
        </v-tab-item>
        <v-tab-item :value="'tab-未割当'">
            <v-card>
                <v-text-field
                    v-model="searchUnallocated"
                    append-icon="fa-search"
                    :label="searchLabel"
                    single-line
                    hide-details
                />
                <v-data-table
                :headers="kentemProductTableHeaders"
                :items="unallocatedItems"
                class="elevation-1"
                :search="searchUnallocated"
                >
                    <template #[`item.osc_product_name`]="{ item }">
                        <select
                            v-model="productNameEntry[item.kentem_product_code]"
                            :name="'osc_product_name' + item.kentem_product_code"
                            class="form-control"
                        >
                            <option
                                v-for="r in oscProductNameList"
                                :key="r.id"
                                :value="r.id"
                            >
                                {{ r.nickname !== null ? r.nickname : r.name }}
                            </option>
                        </select>
                    </template>
                    <template #[`item.apply`]="{ item }">
                        <v-icon
                            small
                            @click="applyOscProductChanges(item)"
                        >
                            fa-save
                        </v-icon>
                    </template>
                </v-data-table>
            </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
</template>

<script>
import Axios from "axios";

export default {
  props: {
    apiToken: {
        type: String,
        required: true,
    },
    baseurl: {
      type: String,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    kentemProductTableHeaders: {
      type: Array,
      required: true,
    },
    kentemProductTableItems: {
      type: Array,
      required: true,
    },
    oscProductNameList: {
        type: Array,
        required: true,
    },
    title: {
      type: String,
      required: true,
    },
    idName: {
      type: String,
      default: "id",
    },
  },
  data() {
    return {
      allocatedItems: [],
      connecting: false,
      deleteConfirmationLabel: this.$i18n.translate("delete ok?"),
      productNameEntry: {},
      progress: 100,
      search: "",
      searchUnallocated: "",
      searchLabel: this.$i18n.translate("search"),
      tab: null,
      tabs: [
          { name: "一覧" },
          { name: "未割当" },
      ],
      unallocatedItems: [],
    };
  },
  created() {
    this.allocatedItems = this.items;
    this.unallocatedItems = this.kentemProductTableItems;
  },
  methods: {
    /**
     * 
     * @param {Object} item 
     */
    applyOscProductChanges(item) {
        if (this.productNameEntry[item.kentem_product_code] === void(0)) {
            alert("OSC商品名が設定されていません");
            return;
        }

        this.connecting = true;
        this.progress = 0;
        Axios.post(
            `/api/v1/kentem_product_osc_information`,
            {
                kentem_product_id: item['kentem_product_code'],
                osc_product_name_id: this.productNameEntry[item.kentem_product_code],
            },
            {
            headers: {
                Authorization: 'Bearer ' + this.apiToken
            },
            }
        ).then((res) => {
                this.connecting = false;
                this.progress = 100;
                this.allocatedItems.push(res.data);
                this.unallocatedItems =
                  this.unallocatedItems.filter(i => i.kentem_product_code != item.kentem_product_code);
        }).catch((error) => {
                this.connecting = false;
                this.progress = 100;
                console.log(error);
                if (error.response.data.message) {
                alert(error.response.data.message);
                return;
                }
                switch (error.response.status) {
                    case 401:
                        alert("認証エラー");
                        break;
                    default:
                        alert(error);
                }
        });
    },
    /**
     * 
     * @param {String} baseurl 
     * @param {Object} item 
     */
    detailItem(baseurl, item) {
      location.href = "/" + baseurl + "/" + item[this.idName];
    },

    /**
     * 
     * @param {String} baseurl 
     * @param {*} item 
     */
    editItem(baseurl, item) {
      location.href = "/" + baseurl + "/" + item[this.idName] + "/edit";
    },

    /**
     * 
     * @param {Object} item 
     */
    isViewableItem(item) {
      return item.view;
    },

    /**
     * 
     * @param {Object} item 
     */
    isEditableItem(item) {
      return item.edit;
    },

    /**
     * 
     * @param {Object} item 
     */
    isDeletableItem(item) {
      return item.delete;
    },

    /**
     * 
     */
    getCsrfToken() {
      return document.getElementsByName("csrf-token")[0].content;
    },

    /**
     * 
     * @param {String} baseurl 
     */
    getCreateUrl(baseurl) {
      return "/" + baseurl + "/create";
    },

    /**
     * 
     * @param {String} baseurl 
     * @param {Object} item 
     */
    getDeleteUrl(baseurl, item) {
      return "/" + baseurl + "/" + item[this.idName];
    },
  },
};
</script>