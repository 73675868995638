<template>
  <div class="text-center">
    <v-progress-circular
      v-if="downloading"
      :indeterminate="progress === 0"
      :value="progress"
      color="light-blue"
    />
    <template v-if="progress === 100 && contents">
      <v-container>
            <v-btn
              rounded
              color="primary"
              @click="openOsc()"
            >
              OSC
            </v-btn>
            <v-btn
              rounded
              color="primary"
              @click="openKentemDetail()"
            >
              顧客・販売店情報
            </v-btn>
            <v-btn
              rounded
              color="primary"
              @click="openCloudDetail(contents.data.shokon_code)"
            >
              クラウド詳細
            </v-btn>
            <v-btn
              rounded
              color="primary"
              @click="openUserDB(contents.data.shokon_code)"
            >
              ユーザーDB
            </v-btn>
            <div class="py-2" v-if="isProductionManagement">
              <v-btn
                rounded
                color="primary"
                @click="openUserPortal(contents.data.shokon_code)"
              >
              統合ページ
              </v-btn>
            </div>
      </v-container>
    </template>
  </div>
</template>

<script>
import Axios from "axios";
import Token from "./token";

export default {
  props: {
    kentemId: {
      type: String,
      required: true,
    },
    kentemIdUrl: {
      type: String,
      default: "https://id.kentem.com",
    },
    apiToken: {
        type: String,
        required: true,
    },
    isProductionManagement: {
        type: Boolean,
        default: false,
    },
  },
  data() {
    return {
      contents: "",
      downloading: true,
      progress: 0,
      baseUrl: this.KentemIdUrl + "/",
      url: this.kentemIdUrl + "/api/v1/kentem/detail/",
      tokenUrl: "/api/v1/token",
      bearer: "",
      customerPartyId: "",
    };
  },
  watch: {
    kentemId: function (newKentemId) {
      this.url = "/api/v1/kentem/detail/";
      this.url = this.url + newKentemId;
      this.getTokenAndLoadList();
    },
  },
  created: function () {
    this.url = this.url + this.kentemId;
    this.getTokenAndLoadList();
  },

  methods: {
    /**
     * 
     */
    async loadList() {
      await Axios.get(this.url, {
        onDownloadProgress: this.onDownload,
        headers: {
          Authorization: this.bearer,
        },
      })
        .then((res) => {
          if (res.data) {
            this.contents = res.data;
            this.getKentemInfo(this.kentemId);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.downloading = false;
      this.progress = 100;

      Object.keys(this.contents.data.reference_numbers).forEach(
        (key) => {
          if (this.contents.data.reference_numbers[key].dekispart) {
            console.log(this.contents.data.reference_numbers[key].dekispart.products);
            this.contents.data.reference_numbers[key].dekispart.products.sort(this.compareDekispartProducts);
          }
        }
      )
    },

    /**
     * 
     * @param {Object} e 
     */
    onDownload(e) {
      this.progress = Math.floor((e.loaded * 100) / e.total);
    },

    /**
     * 
     */
    async getTokenAndLoadList() {
      this.bearer = await Token(this.tokenUrl);
      this.loadList();
    },

    /**
     * 
     * @param {String} id 
     */
    getKentemInfo(id) {
      Axios.get(`/api/v1/osc/kentem_info/${id}`, {
        headers: {
          Authorization: 'Bearer ' + this.apiToken,
        },
        }
        ).then((res) => {
        if(res.data){
             this.customerPartyId = res.data.PartyId ?? ""
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    /**
     * 
     */
    openKentemDetail() {
      const url = '/kentem_information/' + this.kentemId
      window.open(url, '_blank')
    },
    /**
     * 
     * @param {String} shokonCode 
     */
    openUserDB(shokonCode) {
      const url = 'http://ks-db.kentem.net/userdb/userdb2.php?scode=' + shokonCode + '&slist=3&rlist=2'
      window.open(url, '_blank')
    },
    /**
     * 
     * @param {String} shokonCode 
     */
    openCloudDetail(shokonCode) {
      const url = 'http://adks-cloud.kentem.net/Customer/' + shokonCode
      window.open(url, '_blank')
    },
    openOsc() {
      const url = 'https://fa-epck-saasfaprod1.fa.ocs.oraclecloud.com/crmUI/faces/FuseOverview?fndGlobalItemNodeId=itemNode_workspace_workspace&fndTaskItemNodeId=ZCA_CC_INTERACTIVE_UI_CRM&fnd=%3BentityName%253DAccount%253BsubTabName%253DOverview%253BrecordId%253D' + this.customerPartyId + '%253B%3B%3B%3Bfalse%3B256%3B%3B%3B'
      window.open(url, '_blank')
    },
    openUserPortal(shokonCode) {
      const url = 'http://cs-web.kentem.net/usrportal/index.php?stdNamCode=' + shokonCode
      window.open(url, '_blank')
    },
  },
};
</script>