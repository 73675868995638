<template>
  <div>
    <div class="form-row" v-if="segmentId!==10">
      <!-- プラン選択 -->
      <v-col cols="12" sm="6">
        <label class="col-form-label">プラン</label>
        <v-select
          v-model="selectedPlan"
          :options="planListForSelect"
        ></v-select>
        <input type="hidden" name="plan" :value="selectedPlan.value">
        <input type="hidden" name="plan_name" :value="selectedPlan.label">
      </v-col>
    </div>
    <div class="form-row">
      <!-- 期間（日） -->
      <v-col cols="6" sm="3">
        <label class="col-form-label">期間（日）</label>
        <v-select
          v-model="localPeriodDay"
          :options="dayOptions"
          :disabled="isPeriodDayDisabled"
          outlined
          dense
        ></v-select>
        <input type="hidden" name="period_day" :value="localPeriodDay">
      </v-col>
      <!-- 期間（月） -->
      <v-col cols="6" sm="3">
        <label class="col-form-label">期間（月）</label>
        <v-text-field
          v-model="localPeriodMonth"
          type="number"
          suffix="月"
          :disabled="isPeriodMonthDisabled"
          :name="periodMonthName"
          outlined
          dense
          :min="(this.segmentId===9 ? 6 : 1)"
          clearable
          ></v-text-field>
      </v-col>
    </div>
    <div class="form-row">
      <!-- 延長期間（日） -->
      <v-col cols="6" sm="3">
        <label class="col-form-label">延長期間（日）</label>
        <v-select
          v-model="localPeriodDayEx"
          :options="dayOptions"
          :disabled="isPeriodDayExDisabled"
          outlined
          dense
        ></v-select>
        <input type="hidden" name="period_day_ex" :value="localPeriodDayEx">
      </v-col>
      <!-- 延長期間（月） -->
      <v-col cols="6" sm="3">
        <label class="col-form-label">延長期間（月）</label>
        <v-text-field
          v-model="localPeriodMonthEx"
          type="number"
          suffix="月"
          :disabled="isPeriodMonthExDisabled"
          :name="periodMonthExName"
          outlined
          dense
          :min="(this.segmentId===9 ? 6 : 1)"
          clearable
          ></v-text-field>
      </v-col>
    </div>
    <div class="form-row">
      <!-- iPadの場合は、Vue側の処理で出荷日=開始日とする -->
      <!-- 出荷日 -->
      <v-col v-if="(this.segmentId!==10)" cols="6" sm="6">
        <v-text-field
          v-model="shipmentDate"
          name="shipment_date"
          label="出荷日"
          readonly
          outlined
          dense
        ></v-text-field>
        <input type="hidden" name="shipment_date" :value="shipmentDate">
      </v-col>
    </div>
    <div class="form-row">
      <!-- 開始日 -->
      <v-col cols="6" sm="3">
        <datepicker-field
          :value.sync="localStartDate"
          :token="token"
          :label="startDateLabel"
          name="start_date"
          kentem-holidays
          clearable
          outlined
          dense
        ></datepicker-field>
        <input v-if="(this.segmentId===10)" type="hidden" name="shipment_date" :value="localStartDate">
      </v-col>
      <!-- 終了予定日 -->
      <v-col cols="6" sm="3">
        <v-text-field
          v-model="calculatedEndDate"
          :label="endDateLabel"
          name="end_date"
          readonly
          outlined
          dense
        ></v-text-field>
      </v-col>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  props: {
    mode: {
      type: String,
      required: true,
    },
    plan: {
      type: Object,
      default: () => ({ value: 0, label: '未選択' }),
    },
    planListForSelect: {
      type: Array,
      required: true,
    },
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
    shipmentDate: {
      type: String,
      default: "",
    },
    periodDay: {
      type: Number,
      default: 0,
    },
    periodMonth: {
      type: Number,
      default: 0,
    },
    periodDayEx: {
      type: Number,
      default: 0,
    },
    periodMonthEx: {
      type: Number,
      default: 0,
    },
    token: {
      type: String,
      default: "",
    },
    startDateLabel: {
      type: String,
      default: "",
    },
    endDateLabel: {
      type: String,
      default: "",
    },
    shipmentDateLabel: {
      type: String,
      default: "",
    },
    segmentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedPlan: this.plan,
      localPeriodDay: this.periodDay,
      localPeriodMonth: this.periodMonth,
      localPeriodDayEx: this.periodDayEx,
      localPeriodMonthEx: this.periodMonthEx,
      localStartDate: this.startDate,
      dayOptions: [0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300],
      periodDayName: "period_day",
      periodMonthName: "period_month",
      periodDayExName: "period_day_ex",
      periodMonthExName: "period_month_ex",
    };
  },
  computed: {
    isPeriodDayDisabled() {
      if(this.segmentId === 10){
        return true;
      }else{
        return this.selectedPlan.value !== 1;
      }
    },
    isPeriodMonthDisabled() {
      if(this.segmentId === 10){
        return false;
      }else{
        return this.selectedPlan.value !== 2;
      }
    },
    isPeriodDayExDisabled() {
      if(this.segmentId === 10){
        return true;
      }else{
        return this.selectedPlan.value !== 1;
      }
    },
    isPeriodMonthExDisabled() {
      if(this.segmentId === 10){
        return false;
      }else{
        return this.selectedPlan.value !== 2;
      }
    },
    calculatedEndDate() {
      if (!this.localStartDate) return '';
      // 開始日をmomentオブジェクトに変換
      let endDate = moment(this.localStartDate);
      // 期間（日）を加算
      if (this.localPeriodDay) {
        endDate = endDate.add(this.localPeriodDay - 1, 'days');
      }
      // 期間（月）を加算
      if (this.localPeriodMonth) {
        // 開始日から利用期間分の月を加算し、その月の末日を取得
        endDate = endDate.add(this.localPeriodMonth, 'months').endOf('month');
      }
      // 延長期間（日）を加算
      if (this.localPeriodDayEx) {
        endDate = endDate.add(this.localPeriodDayEx, 'days');
      }
      // 延長期間（月）を加算
      if (this.localPeriodMonthEx) {
        endDate = endDate.add(this.localPeriodMonthEx, 'months').endOf('month');
      }
      return endDate.format('YYYY-MM-DD');
    },
  },
  methods: {
    validatePositiveInteger(v) {
      return (v === "" || v > 0) || '1以上の数値を入力してください';
    },
  },
};
</script>
<style scoped>
.form-row{
  .v-input--is-disabled .v-input__slot{
    *{
      cursor: not-allowed!important;
    }
    background: #f8f8f8!important;
  }
}
</style>