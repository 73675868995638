<template>
  <form
    method="post"
    @submit.prevent="callback"
  >
    <div class="col-xs-12 col-sm-6 col-md-6">
      <input
        type="hidden"
        name="number"
        value="1"
      >
      <input
        type="hidden"
        name="estimate_id"
        :value="estimateId || ''"
      >
      <input
        type="hidden"
        name="kentem_id"
        :value="kentemId || ''"
      >
      <input
        type="hidden"
        name="_token"
        :value="csrf"
      >
      <div class="row col-xs-12 col-sm-6 col-md-6">
        <button
          class="btn btn-primary bg-gradient-primary btn-block"
          type="submit"
        >
          {{ $t(buttonLabel) }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    buttonLabel: {
      type: String,
      required: false,
      default: "Register",
    },
    callback: {
      type: Function,
      require: true,
      default: () => 1,
    },
    csrf: {
      type: String,
      required: true,
    },
    estimateId: {
      type: Number,
      default: null,
    },
    kentemId: {
      type: String,
      required: true,
    },
  },
};
</script>
