<template>
  <div class="col-xs-12 col-sm-6 col-md-6">
    <div class="form-group">
      <label
        :for="id"
        class="col-form-label"
      >
        <span>枝番</span>
        <span class="badge badge-primary">必須</span>
      </label>
      <select
        :id="id"
        :name="name"
        class="form-control"
        v-model="managementSubCode"
      >
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
        >
          {{ option.text }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import Token from "../token";

export default {
    props: {
        callbackCreated: {
            type: Function,
            require: true,
            default: () => 1,
        },
        id: {
            type: String,
            default: "management_sub_code_kaisoku_switch",
        },
        kentemIdUrl: {
            type: String,
            default: "https://id.kentem.com",
        },
        name: {
            type: String,
            default: "management_sub_code",
        },
    },
    data() {
        return {
            bearer: "",
            cloudId: "",
            loading: false,
            options: [],
            tokenUrl: "/api/v1/token",
            managementSubCode:""
        };
    },
    watch: {
        /**
         * 
         * @param {String} val 
         */
        cloudId: function (val) {
            this.options = [];
            if (val === "" || val === "新規ID") {
                return;
            }
            if (this.bearer === "") {
                return;
            }
            this.loading = true;

            Axios.get(this.kentemIdUrl + `/api/v1/cloud/detail/${val}`, {
                headers: {
                    Authorization: this.bearer,
                },
            })
                .then((res) => {
                    this.loading = false;
                    if (res.data) {
                        res.data.data.kaisoku_navi.filter(k => k.edition === "STD").forEach((k) => {
                            this.options.push({
                                'text': 'K'+ k.management_sub_code.padStart(4, "0"),
                                'value': k.management_sub_code,
                            });
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
            return;
        }
    },
    /**
     * 
     */
    async created() {
        this.loading = true;
        this.bearer = await Token(this.tokenUrl);
        this.loading = false;
        this.callbackCreated();
    },
};
</script>
